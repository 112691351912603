export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AcceptBanks = {
  __typename?: 'AcceptBanks';
  /** Bank ID */
  id: Scalars['Int'];
  /** Bank's Logo */
  image_url?: Maybe<Scalars['String']>;
  /** Bank name */
  name: Scalars['String'];
  /** Installment plans for bank */
  plans?: Maybe<Array<Maybe<InstallmentPlans>>>;
};

export type AcceptOrdersOutput = {
  __typename?: 'AcceptOrdersOutput';
  orders: Array<Maybe<CustomerOrder>>;
};

/** Defines the input required to run the addGiftMessageForOrderItems mutation */
export type AddBundlePackToCartInput = {
  /** The unique ID that identifies the bundle */
  bundle_id: Scalars['String'];
  bundle_quantity: Scalars['String'];
  cart_id: Scalars['String'];
  main_product_id: Scalars['String'];
  /** Array of selected products of the bundle */
  related_products_ids: Array<Maybe<BundleRelatedProductIdsInput>>;
};

export type AddBundlePackToCartOutput = {
  __typename?: 'AddBundlePackToCartOutput';
  cart?: Maybe<Cart>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
};

/** Defines the bundle products to add to the cart. */
export type AddBundleProductsToCartInput = {
  /** The ID of the cart. */
  cart_id: Scalars['String'];
  /** An array of bundle products to add. */
  cart_items: Array<Maybe<BundleProductCartItemInput>>;
};

/** Contains details about the cart after adding bundle products. */
export type AddBundleProductsToCartOutput = {
  __typename?: 'AddBundleProductsToCartOutput';
  /** The cart after adding products. */
  cart: Cart;
};

/** Defines the configurable products to add to the cart. */
export type AddConfigurableProductsToCartInput = {
  /** The ID of the cart. */
  cart_id: Scalars['String'];
  /** An array of configurable products to add. */
  cart_items: Array<Maybe<ConfigurableProductCartItemInput>>;
};

/** Contains details about the cart after adding configurable products. */
export type AddConfigurableProductsToCartOutput = {
  __typename?: 'AddConfigurableProductsToCartOutput';
  /** The cart after adding products. */
  cart: Cart;
};

export type AddDownloadableProductsToCartInput = {
  /** The ID of the cart. */
  cart_id: Scalars['String'];
  /** An array of downloadable products to add. */
  cart_items: Array<Maybe<DownloadableProductCartItemInput>>;
};

/** Contains details about the cart after adding downloadable products. */
export type AddDownloadableProductsToCartOutput = {
  __typename?: 'AddDownloadableProductsToCartOutput';
  /** The cart after adding products. */
  cart: Cart;
};

/** Contains details about the cart after adding products to it. */
export type AddProductsToCartOutput = {
  __typename?: 'AddProductsToCartOutput';
  /** The cart after products have been added. */
  cart: Cart;
  /** Contains errors encountered while adding an item to the cart. */
  user_errors: Array<Maybe<CartUserInputError>>;
};

/** Contains products to add to an existing compare list. */
export type AddProductsToCompareListInput = {
  /** An array of product IDs to add to the compare list. */
  products: Array<Maybe<Scalars['ID']>>;
  /** The unique identifier of the compare list to modify. */
  uid: Scalars['ID'];
};

/** Contains the customer's wish list and any errors encountered. */
export type AddProductsToWishlistOutput = {
  __typename?: 'AddProductsToWishlistOutput';
  /** An array of errors encountered while adding products to a wish list. */
  user_errors: Array<Maybe<WishListUserInputError>>;
  /** Contains the wish list with all items that were successfully added. */
  wishlist: Wishlist;
};

/** Defines the simple and group products to add to the cart. */
export type AddSimpleProductsToCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** An array of simple and group items to add. */
  cart_items: Array<Maybe<SimpleProductCartItemInput>>;
};

/** Contains details about the cart after adding simple or group products. */
export type AddSimpleProductsToCartOutput = {
  __typename?: 'AddSimpleProductsToCartOutput';
  /** The cart after adding products. */
  cart: Cart;
};

/** Defines the virtual products to add to the cart. */
export type AddVirtualProductsToCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** An array of virtual products to add. */
  cart_items: Array<Maybe<VirtualProductCartItemInput>>;
};

/** Contains details about the cart after adding virtual products. */
export type AddVirtualProductsToCartOutput = {
  __typename?: 'AddVirtualProductsToCartOutput';
  /** The cart after adding products. */
  cart: Cart;
};

/** Contains the resultant wish list and any error information. */
export type AddWishlistItemsToCartOutput = {
  __typename?: 'AddWishlistItemsToCartOutput';
  /** An array of errors encountered while adding products to the customer's cart. */
  add_wishlist_items_to_cart_user_errors: Array<Maybe<WishlistCartUserInputError>>;
  /** Indicates whether the attempt to add items to the customer's cart was successful. */
  status: Scalars['Boolean'];
  /** Contains the wish list with all items that were successfully added. */
  wishlist: Wishlist;
};

export type AddressFilterInput = {
  is_active?: Maybe<Scalars['Boolean']>;
};

export type AddressInfo = {
  __typename?: 'AddressInfo';
  customer_address_details?: Maybe<Scalars['String']>;
  customer_address_nearest_landmark?: Maybe<Scalars['String']>;
  customer_building_number?: Maybe<Scalars['Int']>;
  customer_building_type?: Maybe<Scalars['Int']>;
  customer_city_id?: Maybe<Scalars['Int']>;
  customer_governorate_id?: Maybe<Scalars['Int']>;
  years_of_residance?: Maybe<Scalars['Int']>;
};

export type AddressInfoInput = {
  customer_address_details?: Maybe<Scalars['String']>;
  customer_address_nearest_landmark?: Maybe<Scalars['String']>;
  customer_building_number?: Maybe<Scalars['Int']>;
  customer_building_type?: Maybe<Scalars['Int']>;
  customer_city_id?: Maybe<Scalars['Int']>;
  customer_governorate_id?: Maybe<Scalars['Int']>;
  years_of_residance?: Maybe<Scalars['Int']>;
};

/** Admin defines the admin name and address and other details */
export type Admin = {
  __typename?: 'Admin';
  /** Timestamp indicating when the account was created */
  created?: Maybe<Scalars['String']>;
  /** The admin's email address. Required */
  email?: Maybe<Scalars['String']>;
  /** The admin's first name */
  firstname?: Maybe<Scalars['String']>;
  /** The admin's status. Required */
  isActive?: Maybe<Scalars['String']>;
  /** The admin's family name */
  lastname?: Maybe<Scalars['String']>;
  /** The admin's role. */
  role?: Maybe<AdminRole>;
  /** The admin's username. Required */
  username?: Maybe<Scalars['String']>;
};

export type AdminCustomerOrdersFilterInput = {
  /** Filters by order stock source. */
  source_code?: Maybe<FilterStringTypeInput>;
};

export type AdminOrderSourceOnCartInput = {
  cart_id: Scalars['String'];
  customer_id?: Maybe<Scalars['Int']>;
  order_source: Scalars['String'];
};

export type AdminRole = {
  __typename?: 'AdminRole';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type AdminToken = {
  __typename?: 'AdminToken';
  /** The admin refresh token */
  refresh_token: Scalars['String'];
  /** The admin token */
  token: Scalars['String'];
};

/** Contains information for each filterable option (such as price, category `UID`, and custom attributes). */
export type Aggregation = {
  __typename?: 'Aggregation';
  /** Attribute code of the aggregation group. */
  attribute_code: Scalars['String'];
  /** The number of options in the aggregation group. */
  count?: Maybe<Scalars['Int']>;
  /** The aggregation display name. */
  label?: Maybe<Scalars['String']>;
  /** Array of options for the aggregation. */
  options?: Maybe<Array<Maybe<AggregationOption>>>;
  /** The relative position of the attribute in a layered navigation block. */
  position?: Maybe<Scalars['Int']>;
};

/** An implementation of `AggregationOptionInterface`. */
export type AggregationOption = AggregationOptionInterface & {
  __typename?: 'AggregationOption';
  /** The number of items that match the aggregation option. */
  count?: Maybe<Scalars['Int']>;
  /** The display label for an aggregation option. */
  label?: Maybe<Scalars['String']>;
  /** The internal ID that represents the value of the option. */
  value: Scalars['String'];
};

/** Defines aggregation option fields. */
export type AggregationOptionInterface = {
  /** The number of items that match the aggregation option. */
  count?: Maybe<Scalars['Int']>;
  /** The display label for an aggregation option. */
  label?: Maybe<Scalars['String']>;
  /** The internal ID that represents the value of the option. */
  value: Scalars['String'];
};

/** Filter category aggregations in layered navigation. */
export type AggregationsCategoryFilterInput = {
  /** Indicates whether to include only direct subcategories or all children categories at all levels. */
  includeDirectChildrenOnly?: Maybe<Scalars['Boolean']>;
};

/** An input object that specifies the filters used in product aggregations. */
export type AggregationsFilterInput = {
  /** Filter category aggregations in layered navigation. */
  category?: Maybe<AggregationsCategoryFilterInput>;
};

export type AheadworksBlogPost = {
  __typename?: 'AheadworksBlogPost';
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type AmastyBlogPost = {
  __typename?: 'AmastyBlogPost';
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type AmastyFaqQuestion = {
  __typename?: 'AmastyFaqQuestion';
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type AmsStoreLocator = {
  __typename?: 'AmsStoreLocator';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<City>;
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  installments_store_id?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  lng?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  sources?: Maybe<Array<Maybe<AmsStoreLocatorSources>>>;
  status?: Maybe<Scalars['String']>;
  store?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type AmsStoreLocatorOutput = {
  __typename?: 'AmsStoreLocatorOutput';
  items: Array<Maybe<AmsStoreLocator>>;
  /** An object that includes the current_page, page_info, and page_size values specified in the query */
  page_info?: Maybe<SearchResultPageInfo>;
  /** The total count of store locators */
  total_count?: Maybe<Scalars['Int']>;
};

export type AmsStoreLocatorSources = {
  __typename?: 'AmsStoreLocatorSources';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Contains the applied coupon code. */
export type AppliedCoupon = {
  __typename?: 'AppliedCoupon';
  /** The coupon code the shopper applied to the card. */
  code: Scalars['String'];
};

/** Specifies the coupon code to apply to the cart. */
export type ApplyCouponToCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** A valid coupon code. */
  coupon_code: Scalars['String'];
};

/** Contains details about the cart after applying a coupon. */
export type ApplyCouponToCartOutput = {
  __typename?: 'ApplyCouponToCartOutput';
  /** The cart after applying a coupon. */
  cart: Cart;
};

/** AreaInput defines the parameters which will be used for filter by specified location. */
export type AreaInput = {
  /** The radius for the search in KM. */
  radius: Scalars['Int'];
  /** The country code where search must be performed. Required parameter together with region, city or postcode. */
  search_term: Scalars['String'];
};

/** Contains the results of the request to assign a compare list. */
export type AssignCompareListToCustomerOutput = {
  __typename?: 'AssignCompareListToCustomerOutput';
  /** The contents of the customer's compare list. */
  compare_list?: Maybe<CompareList>;
  /** Indicates whether the compare list was successfully assigned to the customer. */
  result: Scalars['Boolean'];
};

/** Contains details about the attribute, including the code and type. */
export type Attribute = {
  __typename?: 'Attribute';
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attribute_code?: Maybe<Scalars['String']>;
  /** Attribute options list. */
  attribute_options?: Maybe<Array<Maybe<AttributeOption>>>;
  /** The data type of the attribute. */
  attribute_type?: Maybe<Scalars['String']>;
  /** The type of entity that defines the attribute. */
  entity_type?: Maybe<Scalars['String']>;
  /** The frontend input type of the attribute. */
  input_type?: Maybe<Scalars['String']>;
  /** Details about the storefront properties configured for the attribute. */
  storefront_properties?: Maybe<StorefrontProperties>;
};

/** Defines the attribute characteristics to search for the `attribute_code` and `entity_type` to search. */
export type AttributeInput = {
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attribute_code?: Maybe<Scalars['String']>;
  /** The type of entity that defines the attribute. */
  entity_type?: Maybe<Scalars['String']>;
};

/** Defines an attribute option. */
export type AttributeOption = {
  __typename?: 'AttributeOption';
  /** The label assigned to the attribute option. */
  label?: Maybe<Scalars['String']>;
  /** The attribute option value. */
  value?: Maybe<Scalars['String']>;
};

export type AvailableFeatureFlag = {
  __typename?: 'AvailableFeatureFlag';
  enabled?: Maybe<Scalars['Boolean']>;
  feature_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

/** Describes a payment method that the shopper can use to pay for the order. */
export type AvailablePaymentMethod = {
  __typename?: 'AvailablePaymentMethod';
  /** The payment method code. */
  code: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  payment_fee: Scalars['Float'];
  /** The payment method title. */
  title: Scalars['String'];
};

/** Contains details about the possible shipping methods and carriers. */
export type AvailableShippingMethod = {
  __typename?: 'AvailableShippingMethod';
  /** The cost of shipping using this shipping method. */
  amount: Money;
  /** Indicates whether this shipping method can be applied to the cart. */
  available: Scalars['Boolean'];
  /** @deprecated The field should not be used on the storefront. */
  base_amount?: Maybe<Money>;
  /** A string that identifies a commercial carrier or an offline shipping method. */
  carrier_code: Scalars['String'];
  /** The label for the carrier code. */
  carrier_title: Scalars['String'];
  /** Describes an error condition. */
  error_message?: Maybe<Scalars['String']>;
  estimated_delivery_date?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  /** A shipping method code associated with a carrier. The value could be null if no method is available. */
  method_code?: Maybe<Scalars['String']>;
  /** The label for the shipping method code. The value could be null if no method is available. */
  method_title?: Maybe<Scalars['String']>;
  /** The cost of shipping using this shipping method, excluding tax. */
  price_excl_tax: Money;
  /** The cost of shipping using this shipping method, including tax. */
  price_incl_tax: Money;
};

export type AvailableVoucher = {
  __typename?: 'AvailableVoucher';
  id?: Maybe<Scalars['Int']>;
  percentage?: Maybe<Scalars['Float']>;
  voucher?: Maybe<Scalars['String']>;
};

export enum BatchMutationStatus {
  Failure = 'FAILURE',
  MixedResults = 'MIXED_RESULTS',
  Success = 'SUCCESS'
}

export enum BestSellersPeriods {
  Day = 'DAY',
  Month = 'MONTH',
  Year = 'YEAR'
}

/** Defines the billing address. */
export type BillingAddressInput = {
  /** Defines a billing address. */
  address?: Maybe<CartAddressInput>;
  /** An ID from the customer's address book that uniquely identifies the address to be used for billing. */
  customer_address_id?: Maybe<Scalars['Int']>;
  /** Indicates whether to set the billing address to be the same as the existing shipping address on the cart. */
  same_as_shipping?: Maybe<Scalars['Boolean']>;
  /** Indicates whether to set the shipping address to be the same as this billing address. */
  use_for_shipping?: Maybe<Scalars['Boolean']>;
};

/** Contains details about the billing address. */
export type BillingCartAddress = CartAddressInterface & {
  __typename?: 'BillingCartAddress';
  address_name?: Maybe<Scalars['String']>;
  apartment?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  /** An object containing the city name, city code, and city ID */
  city?: Maybe<City>;
  /** The company specified for the billing or shipping address. */
  company?: Maybe<Scalars['String']>;
  /** An object containing the country name, country code, and country ID */
  country?: Maybe<Country>;
  /** @deprecated The field is used only in shipping address. */
  customer_notes?: Maybe<Scalars['String']>;
  /** The first name of the customer or guest. */
  firstname: Scalars['String'];
  floor?: Maybe<Scalars['Int']>;
  /** The last name of the customer or guest. */
  lastname: Scalars['String'];
  /** The ZIP or postal code of the billing or shipping address. */
  postcode?: Maybe<Scalars['String']>;
  region: Region;
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']>>;
  /** The telephone number for the billing or shipping address. */
  telephone?: Maybe<Scalars['String']>;
  /** The unique id of the customer address. */
  uid: Scalars['String'];
  /** The VAT company number for billing or shipping address. */
  vat_id?: Maybe<Scalars['String']>;
};

export type Bins = {
  __typename?: 'Bins';
  bin?: Maybe<Scalars['String']>;
  card_brand_code?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  currency_code?: Maybe<Scalars['String']>;
};

export type Block = {
  __typename?: 'Block';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image_url?: Maybe<Scalars['String']>;
  is_active: Scalars['Boolean'];
  is_support_video?: Maybe<Scalars['Boolean']>;
  manual_name?: Maybe<Scalars['String']>;
  manual_url?: Maybe<Scalars['String']>;
  mobile_image_url?: Maybe<Scalars['String']>;
  position: Scalars['String'];
  responsive_image_url?: Maybe<Scalars['String']>;
  sort: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  video_url?: Maybe<Scalars['String']>;
};

export type BraintreeCcVaultInput = {
  device_data?: Maybe<Scalars['String']>;
  public_hash: Scalars['String'];
};

export type BraintreeInput = {
  /** Contains a fingerprint provided by Braintree JS SDK and should be sent with sale transaction details to the Braintree payment gateway. */
  device_data?: Maybe<Scalars['String']>;
  /** States whether an entered by a customer credit/debit card should be tokenized for later usage. Required only if Vault is enabled for Braintree payment integration. */
  is_active_payment_token_enabler: Scalars['Boolean'];
  /** The one-time payment token generated by Braintree payment gateway based on card details. Required field to make sale transaction. */
  payment_method_nonce: Scalars['String'];
};

export type Brand = {
  __typename?: 'Brand';
  footer_content?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image_alt_value?: Maybe<Scalars['String']>;
  image_url?: Maybe<Scalars['String']>;
  is_brand_page?: Maybe<Scalars['Boolean']>;
  is_in_slider?: Maybe<Scalars['Boolean']>;
  is_shown_in_homepage?: Maybe<Scalars['Int']>;
  lower_category_id?: Maybe<Scalars['Int']>;
  lower_category_name?: Maybe<Scalars['String']>;
  lower_category_url_key?: Maybe<Scalars['String']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** The brand offers. */
  offers: Array<Maybe<BrandOffer>>;
  slider_sort_order?: Maybe<Scalars['Int']>;
  upper_category_id?: Maybe<Scalars['Int']>;
  upper_category_name?: Maybe<Scalars['String']>;
  upper_category_url_key?: Maybe<Scalars['String']>;
  url_key?: Maybe<Scalars['String']>;
};


export type BrandOffersArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export type BrandAttributeFilterInput = {
  is_brand_page?: Maybe<Scalars['Boolean']>;
  is_in_slider?: Maybe<Scalars['Boolean']>;
  is_shown_in_homepage?: Maybe<Scalars['Int']>;
  name?: Maybe<BrandNameMatchTypeInput>;
  url_key?: Maybe<FilterEqualTypeInput>;
};

/** Defines a filter that performs a fuzzy search. */
export type BrandNameMatchTypeInput = {
  /** Use this attribute to exactly match the specified string. For example, to filter on a specific SKU, specify a value such as `24-MB01`. */
  match?: Maybe<Scalars['String']>;
  /** Use this attribute to match the start string. For example, to filter on a specific start alphabet name A, specify a value such as `A` */
  matchAlpha?: Maybe<Scalars['String']>;
};

export type BrandOffer = {
  __typename?: 'BrandOffer';
  button_text?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image_url: Scalars['String'];
  link_object_url_key?: Maybe<Scalars['String']>;
  link_type?: Maybe<Scalars['String']>;
  mobile_image_url: Scalars['String'];
  offer_url: Scalars['String'];
  sort?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** The collection of brands that match the conditions defined in the filter. */
export type Brands = {
  __typename?: 'Brands';
  /** An array of brands. */
  items: Array<Maybe<Brand>>;
  /** Contains pagination metadata. */
  page_info?: Maybe<SearchResultPageInfo>;
  /** The total count of brands. */
  total_count?: Maybe<Scalars['Int']>;
};

export type BrandsSortInput = {
  name?: Maybe<SortEnum>;
  slider_sort_order?: Maybe<SortEnum>;
};

/** Contains details about an individual category that comprises a breadcrumb. */
export type Breadcrumb = {
  __typename?: 'Breadcrumb';
  /**
   * The ID of the category.
   * @deprecated Use `category_uid` instead.
   */
  category_id?: Maybe<Scalars['Int']>;
  /** The category level. */
  category_level?: Maybe<Scalars['Int']>;
  /** The display name of the category. */
  category_name?: Maybe<Scalars['String']>;
  /** The unique ID for a `Breadcrumb` object. */
  category_uid: Scalars['ID'];
  /** The URL key of the category. */
  category_url_key?: Maybe<Scalars['String']>;
  /** The URL path of the category. */
  category_url_path?: Maybe<Scalars['String']>;
};

/** An implementation for bundle product cart items. */
export type BundleCartItem = CartItemInterface & {
  __typename?: 'BundleCartItem';
  /** An array containing the bundle options the shopper selected. */
  bundle_options: Array<Maybe<SelectedBundleOption>>;
  /** An array containing the customizable options the shopper selected. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors?: Maybe<Array<Maybe<CartItemError>>>;
  /** The entered gift message for the cart item */
  gift_message?: Maybe<GiftMessage>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices?: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
};

/** Defines bundle product options for `CreditMemoItemInterface`. */
export type BundleCreditMemoItem = CreditMemoItemInterface & {
  __typename?: 'BundleCreditMemoItem';
  /** A list of bundle options that are assigned to a bundle product that is part of a credit memo. */
  bundle_options?: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of refunded items. */
  quantity_refunded?: Maybe<Scalars['Float']>;
};

/** Defines bundle product options for `InvoiceItemInterface`. */
export type BundleInvoiceItem = InvoiceItemInterface & {
  __typename?: 'BundleInvoiceItem';
  /** A list of bundle options that are assigned to an invoiced bundle product. */
  bundle_options?: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID'];
  /** Details about an individual order item. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of invoiced items. */
  quantity_invoiced?: Maybe<Scalars['Float']>;
};

/** Defines an individual item within a bundle product. */
export type BundleItem = {
  __typename?: 'BundleItem';
  /**
   * An ID assigned to each type of item in a bundle product.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** An array of additional options for this bundle item. */
  options?: Maybe<Array<Maybe<BundleItemOption>>>;
  /** A number indicating the sequence order of this item compared to the other bundle items. */
  position?: Maybe<Scalars['Int']>;
  /** The range of prices for the product */
  price_range: PriceRange;
  /** Indicates whether the item must be included in the bundle. */
  required?: Maybe<Scalars['Boolean']>;
  /** The SKU of the bundle product. */
  sku?: Maybe<Scalars['String']>;
  /** The display name of the item. */
  title?: Maybe<Scalars['String']>;
  /** The input type that the customer uses to select the item. Examples include radio button and checkbox. */
  type?: Maybe<Scalars['String']>;
  /** The unique ID for a `BundleItem` object. */
  uid?: Maybe<Scalars['ID']>;
};

/** Defines the characteristics that comprise a specific bundle item and its options. */
export type BundleItemOption = {
  __typename?: 'BundleItemOption';
  /** Indicates whether the customer can change the number of items for this option. */
  can_change_quantity?: Maybe<Scalars['Boolean']>;
  /**
   * The ID assigned to the bundled item option.
   * @deprecated Use `uid` instead
   */
  id?: Maybe<Scalars['Int']>;
  /** Indicates whether this option is the default option. */
  is_default?: Maybe<Scalars['Boolean']>;
  /** The text that identifies the bundled item option. */
  label?: Maybe<Scalars['String']>;
  /** When a bundle item contains multiple options, the relative position of this option compared to the other options. */
  position?: Maybe<Scalars['Int']>;
  /** The price of the selected option. */
  price?: Maybe<Scalars['Float']>;
  /** One of FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** Contains details about this product option. */
  product?: Maybe<ProductInterface>;
  /**
   * Indicates the quantity of this specific bundle item.
   * @deprecated Use `quantity` instead.
   */
  qty?: Maybe<Scalars['Float']>;
  /** The quantity of this specific bundle item. */
  quantity?: Maybe<Scalars['Float']>;
  /** The unique ID for a `BundleItemOption` object. */
  uid: Scalars['ID'];
};

/** Defines the input for a bundle option. */
export type BundleOptionInput = {
  /** The ID of the option. */
  id: Scalars['Int'];
  /** The number of the selected item to add to the cart. */
  quantity: Scalars['Float'];
  /** An array with the chosen value of the option. */
  value: Array<Maybe<Scalars['String']>>;
};

/** Defines bundle product options for `OrderItemInterface`. */
export type BundleOrderItem = OrderItemInterface & {
  __typename?: 'BundleOrderItem';
  /** A list of bundle options that are assigned to the bundle product. */
  bundle_options?: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** The final discount information for the product. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The entered option for the base product, such as a logo or image. */
  entered_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The selected gift message for the order item */
  gift_message?: Maybe<GiftMessage>;
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID'];
  original_price?: Maybe<Scalars['Float']>;
  price_incl_tax?: Maybe<Scalars['Float']>;
  /** The product brand. */
  product_brand?: Maybe<Brand>;
  /** The categories assigned to the product. */
  product_categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  product_data?: Maybe<ProductInterface>;
  product_image?: Maybe<Scalars['String']>;
  /** The URL of the main product image of the base product */
  product_image_url?: Maybe<Scalars['String']>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The original price of the base product */
  product_original_price: Money;
  /** The sale price of the base product, including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The URL of the thumbnail image of the base product */
  product_thumbnail_url?: Maybe<Scalars['String']>;
  /** The type of product, such as simple, configurable, etc. */
  product_type?: Maybe<Scalars['String']>;
  /** URL key of the base product. */
  product_url_key?: Maybe<Scalars['String']>;
  /** The number of canceled items. */
  quantity_canceled?: Maybe<Scalars['Float']>;
  /** The number of invoiced items. */
  quantity_invoiced?: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item. */
  quantity_ordered?: Maybe<Scalars['Float']>;
  /** The number of refunded items. */
  quantity_refunded?: Maybe<Scalars['Float']>;
  /** The number of returned items. */
  quantity_returned?: Maybe<Scalars['Float']>;
  /** The number of shipped items. */
  quantity_shipped?: Maybe<Scalars['Float']>;
  row_total_incl_tax?: Maybe<Scalars['Float']>;
  /** The selected options for the base product, such as color or size. */
  selected_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item. */
  status?: Maybe<Scalars['String']>;
};

export type BundlePack = {
  __typename?: 'BundlePack';
  apply_only_for_all?: Maybe<Scalars['Boolean']>;
  bundle_id?: Maybe<Scalars['String']>;
  bundle_products: Array<Maybe<BundlePackProduct>>;
  discount?: Maybe<Scalars['String']>;
  final_price?: Maybe<Scalars['String']>;
  main_product?: Maybe<ProductInterface>;
  price_before_discount?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type BundlePackProduct = {
  __typename?: 'BundlePackProduct';
  discount_amount?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  product?: Maybe<ProductInterface>;
  quantity?: Maybe<Scalars['String']>;
};

/** Defines basic features of a bundle product and contains multiple BundleItems. */
export type BundleProduct = CustomizableProductInterface & PhysicalProductInterface & ProductInterface & RoutableInterface & {
  __typename?: 'BundleProduct';
  /** @deprecated Use the `custom_attributes` field instead. */
  Keyboards_backlit?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_cooling_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_cooling_system?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_coverage_area?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_horsepower?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_inverter_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_plasma_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_turbo_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessories_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  armrest_direction?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  as_featured?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  at_coverage_area?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  at_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  at_type?: Maybe<Scalars['Int']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  /** List of product attributes */
  attributes?: Maybe<Array<Maybe<CustomAttribute>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  audio_type?: Maybe<Scalars['Int']>;
  /**
   * Does the product has COD
   * @deprecated Use the `custom_attributes` field instead.
   */
  available_cod?: Maybe<Scalars['Int']>;
  available_vouchers?: Maybe<Array<Maybe<AvailableVoucher>>>;
  /**
   * The product average reviews.
   * @deprecated Use the `custom_attributes` field instead.
   */
  average_rating?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_size?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_size_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_target_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  barcode?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  battery_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  battery_rechargeable_battery?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  battery_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bike_num_of_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bike_types?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bike_wheel_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  blender_type?: Maybe<Scalars['Int']>;
  /** Product Content Blocks */
  blocks?: Maybe<Array<Maybe<Block>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_type?: Maybe<Scalars['Int']>;
  /** The product brand. */
  brand?: Maybe<Brand>;
  bundle_packs: Array<Maybe<BundlePack>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cables_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cables_length?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cables_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_acc_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_memory_cardslot?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_memory_internal?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_package_components?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_ports?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_shooting_contin_shooting?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_acc_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_battery?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_digital_camera_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_features_primary_camera?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_features_secondary?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_flash?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_focus_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_maximum_aperture?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_maximum_focal_length?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_minimum_aperture?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_minimum_focal_length?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_megapixel?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_optical_zoom?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_screen_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_touch_screen?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_usb?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_wifi?: Maybe<Scalars['Int']>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonical_url?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  car_acc_type?: Maybe<Scalars['Int']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chair_height_djustability?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chair_maximum_height?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chargers_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chargers_output?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chargers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  clocks_built_in_alarm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  clocks_clock_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  clocks_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_grinder_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_bar_pressure?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_beverage_form?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_cups?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_grinder?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  comp_lap_accessories_compitable_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  comp_lap_accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  connector_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  connector_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_console_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_hard_disk_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_hdmi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_included_games?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_number_of_controllers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_fan?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_flames?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_grill?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_holders_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_ignition?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_safety?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_filter_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_number_of_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_remote_control_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_wheels_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  copy_resolution?: Maybe<Scalars['String']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_compatible_with_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_compatible_with_model?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_type?: Maybe<Scalars['Int']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  custom_label_1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  custom_label_2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  custom_pages_ids?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_automatic_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  delivery_time?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  delivery_time_inside_cairo_giz?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dental_care_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_form?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_type?: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_half_load?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_number_of_programs?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_sanitization?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_loading_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_rpm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_steam_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dvr_channel_number?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dvr_input?: Maybe<Scalars['String']>;
  /** Product Short Dynamic Link */
  dynamic_link?: Maybe<Scalars['String']>;
  /** Indicates whether the bundle product has a dynamic price. */
  dynamic_price?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the bundle product has a dynamic SKU. */
  dynamic_sku?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the bundle product has a dynamically calculated weight. */
  dynamic_weight?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_light_color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_outlet?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_watt?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  express_shipping_time?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_age_group?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_age_range?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_color?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_decor_style?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_digital_zoom?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_display_technology?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_finish?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_gemstone?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_gender?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_health_concern?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_ingredients?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_is_assembly_required?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_material?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_maximum_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_megapixels?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_minimum_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_model?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_number_of_licenses?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_operating_system?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_optical_zoom?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_package_quantity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_pattern?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_depth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_form?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_height?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_length?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_width?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_recommended_use?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_scent?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_screen_size?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_shoe_width?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_size?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_storage_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_style?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_system_requirements?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_thread_count?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_video_game_platform?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_diameter_in_inches?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_net_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_number_of_blades?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_type?: Maybe<Scalars['Int']>;
  featured_bullet_attributes_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_targeted_age_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_cap_lit?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_cap_lit_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_cooling_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_number_of_drawers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  game_access_compitable?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  game_accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  game_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gaming_controller_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gaming_controller_connectivity?: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_acc_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_dryer_power?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_dryer_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_style_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_style_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_styler_auto_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hand_tools_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hardware_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hardware_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  has_deal?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_battery?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_microphone?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_noise_cancellation?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_fan?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_heat_settings?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_number_of_fins?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_overheating_protection?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heavy_item?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  height?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_flames?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_holders_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_ignition?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_power_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_safety?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  home_decor_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_air_flow_rate?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_filters?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_motors?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_offers?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_plate_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_plate_number_plate?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_plate_power_consumption?: Maybe<Scalars['Int']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ideal_installing_frame_dimensions?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ink_color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ink_compitable_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  installment_offers?: Maybe<Scalars['String']>;
  /** The product qualified installment plans. */
  installments?: Maybe<InstallmentOutput>;
  /** @deprecated Use the `custom_attributes` field instead. */
  intercome_number_way?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  intercome_video_support?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_base_plate_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_vertical_steam_shot?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_air_condition?: Maybe<Scalars['Int']>;
  /** Check if product is a best seller */
  is_best_seller?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_component?: Maybe<Scalars['Int']>;
  is_has_rayax?: Maybe<Scalars['Boolean']>;
  is_shown_in_brands_homepage?: Maybe<Scalars['Int']>;
  is_shown_in_categories_homepage?: Maybe<Scalars['Int']>;
  /** An array containing information about individual bundle items. */
  items?: Maybe<Array<Maybe<BundleItem>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_number_of_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_automatic_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_water_resistant?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kitchen_supplies_materials?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kitchen_supplies_type?: Maybe<Scalars['Int']>;
  /** product labels */
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_battery_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_card_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_card_manufacturer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_card_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_model?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hard_desk_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hard_disk_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hd_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hdmi_port?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_laptop_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_operating_system?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_core?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_type?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_ram_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_ram_technology?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_screen_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_touch_screen?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  length?: Maybe<Scalars['Float']>;
  /** Product Manuals */
  manuals?: Maybe<Array<Maybe<Manual>>>;
  /** Product Manuals Of Both ar_EG And en_US Locals */
  manuals_of_all_locales?: Maybe<Array<Maybe<ManualV2>>>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  marketplace_seller?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maximum_load?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meat_grinder_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meat_grinder_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meat_grinder_reverse_speed?: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  medical_supplies_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  medical_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_card_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_card_speed_class?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_cards_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_cards_type?: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_cavity_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_grill?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mixer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_battery?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_battery_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_camera_video?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_common_model_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_display_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_memory_internal?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_memory_ram?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_network_sim?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_network_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_os?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_os_version?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_processor_chipset?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_processor_cpu?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_screen_size_inch?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_display_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_front_camera?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_rear_camera?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_rear_camera_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_wifi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  model_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  model_number?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mosquito_insect_killer_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouse_rgb?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouse_sensitivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouses_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouses_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouses_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  musical_instrument_type?: Maybe<Scalars['Int']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  network_access_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  network_devices_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  network_number_ports?: Maybe<Scalars['Int']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  new_from_date?: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  new_to_date?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  office_device_type?: Maybe<Scalars['Int']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_cavity_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_fan?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_grill?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_type?: Maybe<Scalars['Int']>;
  /** The product overall rating */
  overallRating: OrverallProductRating;
  /** @deprecated Use the `custom_attributes` field instead. */
  paper_input_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_graphics_card_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_graphics_card_manufacturer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_hard_desk_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_hard_disk_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_operating_system?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_pc_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_core?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_type?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_ram_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_ram_technology?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  perfumes_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  phone_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_ports?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_consumption?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_tools_power_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_tools_type?: Maybe<Scalars['Int']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** The range of prices for the product */
  price_range: PriceRange;
  /** An array of `TierPrice` objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  /** One of PRICE_RANGE or AS_LOW_AS. */
  price_view?: Maybe<PriceViewEnum>;
  /** @deprecated Use the `custom_attributes` field instead. */
  print_quality?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  print_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  print_speed_black?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_accessories_compitable_with?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_compitable_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_memory?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_output_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_processor?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printers_functions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_brand_id?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_color?: Maybe<Scalars['Int']>;
  product_featured_attributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of `ProductLinks` objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_offer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_aspect_ratio?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_hdmi_port?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_resolution?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_vga_port?: Maybe<Scalars['Int']>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** @deprecated Use the `custom_attributes` field instead. */
  receivers_hd_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  receivers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  receivers_wifi?: Maybe<Scalars['Int']>;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirect_code: Scalars['Int'];
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_cap_lit?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_cap_lit_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_cooling_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_doors?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_freezer_position?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_lcd?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_number_of_shelves?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_refrigerator_style?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_water_dispenser?: Maybe<Scalars['Int']>;
  /** An array of products to be displayed in a Related Products block. */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  remote_control_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  report_main_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  report_sub_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  return_refund?: Maybe<Scalars['Int']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_keep_warm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scale_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scales_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scales_maximum_weight_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scan_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scanner_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scanner_touch_screen?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scanner_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_compatible_with_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_protector_compatible_with_model?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_type?: Maybe<Scalars['Int']>;
  /** The product seller. */
  seller?: Maybe<Customer>;
  /** @deprecated Use the `custom_attributes` field instead. */
  seller_note?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_attachment_numbers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_type_of_shaving?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_type_of_usage?: Maybe<Scalars['Int']>;
  /** Indicates whether to ship bundle items together or individually. */
  ship_bundle_items?: Maybe<ShipBundleItemsEnum>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shop_by_seller?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_target_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_type_of_usage?: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sm_non_stick?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sm_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sm_type?: Maybe<Scalars['Int']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_home_device_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_scales_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_accessory_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_accessory_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_accessory_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_band_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_battery_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_compatible_os?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_compatible_with_os?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_ram?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_screen_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_shape?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_sim?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_target_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_waterproof?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_wifi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speaker_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_battery?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_number_of_unit?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_waterproof?: Maybe<Scalars['Int']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specific_appliance_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specific_appliance_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specific_appliance_type?: Maybe<Scalars['Int']>;
  /** Attribute names that contains the product specifications */
  specification_attributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sporting_goods_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sporting_goods_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sports_equipment?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  stationery_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steam_mob_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steam_mob_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_automatic_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_layers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_power_consumption?: Maybe<Scalars['Int']>;
  /** List of product attributes */
  stockQtyTerm?: Maybe<Array<Maybe<ProductStockQty>>>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  /** The product available store locators. */
  store_locators?: Maybe<Array<Maybe<AmsStoreLocator>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_auto_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_max_temp?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_min_temp?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_plates_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_type_of_usage?: Maybe<Scalars['Int']>;
  /** Product Support Videos */
  support_videos?: Maybe<Array<Maybe<SupportVideo>>>;
  /** The file name of a swatch image. */
  swatch_image?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_browning?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_defrost_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_number_of_slices?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_age_target?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_gender?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_vehicles_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_acc_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_built_in_receiver?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_connectivity_usb?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_curved?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_dimentions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_hd_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_hdmi?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_monitor_display_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_monitor_display_size_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_refresh_rate?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_screen_resolution?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_screen_technology?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_smart_connection?: Maybe<Scalars['Int']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ups_power_capacity?: Maybe<Scalars['Int']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  usb_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_dust_bag_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_dust_bag_capacity_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_dust_bag_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_year_model?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vg_game_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vg_game_platform?: Maybe<Scalars['Int']>;
  /** The visibility of a product */
  visibility: UpdateSellerProductVisibilityEnum;
  /** @deprecated Use the `custom_attributes` field instead. */
  wall_mount_adjustable?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wall_mount_size_compatability?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  warranty?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_band_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_diameter_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_shape?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_water_resistant?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_filter_cartridges?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_filter_cartridges_stages?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_filter_or_cartridges?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_inner_tank?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_cold_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_hot_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_loading_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_number_of_taps?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_refrigerator_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_water_temperature_type?: Maybe<Scalars['Int']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  width?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_dryer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_dryer_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_loading_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_rpm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_steam_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  yogurt_maker_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  yogurt_maker_power_consumption?: Maybe<Scalars['Int']>;
};


/** Defines basic features of a bundle product and contains multiple BundleItems. */
export type BundleProductAttributesArgs = {
  groupCode?: Maybe<Scalars['String']>;
};


/** Defines basic features of a bundle product and contains multiple BundleItems. */
export type BundleProductInstallmentsArgs = {
  corporateId?: Maybe<Scalars['Int']>;
};


/** Defines basic features of a bundle product and contains multiple BundleItems. */
export type BundleProductReviewsArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

/** Defines a single bundle product. */
export type BundleProductCartItemInput = {
  /** A mandatory array of options for the bundle product, including each chosen option and specified quantity. */
  bundle_options: Array<Maybe<BundleOptionInput>>;
  /** The ID and value of the option. */
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  /** The quantity and SKU of the bundle product. */
  data: CartItemInput;
};

export type BundleRelatedProductIdsInput = {
  product_id: Scalars['String'];
};

/** Defines bundle product options for `ShipmentItemInterface`. */
export type BundleShipmentItem = ShipmentItemInterface & {
  __typename?: 'BundleShipmentItem';
  /** A list of bundle options that are assigned to a shipped product. */
  bundle_options?: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID'];
  /** The order item associated with the shipment item. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of shipped items. */
  quantity_shipped: Scalars['Float'];
};

/** Defines bundle product options for `WishlistItemInterface`. */
export type BundleWishlistItem = WishlistItemInterface & {
  __typename?: 'BundleWishlistItem';
  /** The date and time the item was added to the wish list. */
  added_at: Scalars['String'];
  /** An array containing information about the selected bundle items. */
  bundle_options?: Maybe<Array<Maybe<SelectedBundleOption>>>;
  /** Custom options selected for the wish list item. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
};

export type CaclulateBundleWithSelectedProductsInput = {
  /** The unique ID that identifies the bundle */
  bundle_id: Scalars['String'];
  main_product_id: Scalars['String'];
  /** Array of selected products of the bundle */
  related_products_ids: Array<Maybe<BundleRelatedProductIdsInput>>;
};

export type CaclulateBundleWithSelectedProductsResult = {
  __typename?: 'CaclulateBundleWithSelectedProductsResult';
  discount?: Maybe<Scalars['String']>;
  final_price?: Maybe<Scalars['String']>;
  price_before_discount?: Maybe<Scalars['String']>;
};

export type Campaign = {
  __typename?: 'Campaign';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** Get Customer Transactions */
  segments: Array<Maybe<Segment>>;
  title?: Maybe<Scalars['String']>;
  user_won: Scalars['Boolean'];
};

export type CancelRequest = {
  __typename?: 'CancelRequest';
  bank_account_number?: Maybe<Scalars['String']>;
  bank_iban?: Maybe<Scalars['String']>;
  bank_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  has_refund?: Maybe<Scalars['Boolean']>;
  order_number: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  refund_method?: Maybe<Scalars['String']>;
  request_id: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type CancelRequestInput = {
  bank_account_number?: Maybe<Scalars['String']>;
  bank_iban?: Maybe<Scalars['String']>;
  bank_name?: Maybe<Scalars['String']>;
  order_number: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  refund_method?: Maybe<RefundMethod>;
};

export type CarOwnershipInformation = {
  __typename?: 'CarOwnershipInformation';
  car_ownership_front_image?: Maybe<Scalars['String']>;
};

export type CarOwnershipInformationInput = {
  car_ownership_front_image?: Maybe<Scalars['String']>;
};

export type Card = {
  __typename?: 'Card';
  card_holder_name: Scalars['String'];
  card_id: Scalars['String'];
  card_number: Scalars['String'];
  expiry_date: Scalars['String'];
  payment_option: Scalars['String'];
};

/** Contains the contents and other details about a guest or customer cart. */
export type Cart = {
  __typename?: 'Cart';
  acceptIframeUrl?: Maybe<Scalars['String']>;
  /** Accept installment bank ID */
  accept_installment_bank_id?: Maybe<Scalars['Float']>;
  /** Accept installment plan payment fees percentage */
  accept_installment_plan_fees?: Maybe<Scalars['Float']>;
  /** Accept installment plan payment fees amount */
  accept_installment_plan_fees_amount?: Maybe<Scalars['Float']>;
  /** Accept installment plan interest rate */
  accept_installment_plan_interest?: Maybe<Scalars['Float']>;
  /** Accept installment plan interest amount */
  accept_installment_plan_interest_amount?: Maybe<Scalars['Float']>;
  /** Accept installment plan months */
  accept_installment_plan_months?: Maybe<Scalars['Int']>;
  /** @deprecated Use `applied_coupons` instead. */
  applied_coupon?: Maybe<AppliedCoupon>;
  /** An array of `AppliedCoupon` objects. Each object contains the `code` text attribute, which specifies the coupon code. */
  applied_coupons?: Maybe<Array<Maybe<AppliedCoupon>>>;
  /** An array of available payment methods. */
  available_payment_methods?: Maybe<Array<Maybe<AvailablePaymentMethod>>>;
  /** The billing address assigned to the cart. */
  billing_address?: Maybe<BillingCartAddress>;
  card?: Maybe<Card>;
  contact_method?: Maybe<Scalars['String']>;
  elite_installmentsInformation: EliteInstallmentsInformation;
  /** The email address of the guest or customer. */
  email?: Maybe<Scalars['String']>;
  fee: Scalars['Float'];
  /** The entered gift message for the cart */
  gift_message?: Maybe<GiftMessage>;
  guest_user_verified?: Maybe<Scalars['Boolean']>;
  /** The unique ID for a `Cart` object. */
  id: Scalars['ID'];
  installments_store_id?: Maybe<Scalars['String']>;
  /** Indicates whether the cart contains only virtual products. */
  is_virtual: Scalars['Boolean'];
  /** An array of products that have been added to the cart. */
  items?: Maybe<Array<Maybe<CartItemInterface>>>;
  mpgsUrl?: Maybe<Scalars['String']>;
  order_medium_source?: Maybe<Scalars['String']>;
  order_source?: Maybe<Scalars['String']>;
  pay_downpayment_at_branch?: Maybe<Scalars['Boolean']>;
  paymentKey?: Maybe<Scalars['String']>;
  payment_fee: Scalars['Float'];
  /** Pricing details for the quote. */
  prices?: Maybe<CartPrices>;
  reservedOrderNumber: Scalars['String'];
  /** Indicates which payment method was applied to the cart. */
  selected_payment_method?: Maybe<SelectedPaymentMethod>;
  /** Get selected store locators in the cart */
  selected_sources?: Maybe<Array<Maybe<AmsStoreLocator>>>;
  /** An array of shipping addresses assigned to the cart. */
  shipping_addresses: Array<Maybe<ShippingCartAddress>>;
  sign_at_branch?: Maybe<Scalars['Boolean']>;
  /** The total number of items in the cart. */
  total_quantity: Scalars['Float'];
};


/** Contains the contents and other details about a guest or customer cart. */
export type CartAcceptIframeUrlArgs = {
  phone_number?: Maybe<Scalars['String']>;
};


/** Contains the contents and other details about a guest or customer cart. */
export type CartMpgsUrlArgs = {
  installments_months?: Maybe<Scalars['Int']>;
  return_url: Scalars['String'];
};

/** Contains details the country in a billing or shipping address. */
export type CartAddressCountry = {
  __typename?: 'CartAddressCountry';
  /** The country code. */
  code: Scalars['String'];
  /** The display label for the country. */
  label: Scalars['String'];
};

/** Defines the billing or shipping address to be applied to the cart. */
export type CartAddressInput = {
  address_name?: Maybe<Scalars['String']>;
  apartment?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  /** The city specified for the billing or shipping address. */
  city: Scalars['String'];
  /** The company specified for the billing or shipping address. */
  company?: Maybe<Scalars['String']>;
  /** The country code and label for the billing or shipping address. */
  country_code: Scalars['String'];
  /** The first name of the customer or guest. */
  firstname: Scalars['String'];
  floor?: Maybe<Scalars['Int']>;
  /** The last name of the customer or guest. */
  lastname: Scalars['String'];
  /** The ZIP or postal code of the billing or shipping address. */
  postcode?: Maybe<Scalars['String']>;
  /** A string that defines the state or province of the billing or shipping address. */
  region?: Maybe<Scalars['String']>;
  /** An integer that defines the state or province of the billing or shipping address. */
  region_id?: Maybe<Scalars['Int']>;
  /** Determines whether to save the address in the customer's address book. The default value is true. */
  save_in_address_book?: Maybe<Scalars['Boolean']>;
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']>>;
  /** The telephone number for the billing or shipping address. */
  telephone?: Maybe<Scalars['String']>;
  /** The VAT company number for billing or shipping address. */
  vat_id?: Maybe<Scalars['String']>;
};

export type CartAddressInterface = {
  address_name?: Maybe<Scalars['String']>;
  apartment?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  /** The city specified for the billing or shipping address. */
  city?: Maybe<City>;
  /** The company specified for the billing or shipping address. */
  company?: Maybe<Scalars['String']>;
  /** An object containing the country label and code. */
  country?: Maybe<Country>;
  /** The first name of the customer or guest. */
  firstname: Scalars['String'];
  floor?: Maybe<Scalars['Int']>;
  /** The last name of the customer or guest. */
  lastname: Scalars['String'];
  /** The ZIP or postal code of the billing or shipping address. */
  postcode?: Maybe<Scalars['String']>;
  /** An object containing the region label and code. */
  region?: Maybe<Region>;
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']>>;
  /** The telephone number for the billing or shipping address. */
  telephone?: Maybe<Scalars['String']>;
  /** The unique id of the customer address. */
  uid: Scalars['String'];
  /** The VAT company number for billing or shipping address. */
  vat_id?: Maybe<Scalars['String']>;
};

/** Contains details about the region in a billing or shipping address. */
export type CartAddressRegion = {
  __typename?: 'CartAddressRegion';
  /** The state or province code. */
  code?: Maybe<Scalars['String']>;
  /** The display label for the region. */
  label?: Maybe<Scalars['String']>;
  /** The unique ID for a pre-defined region. */
  region_id?: Maybe<Scalars['Int']>;
};

/** Contains information about discounts applied to the cart. */
export type CartDiscount = {
  __typename?: 'CartDiscount';
  /** The amount of the discount applied to the item. */
  amount: Money;
  /** The description of the discount. */
  label: Array<Maybe<Scalars['String']>>;
};

export type CartItemError = {
  __typename?: 'CartItemError';
  /** An error code that describes the error encountered */
  code: CartItemErrorType;
  /** A localized error message */
  message: Scalars['String'];
};

export enum CartItemErrorType {
  ItemIncrements = 'ITEM_INCREMENTS',
  ItemQty = 'ITEM_QTY',
  Undefined = 'UNDEFINED'
}

/** Defines an item to be added to the cart. */
export type CartItemInput = {
  /** An array of entered options for the base product, such as personalization text. */
  entered_options?: Maybe<Array<Maybe<EnteredOptionInput>>>;
  /** For a child product, the SKU of its parent product. */
  parent_sku?: Maybe<Scalars['String']>;
  /** The amount or number of an item to add. */
  quantity: Scalars['Float'];
  /** The selected options for the base product, such as color or size, using the unique ID for an object such as `CustomizableRadioOption`, `CustomizableDropDownOption`, or `ConfigurableProductOptionsValues`. */
  selected_options?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The SKU of the product. */
  sku: Scalars['String'];
};

/** An interface for products in a cart. */
export type CartItemInterface = {
  /** An array of errors encountered while loading the cart item */
  errors?: Maybe<Array<Maybe<CartItemError>>>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices?: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
};

/** Contains details about the price of the item, including taxes and discounts. */
export type CartItemPrices = {
  __typename?: 'CartItemPrices';
  /** An array of discounts to be applied to the cart item. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** An array of FPTs applied to the cart item. */
  fixed_product_taxes?: Maybe<Array<Maybe<FixedProductTax>>>;
  /** The price of the item before any discounts were applied. The price that might include tax, depending on the configured display settings for cart. */
  price: Money;
  /** The price of the item before any discounts were applied. The price that might include tax, depending on the configured display settings for cart. */
  price_including_tax: Money;
  /** The value of the price multiplied by the quantity of the item. */
  row_total: Money;
  /** The value of `row_total` plus the tax applied to the item. */
  row_total_including_tax: Money;
  /** The total of all discounts applied to the item. */
  total_item_discount?: Maybe<Money>;
};

/** Deprecated: The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
export type CartItemQuantity = {
  __typename?: 'CartItemQuantity';
  /** @deprecated The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
  cart_item_id: Scalars['Int'];
  /** @deprecated The `ShippingCartAddress.cart_items` field now returns `CartItemInterface`. */
  quantity: Scalars['Float'];
};

/** Contains details about the price of a selected customizable value. */
export type CartItemSelectedOptionValuePrice = {
  __typename?: 'CartItemSelectedOptionValuePrice';
  /** Indicates whether the price type is fixed, percent, or dynamic. */
  type: PriceTypeEnum;
  /** A string that describes the unit of the value. */
  units: Scalars['String'];
  /** A price value. */
  value: Scalars['Float'];
};

/** A single item to be updated. */
export type CartItemUpdateInput = {
  /** Deprecated. Use `cart_item_uid` instead. */
  cart_item_id?: Maybe<Scalars['Int']>;
  /** The unique ID for a `CartItemInterface` object. */
  cart_item_uid?: Maybe<Scalars['ID']>;
  /** An array that defines customizable options for the product. */
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  /** Gift message details for the cart item */
  gift_message?: Maybe<GiftMessageInput>;
  /** The new quantity of the item. */
  quantity?: Maybe<Scalars['Float']>;
};

/** Contains details about the final price of items in the cart, including discount and tax information. */
export type CartPrices = {
  __typename?: 'CartPrices';
  /** An array containing the names and amounts of taxes applied to each item in the cart. */
  applied_taxes?: Maybe<Array<Maybe<CartTaxItem>>>;
  /** @deprecated Use discounts instead. */
  discount?: Maybe<CartDiscount>;
  /** An array containing cart rule discounts, store credit and gift cards applied to the cart. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The total, including discounts, taxes, shipping, and other fees. */
  grand_total?: Maybe<Money>;
  /** The subtotal without any applied taxes. */
  subtotal_excluding_tax?: Maybe<Money>;
  /** The subtotal including any applied taxes. */
  subtotal_including_tax?: Maybe<Money>;
  /** The subtotal with any discounts applied, but not taxes. */
  subtotal_with_discount_excluding_tax?: Maybe<Money>;
};

export type CartSourcesInput = {
  /** Cart ID */
  cart_id: Scalars['String'];
  /** Sources to be selected */
  sources: Array<Maybe<Scalars['String']>>;
};

export type CartSourcesOutput = {
  __typename?: 'CartSourcesOutput';
  cart: Cart;
};

/** Contains tax information about an item in the cart. */
export type CartTaxItem = {
  __typename?: 'CartTaxItem';
  /** The amount of tax applied to the item. */
  amount: Money;
  /** The description of the tax. */
  label: Scalars['String'];
};

/** An error encountered while adding an item to the the cart. */
export type CartUserInputError = {
  __typename?: 'CartUserInputError';
  /** A cart-specific error code. */
  code: CartUserInputErrorType;
  /** A localized error message. */
  message: Scalars['String'];
};

export enum CartUserInputErrorType {
  InsufficientStock = 'INSUFFICIENT_STOCK',
  NotSalable = 'NOT_SALABLE',
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  Undefined = 'UNDEFINED'
}

/** Defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export type CategoryFilterInput = {
  /** Filter by the unique category ID for a `CategoryInterface` object. */
  category_uid?: Maybe<FilterEqualTypeInput>;
  /** Deprecated: use 'category_uid' to filter uniquely identifiers of categories. */
  ids?: Maybe<FilterEqualTypeInput>;
  /** Filter by the display name of the category. */
  name?: Maybe<FilterMatchTypeInput>;
  /** Filter by the unique parent category ID for a `CategoryInterface` object. */
  parent_category_uid?: Maybe<FilterEqualTypeInput>;
  /** Filter by the unique parent category ID for a `CategoryInterface` object. */
  parent_id?: Maybe<FilterEqualTypeInput>;
  /** Filter by the part of the URL that identifies the category. */
  url_key?: Maybe<FilterEqualTypeInput>;
  /** Filter by the URL path for the category. */
  url_path?: Maybe<FilterEqualTypeInput>;
};

/** Contains the full set of attributes that can be returned in a category search. */
export type CategoryInterface = {
  amasty_category_product_sort?: Maybe<Scalars['Int']>;
  amasty_dynamic_conditions?: Maybe<Scalars['String']>;
  amlanding_is_dynamic?: Maybe<Scalars['Int']>;
  assigned_corporate_ids?: Maybe<Scalars['String']>;
  available_sort_by?: Maybe<Array<Maybe<Scalars['String']>>>;
  brand_ids?: Maybe<Scalars['String']>;
  /** The Category Brands. */
  brands: Array<Maybe<Brand>>;
  /** An array of breadcrumb items. */
  breadcrumbs?: Maybe<Array<Maybe<Breadcrumb>>>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Categories' is enabled. */
  canonical_url?: Maybe<Scalars['String']>;
  category_installment_rules?: Maybe<Scalars['String']>;
  children_count?: Maybe<Scalars['String']>;
  /** Contains a category CMS block. */
  cms_block?: Maybe<CmsBlock>;
  commission_for_admin?: Maybe<Scalars['String']>;
  /**
   * The timestamp indicating when the category was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  custom_layout_update_file?: Maybe<Scalars['String']>;
  /** The attribute to use for sorting. */
  default_sort_by?: Maybe<Scalars['String']>;
  /** An optional description of the category. */
  description?: Maybe<Scalars['String']>;
  display_mode?: Maybe<Scalars['String']>;
  featured_brands?: Maybe<Array<Maybe<Brand>>>;
  /** The category featured offers. */
  featured_offers: Array<Maybe<Offer>>;
  /** The category featured offers. */
  featured_offers_rayaelite: Array<Maybe<Offer>>;
  featured_sort?: Maybe<Scalars['Int']>;
  filter_price_range?: Maybe<Scalars['Float']>;
  footer_content?: Maybe<Scalars['String']>;
  /**
   * An ID that uniquely identifies the category.
   * @deprecated Use `uid` instead.
   */
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  include_in_menu?: Maybe<Scalars['Int']>;
  is_anchor?: Maybe<Scalars['Int']>;
  is_shown_in_homepage?: Maybe<Scalars['Int']>;
  landing_page?: Maybe<Scalars['Int']>;
  /** The depth of the category within the tree. */
  level?: Maybe<Scalars['Int']>;
  /** The category mega menu offer. */
  mega_menu_offer?: Maybe<Offer>;
  /** The category mega menu offer. */
  mega_menu_offer_rayaelite?: Maybe<Offer>;
  meta_description?: Maybe<Scalars['String']>;
  meta_keywords?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  /** The display name of the category. */
  name?: Maybe<Scalars['String']>;
  /** The category page offer. */
  page_offers: Array<Maybe<Offer>>;
  /** The category page offer. */
  page_offers_rayaelite: Array<Maybe<Offer>>;
  /** The full category path. */
  path?: Maybe<Scalars['String']>;
  /** The category path within the store. */
  path_in_store?: Maybe<Scalars['String']>;
  /** The position of the category relative to other categories at the same level in tree. */
  position?: Maybe<Scalars['Int']>;
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  product_count?: Maybe<Scalars['Int']>;
  /** The list of products assigned to the category. */
  products?: Maybe<CategoryProducts>;
  sw_menu_block_bottom_content?: Maybe<Scalars['String']>;
  sw_menu_block_left_content?: Maybe<Scalars['String']>;
  sw_menu_block_right_content?: Maybe<Scalars['String']>;
  sw_menu_block_top_content?: Maybe<Scalars['String']>;
  sw_menu_font_icon?: Maybe<Scalars['String']>;
  sw_menu_hide_item?: Maybe<Scalars['Int']>;
  sw_menu_icon_img?: Maybe<Scalars['String']>;
  sw_menu_static_width?: Maybe<Scalars['String']>;
  sw_ocat_additional_content?: Maybe<Scalars['String']>;
  sw_ocat_category_font_icon?: Maybe<Scalars['String']>;
  sw_ocat_category_hoverbgcolor?: Maybe<Scalars['String']>;
  sw_ocat_category_icon_image?: Maybe<Scalars['String']>;
  sw_ocat_hide_this_item?: Maybe<Scalars['Int']>;
  sync_to_facebook_catalog?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  /** The unique ID for a `CategoryInterface` object. */
  uid: Scalars['ID'];
  /**
   * The timestamp indicating when the category was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** The URL key assigned to the category. */
  url_key?: Maybe<Scalars['String']>;
  /** The URL path assigned to the category. */
  url_path?: Maybe<Scalars['String']>;
  /** The part of the category URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
};


/** Contains the full set of attributes that can be returned in a category search. */
export type CategoryInterfaceFeatured_OffersArgs = {
  limit?: Maybe<Scalars['Int']>;
};


/** Contains the full set of attributes that can be returned in a category search. */
export type CategoryInterfaceFeatured_Offers_RayaeliteArgs = {
  limit?: Maybe<Scalars['Int']>;
};


/** Contains the full set of attributes that can be returned in a category search. */
export type CategoryInterfaceProductsArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<ProductAttributeSortInput>;
};

/** Contains details about the products assigned to a category. */
export type CategoryProducts = {
  __typename?: 'CategoryProducts';
  /** An array of products that are assigned to the category. */
  items?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Pagination metadata. */
  page_info?: Maybe<SearchResultPageInfo>;
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  total_count?: Maybe<Scalars['Int']>;
};

/** Contains a collection of `CategoryTree` objects and pagination information. */
export type CategoryResult = {
  __typename?: 'CategoryResult';
  /** A list of categories that match the filter criteria. */
  items?: Maybe<Array<Maybe<CategoryTree>>>;
  /** An object that includes the `page_info` and `currentPage` values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>;
  /** The total number of categories that match the criteria. */
  total_count?: Maybe<Scalars['Int']>;
};

/** Contains the hierarchy of categories. */
export type CategoryTree = CategoryInterface & RoutableInterface & {
  __typename?: 'CategoryTree';
  amasty_category_product_sort?: Maybe<Scalars['Int']>;
  amasty_dynamic_conditions?: Maybe<Scalars['String']>;
  amlanding_is_dynamic?: Maybe<Scalars['Int']>;
  assigned_corporate_ids?: Maybe<Scalars['String']>;
  available_sort_by?: Maybe<Array<Maybe<Scalars['String']>>>;
  brand_ids?: Maybe<Scalars['String']>;
  /** The Category Brands. */
  brands: Array<Maybe<Brand>>;
  /** An array of breadcrumb items. */
  breadcrumbs?: Maybe<Array<Maybe<Breadcrumb>>>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Categories' is enabled. */
  canonical_url?: Maybe<Scalars['String']>;
  category_installment_rules?: Maybe<Scalars['String']>;
  /** A tree of child categories. */
  children?: Maybe<Array<Maybe<CategoryTree>>>;
  children_count?: Maybe<Scalars['String']>;
  /** Contains a category CMS block. */
  cms_block?: Maybe<CmsBlock>;
  commission_for_admin?: Maybe<Scalars['String']>;
  /**
   * The timestamp indicating when the category was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  custom_layout_update_file?: Maybe<Scalars['String']>;
  /** The attribute to use for sorting. */
  default_sort_by?: Maybe<Scalars['String']>;
  /** An optional description of the category. */
  description?: Maybe<Scalars['String']>;
  display_mode?: Maybe<Scalars['String']>;
  featured_brands?: Maybe<Array<Maybe<Brand>>>;
  /** The category featured offers. */
  featured_offers: Array<Maybe<Offer>>;
  /** The category featured offers. */
  featured_offers_rayaelite: Array<Maybe<Offer>>;
  featured_sort?: Maybe<Scalars['Int']>;
  filter_price_range?: Maybe<Scalars['Float']>;
  footer_content?: Maybe<Scalars['String']>;
  /**
   * An ID that uniquely identifies the category.
   * @deprecated Use `uid` instead.
   */
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  include_in_menu?: Maybe<Scalars['Int']>;
  is_anchor?: Maybe<Scalars['Int']>;
  is_shown_in_homepage?: Maybe<Scalars['Int']>;
  landing_page?: Maybe<Scalars['Int']>;
  /** The depth of the category within the tree. */
  level?: Maybe<Scalars['Int']>;
  /** The category mega menu offer. */
  mega_menu_offer?: Maybe<Offer>;
  /** The category mega menu offer. */
  mega_menu_offer_rayaelite?: Maybe<Offer>;
  meta_description?: Maybe<Scalars['String']>;
  meta_keywords?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  /** The display name of the category. */
  name?: Maybe<Scalars['String']>;
  /** The category page offer. */
  page_offers: Array<Maybe<Offer>>;
  /** The category page offer. */
  page_offers_rayaelite: Array<Maybe<Offer>>;
  /** The full category path. */
  path?: Maybe<Scalars['String']>;
  /** The category path within the store. */
  path_in_store?: Maybe<Scalars['String']>;
  /** The position of the category relative to other categories at the same level in tree. */
  position?: Maybe<Scalars['Int']>;
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  product_count?: Maybe<Scalars['Int']>;
  /** The list of products assigned to the category. */
  products?: Maybe<CategoryProducts>;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirect_code: Scalars['Int'];
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  sw_menu_block_bottom_content?: Maybe<Scalars['String']>;
  sw_menu_block_left_content?: Maybe<Scalars['String']>;
  sw_menu_block_right_content?: Maybe<Scalars['String']>;
  sw_menu_block_top_content?: Maybe<Scalars['String']>;
  sw_menu_font_icon?: Maybe<Scalars['String']>;
  sw_menu_hide_item?: Maybe<Scalars['Int']>;
  sw_menu_icon_img?: Maybe<Scalars['String']>;
  sw_menu_static_width?: Maybe<Scalars['String']>;
  sw_ocat_additional_content?: Maybe<Scalars['String']>;
  sw_ocat_category_font_icon?: Maybe<Scalars['String']>;
  sw_ocat_category_hoverbgcolor?: Maybe<Scalars['String']>;
  sw_ocat_category_icon_image?: Maybe<Scalars['String']>;
  sw_ocat_hide_this_item?: Maybe<Scalars['Int']>;
  sync_to_facebook_catalog?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /** The unique ID for a `CategoryInterface` object. */
  uid: Scalars['ID'];
  /**
   * The timestamp indicating when the category was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** The URL key assigned to the category. */
  url_key?: Maybe<Scalars['String']>;
  /** The URL path assigned to the category. */
  url_path?: Maybe<Scalars['String']>;
  /** The part of the category URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
};


/** Contains the hierarchy of categories. */
export type CategoryTreeFeatured_OffersArgs = {
  limit?: Maybe<Scalars['Int']>;
};


/** Contains the hierarchy of categories. */
export type CategoryTreeFeatured_Offers_RayaeliteArgs = {
  limit?: Maybe<Scalars['Int']>;
};


/** Contains the hierarchy of categories. */
export type CategoryTreeProductsArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<ProductAttributeSortInput>;
};

export type CheckCustomerCreditInput = {
  installment_items: Array<Maybe<InstallmentItems>>;
};

export type CheckCustomerCreditResponse = {
  __typename?: 'CheckCustomerCreditResponse';
  have_limit: Scalars['Boolean'];
  reason_ar?: Maybe<Scalars['String']>;
  reason_en?: Maybe<Scalars['String']>;
};

export type CheckIfCustomerHasEliteAccountOutput = {
  __typename?: 'CheckIfCustomerHasEliteAccountOutput';
  has_elite_account: Scalars['Boolean'];
  phone_number?: Maybe<Scalars['String']>;
};

/** Defines details about an individual checkout agreement. */
export type CheckoutAgreement = {
  __typename?: 'CheckoutAgreement';
  /** The ID for a checkout agreement. */
  agreement_id: Scalars['Int'];
  /** The checkbox text for the checkout agreement. */
  checkbox_text: Scalars['String'];
  /** Required. The text of the agreement. */
  content: Scalars['String'];
  /** The height of the text box where the Terms and Conditions statement appears during checkout. */
  content_height?: Maybe<Scalars['String']>;
  /** Indicates whether the `content` text is in HTML format. */
  is_html: Scalars['Boolean'];
  /** Indicates whether agreements are accepted automatically or manually. */
  mode: CheckoutAgreementMode;
  /** The name given to the condition. */
  name: Scalars['String'];
};

/** Indicates how agreements are accepted. */
export enum CheckoutAgreementMode {
  /** Conditions are automatically accepted upon checkout. */
  Auto = 'AUTO',
  /** Shoppers must manually accept the conditions to place an order. */
  Manual = 'MANUAL'
}

/** An error encountered while adding an item to the cart. */
export type CheckoutUserInputError = {
  __typename?: 'CheckoutUserInputError';
  /** An error code that is specific to Checkout. */
  code: CheckoutUserInputErrorCodes;
  /** A localized error message. */
  message: Scalars['String'];
  /** The path to the input field that caused an error. See the GraphQL specification about path errors for details: http://spec.graphql.org/draft/#sec-Errors */
  path: Array<Maybe<Scalars['String']>>;
};

export enum CheckoutUserInputErrorCodes {
  InsufficientStock = 'INSUFFICIENT_STOCK',
  NotSalable = 'NOT_SALABLE',
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  ReorderNotAvailable = 'REORDER_NOT_AVAILABLE',
  Undefined = 'UNDEFINED'
}

export type Choice = {
  __typename?: 'Choice';
  id: Scalars['String'];
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type ChoiceOutput = {
  __typename?: 'ChoiceOutput';
  coupon_code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type CitiesInfo = {
  __typename?: 'CitiesInfo';
  city?: Maybe<City>;
  region?: Maybe<Region>;
};

export type City = {
  __typename?: 'City';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type Club = {
  __typename?: 'Club';
  arabicName?: Maybe<Scalars['String']>;
  englishName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
};

/** Contains details about a specific CMS block. */
export type CmsBlock = {
  __typename?: 'CmsBlock';
  /** The content of the CMS block in raw HTML. */
  content?: Maybe<Scalars['String']>;
  /** The CMS block identifier. */
  identifier?: Maybe<Scalars['String']>;
  /** The title assigned to the CMS block. */
  title?: Maybe<Scalars['String']>;
};

/** Contains an array CMS block items. */
export type CmsBlocks = {
  __typename?: 'CmsBlocks';
  /** An array of CMS blocks. */
  items?: Maybe<Array<Maybe<CmsBlock>>>;
};

/** Contains details about a CMS page. */
export type CmsPage = RoutableInterface & {
  __typename?: 'CmsPage';
  /** The content of the CMS page in raw HTML. */
  content?: Maybe<Scalars['String']>;
  /** The heading that displays at the top of the CMS page. */
  content_heading?: Maybe<Scalars['String']>;
  /** The ID of a CMS page. */
  identifier?: Maybe<Scalars['String']>;
  /** A brief description of the page for search results listings. */
  meta_description?: Maybe<Scalars['String']>;
  /** A brief description of the page for search results listings. */
  meta_keywords?: Maybe<Scalars['String']>;
  /** A page title that is indexed by search engines and appears in search results listings. */
  meta_title?: Maybe<Scalars['String']>;
  /** The design layout of the page, indicating the number of columns and navigation features used on the page. */
  page_layout?: Maybe<Scalars['String']>;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirect_code: Scalars['Int'];
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  /** The name that appears in the breadcrumb trail navigation and in the browser title bar and tab. */
  title?: Maybe<Scalars['String']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /** The URL key of the CMS page, which is often based on the `content_heading`. */
  url_key?: Maybe<Scalars['String']>;
};

export type ColorSwatchData = SwatchDataInterface & {
  __typename?: 'ColorSwatchData';
  /** The value can be represented as color (HEX code), image link, or text. */
  value?: Maybe<Scalars['String']>;
};

/** Contains an attribute code that is used for product comparisons. */
export type ComparableAttribute = {
  __typename?: 'ComparableAttribute';
  /** An attribute code that is enabled for product comparisons. */
  code: Scalars['String'];
  /** The label of the attribute code. */
  label: Scalars['String'];
};

/** Defines an object used to iterate through items for product comparisons. */
export type ComparableItem = {
  __typename?: 'ComparableItem';
  /** An array of product attributes that can be used to compare products. */
  attributes: Array<Maybe<ProductAttribute>>;
  /** Details about a product in a compare list. */
  product: ProductInterface;
  /** The unique ID of an item in a compare list. */
  uid: Scalars['ID'];
};

/** Contains iterable information such as the array of items, the count, and attributes that represent the compare list. */
export type CompareList = {
  __typename?: 'CompareList';
  /** An array of attributes that can be used for comparing products. */
  attributes?: Maybe<Array<Maybe<ComparableAttribute>>>;
  /** The number of items in the compare list. */
  item_count: Scalars['Int'];
  /** An array of products to compare. */
  items?: Maybe<Array<Maybe<ComparableItem>>>;
  /** The unique ID assigned to the compare list. */
  uid: Scalars['ID'];
};

export type ComplexTextValue = {
  __typename?: 'ComplexTextValue';
  /** Text that can contain HTML tags. */
  html: Scalars['String'];
};

/** Contains details about a configurable product attribute option. */
export type ConfigurableAttributeOption = {
  __typename?: 'ConfigurableAttributeOption';
  /** The ID assigned to the attribute. */
  code?: Maybe<Scalars['String']>;
  /** A string that describes the configurable attribute option. */
  label?: Maybe<Scalars['String']>;
  /** The unique ID for a `ConfigurableAttributeOption` object. */
  uid: Scalars['ID'];
  /** A unique index number assigned to the configurable product option. */
  value_index?: Maybe<Scalars['Int']>;
};

/** An implementation for configurable product cart items. */
export type ConfigurableCartItem = CartItemInterface & {
  __typename?: 'ConfigurableCartItem';
  /** An array containing the configuranle options the shopper selected. */
  configurable_options: Array<Maybe<SelectedConfigurableOption>>;
  /** Product details of the cart item. */
  configured_variant: ProductInterface;
  /** An array containing the customizable options the shopper selected. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors?: Maybe<Array<Maybe<CartItemError>>>;
  /** The entered gift message for the cart item */
  gift_message?: Maybe<GiftMessage>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices?: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
};

/** Describes configurable options that have been selected and can be selected as a result of the previous selections. */
export type ConfigurableOptionAvailableForSelection = {
  __typename?: 'ConfigurableOptionAvailableForSelection';
  /** An attribute code that uniquely identifies a configurable option. */
  attribute_code: Scalars['String'];
  /** An array of selectable option value IDs. */
  option_value_uids: Array<Maybe<Scalars['ID']>>;
};

/** Defines basic features of a configurable product and its simple product variants. */
export type ConfigurableProduct = CustomizableProductInterface & PhysicalProductInterface & ProductInterface & RoutableInterface & {
  __typename?: 'ConfigurableProduct';
  /** @deprecated Use the `custom_attributes` field instead. */
  Keyboards_backlit?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_cooling_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_cooling_system?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_coverage_area?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_horsepower?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_inverter_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_plasma_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_turbo_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessories_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  armrest_direction?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  as_featured?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  at_coverage_area?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  at_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  at_type?: Maybe<Scalars['Int']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  /** List of product attributes */
  attributes?: Maybe<Array<Maybe<CustomAttribute>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  audio_type?: Maybe<Scalars['Int']>;
  /**
   * Does the product has COD
   * @deprecated Use the `custom_attributes` field instead.
   */
  available_cod?: Maybe<Scalars['Int']>;
  available_vouchers?: Maybe<Array<Maybe<AvailableVoucher>>>;
  /**
   * The product average reviews.
   * @deprecated Use the `custom_attributes` field instead.
   */
  average_rating?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_size?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_size_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_target_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  barcode?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  battery_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  battery_rechargeable_battery?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  battery_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bike_num_of_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bike_types?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bike_wheel_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  blender_type?: Maybe<Scalars['Int']>;
  /** Product Content Blocks */
  blocks?: Maybe<Array<Maybe<Block>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_type?: Maybe<Scalars['Int']>;
  /** The product brand. */
  brand?: Maybe<Brand>;
  bundle_packs: Array<Maybe<BundlePack>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cables_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cables_length?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cables_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_acc_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_memory_cardslot?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_memory_internal?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_package_components?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_ports?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_shooting_contin_shooting?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_acc_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_battery?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_digital_camera_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_features_primary_camera?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_features_secondary?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_flash?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_focus_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_maximum_aperture?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_maximum_focal_length?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_minimum_aperture?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_minimum_focal_length?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_megapixel?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_optical_zoom?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_screen_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_touch_screen?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_usb?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_wifi?: Maybe<Scalars['Int']>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonical_url?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  car_acc_type?: Maybe<Scalars['Int']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chair_height_djustability?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chair_maximum_height?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chargers_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chargers_output?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chargers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  clocks_built_in_alarm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  clocks_clock_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  clocks_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_grinder_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_bar_pressure?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_beverage_form?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_cups?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_grinder?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  comp_lap_accessories_compitable_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  comp_lap_accessories_type?: Maybe<Scalars['Int']>;
  /** An array of options for the configurable product. */
  configurable_options?: Maybe<Array<Maybe<ConfigurableProductOptions>>>;
  /** An array of media gallery items and other details about selected configurable product options as well as details about remaining selectable options. */
  configurable_product_options_selection?: Maybe<ConfigurableProductOptionsSelection>;
  /** @deprecated Use the `custom_attributes` field instead. */
  connector_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  connector_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_console_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_hard_disk_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_hdmi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_included_games?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_number_of_controllers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_fan?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_flames?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_grill?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_holders_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_ignition?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_safety?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_filter_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_number_of_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_remote_control_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_wheels_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  copy_resolution?: Maybe<Scalars['String']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_compatible_with_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_compatible_with_model?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_type?: Maybe<Scalars['Int']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  custom_label_1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  custom_label_2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  custom_pages_ids?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_automatic_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  delivery_time?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  delivery_time_inside_cairo_giz?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dental_care_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_form?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_type?: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_half_load?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_number_of_programs?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_sanitization?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_loading_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_rpm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_steam_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dvr_channel_number?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dvr_input?: Maybe<Scalars['String']>;
  /** Product Short Dynamic Link */
  dynamic_link?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_light_color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_outlet?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_watt?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  express_shipping_time?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_age_group?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_age_range?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_color?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_decor_style?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_digital_zoom?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_display_technology?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_finish?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_gemstone?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_gender?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_health_concern?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_ingredients?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_is_assembly_required?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_material?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_maximum_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_megapixels?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_minimum_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_model?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_number_of_licenses?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_operating_system?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_optical_zoom?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_package_quantity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_pattern?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_depth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_form?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_height?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_length?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_width?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_recommended_use?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_scent?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_screen_size?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_shoe_width?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_size?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_storage_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_style?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_system_requirements?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_thread_count?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_video_game_platform?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_diameter_in_inches?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_net_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_number_of_blades?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_type?: Maybe<Scalars['Int']>;
  featured_bullet_attributes_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_targeted_age_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_cap_lit?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_cap_lit_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_cooling_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_number_of_drawers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  game_access_compitable?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  game_accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  game_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gaming_controller_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gaming_controller_connectivity?: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_acc_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_dryer_power?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_dryer_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_style_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_style_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_styler_auto_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hand_tools_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hardware_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hardware_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  has_deal?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_battery?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_microphone?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_noise_cancellation?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_fan?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_heat_settings?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_number_of_fins?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_overheating_protection?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heavy_item?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  height?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_flames?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_holders_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_ignition?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_power_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_safety?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  home_decor_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_air_flow_rate?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_filters?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_motors?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_offers?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_plate_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_plate_number_plate?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_plate_power_consumption?: Maybe<Scalars['Int']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ideal_installing_frame_dimensions?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ink_color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ink_compitable_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  installment_offers?: Maybe<Scalars['String']>;
  /** The product qualified installment plans. */
  installments?: Maybe<InstallmentOutput>;
  /** @deprecated Use the `custom_attributes` field instead. */
  intercome_number_way?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  intercome_video_support?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_base_plate_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_vertical_steam_shot?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_air_condition?: Maybe<Scalars['Int']>;
  /** Check if product is a best seller */
  is_best_seller?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_component?: Maybe<Scalars['Int']>;
  is_has_rayax?: Maybe<Scalars['Boolean']>;
  is_shown_in_brands_homepage?: Maybe<Scalars['Int']>;
  is_shown_in_categories_homepage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_number_of_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_automatic_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_water_resistant?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kitchen_supplies_materials?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kitchen_supplies_type?: Maybe<Scalars['Int']>;
  /** product labels */
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_battery_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_card_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_card_manufacturer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_card_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_model?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hard_desk_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hard_disk_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hd_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hdmi_port?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_laptop_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_operating_system?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_core?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_type?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_ram_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_ram_technology?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_screen_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_touch_screen?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  length?: Maybe<Scalars['Float']>;
  /** Product Manuals */
  manuals?: Maybe<Array<Maybe<Manual>>>;
  /** Product Manuals Of Both ar_EG And en_US Locals */
  manuals_of_all_locales?: Maybe<Array<Maybe<ManualV2>>>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  marketplace_seller?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maximum_load?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meat_grinder_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meat_grinder_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meat_grinder_reverse_speed?: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  medical_supplies_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  medical_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_card_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_card_speed_class?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_cards_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_cards_type?: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_cavity_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_grill?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mixer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_battery?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_battery_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_camera_video?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_common_model_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_display_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_memory_internal?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_memory_ram?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_network_sim?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_network_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_os?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_os_version?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_processor_chipset?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_processor_cpu?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_screen_size_inch?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_display_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_front_camera?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_rear_camera?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_rear_camera_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_wifi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  model_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  model_number?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mosquito_insect_killer_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouse_rgb?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouse_sensitivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouses_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouses_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouses_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  musical_instrument_type?: Maybe<Scalars['Int']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  network_access_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  network_devices_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  network_number_ports?: Maybe<Scalars['Int']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  new_from_date?: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  new_to_date?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  office_device_type?: Maybe<Scalars['Int']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_cavity_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_fan?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_grill?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_type?: Maybe<Scalars['Int']>;
  /** The product overall rating */
  overallRating: OrverallProductRating;
  /** @deprecated Use the `custom_attributes` field instead. */
  paper_input_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_graphics_card_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_graphics_card_manufacturer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_hard_desk_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_hard_disk_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_operating_system?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_pc_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_core?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_type?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_ram_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_ram_technology?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  perfumes_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  phone_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_ports?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_consumption?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_tools_power_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_tools_type?: Maybe<Scalars['Int']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** The range of prices for the product */
  price_range: PriceRange;
  /** An array of `TierPrice` objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  print_quality?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  print_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  print_speed_black?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_accessories_compitable_with?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_compitable_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_memory?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_output_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_processor?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printers_functions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_brand_id?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_color?: Maybe<Scalars['Int']>;
  product_featured_attributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of `ProductLinks` objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_offer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_aspect_ratio?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_hdmi_port?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_resolution?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_vga_port?: Maybe<Scalars['Int']>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** @deprecated Use the `custom_attributes` field instead. */
  receivers_hd_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  receivers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  receivers_wifi?: Maybe<Scalars['Int']>;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirect_code: Scalars['Int'];
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_cap_lit?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_cap_lit_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_cooling_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_doors?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_freezer_position?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_lcd?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_number_of_shelves?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_refrigerator_style?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_water_dispenser?: Maybe<Scalars['Int']>;
  /** An array of products to be displayed in a Related Products block. */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  remote_control_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  report_main_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  report_sub_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  return_refund?: Maybe<Scalars['Int']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_keep_warm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scale_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scales_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scales_maximum_weight_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scan_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scanner_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scanner_touch_screen?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scanner_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_compatible_with_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_protector_compatible_with_model?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_type?: Maybe<Scalars['Int']>;
  /** The product seller. */
  seller?: Maybe<Customer>;
  /** @deprecated Use the `custom_attributes` field instead. */
  seller_note?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_attachment_numbers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_type_of_shaving?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_type_of_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shop_by_seller?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_target_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_type_of_usage?: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sm_non_stick?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sm_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sm_type?: Maybe<Scalars['Int']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_home_device_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_scales_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_accessory_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_accessory_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_accessory_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_band_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_battery_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_compatible_os?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_compatible_with_os?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_ram?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_screen_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_shape?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_sim?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_target_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_waterproof?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_wifi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speaker_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_battery?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_number_of_unit?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_waterproof?: Maybe<Scalars['Int']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specific_appliance_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specific_appliance_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specific_appliance_type?: Maybe<Scalars['Int']>;
  /** Attribute names that contains the product specifications */
  specification_attributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sporting_goods_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sporting_goods_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sports_equipment?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  stationery_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steam_mob_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steam_mob_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_automatic_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_layers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_power_consumption?: Maybe<Scalars['Int']>;
  /** List of product attributes */
  stockQtyTerm?: Maybe<Array<Maybe<ProductStockQty>>>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  /** The product available store locators. */
  store_locators?: Maybe<Array<Maybe<AmsStoreLocator>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_auto_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_max_temp?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_min_temp?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_plates_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_type_of_usage?: Maybe<Scalars['Int']>;
  /** Product Support Videos */
  support_videos?: Maybe<Array<Maybe<SupportVideo>>>;
  /** The file name of a swatch image. */
  swatch_image?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_browning?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_defrost_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_number_of_slices?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_age_target?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_gender?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_vehicles_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_acc_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_built_in_receiver?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_connectivity_usb?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_curved?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_dimentions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_hd_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_hdmi?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_monitor_display_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_monitor_display_size_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_refresh_rate?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_screen_resolution?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_screen_technology?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_smart_connection?: Maybe<Scalars['Int']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ups_power_capacity?: Maybe<Scalars['Int']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  usb_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_dust_bag_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_dust_bag_capacity_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_dust_bag_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_type?: Maybe<Scalars['Int']>;
  /** An array of simple product variants. */
  variants?: Maybe<Array<Maybe<ConfigurableVariant>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_year_model?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vg_game_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vg_game_platform?: Maybe<Scalars['Int']>;
  /** The visibility of a product */
  visibility: UpdateSellerProductVisibilityEnum;
  /** @deprecated Use the `custom_attributes` field instead. */
  wall_mount_adjustable?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wall_mount_size_compatability?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  warranty?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_band_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_diameter_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_shape?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_water_resistant?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_filter_cartridges?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_filter_cartridges_stages?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_filter_or_cartridges?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_inner_tank?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_cold_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_hot_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_loading_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_number_of_taps?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_refrigerator_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_water_temperature_type?: Maybe<Scalars['Int']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  width?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_dryer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_dryer_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_loading_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_rpm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_steam_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  yogurt_maker_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  yogurt_maker_power_consumption?: Maybe<Scalars['Int']>;
};


/** Defines basic features of a configurable product and its simple product variants. */
export type ConfigurableProductAttributesArgs = {
  groupCode?: Maybe<Scalars['String']>;
};


/** Defines basic features of a configurable product and its simple product variants. */
export type ConfigurableProductConfigurable_Product_Options_SelectionArgs = {
  configurableOptionValueUids?: Maybe<Array<Scalars['ID']>>;
};


/** Defines basic features of a configurable product and its simple product variants. */
export type ConfigurableProductInstallmentsArgs = {
  corporateId?: Maybe<Scalars['Int']>;
};


/** Defines basic features of a configurable product and its simple product variants. */
export type ConfigurableProductReviewsArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type ConfigurableProductCartItemInput = {
  /** The ID and value of the option. */
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  /** The quantity and SKU of the configurable product. */
  data: CartItemInput;
  /** The SKU of the parent configurable product. */
  parent_sku?: Maybe<Scalars['String']>;
  /** Deprecated. Use `CartItemInput.sku` instead. */
  variant_sku?: Maybe<Scalars['String']>;
};

/** Contains details about configurable product options. */
export type ConfigurableProductOption = {
  __typename?: 'ConfigurableProductOption';
  /** An attribute code that uniquely identifies a configurable option. */
  attribute_code: Scalars['String'];
  /** The display name of the option. */
  label: Scalars['String'];
  /** The unique ID of the configurable option. */
  uid: Scalars['ID'];
  /** An array of values that are applicable for this option. */
  values?: Maybe<Array<Maybe<ConfigurableProductOptionValue>>>;
};

/** Defines a value for a configurable product option. */
export type ConfigurableProductOptionValue = {
  __typename?: 'ConfigurableProductOptionValue';
  /** Indicates whether the product is available with this selected option. */
  is_available: Scalars['Boolean'];
  /** Indicates whether the value is the default. */
  is_use_default: Scalars['Boolean'];
  /** The display name of the value. */
  label: Scalars['String'];
  /** The URL assigned to the thumbnail of the swatch image. */
  swatch?: Maybe<SwatchDataInterface>;
  /** The unique ID of the value. */
  uid: Scalars['ID'];
};

/** Defines configurable attributes for the specified product. */
export type ConfigurableProductOptions = {
  __typename?: 'ConfigurableProductOptions';
  /** A string that identifies the attribute. */
  attribute_code?: Maybe<Scalars['String']>;
  /**
   * The ID assigned to the attribute.
   * @deprecated Use `attribute_uid` instead.
   */
  attribute_id?: Maybe<Scalars['String']>;
  /**
   * The ID assigned to the attribute.
   * @deprecated Use `attribute_uid` instead.
   */
  attribute_id_v2?: Maybe<Scalars['Int']>;
  /** The unique ID for an `Attribute` object. */
  attribute_uid: Scalars['ID'];
  /**
   * The configurable option ID number assigned by the system.
   * @deprecated Use `uid` instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** A displayed string that describes the configurable product option. */
  label?: Maybe<Scalars['String']>;
  /** A number that indicates the order in which the attribute is displayed. */
  position?: Maybe<Scalars['Int']>;
  /**
   * This is the same as a product's `id` field.
   * @deprecated `product_id` is not needed and can be obtained from its parent.
   */
  product_id?: Maybe<Scalars['Int']>;
  /** The unique ID for a `ConfigurableProductOptions` object. */
  uid: Scalars['ID'];
  /** Indicates whether the option is the default. */
  use_default?: Maybe<Scalars['Boolean']>;
  /** An array that defines the `value_index` codes assigned to the configurable product. */
  values?: Maybe<Array<Maybe<ConfigurableProductOptionsValues>>>;
};

/** Contains metadata corresponding to the selected configurable options. */
export type ConfigurableProductOptionsSelection = {
  __typename?: 'ConfigurableProductOptionsSelection';
  /** An array of all possible configurable options. */
  configurable_options?: Maybe<Array<Maybe<ConfigurableProductOption>>>;
  /** Product images and videos corresponding to the specified configurable options selection. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /** The configurable options available for further selection based on the current selection. */
  options_available_for_selection?: Maybe<Array<Maybe<ConfigurableOptionAvailableForSelection>>>;
  /** A variant represented by the specified configurable options selection. The value is expected to be null until selections are made for each configurable option. */
  variant?: Maybe<SimpleProduct>;
};

/** Contains the index number assigned to a configurable product option. */
export type ConfigurableProductOptionsValues = {
  __typename?: 'ConfigurableProductOptionsValues';
  /** The label of the product on the default store. */
  default_label?: Maybe<Scalars['String']>;
  /** The label of the product. */
  label?: Maybe<Scalars['String']>;
  /** The label of the product on the current store. */
  store_label?: Maybe<Scalars['String']>;
  /** Swatch data for a configurable product option. */
  swatch_data?: Maybe<SwatchDataInterface>;
  /** The unique ID for a `ConfigurableProductOptionsValues` object. */
  uid?: Maybe<Scalars['ID']>;
  /** Indicates whether to use the default_label. */
  use_default_value?: Maybe<Scalars['Boolean']>;
  /**
   * A unique index number assigned to the configurable product option.
   * @deprecated Use `uid` instead.
   */
  value_index?: Maybe<Scalars['Int']>;
};

/** Contains all the simple product variants of a configurable product. */
export type ConfigurableVariant = {
  __typename?: 'ConfigurableVariant';
  /** An array of configurable attribute options. */
  attributes?: Maybe<Array<Maybe<ConfigurableAttributeOption>>>;
  /** An array of linked simple products. */
  product?: Maybe<SimpleProduct>;
};

/** A configurable product wish list item. */
export type ConfigurableWishlistItem = WishlistItemInterface & {
  __typename?: 'ConfigurableWishlistItem';
  /** The date and time the item was added to the wish list. */
  added_at: Scalars['String'];
  /**
   * The SKU of the simple product corresponding to a set of selected configurable options.
   * @deprecated Use `ConfigurableWishlistItem.configured_variant.sku` instead.
   */
  child_sku: Scalars['String'];
  /** An array of selected configurable options. */
  configurable_options?: Maybe<Array<Maybe<SelectedConfigurableOption>>>;
  /** Product details of the selected variant. The value is null if some options are not configured. */
  configured_variant?: Maybe<ProductInterface>;
  /** Custom options selected for the wish list item. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
};

export type ContactFormInput = {
  attachment?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  phone_number: Scalars['String'];
  preferred_contact_method?: Maybe<Scalars['String']>;
  reason_of_contact: Scalars['String'];
};

export type ContactFormSubject = {
  __typename?: 'ContactFormSubject';
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export enum ContactMethodsEnum {
  Call = 'CALL',
  Email = 'EMAIL',
  Sms = 'SMS'
}

export type Contest = {
  __typename?: 'Contest';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image_url?: Maybe<Scalars['String']>;
  mobile_image_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  responsive_image_url?: Maybe<Scalars['String']>;
  stages: Array<Maybe<Stage>>;
  title?: Maybe<Scalars['String']>;
  today_matches: Array<Maybe<Match>>;
  user_won_matches: Array<Maybe<Match>>;
};

export type Corporate = {
  __typename?: 'Corporate';
  assigned_seller_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  id: Scalars['Int'];
  image_url?: Maybe<Scalars['String']>;
  mobile_image_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  plans?: Maybe<Array<Maybe<CorporatePlan>>>;
  require_employee_id?: Maybe<Scalars['Boolean']>;
  require_hr_letter?: Maybe<Scalars['Boolean']>;
  require_salary?: Maybe<Scalars['Boolean']>;
  responsive_image_url?: Maybe<Scalars['String']>;
};

export type CorporatePlan = {
  __typename?: 'CorporatePlan';
  interest_rate: Scalars['Float'];
  plan: Scalars['Int'];
};

export type Country = {
  __typename?: 'Country';
  /** An array of regions within a particular country. */
  available_regions?: Maybe<Array<Maybe<Region>>>;
  /** The name of the country in English. */
  full_name_english?: Maybe<Scalars['String']>;
  /** The name of the country in the current locale. */
  full_name_locale?: Maybe<Scalars['String']>;
  /** The unique ID for a `Country` object. */
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** The three-letter abbreviation of the country, such as USA. */
  three_letter_abbreviation?: Maybe<Scalars['String']>;
  /** The two-letter abbreviation of the country, such as US. */
  two_letter_abbreviation?: Maybe<Scalars['String']>;
};


export type CountryAvailable_RegionsArgs = {
  filter?: Maybe<AddressFilterInput>;
};

/** The list of country codes. */
export enum CountryCodeEnum {
  /** Andorra */
  Ad = 'AD',
  /** United Arab Emirates */
  Ae = 'AE',
  /** Afghanistan */
  Af = 'AF',
  /** Antigua & Barbuda */
  Ag = 'AG',
  /** Anguilla */
  Ai = 'AI',
  /** Albania */
  Al = 'AL',
  /** Armenia */
  Am = 'AM',
  /** Netherlands Antilles */
  An = 'AN',
  /** Angola */
  Ao = 'AO',
  /** Antarctica */
  Aq = 'AQ',
  /** Argentina */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Aruba */
  Aw = 'AW',
  /** Åland Islands */
  Ax = 'AX',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bosnia & Herzegovina */
  Ba = 'BA',
  /** Barbados */
  Bb = 'BB',
  /** Bangladesh */
  Bd = 'BD',
  /** Belgium */
  Be = 'BE',
  /** Burkina Faso */
  Bf = 'BF',
  /** Bulgaria */
  Bg = 'BG',
  /** Bahrain */
  Bh = 'BH',
  /** Burundi */
  Bi = 'BI',
  /** Benin */
  Bj = 'BJ',
  /** St. Barthélemy */
  Bl = 'BL',
  /** Bermuda */
  Bm = 'BM',
  /** Brunei */
  Bn = 'BN',
  /** Bolivia */
  Bo = 'BO',
  /** Brazil */
  Br = 'BR',
  /** Bahamas */
  Bs = 'BS',
  /** Bhutan */
  Bt = 'BT',
  /** Bouvet Island */
  Bv = 'BV',
  /** Botswana */
  Bw = 'BW',
  /** Belarus */
  By = 'BY',
  /** Belize */
  Bz = 'BZ',
  /** Canada */
  Ca = 'CA',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Congo-Kinshasa */
  Cd = 'CD',
  /** Central African Republic */
  Cf = 'CF',
  /** Congo-Brazzaville */
  Cg = 'CG',
  /** Switzerland */
  Ch = 'CH',
  /** Côte d’Ivoire */
  Ci = 'CI',
  /** Cook Islands */
  Ck = 'CK',
  /** Chile */
  Cl = 'CL',
  /** Cameroon */
  Cm = 'CM',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** Costa Rica */
  Cr = 'CR',
  /** Cuba */
  Cu = 'CU',
  /** Cape Verde */
  Cv = 'CV',
  /** Christmas Island */
  Cx = 'CX',
  /** Cyprus */
  Cy = 'CY',
  /** Czech Republic */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Djibouti */
  Dj = 'DJ',
  /** Denmark */
  Dk = 'DK',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Algeria */
  Dz = 'DZ',
  /** Ecuador */
  Ec = 'EC',
  /** Estonia */
  Ee = 'EE',
  /** Egypt */
  Eg = 'EG',
  /** Western Sahara */
  Eh = 'EH',
  /** Eritrea */
  Er = 'ER',
  /** Spain */
  Es = 'ES',
  /** Ethiopia */
  Et = 'ET',
  /** Finland */
  Fi = 'FI',
  /** Fiji */
  Fj = 'FJ',
  /** Falkland Islands */
  Fk = 'FK',
  /** Micronesia */
  Fm = 'FM',
  /** Faroe Islands */
  Fo = 'FO',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** United Kingdom */
  Gb = 'GB',
  /** Grenada */
  Gd = 'GD',
  /** Georgia */
  Ge = 'GE',
  /** French Guiana */
  Gf = 'GF',
  /** Guernsey */
  Gg = 'GG',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Gambia */
  Gm = 'GM',
  /** Guinea */
  Gn = 'GN',
  /** Guadeloupe */
  Gp = 'GP',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Greece */
  Gr = 'GR',
  /** South Georgia & South Sandwich Islands */
  Gs = 'GS',
  /** Guatemala */
  Gt = 'GT',
  /** Guam */
  Gu = 'GU',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Hong Kong SAR China */
  Hk = 'HK',
  /** Heard &amp; McDonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Croatia */
  Hr = 'HR',
  /** Haiti */
  Ht = 'HT',
  /** Hungary */
  Hu = 'HU',
  /** Indonesia */
  Id = 'ID',
  /** Ireland */
  Ie = 'IE',
  /** Israel */
  Il = 'IL',
  /** Isle of Man */
  Im = 'IM',
  /** India */
  In = 'IN',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Iraq */
  Iq = 'IQ',
  /** Iran */
  Ir = 'IR',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Jersey */
  Je = 'JE',
  /** Jamaica */
  Jm = 'JM',
  /** Jordan */
  Jo = 'JO',
  /** Japan */
  Jp = 'JP',
  /** Kenya */
  Ke = 'KE',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Cambodia */
  Kh = 'KH',
  /** Kiribati */
  Ki = 'KI',
  /** Comoros */
  Km = 'KM',
  /** St. Kitts & Nevis */
  Kn = 'KN',
  /** North Korea */
  Kp = 'KP',
  /** South Korea */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Cayman Islands */
  Ky = 'KY',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Laos */
  La = 'LA',
  /** Lebanon */
  Lb = 'LB',
  /** St. Lucia */
  Lc = 'LC',
  /** Liechtenstein */
  Li = 'LI',
  /** Sri Lanka */
  Lk = 'LK',
  /** Liberia */
  Lr = 'LR',
  /** Lesotho */
  Ls = 'LS',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Libya */
  Ly = 'LY',
  /** Morocco */
  Ma = 'MA',
  /** Monaco */
  Mc = 'MC',
  /** Moldova */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** St. Martin */
  Mf = 'MF',
  /** Madagascar */
  Mg = 'MG',
  /** Marshall Islands */
  Mh = 'MH',
  /** Macedonia */
  Mk = 'MK',
  /** Mali */
  Ml = 'ML',
  /** Myanmar (Burma) */
  Mm = 'MM',
  /** Mongolia */
  Mn = 'MN',
  /** Macau SAR China */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Montserrat */
  Ms = 'MS',
  /** Malta */
  Mt = 'MT',
  /** Mauritius */
  Mu = 'MU',
  /** Maldives */
  Mv = 'MV',
  /** Malawi */
  Mw = 'MW',
  /** Mexico */
  Mx = 'MX',
  /** Malaysia */
  My = 'MY',
  /** Mozambique */
  Mz = 'MZ',
  /** Namibia */
  Na = 'NA',
  /** New Caledonia */
  Nc = 'NC',
  /** Niger */
  Ne = 'NE',
  /** Norfolk Island */
  Nf = 'NF',
  /** Nigeria */
  Ng = 'NG',
  /** Nicaragua */
  Ni = 'NI',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Nepal */
  Np = 'NP',
  /** Nauru */
  Nr = 'NR',
  /** Niue */
  Nu = 'NU',
  /** New Zealand */
  Nz = 'NZ',
  /** Oman */
  Om = 'OM',
  /** Panama */
  Pa = 'PA',
  /** Peru */
  Pe = 'PE',
  /** French Polynesia */
  Pf = 'PF',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Philippines */
  Ph = 'PH',
  /** Pakistan */
  Pk = 'PK',
  /** Poland */
  Pl = 'PL',
  /** St. Pierre & Miquelon */
  Pm = 'PM',
  /** Pitcairn Islands */
  Pn = 'PN',
  /** Palestinian Territories */
  Ps = 'PS',
  /** Portugal */
  Pt = 'PT',
  /** Palau */
  Pw = 'PW',
  /** Paraguay */
  Py = 'PY',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Solomon Islands */
  Sb = 'SB',
  /** Seychelles */
  Sc = 'SC',
  /** Sudan */
  Sd = 'SD',
  /** Sweden */
  Se = 'SE',
  /** Singapore */
  Sg = 'SG',
  /** St. Helena */
  Sh = 'SH',
  /** Slovenia */
  Si = 'SI',
  /** Svalbard & Jan Mayen */
  Sj = 'SJ',
  /** Slovakia */
  Sk = 'SK',
  /** Sierra Leone */
  Sl = 'SL',
  /** San Marino */
  Sm = 'SM',
  /** Senegal */
  Sn = 'SN',
  /** Somalia */
  So = 'SO',
  /** Suriname */
  Sr = 'SR',
  /** São Tomé & Príncipe */
  St = 'ST',
  /** El Salvador */
  Sv = 'SV',
  /** Syria */
  Sy = 'SY',
  /** Swaziland */
  Sz = 'SZ',
  /** Turks & Caicos Islands */
  Tc = 'TC',
  /** Chad */
  Td = 'TD',
  /** French Southern Territories */
  Tf = 'TF',
  /** Togo */
  Tg = 'TG',
  /** Thailand */
  Th = 'TH',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tokelau */
  Tk = 'TK',
  /** Timor-Leste */
  Tl = 'TL',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tunisia */
  Tn = 'TN',
  /** Tonga */
  To = 'TO',
  /** Turkey */
  Tr = 'TR',
  /** Trinidad & Tobago */
  Tt = 'TT',
  /** Tuvalu */
  Tv = 'TV',
  /** Taiwan */
  Tw = 'TW',
  /** Tanzania */
  Tz = 'TZ',
  /** Ukraine */
  Ua = 'UA',
  /** Uganda */
  Ug = 'UG',
  /** U.S. Outlying Islands */
  Um = 'UM',
  /** United States */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Vatican City */
  Va = 'VA',
  /** St. Vincent & Grenadines */
  Vc = 'VC',
  /** Venezuela */
  Ve = 'VE',
  /** British Virgin Islands */
  Vg = 'VG',
  /** U.S. Virgin Islands */
  Vi = 'VI',
  /** Vietnam */
  Vn = 'VN',
  /** Vanuatu */
  Vu = 'VU',
  /** Wallis & Futuna */
  Wf = 'WF',
  /** Samoa */
  Ws = 'WS',
  /** Yemen */
  Ye = 'YE',
  /** Mayotte */
  Yt = 'YT',
  /** South Africa */
  Za = 'ZA',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

/** Contains an array of product IDs to use for creating a compare list. */
export type CreateCompareListInput = {
  /** An array of product IDs to add to the compare list. */
  products?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CreateCustomerInput = {
  /** Guest User Cart ID */
  cart_id?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  /** The customer's date of birth */
  date_of_birth?: Maybe<Scalars['String']>;
  /** Deprecated: Use `date_of_birth` instead */
  dob?: Maybe<Scalars['String']>;
  /** The customer's email address. Required for customer creation */
  email?: Maybe<Scalars['String']>;
  /** The customer's first name */
  firstname?: Maybe<Scalars['String']>;
  /** The customer's gender (Male - 1, Female - 2) */
  gender?: Maybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter */
  is_subscribed?: Maybe<Scalars['Boolean']>;
  job_title?: Maybe<Scalars['String']>;
  /** The customer's family name */
  lastname?: Maybe<Scalars['String']>;
  /** The customer's middle name */
  middlename?: Maybe<Scalars['String']>;
  /** The customer's password */
  password?: Maybe<Scalars['String']>;
  /** Customer phone number */
  phone_number: Scalars['String'];
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['String']>;
  /** A value such as Sr., Jr., or III */
  suffix?: Maybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers) */
  taxvat?: Maybe<Scalars['String']>;
};

export type CreateMaintenanceRequestInput = {
  brand: Scalars['String'];
  category: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  productModel: Scalars['String'];
  serialNumber: Scalars['String'];
  serviceType: Scalars['String'];
  storeLocation: Scalars['String'];
};

/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export type CreatePayflowProTokenOutput = {
  __typename?: 'CreatePayflowProTokenOutput';
  /** The RESPMSG returned by PayPal. If the `result` is `0`, then `response_message` is `Approved`. */
  response_message: Scalars['String'];
  /** A non-zero value if any errors occurred. */
  result: Scalars['Int'];
  /** The RESULT returned by PayPal. A value of `0` indicates the transaction was approved. */
  result_code: Scalars['Int'];
  /** A secure token generated by PayPal. */
  secure_token: Scalars['String'];
  /** A secure token ID generated by PayPal. */
  secure_token_id: Scalars['String'];
};

/** Defines a new product review. */
export type CreateProductReviewInput = {
  /** The customer's nickname. Defaults to the customer name, if logged in. */
  nickname: Scalars['String'];
  /** The ratings details by category. For example, Price: 5 stars, Quality: 4 stars, etc. */
  ratings: Array<Maybe<ProductReviewRatingInput>>;
  /** The SKU of the reviewed product. */
  sku: Scalars['String'];
  /** The summary (title) of the review. */
  summary: Scalars['String'];
  /** The review text. */
  text: Scalars['String'];
};

/** Contains the completed product review. */
export type CreateProductReviewOutput = {
  __typename?: 'CreateProductReviewOutput';
  /** Product review details. */
  review: ProductReview;
};

/** Required fields for Payflow Pro and Payments Pro credit card payments. */
export type CreditCardDetailsInput = {
  /** The credit card expiration month. */
  cc_exp_month: Scalars['Int'];
  /** The credit card expiration year. */
  cc_exp_year: Scalars['Int'];
  /** The last 4 digits of the credit card. */
  cc_last_4: Scalars['Int'];
  /** The credit card type. */
  cc_type: Scalars['String'];
};

export type CreditLimit = {
  __typename?: 'CreditLimit';
  amount: Scalars['Float'];
  months: Scalars['Int'];
  total_amount: Scalars['Float'];
};

/** Contains credit memo details. */
export type CreditMemo = {
  __typename?: 'CreditMemo';
  /** Comments on the credit memo. */
  comments?: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** The unique ID for a `CreditMemo` object. */
  id: Scalars['ID'];
  /** An array containing details about refunded items. */
  items?: Maybe<Array<Maybe<CreditMemoItemInterface>>>;
  /** The sequential credit memo number. */
  number: Scalars['String'];
  /** Details about the total refunded amount. */
  total?: Maybe<CreditMemoTotal>;
};

export type CreditMemoItem = CreditMemoItemInterface & {
  __typename?: 'CreditMemoItem';
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of refunded items. */
  quantity_refunded?: Maybe<Scalars['Float']>;
};

/** Credit memo item details. */
export type CreditMemoItemInterface = {
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of refunded items. */
  quantity_refunded?: Maybe<Scalars['Float']>;
};

/** Contains credit memo price details. */
export type CreditMemoTotal = {
  __typename?: 'CreditMemoTotal';
  /** An adjustment manually applied to the order. */
  adjustment: Money;
  /** The final base grand total amount in the base currency. */
  base_grand_total: Money;
  /** The applied discounts to the credit memo. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The final total amount, including shipping, discounts, and taxes. */
  grand_total: Money;
  /** Details about the shipping and handling costs for the credit memo. */
  shipping_handling?: Maybe<ShippingHandling>;
  /** The subtotal of the invoice, excluding shipping, discounts, and taxes. */
  subtotal: Money;
  /** The credit memo tax details. */
  taxes?: Maybe<Array<Maybe<TaxItem>>>;
  /** The shipping amount for the credit memo. */
  total_shipping: Money;
  /** The amount of tax applied to the credit memo. */
  total_tax: Money;
};

export type Currency = {
  __typename?: 'Currency';
  /** An array of three-letter currency codes accepted by the store, such as USD and EUR. */
  available_currency_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The base currency set for the store, such as USD. */
  base_currency_code?: Maybe<Scalars['String']>;
  /** The symbol for the specified base currency, such as $. */
  base_currency_symbol?: Maybe<Scalars['String']>;
  /** @deprecated Symbol was missed. Use `default_display_currency_code`. */
  default_display_currecy_code?: Maybe<Scalars['String']>;
  /** @deprecated Symbol was missed. Use `default_display_currency_code`. */
  default_display_currecy_symbol?: Maybe<Scalars['String']>;
  /** The currency that is displayed by default, such as USD. */
  default_display_currency_code?: Maybe<Scalars['String']>;
  /** The currency symbol that is displayed by default, such as $. */
  default_display_currency_symbol?: Maybe<Scalars['String']>;
  /** An array of exchange rates for currencies defined in the store. */
  exchange_rates?: Maybe<Array<Maybe<ExchangeRate>>>;
};

/** The list of available currency codes. */
export enum CurrencyEnum {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azm = 'AZM',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Buk = 'BUK',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Eek = 'EEK',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gek = 'GEK',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gqe = 'GQE',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lsm = 'LSM',
  Ltl = 'LTL',
  Lvl = 'LVL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mro = 'MRO',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nic = 'NIC',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Rhd = 'RHD',
  Rol = 'ROL',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Skk = 'SKK',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Std = 'STD',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmm = 'TMM',
  Tnd = 'TND',
  Top = 'TOP',
  Trl = 'TRL',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Veb = 'VEB',
  Vef = 'VEF',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xcd = 'XCD',
  Xof = 'XOF',
  Xpf = 'XPF',
  Yer = 'YER',
  Ytl = 'YTL',
  Zar = 'ZAR',
  Zmk = 'ZMK',
  Zwd = 'ZWD'
}

export type CustomAttribute = {
  __typename?: 'CustomAttribute';
  key: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
};

/** Defines an array of custom attributes. */
export type CustomAttributeMetadata = {
  __typename?: 'CustomAttributeMetadata';
  /** An array of attributes. */
  items?: Maybe<Array<Maybe<Attribute>>>;
};

/** Customer defines the customer name and address and other details */
export type Customer = {
  __typename?: 'Customer';
  /** An array containing the customer's shipping and billing addresses. */
  addresses?: Maybe<Array<Maybe<CustomerAddress>>>;
  all_installments_by_month?: Maybe<Array<Maybe<CustomerInstallmentByMonthOutput>>>;
  /** Indicates whether the customer has enabled remote shopping assistance. */
  allow_remote_shopping_assistance: Scalars['Boolean'];
  cards?: Maybe<Array<Maybe<Card>>>;
  company_name?: Maybe<Scalars['String']>;
  /** The contents of the customer's compare list. */
  compare_list?: Maybe<CompareList>;
  /** Timestamp indicating when the account was created. */
  created_at?: Maybe<Scalars['String']>;
  credit_limit_data?: Maybe<CustomerCreditLimitOutput>;
  custom_docs?: Maybe<Scalars['String']>;
  /** Get Customer Points */
  customer_points: CustomerPoints;
  /** The customer's date of birth. */
  date_of_birth?: Maybe<Scalars['String']>;
  /** The ID assigned to the billing address. */
  default_billing?: Maybe<Scalars['String']>;
  /** The ID assigned to the shipping address. */
  default_shipping?: Maybe<Scalars['String']>;
  /**
   * The customer's date of birth.
   * @deprecated Use `date_of_birth` instead.
   */
  dob?: Maybe<Scalars['String']>;
  /** The customer's email address. Required. */
  email?: Maybe<Scalars['String']>;
  /** The customer's first name. */
  firstname?: Maybe<Scalars['String']>;
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: Maybe<Scalars['Int']>;
  /** @deprecated Customer group should not be exposed in the storefront scenarios. */
  group_id?: Maybe<Scalars['Int']>;
  /**
   * The ID assigned to the customer.
   * @deprecated `id` is not needed as part of `Customer`, because on the server side, it can be identified based on the customer token used for authentication. There is no need to know customer ID on the client side.
   */
  id?: Maybe<Scalars['Int']>;
  installments?: Maybe<CustomerInstallmentOutput>;
  is_national_id_verified?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  is_subscribed?: Maybe<Scalars['Boolean']>;
  job_title?: Maybe<Scalars['String']>;
  /** The customer's family name. */
  lastname?: Maybe<Scalars['String']>;
  marketplace_orders?: Maybe<MarketplaceOrderOutput>;
  /** The customer's middle name. */
  middlename?: Maybe<Scalars['String']>;
  national_id?: Maybe<Scalars['String']>;
  national_photo?: Maybe<Scalars['String']>;
  orders?: Maybe<CustomerOrders>;
  phone_number: Scalars['String'];
  preferred_contact_method?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** Raya Elite Information */
  raya_elite_information?: Maybe<RayaEliteInformation>;
  /** Contains the customer's product reviews. */
  reviews: ProductReviews;
  salary?: Maybe<Scalars['String']>;
  selected_stock_source?: Maybe<Scalars['String']>;
  seller_data?: Maybe<SellerData>;
  seller_marketplace_orders?: Maybe<CustomerOrders>;
  seller_products: SellerProductOutput;
  /** Get seller transactions */
  seller_transactions: SellerTransactionsOutput;
  /** A value such as Sr., Jr., or III. */
  suffix?: Maybe<Scalars['String']>;
  /** The customer's Value-added tax (VAT) number (for corporate customers). */
  taxvat?: Maybe<Scalars['String']>;
  total_active_online_vouchers?: Maybe<Scalars['String']>;
  total_active_points?: Maybe<Scalars['String']>;
  total_active_vouchers?: Maybe<Scalars['String']>;
  upcoming_installments?: Maybe<CustomerInstallmentByMonthOutput>;
  /** An array containing the customer's viewed products */
  viewed_products?: Maybe<Products>;
  /**
   * Return a customer's wish lists.
   * @deprecated Use `Customer.wishlists` or `Customer.wishlist_v2` instead.
   */
  wishlist: Wishlist;
  /** Retrieve the wish list identified by the unique ID for a `Wishlist` object. */
  wishlist_v2?: Maybe<Wishlist>;
  /** An array of wishlists. In Magento Open Source, customers are limited to one wish list. The number of wish lists is configurable for Adobe Commerce. */
  wishlists: Array<Maybe<Wishlist>>;
};


/** Customer defines the customer name and address and other details */
export type CustomerInstallmentsArgs = {
  applicationId?: Maybe<Scalars['String']>;
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


/** Customer defines the customer name and address and other details */
export type CustomerMarketplace_OrdersArgs = {
  marketplaceOrderId?: Maybe<Scalars['String']>;
  orderIncrementId?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};


/** Customer defines the customer name and address and other details */
export type CustomerOrdersArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<CustomerOrdersFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
  scope?: Maybe<ScopeTypeEnum>;
  sort?: Maybe<CustomerOrderSortInput>;
};


/** Customer defines the customer name and address and other details */
export type CustomerReviewsArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


/** Customer defines the customer name and address and other details */
export type CustomerSeller_Marketplace_OrdersArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<CustomerOrdersFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
};


/** Customer defines the customer name and address and other details */
export type CustomerSeller_ProductsArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<ProductAttributeFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
};


/** Customer defines the customer name and address and other details */
export type CustomerSeller_TransactionsArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<SellerTransactionFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
};


/** Customer defines the customer name and address and other details */
export type CustomerViewed_ProductsArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


/** Customer defines the customer name and address and other details */
export type CustomerWishlist_V2Args = {
  id: Scalars['ID'];
};


/** Customer defines the customer name and address and other details */
export type CustomerWishlistsArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

/** CustomerAddress contains detailed information about a customer's billing and shipping addresses */
export type CustomerAddress = {
  __typename?: 'CustomerAddress';
  address_name?: Maybe<Scalars['String']>;
  apartment?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  /** An object containing the city name, city code, and city ID */
  city?: Maybe<City>;
  /** The customer's company. */
  company?: Maybe<Scalars['String']>;
  /** An object containing the country name, country code, and country ID */
  country?: Maybe<Country>;
  /** The customer's country. */
  country_code?: Maybe<CountryCodeEnum>;
  /**
   * The customer's country.
   * @deprecated Use `country_code` instead.
   */
  country_id?: Maybe<Scalars['String']>;
  /** @deprecated Custom attributes should not be put into a container. */
  custom_attributes?: Maybe<Array<Maybe<CustomerAddressAttribute>>>;
  /**
   * The customer ID
   * @deprecated `customer_id` is not needed as part of `CustomerAddress`. The `id` is a unique identifier for the addresses.
   */
  customer_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the address is the customer's default billing address. */
  default_billing?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the address is the customer's default shipping address. */
  default_shipping?: Maybe<Scalars['Boolean']>;
  /** Contains any extension attributes for the address. */
  extension_attributes?: Maybe<Array<Maybe<CustomerAddressAttribute>>>;
  /** The customer's fax number. */
  fax?: Maybe<Scalars['String']>;
  /** The first name of the person associated with the shipping/billing address. */
  firstname?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['Int']>;
  /** The ID of a `CustomerAddress` object. */
  id?: Maybe<Scalars['Int']>;
  /** The family name of the person associated with the shipping/billing address. */
  lastname?: Maybe<Scalars['String']>;
  /** The middle name of the person associated with the shipping/billing address. */
  middlename?: Maybe<Scalars['String']>;
  /** The customer's ZIP or postal code. */
  postcode?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** An object containing the region name, region code, and region ID. */
  region?: Maybe<CustomerAddressRegion>;
  /** The unique ID for a pre-defined region. */
  region_id?: Maybe<Scalars['Int']>;
  /** An array of strings that define the street number and name. */
  street?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A value such as Sr., Jr., or III. */
  suffix?: Maybe<Scalars['String']>;
  /** The customer's telephone number. */
  telephone?: Maybe<Scalars['String']>;
  /** The customer's Value-added tax (VAT) number (for corporate customers). */
  vat_id?: Maybe<Scalars['String']>;
};

/** Specifies the attribute code and value of a customer address attribute. */
export type CustomerAddressAttribute = {
  __typename?: 'CustomerAddressAttribute';
  /** The name assigned to the customer address attribute. */
  attribute_code?: Maybe<Scalars['String']>;
  /** The valuue assigned to the customer address attribute. */
  value?: Maybe<Scalars['String']>;
};

/** Specifies the attribute code and value of a customer attribute. */
export type CustomerAddressAttributeInput = {
  /** The name assigned to the attribute. */
  attribute_code: Scalars['String'];
  /** The value assigned to the attribute. */
  value: Scalars['String'];
};

/** Contains details about a billing or shipping address. */
export type CustomerAddressInput = {
  address_name?: Maybe<Scalars['String']>;
  apartment?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  /** The customer's city or town. */
  city?: Maybe<Scalars['String']>;
  /** The customer's company. */
  company?: Maybe<Scalars['String']>;
  /** The two-letter code representing the customer's country. */
  country_code?: Maybe<CountryCodeEnum>;
  /** Deprecated: use `country_code` instead. */
  country_id?: Maybe<CountryCodeEnum>;
  /** Deprecated: Custom attributes should not be put into container. */
  custom_attributes?: Maybe<Array<Maybe<CustomerAddressAttributeInput>>>;
  /** Indicates whether the address is the default billing address. */
  default_billing?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the address is the default shipping address. */
  default_shipping?: Maybe<Scalars['Boolean']>;
  /** The customer's fax number. */
  fax?: Maybe<Scalars['String']>;
  /** The first name of the person associated with the billing/shipping address. */
  firstname?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['Int']>;
  /** The family name of the person associated with the billing/shipping address. */
  lastname?: Maybe<Scalars['String']>;
  /** The middle name of the person associated with the billing/shipping address. */
  middlename?: Maybe<Scalars['String']>;
  /** The customer's ZIP or postal code. */
  postcode?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** An object containing the region name, region code, and region ID. */
  region?: Maybe<CustomerAddressRegionInput>;
  /** An array of strings that define the street number and name. */
  street?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A value such as Sr., Jr., or III. */
  suffix?: Maybe<Scalars['String']>;
  /** The customer's telephone number. */
  telephone?: Maybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers). */
  vat_id?: Maybe<Scalars['String']>;
};

/** Defines the customer's state or province. */
export type CustomerAddressRegion = {
  __typename?: 'CustomerAddressRegion';
  /** The state or province name. */
  region?: Maybe<Scalars['String']>;
  /** The address region code. */
  region_code?: Maybe<Scalars['String']>;
  /** The unique ID for a pre-defined region. */
  region_id?: Maybe<Scalars['Int']>;
};

/** Defines the customer's state or province. */
export type CustomerAddressRegionInput = {
  /** The state or province name. */
  region?: Maybe<Scalars['String']>;
  /** The address region code. */
  region_code?: Maybe<Scalars['String']>;
  /** The unique ID for a pre-defined region. */
  region_id?: Maybe<Scalars['Int']>;
};

export type CustomerCoupon = {
  __typename?: 'CustomerCoupon';
  /** Coupon Code */
  coupon_code?: Maybe<Scalars['String']>;
};

/** An input object for creating a customer. */
export type CustomerCreateInput = {
  /** Indicates whether the customer has enabled remote shopping assistance. */
  allow_remote_shopping_assistance?: Maybe<Scalars['Boolean']>;
  /** The customer's date of birth. */
  date_of_birth?: Maybe<Scalars['String']>;
  /** Deprecated: Use `date_of_birth` instead. */
  dob?: Maybe<Scalars['String']>;
  /** The customer's email address. */
  email: Scalars['String'];
  /** The customer's first name. */
  firstname: Scalars['String'];
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: Maybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  is_subscribed?: Maybe<Scalars['Boolean']>;
  /** The customer's family name. */
  lastname: Scalars['String'];
  /** The customer's middle name. */
  middlename?: Maybe<Scalars['String']>;
  /** The customer's password. */
  password?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** A value such as Sr., Jr., or III. */
  suffix?: Maybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers). */
  taxvat?: Maybe<Scalars['String']>;
};

export type CustomerCreditLimitOutput = {
  __typename?: 'CustomerCreditLimitOutput';
  credit_limit?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  national_id?: Maybe<Scalars['String']>;
};

/** Contains details about a single downloadable product. */
export type CustomerDownloadableProduct = {
  __typename?: 'CustomerDownloadableProduct';
  /** The date and time the purchase was made. */
  date?: Maybe<Scalars['String']>;
  /** The fully qualified URL to the download file. */
  download_url?: Maybe<Scalars['String']>;
  /** The unique ID assigned to the item. */
  order_increment_id?: Maybe<Scalars['String']>;
  /** The remaining number of times the customer can download the product. */
  remaining_downloads?: Maybe<Scalars['String']>;
  /** Indicates when the product becomes available for download. Options are `Pending` and `Invoiced`. */
  status?: Maybe<Scalars['String']>;
};

/** Contains a list of downloadable products. */
export type CustomerDownloadableProducts = {
  __typename?: 'CustomerDownloadableProducts';
  /** An array of purchased downloadable items. */
  items?: Maybe<Array<Maybe<CustomerDownloadableProduct>>>;
};

/** An input object that assigns or updates customer attributes. */
export type CustomerInput = {
  company_name?: Maybe<Scalars['String']>;
  custom_docs?: Maybe<InstallmentsApplicationPhotoFileInput>;
  /** The customer's date of birth. */
  date_of_birth?: Maybe<Scalars['String']>;
  /** Deprecated: Use `date_of_birth` instead. */
  dob?: Maybe<Scalars['String']>;
  /** The customer's email address. Required when creating a customer. */
  email?: Maybe<Scalars['String']>;
  /** The customer's first name. */
  firstname?: Maybe<Scalars['String']>;
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: Maybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  is_subscribed?: Maybe<Scalars['Boolean']>;
  job_title?: Maybe<Scalars['String']>;
  /** The customer's family name. */
  lastname?: Maybe<Scalars['String']>;
  /** The customer's middle name. */
  middlename?: Maybe<Scalars['String']>;
  national_photo?: Maybe<InstallmentsApplicationPhotoFileInput>;
  /** The customer's password. */
  password?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['String']>;
  /** A value such as Sr., Jr., or III. */
  suffix?: Maybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers). */
  taxvat?: Maybe<Scalars['String']>;
};

export type CustomerInstallment = {
  __typename?: 'CustomerInstallment';
  application_id?: Maybe<Scalars['String']>;
  bills?: Maybe<Array<Maybe<CustomerInstallmentBills>>>;
  down_payment_amount?: Maybe<Scalars['Float']>;
  paid_amount?: Maybe<Scalars['Float']>;
  paid_months?: Maybe<Scalars['Int']>;
  total_amount?: Maybe<Scalars['Float']>;
  total_months?: Maybe<Scalars['Int']>;
};

export type CustomerInstallmentBills = {
  __typename?: 'CustomerInstallmentBills';
  amount?: Maybe<Scalars['Float']>;
  due_date?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  penalty?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
};

export type CustomerInstallmentByMonth = {
  __typename?: 'CustomerInstallmentByMonth';
  due_date?: Maybe<Scalars['Int']>;
  installments?: Maybe<Array<Maybe<CustomerInstallment>>>;
  is_due?: Maybe<Scalars['Boolean']>;
  month?: Maybe<Scalars['Int']>;
  total_amount?: Maybe<Scalars['Float']>;
  total_count?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type CustomerInstallmentByMonthOutput = {
  __typename?: 'CustomerInstallmentByMonthOutput';
  upcoming?: Maybe<CustomerInstallmentByMonth>;
};

export type CustomerInstallmentOutput = {
  __typename?: 'CustomerInstallmentOutput';
  items?: Maybe<Array<Maybe<CustomerInstallment>>>;
  /** An object that includes the current_page, page_info, and page_size values specified in the query */
  page_info?: Maybe<SearchResultPageInfo>;
  /** The total count of customer installments */
  total_count?: Maybe<Scalars['Int']>;
};

/** Contains details about each of the customer's orders. */
export type CustomerOrder = {
  __typename?: 'CustomerOrder';
  acceptIframeUrl?: Maybe<Scalars['String']>;
  /** Accept installment plan payment fees */
  accept_installment_plan_fees?: Maybe<Scalars['Float']>;
  /** Accept installment plan payment fees */
  accept_installment_plan_interest_amount?: Maybe<Scalars['Float']>;
  /** The Order aramex airway slip. */
  aramex_airway_slip?: Maybe<Scalars['String']>;
  /** The billing address for the order. */
  billing_address?: Maybe<OrderAddress>;
  /** The shipping carrier for the order delivery. */
  carrier?: Maybe<Scalars['String']>;
  /** Comments about the order. */
  comments?: Maybe<Array<Maybe<SalesCommentItem>>>;
  contact_method?: Maybe<Scalars['String']>;
  coupon_code?: Maybe<Scalars['String']>;
  /** @deprecated Use the `order_date` field instead. */
  created_at?: Maybe<Scalars['String']>;
  /** A list of credit memos. */
  credit_memos?: Maybe<Array<Maybe<CreditMemo>>>;
  /** Get order rating by a customer */
  customer_rating?: Maybe<Array<Maybe<OrderRateSingleCriteria>>>;
  /** The Order delivery date. */
  delivery_date?: Maybe<Scalars['String']>;
  details?: Maybe<CustomerOrderDetails>;
  email: Scalars['String'];
  fee: Scalars['Float'];
  /** The entered gift message for the order */
  gift_message?: Maybe<GiftMessage>;
  /** @deprecated Use the `totals.grand_total` field instead. */
  grand_total?: Maybe<Scalars['Float']>;
  /** The unique ID for a `CustomerOrder` object. */
  id: Scalars['ID'];
  /** The Order in_process_timestamp. */
  in_process_timestamp?: Maybe<Scalars['String']>;
  /** @deprecated Use the `id` field instead. */
  increment_id?: Maybe<Scalars['String']>;
  /** A list of invoices for the order. */
  invoices: Array<Maybe<Invoice>>;
  is_cancelable?: Maybe<Scalars['Boolean']>;
  is_refundable_for_cancel?: Maybe<Scalars['Boolean']>;
  /** get is refundable for return or not */
  is_refundable_for_return: Scalars['Boolean'];
  /** An array containing the items purchased in this order. */
  items?: Maybe<Array<Maybe<OrderItemInterface>>>;
  mpgsUrl?: Maybe<Scalars['String']>;
  /** The order number. */
  number: Scalars['String'];
  /** The date the order was placed. */
  order_date: Scalars['String'];
  order_medium_source?: Maybe<Scalars['String']>;
  /** @deprecated Use the `number` field instead. */
  order_number: Scalars['String'];
  /** Returnable details if order is returnable, null if order is not returnable */
  order_returnable_details?: Maybe<IsOrderReturnableOutput>;
  order_source?: Maybe<Scalars['String']>;
  pay_downpayment_at_branch?: Maybe<Scalars['Boolean']>;
  payment_fee: Scalars['Float'];
  /** Payment details for the order. */
  payment_methods?: Maybe<Array<Maybe<OrderPaymentMethod>>>;
  /** Order online payment order reference */
  payment_reference?: Maybe<Scalars['String']>;
  /** Order online payment transaction id */
  payment_transaction_id?: Maybe<Scalars['String']>;
  products?: Maybe<CustomerOrderProducts>;
  /** return requests details */
  return_requests?: Maybe<Array<Maybe<ReturnRequestsOutput>>>;
  seen?: Maybe<Scalars['Boolean']>;
  /** The Order seller. */
  seller?: Maybe<Customer>;
  /** A list of shipments for the order. */
  shipments?: Maybe<Array<Maybe<OrderShipment>>>;
  /** The shipping address for the order. */
  shipping_address?: Maybe<OrderAddress>;
  /** The delivery method for the order. */
  shipping_method?: Maybe<Scalars['String']>;
  /** The delivery method code for the order. */
  shipping_method_code?: Maybe<Scalars['String']>;
  sign_at_branch?: Maybe<Scalars['Boolean']>;
  /** The current status of the order. */
  status: Scalars['String'];
  status_code?: Maybe<Scalars['String']>;
  status_label?: Maybe<Scalars['String']>;
  stock_source?: Maybe<StockSourceItem>;
  /** Get Order's Store Pickup Sources */
  store_pickup_sources?: Maybe<Array<Maybe<AmsStoreLocator>>>;
  /** Details about the calculated totals for this order. */
  total?: Maybe<OrderTotal>;
  /** The Order raya order status. */
  tracking_order_status?: Maybe<OrderStatus>;
  /** The Order updated_at. */
  updated_at?: Maybe<Scalars['String']>;
};


/** Contains details about each of the customer's orders. */
export type CustomerOrderMpgsUrlArgs = {
  installments_months?: Maybe<Scalars['Int']>;
  return_url: Scalars['String'];
};

export type CustomerOrderDetails = {
  __typename?: 'CustomerOrderDetails';
  cancel_request?: Maybe<OrderCancelRequestStatus>;
  discount?: Maybe<Scalars['Float']>;
  payment_details?: Maybe<CustomerOrderDetailsPaymentMethod>;
  shipping_details?: Maybe<CustomerOrderDetailsShippingDetails>;
  subtotal?: Maybe<Scalars['Float']>;
};

export type CustomerOrderDetailsPaymentMethod = {
  __typename?: 'CustomerOrderDetailsPaymentMethod';
  code?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type CustomerOrderDetailsShippingAddress = {
  __typename?: 'CustomerOrderDetailsShippingAddress';
  address?: Maybe<Scalars['String']>;
  mobile_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CustomerOrderDetailsShippingDetails = {
  __typename?: 'CustomerOrderDetailsShippingDetails';
  address?: Maybe<CustomerOrderDetailsShippingAddress>;
  cost?: Maybe<Scalars['Float']>;
  method?: Maybe<Scalars['String']>;
  method_code?: Maybe<Scalars['String']>;
};

export type CustomerOrderProduct = {
  __typename?: 'CustomerOrderProduct';
  id: Scalars['Int'];
  name: Scalars['String'];
  price_after: Scalars['Float'];
  price_after_including_tax: Scalars['Float'];
  price_before: Scalars['Float'];
  price_before_including_tax: Scalars['Float'];
  product?: Maybe<ProductInterface>;
  quantity: Scalars['Float'];
  row_total: Scalars['Float'];
  row_total_including_tax: Scalars['Float'];
  sku: Scalars['String'];
};

export type CustomerOrderProducts = {
  __typename?: 'CustomerOrderProducts';
  count: Scalars['Int'];
  items: Array<Maybe<CustomerOrderProduct>>;
};

/** CustomerOrderSortInput specifies the field to use for sorting search results and indicates whether the results are sorted in ascending or descending order. */
export type CustomerOrderSortInput = {
  /** This enumeration indicates whether to return results in ascending or descending order */
  sort_direction: SortEnum;
  /** Specifies the field to use for sorting */
  sort_field: CustomerOrderSortableField;
};

/** Specifies the field to use for sorting */
export enum CustomerOrderSortableField {
  /** Sorts customer orders by created_at field */
  CreatedAt = 'CREATED_AT',
  /** Sorts customer orders by number */
  Number = 'NUMBER'
}

/** The collection of orders that match the conditions defined in the filter. */
export type CustomerOrders = {
  __typename?: 'CustomerOrders';
  /** An array of customer orders. */
  items: Array<Maybe<CustomerOrder>>;
  /** Contains pagination metadata. */
  page_info?: Maybe<SearchResultPageInfo>;
  /** The total count of customer orders. */
  total_count?: Maybe<Scalars['Int']>;
};

/** Identifies the filter to use for filtering orders. */
export type CustomerOrdersFilterInput = {
  created_at?: Maybe<FilterRangeTypeInput>;
  /** Filters by order number. */
  number?: Maybe<FilterStringTypeInput>;
  /** Filters by payment_reference. */
  payment_reference?: Maybe<FilterStringTypeInput>;
  /** Filters by payment_transaction_id. */
  payment_transaction_id?: Maybe<FilterStringTypeInput>;
  status?: Maybe<FilterStringTypeInput>;
};

/** Contains details about a newly-created or updated customer. */
export type CustomerOutput = {
  __typename?: 'CustomerOutput';
  /** Customer details after creating or updating a customer. */
  customer: Customer;
};

/** Contains payment tokens stored in the customer's vault. */
export type CustomerPaymentTokens = {
  __typename?: 'CustomerPaymentTokens';
  /** An array of payment tokens. */
  items: Array<Maybe<PaymentToken>>;
};

export type CustomerPoints = {
  __typename?: 'CustomerPoints';
  current_points?: Maybe<Scalars['String']>;
  expire_date?: Maybe<Scalars['String']>;
  points_to_expire?: Maybe<Scalars['String']>;
  /** Get Customer Transactions */
  transactions: Array<Maybe<Transaction>>;
};

/** Contains a customer authorization token. */
export type CustomerToken = {
  __typename?: 'CustomerToken';
  refreshToken: Scalars['String'];
  /** in timestamp */
  refresh_token_expires_at: Scalars['String'];
  /** The customer authorization token. */
  token?: Maybe<Scalars['String']>;
  /** in timestamp */
  token_expires_at: Scalars['String'];
};

/** An input object for updating a customer. */
export type CustomerUpdateInput = {
  /** Indicates whether the customer has enabled remote shopping assistance. */
  allow_remote_shopping_assistance?: Maybe<Scalars['Boolean']>;
  /** The customer's date of birth. */
  date_of_birth?: Maybe<Scalars['String']>;
  /** Deprecated: Use `date_of_birth` instead. */
  dob?: Maybe<Scalars['String']>;
  /** The customer's first name. */
  firstname?: Maybe<Scalars['String']>;
  /** The customer's gender (Male - 1, Female - 2). */
  gender?: Maybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter. */
  is_subscribed?: Maybe<Scalars['Boolean']>;
  /** The customer's family name. */
  lastname?: Maybe<Scalars['String']>;
  /** The customer's middle name. */
  middlename?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** A value such as Sr., Jr., or III. */
  suffix?: Maybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers). */
  taxvat?: Maybe<Scalars['String']>;
};

/** Contains information about a text area that is defined as part of a customizable option. */
export type CustomizableAreaOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableAreaOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An object that defines a text area. */
  value?: Maybe<CustomizableAreaValue>;
};

/** Defines the price and sku of a product whose page contains a customized text area. */
export type CustomizableAreaValue = {
  __typename?: 'CustomizableAreaValue';
  /** The maximum number of characters that can be entered for this customizable option. */
  max_characters?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableAreaValue` object. */
  uid: Scalars['ID'];
};

/** Contains information about a set of checkbox values that are defined as part of a customizable option. */
export type CustomizableCheckboxOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableCheckboxOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An array that defines a set of checkbox values. */
  value?: Maybe<Array<Maybe<CustomizableCheckboxValue>>>;
};

/** Defines the price and sku of a product whose page contains a customized set of checkbox values. */
export type CustomizableCheckboxValue = {
  __typename?: 'CustomizableCheckboxValue';
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The order in which the checkbox value is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableCheckboxValue` object. */
  uid: Scalars['ID'];
};

/** Contains information about a date picker that is defined as part of a customizable option. */
export type CustomizableDateOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableDateOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An object that defines a date field in a customizable option. */
  value?: Maybe<CustomizableDateValue>;
};

/** Defines the customizable date type. */
export enum CustomizableDateTypeEnum {
  Date = 'DATE',
  DateTime = 'DATE_TIME',
  Time = 'TIME'
}

/** Defines the price and sku of a product whose page contains a customized date picker. */
export type CustomizableDateValue = {
  __typename?: 'CustomizableDateValue';
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** DATE, DATE_TIME or TIME */
  type?: Maybe<CustomizableDateTypeEnum>;
  /** The unique ID for a `CustomizableDateValue` object. */
  uid: Scalars['ID'];
};

/** Contains information about a drop down menu that is defined as part of a customizable option. */
export type CustomizableDropDownOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableDropDownOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An array that defines the set of options for a drop down menu. */
  value?: Maybe<Array<Maybe<CustomizableDropDownValue>>>;
};

/** Defines the price and sku of a product whose page contains a customized drop down menu. */
export type CustomizableDropDownValue = {
  __typename?: 'CustomizableDropDownValue';
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableDropDownValue` object. */
  uid: Scalars['ID'];
};

/** Contains information about a text field that is defined as part of a customizable option. */
export type CustomizableFieldOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableFieldOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An object that defines a text field. */
  value?: Maybe<CustomizableFieldValue>;
};

/** Defines the price and sku of a product whose page contains a customized text field. */
export type CustomizableFieldValue = {
  __typename?: 'CustomizableFieldValue';
  /** The maximum number of characters that can be entered for this customizable option. */
  max_characters?: Maybe<Scalars['Int']>;
  /** The price of the custom value. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableFieldValue` object. */
  uid: Scalars['ID'];
};

/** Contains information about a file picker that is defined as part of a customizable option. */
export type CustomizableFileOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableFileOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An object that defines a file value. */
  value?: Maybe<CustomizableFileValue>;
};

/** Defines the price and sku of a product whose page contains a customized file picker. */
export type CustomizableFileValue = {
  __typename?: 'CustomizableFileValue';
  /** The file extension to accept. */
  file_extension?: Maybe<Scalars['String']>;
  /** The maximum width of an image. */
  image_size_x?: Maybe<Scalars['Int']>;
  /** The maximum height of an image. */
  image_size_y?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableFileValue` object. */
  uid: Scalars['ID'];
};

/** Contains information about a multiselect that is defined as part of a customizable option. */
export type CustomizableMultipleOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableMultipleOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An array that defines the set of options for a multiselect. */
  value?: Maybe<Array<Maybe<CustomizableMultipleValue>>>;
};

/** Defines the price and sku of a product whose page contains a customized multiselect. */
export type CustomizableMultipleValue = {
  __typename?: 'CustomizableMultipleValue';
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableMultipleValue` object. */
  uid: Scalars['ID'];
};

/** Defines a customizable option. */
export type CustomizableOptionInput = {
  /** The customizable option ID of the product. */
  id?: Maybe<Scalars['Int']>;
  /** The string value of the option. */
  value_string: Scalars['String'];
};

/** Contains basic information about a customizable option. It can be implemented by several types of configurable options. */
export type CustomizableOptionInterface = {
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
};

/** Contains information about customizable product options. */
export type CustomizableProductInterface = {
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
};

/** Contains information about a set of radio buttons that are defined as part of a customizable option. */
export type CustomizableRadioOption = CustomizableOptionInterface & {
  __typename?: 'CustomizableRadioOption';
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An array that defines a set of radio buttons. */
  value?: Maybe<Array<Maybe<CustomizableRadioValue>>>;
};

/** Defines the price and sku of a product whose page contains a customized set of radio buttons. */
export type CustomizableRadioValue = {
  __typename?: 'CustomizableRadioValue';
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The order in which the radio button is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableRadioValue` object. */
  uid: Scalars['ID'];
};

/** Contains the results of the request to delete a compare list. */
export type DeleteCompareListOutput = {
  __typename?: 'DeleteCompareListOutput';
  /** Indicates whether the compare list was successfully deleted. */
  result: Scalars['Boolean'];
};

/** Indicates whether the request succeeded and returns the remaining customer payment tokens. */
export type DeletePaymentTokenOutput = {
  __typename?: 'DeletePaymentTokenOutput';
  /** A container for the customer's remaining payment tokens. */
  customerPaymentTokens?: Maybe<CustomerPaymentTokens>;
  /** Indicates whether the request succeeded. */
  result: Scalars['Boolean'];
};

/** Defines an individual discount. A discount can be applied to the cart as a whole or to an item. */
export type Discount = {
  __typename?: 'Discount';
  /** The amount of the discount. */
  amount: Money;
  /** A description of the discount. */
  label: Scalars['String'];
};

export type DismissPopupInput = {
  match_id: Scalars['String'];
};

export enum DownPaymentPaymentMethod {
  Cash = 'CASH',
  CreditCard = 'CREDIT_CARD'
}

export type DownPaymentWithPlans = {
  __typename?: 'DownPaymentWithPlans';
  minimum_down_payment: Scalars['Float'];
  plans: Array<Maybe<Scalars['Int']>>;
};

/** An implementation for downloadable product cart items. */
export type DownloadableCartItem = CartItemInterface & {
  __typename?: 'DownloadableCartItem';
  /** An array containing the customizable options the shopper selected. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors?: Maybe<Array<Maybe<CartItemError>>>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** An array containing information about the links for the downloadable product added to the cart. */
  links?: Maybe<Array<Maybe<DownloadableProductLinks>>>;
  /** Contains details about the price of the item, including taxes and discounts. */
  prices?: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  /** An array containing information about samples of the selected downloadable product. */
  samples?: Maybe<Array<Maybe<DownloadableProductSamples>>>;
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
};

/** Defines downloadable product options for `CreditMemoItemInterface`. */
export type DownloadableCreditMemoItem = CreditMemoItemInterface & {
  __typename?: 'DownloadableCreditMemoItem';
  /** Details about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** A list of downloadable links that are refunded from the downloadable product. */
  downloadable_links?: Maybe<Array<Maybe<DownloadableItemsLinks>>>;
  /** The unique ID for a `CreditMemoItemInterface` object. */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of refunded items. */
  quantity_refunded?: Maybe<Scalars['Float']>;
};

export enum DownloadableFileTypeEnum {
  File = 'FILE',
  Url = 'URL'
}

/** Defines downloadable product options for `InvoiceItemInterface`. */
export type DownloadableInvoiceItem = InvoiceItemInterface & {
  __typename?: 'DownloadableInvoiceItem';
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** A list of downloadable links that are invoiced from the downloadable product. */
  downloadable_links?: Maybe<Array<Maybe<DownloadableItemsLinks>>>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID'];
  /** Details about an individual order item. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of invoiced items. */
  quantity_invoiced?: Maybe<Scalars['Float']>;
};

/** Defines characteristics of the links for downloadable product. */
export type DownloadableItemsLinks = {
  __typename?: 'DownloadableItemsLinks';
  /** A number indicating the sort order. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name of the link. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `DownloadableItemsLinks` object. */
  uid: Scalars['ID'];
};

/** Defines downloadable product options for `OrderItemInterface`. */
export type DownloadableOrderItem = OrderItemInterface & {
  __typename?: 'DownloadableOrderItem';
  /** The final discount information for the product. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** A list of downloadable links that are ordered from the downloadable product. */
  downloadable_links?: Maybe<Array<Maybe<DownloadableItemsLinks>>>;
  /** The entered option for the base product, such as a logo or image. */
  entered_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The selected gift message for the order item */
  gift_message?: Maybe<GiftMessage>;
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID'];
  original_price?: Maybe<Scalars['Float']>;
  price_incl_tax?: Maybe<Scalars['Float']>;
  /** The product brand. */
  product_brand?: Maybe<Brand>;
  /** The categories assigned to the product. */
  product_categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  product_data?: Maybe<ProductInterface>;
  product_image?: Maybe<Scalars['String']>;
  /** The URL of the main product image of the base product */
  product_image_url?: Maybe<Scalars['String']>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The original price of the base product */
  product_original_price: Money;
  /** The sale price of the base product, including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The URL of the thumbnail image of the base product */
  product_thumbnail_url?: Maybe<Scalars['String']>;
  /** The type of product, such as simple, configurable, etc. */
  product_type?: Maybe<Scalars['String']>;
  /** URL key of the base product. */
  product_url_key?: Maybe<Scalars['String']>;
  /** The number of canceled items. */
  quantity_canceled?: Maybe<Scalars['Float']>;
  /** The number of invoiced items. */
  quantity_invoiced?: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item. */
  quantity_ordered?: Maybe<Scalars['Float']>;
  /** The number of refunded items. */
  quantity_refunded?: Maybe<Scalars['Float']>;
  /** The number of returned items. */
  quantity_returned?: Maybe<Scalars['Float']>;
  /** The number of shipped items. */
  quantity_shipped?: Maybe<Scalars['Float']>;
  row_total_incl_tax?: Maybe<Scalars['Float']>;
  /** The selected options for the base product, such as color or size. */
  selected_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item. */
  status?: Maybe<Scalars['String']>;
};

/** Defines a product that the shopper downloads. */
export type DownloadableProduct = CustomizableProductInterface & ProductInterface & RoutableInterface & {
  __typename?: 'DownloadableProduct';
  /** @deprecated Use the `custom_attributes` field instead. */
  Keyboards_backlit?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_cooling_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_cooling_system?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_coverage_area?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_horsepower?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_inverter_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_plasma_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_turbo_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessories_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  armrest_direction?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  as_featured?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  at_coverage_area?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  at_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  at_type?: Maybe<Scalars['Int']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  /** List of product attributes */
  attributes?: Maybe<Array<Maybe<CustomAttribute>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  audio_type?: Maybe<Scalars['Int']>;
  /**
   * Does the product has COD
   * @deprecated Use the `custom_attributes` field instead.
   */
  available_cod?: Maybe<Scalars['Int']>;
  available_vouchers?: Maybe<Array<Maybe<AvailableVoucher>>>;
  /**
   * The product average reviews.
   * @deprecated Use the `custom_attributes` field instead.
   */
  average_rating?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_size?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_size_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_target_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  barcode?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  battery_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  battery_rechargeable_battery?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  battery_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bike_num_of_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bike_types?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bike_wheel_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  blender_type?: Maybe<Scalars['Int']>;
  /** Product Content Blocks */
  blocks?: Maybe<Array<Maybe<Block>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_type?: Maybe<Scalars['Int']>;
  /** The product brand. */
  brand?: Maybe<Brand>;
  bundle_packs: Array<Maybe<BundlePack>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cables_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cables_length?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cables_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_acc_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_memory_cardslot?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_memory_internal?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_package_components?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_ports?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_shooting_contin_shooting?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_acc_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_battery?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_digital_camera_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_features_primary_camera?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_features_secondary?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_flash?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_focus_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_maximum_aperture?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_maximum_focal_length?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_minimum_aperture?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_minimum_focal_length?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_megapixel?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_optical_zoom?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_screen_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_touch_screen?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_usb?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_wifi?: Maybe<Scalars['Int']>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonical_url?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  car_acc_type?: Maybe<Scalars['Int']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chair_height_djustability?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chair_maximum_height?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chargers_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chargers_output?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chargers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  clocks_built_in_alarm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  clocks_clock_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  clocks_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_grinder_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_bar_pressure?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_beverage_form?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_cups?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_grinder?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  comp_lap_accessories_compitable_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  comp_lap_accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  connector_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  connector_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_console_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_hard_disk_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_hdmi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_included_games?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_number_of_controllers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_fan?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_flames?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_grill?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_holders_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_ignition?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_safety?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_filter_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_number_of_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_remote_control_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_wheels_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  copy_resolution?: Maybe<Scalars['String']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_compatible_with_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_compatible_with_model?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_type?: Maybe<Scalars['Int']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  custom_label_1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  custom_label_2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  custom_pages_ids?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_automatic_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  delivery_time?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  delivery_time_inside_cairo_giz?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dental_care_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_form?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_type?: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_half_load?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_number_of_programs?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_sanitization?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_type?: Maybe<Scalars['Int']>;
  /** An array containing information about the links for this downloadable product. */
  downloadable_product_links?: Maybe<Array<Maybe<DownloadableProductLinks>>>;
  /** An array containing information about samples of this downloadable product. */
  downloadable_product_samples?: Maybe<Array<Maybe<DownloadableProductSamples>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_loading_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_rpm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_steam_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dvr_channel_number?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dvr_input?: Maybe<Scalars['String']>;
  /** Product Short Dynamic Link */
  dynamic_link?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_light_color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_outlet?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_watt?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  express_shipping_time?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_age_group?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_age_range?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_color?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_decor_style?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_digital_zoom?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_display_technology?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_finish?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_gemstone?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_gender?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_health_concern?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_ingredients?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_is_assembly_required?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_material?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_maximum_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_megapixels?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_minimum_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_model?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_number_of_licenses?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_operating_system?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_optical_zoom?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_package_quantity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_pattern?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_depth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_form?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_height?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_length?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_width?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_recommended_use?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_scent?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_screen_size?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_shoe_width?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_size?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_storage_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_style?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_system_requirements?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_thread_count?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_video_game_platform?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_diameter_in_inches?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_net_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_number_of_blades?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_type?: Maybe<Scalars['Int']>;
  featured_bullet_attributes_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_targeted_age_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_cap_lit?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_cap_lit_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_cooling_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_number_of_drawers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  game_access_compitable?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  game_accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  game_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gaming_controller_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gaming_controller_connectivity?: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_acc_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_dryer_power?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_dryer_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_style_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_style_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_styler_auto_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hand_tools_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hardware_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hardware_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  has_deal?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_battery?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_microphone?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_noise_cancellation?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_fan?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_heat_settings?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_number_of_fins?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_overheating_protection?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heavy_item?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  height?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_flames?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_holders_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_ignition?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_power_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_safety?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  home_decor_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_air_flow_rate?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_filters?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_motors?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_offers?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_plate_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_plate_number_plate?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_plate_power_consumption?: Maybe<Scalars['Int']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ideal_installing_frame_dimensions?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ink_color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ink_compitable_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  installment_offers?: Maybe<Scalars['String']>;
  /** The product qualified installment plans. */
  installments?: Maybe<InstallmentOutput>;
  /** @deprecated Use the `custom_attributes` field instead. */
  intercome_number_way?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  intercome_video_support?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_base_plate_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_vertical_steam_shot?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_air_condition?: Maybe<Scalars['Int']>;
  /** Check if product is a best seller */
  is_best_seller?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_component?: Maybe<Scalars['Int']>;
  is_has_rayax?: Maybe<Scalars['Boolean']>;
  is_shown_in_brands_homepage?: Maybe<Scalars['Int']>;
  is_shown_in_categories_homepage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_number_of_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_automatic_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_water_resistant?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kitchen_supplies_materials?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kitchen_supplies_type?: Maybe<Scalars['Int']>;
  /** product labels */
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_battery_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_card_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_card_manufacturer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_card_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_model?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hard_desk_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hard_disk_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hd_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hdmi_port?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_laptop_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_operating_system?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_core?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_type?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_ram_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_ram_technology?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_screen_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_touch_screen?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  length?: Maybe<Scalars['Float']>;
  /** A value of 1 indicates that each link in the array must be purchased separately. */
  links_purchased_separately?: Maybe<Scalars['Int']>;
  /** The heading above the list of downloadable products. */
  links_title?: Maybe<Scalars['String']>;
  /** Product Manuals */
  manuals?: Maybe<Array<Maybe<Manual>>>;
  /** Product Manuals Of Both ar_EG And en_US Locals */
  manuals_of_all_locales?: Maybe<Array<Maybe<ManualV2>>>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  marketplace_seller?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maximum_load?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meat_grinder_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meat_grinder_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meat_grinder_reverse_speed?: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  medical_supplies_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  medical_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_card_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_card_speed_class?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_cards_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_cards_type?: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_cavity_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_grill?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mixer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_battery?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_battery_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_camera_video?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_common_model_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_display_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_memory_internal?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_memory_ram?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_network_sim?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_network_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_os?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_os_version?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_processor_chipset?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_processor_cpu?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_screen_size_inch?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_display_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_front_camera?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_rear_camera?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_rear_camera_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_wifi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  model_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  model_number?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mosquito_insect_killer_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouse_rgb?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouse_sensitivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouses_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouses_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouses_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  musical_instrument_type?: Maybe<Scalars['Int']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  network_access_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  network_devices_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  network_number_ports?: Maybe<Scalars['Int']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  new_from_date?: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  new_to_date?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  office_device_type?: Maybe<Scalars['Int']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_cavity_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_fan?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_grill?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_type?: Maybe<Scalars['Int']>;
  /** The product overall rating */
  overallRating: OrverallProductRating;
  /** @deprecated Use the `custom_attributes` field instead. */
  paper_input_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_graphics_card_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_graphics_card_manufacturer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_hard_desk_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_hard_disk_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_operating_system?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_pc_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_core?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_type?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_ram_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_ram_technology?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  perfumes_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  phone_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_ports?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_consumption?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_tools_power_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_tools_type?: Maybe<Scalars['Int']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** The range of prices for the product */
  price_range: PriceRange;
  /** An array of `TierPrice` objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  print_quality?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  print_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  print_speed_black?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_accessories_compitable_with?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_compitable_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_memory?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_output_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_processor?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printers_functions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_brand_id?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_color?: Maybe<Scalars['Int']>;
  product_featured_attributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of `ProductLinks` objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_offer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_aspect_ratio?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_hdmi_port?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_resolution?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_vga_port?: Maybe<Scalars['Int']>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** @deprecated Use the `custom_attributes` field instead. */
  receivers_hd_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  receivers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  receivers_wifi?: Maybe<Scalars['Int']>;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirect_code: Scalars['Int'];
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_cap_lit?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_cap_lit_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_cooling_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_doors?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_freezer_position?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_lcd?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_number_of_shelves?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_refrigerator_style?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_water_dispenser?: Maybe<Scalars['Int']>;
  /** An array of products to be displayed in a Related Products block. */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  remote_control_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  report_main_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  report_sub_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  return_refund?: Maybe<Scalars['Int']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_keep_warm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scale_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scales_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scales_maximum_weight_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scan_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scanner_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scanner_touch_screen?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scanner_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_compatible_with_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_protector_compatible_with_model?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_type?: Maybe<Scalars['Int']>;
  /** The product seller. */
  seller?: Maybe<Customer>;
  /** @deprecated Use the `custom_attributes` field instead. */
  seller_note?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_attachment_numbers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_type_of_shaving?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_type_of_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shop_by_seller?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_target_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_type_of_usage?: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sm_non_stick?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sm_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sm_type?: Maybe<Scalars['Int']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_home_device_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_scales_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_accessory_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_accessory_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_accessory_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_band_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_battery_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_compatible_os?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_compatible_with_os?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_ram?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_screen_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_shape?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_sim?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_target_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_waterproof?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_wifi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speaker_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_battery?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_number_of_unit?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_waterproof?: Maybe<Scalars['Int']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specific_appliance_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specific_appliance_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specific_appliance_type?: Maybe<Scalars['Int']>;
  /** Attribute names that contains the product specifications */
  specification_attributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sporting_goods_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sporting_goods_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sports_equipment?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  stationery_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steam_mob_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steam_mob_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_automatic_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_layers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_power_consumption?: Maybe<Scalars['Int']>;
  /** List of product attributes */
  stockQtyTerm?: Maybe<Array<Maybe<ProductStockQty>>>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  /** The product available store locators. */
  store_locators?: Maybe<Array<Maybe<AmsStoreLocator>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_auto_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_max_temp?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_min_temp?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_plates_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_type_of_usage?: Maybe<Scalars['Int']>;
  /** Product Support Videos */
  support_videos?: Maybe<Array<Maybe<SupportVideo>>>;
  /** The file name of a swatch image. */
  swatch_image?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_browning?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_defrost_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_number_of_slices?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_age_target?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_gender?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_vehicles_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_acc_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_built_in_receiver?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_connectivity_usb?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_curved?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_dimentions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_hd_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_hdmi?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_monitor_display_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_monitor_display_size_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_refresh_rate?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_screen_resolution?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_screen_technology?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_smart_connection?: Maybe<Scalars['Int']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ups_power_capacity?: Maybe<Scalars['Int']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  usb_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_dust_bag_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_dust_bag_capacity_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_dust_bag_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_year_model?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vg_game_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vg_game_platform?: Maybe<Scalars['Int']>;
  /** The visibility of a product */
  visibility: UpdateSellerProductVisibilityEnum;
  /** @deprecated Use the `custom_attributes` field instead. */
  wall_mount_adjustable?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wall_mount_size_compatability?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  warranty?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_band_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_diameter_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_shape?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_water_resistant?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_filter_cartridges?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_filter_cartridges_stages?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_filter_or_cartridges?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_inner_tank?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_cold_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_hot_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_loading_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_number_of_taps?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_refrigerator_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_water_temperature_type?: Maybe<Scalars['Int']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  width?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_dryer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_dryer_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_loading_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_rpm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_steam_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  yogurt_maker_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  yogurt_maker_power_consumption?: Maybe<Scalars['Int']>;
};


/** Defines a product that the shopper downloads. */
export type DownloadableProductAttributesArgs = {
  groupCode?: Maybe<Scalars['String']>;
};


/** Defines a product that the shopper downloads. */
export type DownloadableProductInstallmentsArgs = {
  corporateId?: Maybe<Scalars['Int']>;
};


/** Defines a product that the shopper downloads. */
export type DownloadableProductReviewsArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

/** Defines a single downloadable product. */
export type DownloadableProductCartItemInput = {
  /** The ID and value of the option. */
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  /** The quantity and SKU of the downloadable product. */
  data: CartItemInput;
  /** An array of objects containing the link_id of the downloadable product link. */
  downloadable_product_links?: Maybe<Array<Maybe<DownloadableProductLinksInput>>>;
};

/** Defines characteristics of a downloadable product. */
export type DownloadableProductLinks = {
  __typename?: 'DownloadableProductLinks';
  /** @deprecated This information should not be exposed on frontend. */
  id?: Maybe<Scalars['Int']>;
  /** @deprecated This information should not be exposed on frontend. */
  is_shareable?: Maybe<Scalars['Boolean']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  link_type?: Maybe<DownloadableFileTypeEnum>;
  /** @deprecated This information should not be exposed on frontend. */
  number_of_downloads?: Maybe<Scalars['Int']>;
  /** The price of the downloadable product. */
  price?: Maybe<Scalars['Float']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_file?: Maybe<Scalars['String']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_type?: Maybe<DownloadableFileTypeEnum>;
  /** The full URL to the downloadable sample. */
  sample_url?: Maybe<Scalars['String']>;
  /** A number indicating the sort order. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name of the link. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `DownloadableProductLinks` object. */
  uid: Scalars['ID'];
};

/** Contains the link ID for the downloadable product. */
export type DownloadableProductLinksInput = {
  /** The unique ID of the downloadable product link. */
  link_id: Scalars['Int'];
};

/** Defines characteristics of a downloadable product. */
export type DownloadableProductSamples = {
  __typename?: 'DownloadableProductSamples';
  /** @deprecated This information should not be exposed on frontend. */
  id?: Maybe<Scalars['Int']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_file?: Maybe<Scalars['String']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_type?: Maybe<DownloadableFileTypeEnum>;
  /** The full URL to the downloadable sample. */
  sample_url?: Maybe<Scalars['String']>;
  /** A number indicating the sort order. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name of the sample. */
  title?: Maybe<Scalars['String']>;
};

/** A downloadable product wish list item. */
export type DownloadableWishlistItem = WishlistItemInterface & {
  __typename?: 'DownloadableWishlistItem';
  /** The date and time the item was added to the wish list. */
  added_at: Scalars['String'];
  /** Custom options selected for the wish list item. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** An array containing information about the selected links. */
  links_v2?: Maybe<Array<Maybe<DownloadableProductLinks>>>;
  /** Product details of the wish list item. */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
  /** An array containing information about the selected samples. */
  samples?: Maybe<Array<Maybe<DownloadableProductSamples>>>;
};

export type DynamicValue = {
  __typename?: 'DynamicValue';
  body?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  image_location?: Maybe<Scalars['String']>;
};

export type EliteCreditLimits = {
  __typename?: 'EliteCreditLimits';
  available_amount?: Maybe<Scalars['Float']>;
  total_amount?: Maybe<Scalars['Float']>;
  used_amount?: Maybe<Scalars['Float']>;
};

export type EliteInstallmentsInformation = {
  __typename?: 'EliteInstallmentsInformation';
  down_payment?: Maybe<Scalars['Float']>;
  down_payment_payment_method?: Maybe<DownPaymentPaymentMethod>;
  is_verified: Scalars['Boolean'];
  plan?: Maybe<Scalars['Int']>;
};

/** Defines a customer-entered option. */
export type EnteredOptionInput = {
  /** The unique ID for a `CustomizableOptionInterface` object, such as a `CustomizableFieldOption`, `CustomizableFileOption`, or `CustomizableAreaOption` object. */
  uid: Scalars['ID'];
  /** Text the customer entered. */
  value: Scalars['String'];
};

/** Contains the `uid`, `relative_url`, and `type` attributes. */
export type EntityUrl = {
  __typename?: 'EntityUrl';
  /** @deprecated Use `relative_url` instead. */
  canonical_url?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface`, `CategoryInterface`, `CmsPage`, or similar object associated with the specified URL. This could be a product, category, or CMS page UID. */
  entity_uid?: Maybe<Scalars['ID']>;
  /**
   * The ID assigned to the object associated with the specified url. This could be a product ID, category ID, or page ID.
   * @deprecated Use `entity_uid` instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirectCode?: Maybe<Scalars['Int']>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
};

export type ErrorInterface = {
  /** The returned error message. */
  message: Scalars['String'];
};

/** Lists the exchange rate. */
export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  /** Specifies the store’s default currency to exchange to. */
  currency_to?: Maybe<Scalars['String']>;
  /** The exchange rate for the store’s default currency. */
  rate?: Maybe<Scalars['Float']>;
};

export type ExternalWordpressPost = {
  __typename?: 'ExternalWordpressPost';
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Faq = {
  __typename?: 'FAQ';
  body: Scalars['String'];
  category?: Maybe<FaqCategory>;
  code?: Maybe<FaqCode>;
  id: Scalars['Int'];
  slug?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type FaqCategory = {
  __typename?: 'FAQCategory';
  icon?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  slug?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type FaqCode = {
  __typename?: 'FAQCode';
  code: Scalars['String'];
  id: Scalars['Int'];
};

export enum FcmChannelEnum {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB'
}

/** Defines a filter that matches the input exactly. */
export type FilterEqualTypeInput = {
  /** Use this attribute to exactly match the specified string. For example, to filter on a specific category ID, specify a value such as `5`. */
  eq?: Maybe<Scalars['String']>;
  /** Use this attribute to filter on an array of values. For example, to filter on category IDs 4, 5, and 6, specify a value of `["4", "5", "6"]`. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Defines a filter that performs a fuzzy search. */
export type FilterMatchTypeInput = {
  /** Use this attribute to exactly match the specified string. For example, to filter on a specific SKU, specify a value such as `24-MB01`. */
  match?: Maybe<Scalars['String']>;
};

/** Defines a filter that matches a range of values, such as prices or dates. */
export type FilterRangeTypeInput = {
  /** Use this attribute to specify the lowest possible value in the range. */
  from?: Maybe<Scalars['String']>;
  /** Use this attribute to specify the highest possible value in the range. */
  to?: Maybe<Scalars['String']>;
};

/** Defines a filter for an input string. */
export type FilterStringTypeInput = {
  /** Filters items that are exactly the same as the specified string. */
  eq?: Maybe<Scalars['String']>;
  /** Filters items that are exactly the same as entries specified in an array of strings. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Defines a filter that performs a fuzzy search using the specified string. */
  match?: Maybe<Scalars['String']>;
};

/** Defines the comparison operators that can be used in a filter. */
export type FilterTypeInput = {
  /** Equals. */
  eq?: Maybe<Scalars['String']>;
  finset?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** From. Must be used with the `to` field. */
  from?: Maybe<Scalars['String']>;
  /** Greater than. */
  gt?: Maybe<Scalars['String']>;
  /** Greater than or equal to. */
  gteq?: Maybe<Scalars['String']>;
  /** In. The value can contain a set of comma-separated values. */
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Like. The specified value can contain % (percent signs) to allow matching of 0 or more characters. */
  like?: Maybe<Scalars['String']>;
  /** Less than. */
  lt?: Maybe<Scalars['String']>;
  /** Less than or equal to. */
  lteq?: Maybe<Scalars['String']>;
  /** More than or equal to. */
  moreq?: Maybe<Scalars['String']>;
  /** Not equal to. */
  neq?: Maybe<Scalars['String']>;
  /** Not in. The value can contain a set of comma-separated values. */
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Not null. */
  notnull?: Maybe<Scalars['String']>;
  /** Is null. */
  null?: Maybe<Scalars['String']>;
  /** To. Must be used with the `from` field. */
  to?: Maybe<Scalars['String']>;
};

/** A single FPT that can be applied to a product price. */
export type FixedProductTax = {
  __typename?: 'FixedProductTax';
  /** The amount of the Fixed Product Tax. */
  amount?: Maybe<Money>;
  /** The display label assigned to the Fixed Product Tax. */
  label?: Maybe<Scalars['String']>;
};

/** Lists display settings for the Fixed Product Tax. */
export enum FixedProductTaxDisplaySettings {
  /** The displayed price does not include the FPT amount. The values of `ProductPrice.fixed_product_taxes` and the price including the FPT are displayed separately. This value corresponds to 'Excluding FPT, Including FPT description and final price.' */
  ExcludeFptAndIncludeWithDetails = 'EXCLUDE_FPT_AND_INCLUDE_WITH_DETAILS',
  /** The displayed price does not include the FPT amount. The values from `ProductPrice.fixed_product_taxes` are not displayed. This value corresponds to 'Excluding FPT'. */
  ExcludeFptWithoutDetails = 'EXCLUDE_FPT_WITHOUT_DETAILS',
  /** The FPT feature is not enabled. You can omit `ProductPrice.fixed_product_taxes` from your query. */
  FptDisabled = 'FPT_DISABLED',
  /** The displayed price includes the FPT amount without displaying the `ProductPrice.fixed_product_taxes` values. This value corresponds to 'Including FPT only'. */
  IncludeFptWithoutDetails = 'INCLUDE_FPT_WITHOUT_DETAILS',
  /** The displayed price includes the FPT amount while displaying the values of `ProductPrice.fixed_product_taxes` separately. This value corresponds to 'Including FPT and FPT description'. */
  IncludeFptWithDetails = 'INCLUDE_FPT_WITH_DETAILS'
}

export type FlipAndWinCampaignDetails = {
  __typename?: 'FlipAndWinCampaignDetails';
  choices: Array<Maybe<ChoiceOutput>>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  start_date?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type FlipAndWinCampaignOutput = {
  __typename?: 'FlipAndWinCampaignOutput';
  active: Scalars['Boolean'];
  campaign?: Maybe<FlipAndWinCampaignDetails>;
  played_before: Scalars['Boolean'];
};

/** Identifies which customer requires remote shopping assistance. */
export type GenerateCustomerTokenAsAdminInput = {
  /** The email address of the customer requesting remote shopping assistance. */
  customer_email: Scalars['String'];
};

/** Contains the generated customer token. */
export type GenerateCustomerTokenAsAdminOutput = {
  __typename?: 'GenerateCustomerTokenAsAdminOutput';
  /** The generated customer token. */
  customer_token: Scalars['String'];
};

export type GenerateValuOtpResponse = {
  __typename?: 'GenerateValuOtpResponse';
  merchant_reference: Scalars['String'];
  response_message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type GetCustomerCreditResponse = {
  __typename?: 'GetCustomerCreditResponse';
  credit_limits?: Maybe<Array<Maybe<CreditLimit>>>;
  status_ar?: Maybe<Scalars['String']>;
  status_en?: Maybe<Scalars['String']>;
};

export type GetCustomerDataResponse = {
  __typename?: 'GetCustomerDataResponse';
  building_number?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  current_address?: Maybe<Scalars['String']>;
  district_name?: Maybe<Scalars['String']>;
  family_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  job?: Maybe<Scalars['String']>;
  job_address?: Maybe<Scalars['String']>;
  job_date?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  martial_status?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  national_id?: Maybe<Scalars['String']>;
  national_id_address?: Maybe<Scalars['String']>;
  national_id_date?: Maybe<Scalars['String']>;
  national_id_governorate?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  street_name?: Maybe<Scalars['String']>;
  total_salary?: Maybe<Scalars['String']>;
};

export type GetInstallmentsMpgsDataInput = {
  requested_installments: Array<Maybe<RequestedInstallments>>;
  social_number: Scalars['String'];
};

export type GetInstallmentsMpgsDataOutput = {
  __typename?: 'GetInstallmentsMPGSDataOutput';
  amount?: Maybe<Scalars['String']>;
  auth_header?: Maybe<Scalars['String']>;
  fees_amount?: Maybe<Scalars['String']>;
  installment_id?: Maybe<Scalars['String']>;
  merchant_id?: Maybe<Scalars['String']>;
  merchant_key?: Maybe<Scalars['String']>;
};

export type GetPhoneNumberByEmailOutput = {
  __typename?: 'GetPhoneNumberByEmailOutput';
  phone_number: Scalars['String'];
};

export type GetSdkTokenOutput = {
  __typename?: 'GetSDKTokenOutput';
  response_message: Scalars['String'];
  sdk_token: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Contains the text of a gift message, its sender, and recipient */
export type GiftMessage = {
  __typename?: 'GiftMessage';
  /** Sender name */
  from: Scalars['String'];
  /** Gift message text */
  message: Scalars['String'];
  /** Recipient name */
  to: Scalars['String'];
};

/** Contains the text of a gift message, its sender, and recipient */
export type GiftMessageInput = {
  /** Sender name */
  from: Scalars['String'];
  /** Gift message text */
  message: Scalars['String'];
  /** Recipient name */
  to: Scalars['String'];
};

/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export type GroupedProduct = PhysicalProductInterface & ProductInterface & RoutableInterface & {
  __typename?: 'GroupedProduct';
  /** @deprecated Use the `custom_attributes` field instead. */
  Keyboards_backlit?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_cooling_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_cooling_system?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_coverage_area?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_horsepower?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_inverter_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_plasma_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_turbo_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessories_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  armrest_direction?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  as_featured?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  at_coverage_area?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  at_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  at_type?: Maybe<Scalars['Int']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  /** List of product attributes */
  attributes?: Maybe<Array<Maybe<CustomAttribute>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  audio_type?: Maybe<Scalars['Int']>;
  /**
   * Does the product has COD
   * @deprecated Use the `custom_attributes` field instead.
   */
  available_cod?: Maybe<Scalars['Int']>;
  available_vouchers?: Maybe<Array<Maybe<AvailableVoucher>>>;
  /**
   * The product average reviews.
   * @deprecated Use the `custom_attributes` field instead.
   */
  average_rating?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_size?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_size_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_target_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  barcode?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  battery_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  battery_rechargeable_battery?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  battery_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bike_num_of_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bike_types?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bike_wheel_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  blender_type?: Maybe<Scalars['Int']>;
  /** Product Content Blocks */
  blocks?: Maybe<Array<Maybe<Block>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_type?: Maybe<Scalars['Int']>;
  /** The product brand. */
  brand?: Maybe<Brand>;
  bundle_packs: Array<Maybe<BundlePack>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cables_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cables_length?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cables_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_acc_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_memory_cardslot?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_memory_internal?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_package_components?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_ports?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_shooting_contin_shooting?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_acc_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_battery?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_digital_camera_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_features_primary_camera?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_features_secondary?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_flash?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_focus_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_maximum_aperture?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_maximum_focal_length?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_minimum_aperture?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_minimum_focal_length?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_megapixel?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_optical_zoom?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_screen_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_touch_screen?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_usb?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_wifi?: Maybe<Scalars['Int']>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonical_url?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  car_acc_type?: Maybe<Scalars['Int']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chair_height_djustability?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chair_maximum_height?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chargers_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chargers_output?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chargers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  clocks_built_in_alarm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  clocks_clock_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  clocks_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_grinder_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_bar_pressure?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_beverage_form?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_cups?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_grinder?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  comp_lap_accessories_compitable_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  comp_lap_accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  connector_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  connector_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_console_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_hard_disk_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_hdmi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_included_games?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_number_of_controllers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_fan?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_flames?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_grill?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_holders_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_ignition?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_safety?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_filter_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_number_of_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_remote_control_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_wheels_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  copy_resolution?: Maybe<Scalars['String']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_compatible_with_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_compatible_with_model?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_type?: Maybe<Scalars['Int']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  custom_label_1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  custom_label_2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  custom_pages_ids?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_automatic_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  delivery_time?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  delivery_time_inside_cairo_giz?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dental_care_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_form?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_type?: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_half_load?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_number_of_programs?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_sanitization?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_loading_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_rpm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_steam_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dvr_channel_number?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dvr_input?: Maybe<Scalars['String']>;
  /** Product Short Dynamic Link */
  dynamic_link?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_light_color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_outlet?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_watt?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  express_shipping_time?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_age_group?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_age_range?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_color?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_decor_style?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_digital_zoom?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_display_technology?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_finish?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_gemstone?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_gender?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_health_concern?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_ingredients?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_is_assembly_required?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_material?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_maximum_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_megapixels?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_minimum_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_model?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_number_of_licenses?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_operating_system?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_optical_zoom?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_package_quantity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_pattern?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_depth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_form?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_height?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_length?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_width?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_recommended_use?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_scent?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_screen_size?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_shoe_width?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_size?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_storage_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_style?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_system_requirements?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_thread_count?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_video_game_platform?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_diameter_in_inches?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_net_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_number_of_blades?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_type?: Maybe<Scalars['Int']>;
  featured_bullet_attributes_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_targeted_age_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_cap_lit?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_cap_lit_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_cooling_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_number_of_drawers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  game_access_compitable?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  game_accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  game_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gaming_controller_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gaming_controller_connectivity?: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_acc_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_dryer_power?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_dryer_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_style_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_style_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_styler_auto_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hand_tools_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hardware_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hardware_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  has_deal?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_battery?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_microphone?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_noise_cancellation?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_fan?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_heat_settings?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_number_of_fins?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_overheating_protection?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heavy_item?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  height?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_flames?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_holders_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_ignition?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_power_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_safety?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  home_decor_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_air_flow_rate?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_filters?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_motors?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_offers?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_plate_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_plate_number_plate?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_plate_power_consumption?: Maybe<Scalars['Int']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ideal_installing_frame_dimensions?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ink_color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ink_compitable_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  installment_offers?: Maybe<Scalars['String']>;
  /** The product qualified installment plans. */
  installments?: Maybe<InstallmentOutput>;
  /** @deprecated Use the `custom_attributes` field instead. */
  intercome_number_way?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  intercome_video_support?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_base_plate_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_vertical_steam_shot?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_air_condition?: Maybe<Scalars['Int']>;
  /** Check if product is a best seller */
  is_best_seller?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_component?: Maybe<Scalars['Int']>;
  is_has_rayax?: Maybe<Scalars['Boolean']>;
  is_shown_in_brands_homepage?: Maybe<Scalars['Int']>;
  is_shown_in_categories_homepage?: Maybe<Scalars['Int']>;
  /** An array containing grouped product items. */
  items?: Maybe<Array<Maybe<GroupedProductItem>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_number_of_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_automatic_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_water_resistant?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kitchen_supplies_materials?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kitchen_supplies_type?: Maybe<Scalars['Int']>;
  /** product labels */
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_battery_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_card_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_card_manufacturer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_card_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_model?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hard_desk_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hard_disk_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hd_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hdmi_port?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_laptop_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_operating_system?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_core?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_type?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_ram_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_ram_technology?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_screen_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_touch_screen?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  length?: Maybe<Scalars['Float']>;
  /** Product Manuals */
  manuals?: Maybe<Array<Maybe<Manual>>>;
  /** Product Manuals Of Both ar_EG And en_US Locals */
  manuals_of_all_locales?: Maybe<Array<Maybe<ManualV2>>>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  marketplace_seller?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maximum_load?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meat_grinder_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meat_grinder_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meat_grinder_reverse_speed?: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  medical_supplies_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  medical_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_card_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_card_speed_class?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_cards_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_cards_type?: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_cavity_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_grill?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mixer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_battery?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_battery_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_camera_video?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_common_model_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_display_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_memory_internal?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_memory_ram?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_network_sim?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_network_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_os?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_os_version?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_processor_chipset?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_processor_cpu?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_screen_size_inch?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_display_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_front_camera?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_rear_camera?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_rear_camera_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_wifi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  model_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  model_number?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mosquito_insect_killer_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouse_rgb?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouse_sensitivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouses_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouses_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouses_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  musical_instrument_type?: Maybe<Scalars['Int']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  network_access_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  network_devices_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  network_number_ports?: Maybe<Scalars['Int']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  new_from_date?: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  new_to_date?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  office_device_type?: Maybe<Scalars['Int']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_cavity_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_fan?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_grill?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_type?: Maybe<Scalars['Int']>;
  /** The product overall rating */
  overallRating: OrverallProductRating;
  /** @deprecated Use the `custom_attributes` field instead. */
  paper_input_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_graphics_card_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_graphics_card_manufacturer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_hard_desk_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_hard_disk_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_operating_system?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_pc_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_core?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_type?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_ram_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_ram_technology?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  perfumes_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  phone_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_ports?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_consumption?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_tools_power_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_tools_type?: Maybe<Scalars['Int']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** The range of prices for the product */
  price_range: PriceRange;
  /** An array of `TierPrice` objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  print_quality?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  print_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  print_speed_black?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_accessories_compitable_with?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_compitable_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_memory?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_output_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_processor?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printers_functions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_brand_id?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_color?: Maybe<Scalars['Int']>;
  product_featured_attributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of `ProductLinks` objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_offer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_aspect_ratio?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_hdmi_port?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_resolution?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_vga_port?: Maybe<Scalars['Int']>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** @deprecated Use the `custom_attributes` field instead. */
  receivers_hd_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  receivers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  receivers_wifi?: Maybe<Scalars['Int']>;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirect_code: Scalars['Int'];
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_cap_lit?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_cap_lit_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_cooling_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_doors?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_freezer_position?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_lcd?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_number_of_shelves?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_refrigerator_style?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_water_dispenser?: Maybe<Scalars['Int']>;
  /** An array of products to be displayed in a Related Products block. */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  remote_control_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  report_main_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  report_sub_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  return_refund?: Maybe<Scalars['Int']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_keep_warm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scale_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scales_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scales_maximum_weight_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scan_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scanner_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scanner_touch_screen?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scanner_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_compatible_with_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_protector_compatible_with_model?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_type?: Maybe<Scalars['Int']>;
  /** The product seller. */
  seller?: Maybe<Customer>;
  /** @deprecated Use the `custom_attributes` field instead. */
  seller_note?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_attachment_numbers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_type_of_shaving?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_type_of_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shop_by_seller?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_target_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_type_of_usage?: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sm_non_stick?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sm_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sm_type?: Maybe<Scalars['Int']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_home_device_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_scales_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_accessory_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_accessory_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_accessory_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_band_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_battery_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_compatible_os?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_compatible_with_os?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_ram?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_screen_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_shape?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_sim?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_target_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_waterproof?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_wifi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speaker_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_battery?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_number_of_unit?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_waterproof?: Maybe<Scalars['Int']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specific_appliance_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specific_appliance_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specific_appliance_type?: Maybe<Scalars['Int']>;
  /** Attribute names that contains the product specifications */
  specification_attributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sporting_goods_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sporting_goods_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sports_equipment?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  stationery_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steam_mob_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steam_mob_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_automatic_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_layers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_power_consumption?: Maybe<Scalars['Int']>;
  /** List of product attributes */
  stockQtyTerm?: Maybe<Array<Maybe<ProductStockQty>>>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  /** The product available store locators. */
  store_locators?: Maybe<Array<Maybe<AmsStoreLocator>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_auto_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_max_temp?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_min_temp?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_plates_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_type_of_usage?: Maybe<Scalars['Int']>;
  /** Product Support Videos */
  support_videos?: Maybe<Array<Maybe<SupportVideo>>>;
  /** The file name of a swatch image. */
  swatch_image?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_browning?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_defrost_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_number_of_slices?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_age_target?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_gender?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_vehicles_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_acc_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_built_in_receiver?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_connectivity_usb?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_curved?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_dimentions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_hd_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_hdmi?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_monitor_display_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_monitor_display_size_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_refresh_rate?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_screen_resolution?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_screen_technology?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_smart_connection?: Maybe<Scalars['Int']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ups_power_capacity?: Maybe<Scalars['Int']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  usb_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_dust_bag_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_dust_bag_capacity_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_dust_bag_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_year_model?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vg_game_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vg_game_platform?: Maybe<Scalars['Int']>;
  /** The visibility of a product */
  visibility: UpdateSellerProductVisibilityEnum;
  /** @deprecated Use the `custom_attributes` field instead. */
  wall_mount_adjustable?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wall_mount_size_compatability?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  warranty?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_band_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_diameter_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_shape?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_water_resistant?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_filter_cartridges?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_filter_cartridges_stages?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_filter_or_cartridges?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_inner_tank?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_cold_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_hot_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_loading_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_number_of_taps?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_refrigerator_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_water_temperature_type?: Maybe<Scalars['Int']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  width?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_dryer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_dryer_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_loading_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_rpm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_steam_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  yogurt_maker_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  yogurt_maker_power_consumption?: Maybe<Scalars['Int']>;
};


/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export type GroupedProductAttributesArgs = {
  groupCode?: Maybe<Scalars['String']>;
};


/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export type GroupedProductInstallmentsArgs = {
  corporateId?: Maybe<Scalars['Int']>;
};


/** Defines a grouped product, which consists of simple standalone products that are presented as a group. */
export type GroupedProductReviewsArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

/** Contains information about an individual grouped product item. */
export type GroupedProductItem = {
  __typename?: 'GroupedProductItem';
  /** The relative position of this item compared to the other group items. */
  position?: Maybe<Scalars['Int']>;
  /** Details about this product option. */
  product?: Maybe<ProductInterface>;
  /** The quantity of this grouped product item. */
  qty?: Maybe<Scalars['Float']>;
};

/** A grouped product wish list item. */
export type GroupedProductWishlistItem = WishlistItemInterface & {
  __typename?: 'GroupedProductWishlistItem';
  /** The date and time the item was added to the wish list. */
  added_at: Scalars['String'];
  /** Custom options selected for the wish list item. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
};

export type GuarantorInformation = {
  __typename?: 'GuarantorInformation';
  grantor_address_details?: Maybe<Scalars['String']>;
  grantor_address_nearest_landmark?: Maybe<Scalars['String']>;
  grantor_city_id?: Maybe<Scalars['Int']>;
  grantor_family_name?: Maybe<Scalars['String']>;
  grantor_first_name?: Maybe<Scalars['String']>;
  grantor_governorate_id?: Maybe<Scalars['Int']>;
  grantor_last_name?: Maybe<Scalars['String']>;
  grantor_middle_name?: Maybe<Scalars['String']>;
  grantor_mobile_number?: Maybe<Scalars['String']>;
  grantor_name?: Maybe<Scalars['String']>;
  grantor_national_id?: Maybe<Scalars['String']>;
  grantor_relation_id?: Maybe<Scalars['Int']>;
};

export type GuarantorInformationInput = {
  grantor_address_details?: Maybe<Scalars['String']>;
  grantor_address_nearest_landmark?: Maybe<Scalars['String']>;
  grantor_city_id?: Maybe<Scalars['Int']>;
  grantor_family_name?: Maybe<Scalars['String']>;
  grantor_first_name?: Maybe<Scalars['String']>;
  grantor_governorate_id?: Maybe<Scalars['Int']>;
  grantor_last_name?: Maybe<Scalars['String']>;
  grantor_middle_name?: Maybe<Scalars['String']>;
  grantor_mobile_number?: Maybe<Scalars['String']>;
  grantor_name?: Maybe<Scalars['String']>;
  grantor_national_id?: Maybe<Scalars['String']>;
  grantor_relation_id?: Maybe<Scalars['Int']>;
};

/** Contains a set of relative URLs that PayPal uses in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payments Pro Hosted Solution payment method. */
export type HostedProInput = {
  /** The relative URL of the page that PayPal redirects to when the buyer cancels the transaction in order to choose a different payment method. For example, if the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String'];
  /** The relative URL of the final confirmation page that PayPal redirects to upon payment success. For example, if the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String'];
};

/** Contains the secure URL used for the Payments Pro Hosted Solution payment method. */
export type HostedProUrl = {
  __typename?: 'HostedProUrl';
  /** The secure URL generated by PayPal. */
  secure_form_url?: Maybe<Scalars['String']>;
};

/** Contains the required input to request the secure URL for Payments Pro Hosted Solution payment. */
export type HostedProUrlInput = {
  /** The unique ID that identifies the shopper's cart. */
  cart_id: Scalars['String'];
};

/** Contains target path parameters. */
export type HttpQueryParameter = {
  __typename?: 'HttpQueryParameter';
  /** A parameter name. */
  name?: Maybe<Scalars['String']>;
  /** A parameter value. */
  value?: Maybe<Scalars['String']>;
};

export type IdArabicEnglishName = {
  __typename?: 'IdArabicEnglishName';
  arabicName?: Maybe<Scalars['String']>;
  englishName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
};

export type ImageSwatchData = SwatchDataInterface & {
  __typename?: 'ImageSwatchData';
  /** The URL assigned to the thumbnail of the swatch image. */
  thumbnail?: Maybe<Scalars['String']>;
  /** The value can be represented as color (HEX code), image link, or text. */
  value?: Maybe<Scalars['String']>;
};

export type InquiryDetails = {
  __typename?: 'InquiryDetails';
  /** Amount to be paid without fees and app fees */
  amount: Scalars['Float'];
  /** Category ID */
  category_id?: Maybe<Scalars['Int']>;
  /** date created for the inquiry */
  date_created: Scalars['String'];
  /** service inquiry entity id */
  entity_id: Scalars['Int'];
  /** Service fees */
  fees: Scalars['Float'];
  /** service inquiry id */
  inquiry_id: Scalars['Int'];
  /** Payment method that utility paid with */
  payment_method?: Maybe<PaymobUtilitesPaymentMethods>;
  /** Provider ID */
  provider_id?: Maybe<Scalars['Int']>;
  /** Rayashop extra fees */
  raya_fees: Scalars['Float'];
  /** name of the service */
  service_name?: Maybe<Scalars['String']>;
};

export type InquiryOutput = {
  __typename?: 'InquiryOutput';
  /** response code from service, 0000 for success response */
  code?: Maybe<Scalars['String']>;
  /** Inquiry details came from service */
  data?: Maybe<InquiryDetails>;
  /** validation errors if exists */
  errors?: Maybe<Array<Maybe<KeyValueValidationErrors>>>;
};

export type InstallmentDetails = {
  __typename?: 'InstallmentDetails';
  issuer_detail?: Maybe<Array<Maybe<IssuerDetail>>>;
};

export type InstallmentItems = {
  amount: Scalars['Float'];
  down_payment: Scalars['Float'];
  monthly_amount: Scalars['Float'];
  months: Scalars['Int'];
};

export type InstallmentOutput = {
  __typename?: 'InstallmentOutput';
  installment_data?: Maybe<Array<Maybe<InstallmentResponse>>>;
  lowest_installment_data?: Maybe<SingleInstallmentPlanOutput>;
};

export type InstallmentPlans = {
  __typename?: 'InstallmentPlans';
  /** Installment duration in months */
  duration: Scalars['Int'];
  /** Installment fees on order */
  fees: Scalars['Float'];
  /** Installment interest on order */
  interest: Scalars['Float'];
};

export type InstallmentResponse = {
  __typename?: 'InstallmentResponse';
  logo?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  method_name?: Maybe<Scalars['String']>;
  plans?: Maybe<Array<Maybe<SingleInstallmentPlanOutput>>>;
};

export type InstallmentsApplicationPhotoFileInput = {
  /** The  base64 code format's file */
  base64_encoded_file: Scalars['String'];
  /** The file name include extensions */
  name: Scalars['String'];
};

export type InstallmentsDistrict = {
  __typename?: 'InstallmentsDistrict';
  district: Scalars['String'];
};

/** Contains an error message when an internal error occurred. */
export type InternalError = ErrorInterface & {
  __typename?: 'InternalError';
  /** The returned error message. */
  message: Scalars['String'];
};

/** Contains invoice details. */
export type Invoice = {
  __typename?: 'Invoice';
  /** Comments on the invoice. */
  comments?: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** The unique ID for a `Invoice` object. */
  id: Scalars['ID'];
  /** Invoiced product details. */
  items?: Maybe<Array<Maybe<InvoiceItemInterface>>>;
  /** Sequential invoice number. */
  number: Scalars['String'];
  /** Invoice total amount details. */
  total?: Maybe<InvoiceTotal>;
};

export type InvoiceItem = InvoiceItemInterface & {
  __typename?: 'InvoiceItem';
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID'];
  /** Details about an individual order item. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of invoiced items. */
  quantity_invoiced?: Maybe<Scalars['Float']>;
};

/** Contains detailes about invoiced items. */
export type InvoiceItemInterface = {
  /** Information about the final discount amount for the base product, including discounts on options. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for an `InvoiceItemInterface` object. */
  id: Scalars['ID'];
  /** Details about an individual order item. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of invoiced items. */
  quantity_invoiced?: Maybe<Scalars['Float']>;
};

/** Contains price details from an invoice. */
export type InvoiceTotal = {
  __typename?: 'InvoiceTotal';
  /** The final base grand total amount in the base currency. */
  base_grand_total: Money;
  /** The applied discounts to the invoice. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The final total amount, including shipping, discounts, and taxes. */
  grand_total: Money;
  /** Details about the shipping and handling costs for the invoice. */
  shipping_handling?: Maybe<ShippingHandling>;
  /** The subtotal of the invoice, excluding shipping, discounts, and taxes. */
  subtotal: Money;
  /** The invoice tax details. */
  taxes?: Maybe<Array<Maybe<TaxItem>>>;
  /** The shipping amount for the invoice. */
  total_shipping: Money;
  /** The amount of tax applied to the invoice. */
  total_tax: Money;
};

/** Contains the result of the `isEmailAvailable` query. */
export type IsEmailAvailableOutput = {
  __typename?: 'IsEmailAvailableOutput';
  /** Indicates whether the specified email address can be used to create a customer. */
  is_email_available?: Maybe<Scalars['Boolean']>;
};

export type IsOrderReturnableOutput = {
  __typename?: 'IsOrderReturnableOutput';
  /** check if order is refundable or not */
  is_refundable: Scalars['Boolean'];
  /** return if order can be returned or not */
  is_returnable: Scalars['Boolean'];
  /** Items can be returned and their quantities */
  items?: Maybe<Array<Maybe<ReturnableOrderItemOutput>>>;
};

export type IssuerDetail = {
  __typename?: 'IssuerDetail';
  banking_system?: Maybe<Scalars['String']>;
  bins?: Maybe<Array<Maybe<Bins>>>;
  confirmation_message_ar?: Maybe<Scalars['String']>;
  confirmation_message_en?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  disclaimer_message_ar?: Maybe<Scalars['String']>;
  disclaimer_message_en?: Maybe<Scalars['String']>;
  formula?: Maybe<Scalars['String']>;
  issuer_code?: Maybe<Scalars['String']>;
  issuer_logo_ar?: Maybe<Scalars['String']>;
  issuer_logo_en?: Maybe<Scalars['String']>;
  issuer_name_ar?: Maybe<Scalars['String']>;
  issuer_name_en?: Maybe<Scalars['String']>;
  plan_details: Array<Maybe<PlanDetails>>;
  processing_fees_message_ar?: Maybe<Scalars['String']>;
  processing_fees_message_en?: Maybe<Scalars['String']>;
  terms_and_condition_ar?: Maybe<Scalars['String']>;
  terms_and_condition_en?: Maybe<Scalars['String']>;
};

/** A list of options of the selected bundle product. */
export type ItemSelectedBundleOption = {
  __typename?: 'ItemSelectedBundleOption';
  /**
   * The unique ID for a `ItemSelectedBundleOption` object.
   * @deprecated Use `uid` instead.
   */
  id: Scalars['ID'];
  /** The label of the option. */
  label: Scalars['String'];
  /** The unique ID for a `ItemSelectedBundleOption` object. */
  uid: Scalars['ID'];
  /** A list of products that represent the values of the parent option. */
  values?: Maybe<Array<Maybe<ItemSelectedBundleOptionValue>>>;
};

/** A list of values for the selected bundle product. */
export type ItemSelectedBundleOptionValue = {
  __typename?: 'ItemSelectedBundleOptionValue';
  /**
   * The unique ID for a `ItemSelectedBundleOptionValue` object.
   * @deprecated Use `uid` instead.
   */
  id: Scalars['ID'];
  /** The price of the child bundle product. */
  price: Money;
  /** The name of the child bundle product. */
  product_name: Scalars['String'];
  /** The SKU of the child bundle product. */
  product_sku: Scalars['String'];
  /** The number of this bundle product that were ordered. */
  quantity: Scalars['Float'];
  /** The unique ID for a `ItemSelectedBundleOptionValue` object. */
  uid: Scalars['ID'];
};

/** Contains a key-value pair. */
export type KeyValue = {
  __typename?: 'KeyValue';
  /** The name part of the key/value pair. */
  name?: Maybe<Scalars['String']>;
  /** The value part of the key/value pair. */
  value?: Maybe<Scalars['String']>;
};

export type KeyValuePair = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type KeyValueValidationErrors = {
  __typename?: 'KeyValueValidationErrors';
  error?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

/** Contains information for rendering layered navigation. */
export type LayerFilter = {
  __typename?: 'LayerFilter';
  /**
   * An array of filter items.
   * @deprecated Use `Aggregation.options` instead.
   */
  filter_items?: Maybe<Array<Maybe<LayerFilterItemInterface>>>;
  /**
   * The count of filter items in filter group.
   * @deprecated Use `Aggregation.count` instead.
   */
  filter_items_count?: Maybe<Scalars['Int']>;
  /**
   * The name of a layered navigation filter.
   * @deprecated Use `Aggregation.label` instead.
   */
  name?: Maybe<Scalars['String']>;
  /**
   * The request variable name for a filter query.
   * @deprecated Use `Aggregation.attribute_code` instead.
   */
  request_var?: Maybe<Scalars['String']>;
};

export type LayerFilterItem = LayerFilterItemInterface & {
  __typename?: 'LayerFilterItem';
  /**
   * The count of items per filter.
   * @deprecated Use `AggregationOption.count` instead.
   */
  items_count?: Maybe<Scalars['Int']>;
  /**
   * The label for a filter.
   * @deprecated Use `AggregationOption.label` instead.
   */
  label?: Maybe<Scalars['String']>;
  /**
   * The value of a filter request variable to be used in query.
   * @deprecated Use `AggregationOption.value` instead.
   */
  value_string?: Maybe<Scalars['String']>;
};

export type LayerFilterItemInterface = {
  /**
   * The count of items per filter.
   * @deprecated Use `AggregationOption.count` instead.
   */
  items_count?: Maybe<Scalars['Int']>;
  /**
   * The label for a filter.
   * @deprecated Use `AggregationOption.label` instead.
   */
  label?: Maybe<Scalars['String']>;
  /**
   * The value of a filter request variable to be used in query.
   * @deprecated Use `AggregationOption.value` instead.
   */
  value_string?: Maybe<Scalars['String']>;
};

export type MpgsCredentials = {
  __typename?: 'MPGSCredentials';
  mpgsAuthorizationHeader?: Maybe<Scalars['String']>;
  mpgsMerchantId?: Maybe<Scalars['String']>;
  mpgsMerchantKey?: Maybe<Scalars['String']>;
};

export type MagefanBlogPost = {
  __typename?: 'MagefanBlogPost';
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type MageplazaBlogPost = {
  __typename?: 'MageplazaBlogPost';
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Manual = {
  __typename?: 'Manual';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_active: Scalars['Boolean'];
  manual_url?: Maybe<Scalars['String']>;
  sort: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
};

export type ManualV2 = {
  __typename?: 'ManualV2';
  id: Scalars['Int'];
  is_active: Scalars['Boolean'];
  locale?: Maybe<Scalars['String']>;
  manual_name?: Maybe<Scalars['String']>;
  manual_url?: Maybe<Scalars['String']>;
  sort: Scalars['Int'];
};

export type MarketplaceOrder = {
  __typename?: 'MarketplaceOrder';
  base_total?: Maybe<Scalars['Float']>;
  carrier_name?: Maybe<Scalars['String']>;
  coupon_amount?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['String']>;
  customer_rating?: Maybe<Array<Maybe<OrderRateSingleCriteria>>>;
  delivery_date?: Maybe<Scalars['String']>;
  grand_total?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Int']>;
  is_canceled?: Maybe<Scalars['Boolean']>;
  parent_order_data?: Maybe<CustomerOrder>;
  product_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  product_skus?: Maybe<Array<Maybe<Scalars['String']>>>;
  products?: Maybe<Array<Maybe<OrderItemInterface>>>;
  refunded_coupon_amount?: Maybe<Scalars['Float']>;
  refunded_shipping_charges?: Maybe<Scalars['Float']>;
  seller?: Maybe<Customer>;
  shipping_charges?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  store_locators?: Maybe<Array<Maybe<AmsStoreLocator>>>;
  total_tax?: Maybe<Scalars['Float']>;
  tracking_number?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

/** The collection of orders that match the conditions defined in the filter */
export type MarketplaceOrderOutput = {
  __typename?: 'MarketplaceOrderOutput';
  /** An array of customer marketplace orders */
  items: Array<Maybe<MarketplaceOrder>>;
  /** An object that includes the current_page, page_info, and page_size values specified in the query */
  page_info?: Maybe<SearchResultPageInfo>;
  /** The total count of customer marketplace orders */
  total_count?: Maybe<Scalars['Int']>;
};

export type Match = {
  __typename?: 'Match';
  can_submit_prediction?: Maybe<Scalars['Boolean']>;
  coupon_code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  match_result?: Maybe<Scalars['String']>;
  match_time?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  prediction_end_time?: Maybe<Scalars['String']>;
  prediction_start_time?: Maybe<Scalars['String']>;
  teamA?: Maybe<Scalars['String']>;
  teamA_image_url?: Maybe<Scalars['String']>;
  teamB?: Maybe<Scalars['String']>;
  teamB_image_url?: Maybe<Scalars['String']>;
  team_A_score?: Maybe<Scalars['String']>;
  team_B_score?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  user_submission?: Maybe<Scalars['String']>;
  user_win?: Maybe<Scalars['Boolean']>;
};

/** Defines characteristics about images and videos associated with a specific product. */
export type MediaGalleryEntry = {
  __typename?: 'MediaGalleryEntry';
  /** Details about the content of the media gallery item. */
  content?: Maybe<ProductMediaGalleryEntriesContent>;
  /** Indicates whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>;
  /** The path of the image on the server. */
  file?: Maybe<Scalars['String']>;
  /**
   * The identifier assigned to the object.
   * @deprecated Use `uid` instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The alt text displayed on the storefront when the user points to the image. */
  label?: Maybe<Scalars['String']>;
  /** Either `image` or `video`. */
  media_type?: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>;
  /** Array of image types. It can have the following values: image, small_image, thumbnail. */
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The unique ID for a `MediaGalleryEntry` object. */
  uid: Scalars['ID'];
  /** Details about the content of a video item. */
  video_content?: Maybe<ProductMediaGalleryEntriesVideoContent>;
};

/** Contains basic information about a product image or video. */
export type MediaGalleryInterface = {
  /** Indicates whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>;
  /** The label of the product image or video. */
  label?: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>;
  /** The URL of the product image or video. */
  url?: Maybe<Scalars['String']>;
};

export type MedicalInsurance = {
  __typename?: 'MedicalInsurance';
  medical_insurance_front_image?: Maybe<Scalars['String']>;
};

export type MedicalInsuranceInput = {
  medical_insurance_front_image?: Maybe<Scalars['String']>;
};

export type Merchant = {
  __typename?: 'Merchant';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image_url?: Maybe<Scalars['String']>;
  merchant_url?: Maybe<Scalars['String']>;
  mobile_image_url?: Maybe<Scalars['String']>;
  responsive_image_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type MirasvitKbArticle = {
  __typename?: 'MirasvitKbArticle';
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Defines a monetary value, including a numeric value and a currency code. */
export type Money = {
  __typename?: 'Money';
  /** A three-letter currency code, such as USD or EUR. */
  currency?: Maybe<CurrencyEnum>;
  /** A number expressing a monetary value. */
  value?: Maybe<Scalars['Float']>;
};

export type MultipleInstallmentInput = {
  phone_number: Scalars['String'];
  requested_installments: Array<Maybe<RequestedInstallments>>;
  social_number: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** sets the client type the user used on cart */
  AdminSetOrderSourceOnCart: OrderSourceOnCartOutput;
  addBundlePackToCart?: Maybe<AddBundlePackToCartOutput>;
  /** Add one or more bundle products to the specified cart. We recommend using `addProductsToCart` instead. */
  addBundleProductsToCart?: Maybe<AddBundleProductsToCartOutput>;
  /** Add one or more configurable products to the specified cart. We recommend using `addProductsToCart` instead. */
  addConfigurableProductsToCart?: Maybe<AddConfigurableProductsToCartOutput>;
  /** Log custom logs messages */
  addCustomLogs: Scalars['Boolean'];
  /**
   * Add Customizable options to cart items
   * @deprecated Use `updateCartItemsCustomOptions` instead.
   */
  addCustomOptionsToCartItems?: Maybe<UpdateCartItemCustomizableOptionsOutput>;
  /** Add one or more downloadable products to the specified cart. We recommend using `addProductsToCart` instead. */
  addDownloadableProductsToCart?: Maybe<AddDownloadableProductsToCartOutput>;
  /** Add any type of product to the cart. */
  addProductsToCart?: Maybe<AddProductsToCartOutput>;
  /** Add products to the specified compare list. */
  addProductsToCompareList?: Maybe<CompareList>;
  /** Add one or more products to the specified wish list. This mutation supports all product types. */
  addProductsToWishlist?: Maybe<AddProductsToWishlistOutput>;
  /** Add one or more simple products to the specified cart. We recommend using `addProductsToCart` instead. */
  addSimpleProductsToCart?: Maybe<AddSimpleProductsToCartOutput>;
  /** add a product to wishlist */
  addToWishlist: Wishlist;
  /** Add one or more virtual products to the specified cart. We recommend using `addProductsToCart` instead. */
  addVirtualProductsToCart?: Maybe<AddVirtualProductsToCartOutput>;
  /** Add items in the specified wishlist to the customer's cart. */
  addWishlistItemsToCart?: Maybe<AddWishlistItemsToCartOutput>;
  /** Apply a pre-defined coupon code to the specified cart. */
  applyCouponToCart?: Maybe<ApplyCouponToCartOutput>;
  /** Assign the specified compare list to the logged in customer. */
  assignCompareListToCustomer?: Maybe<AssignCompareListToCustomerOutput>;
  /** Assign a logged-in customer to the specified guest shopping cart. */
  assignCustomerToGuestCart: Cart;
  changeAdminPassword: AdminToken;
  /** Change the password for the logged-in customer. */
  changeCustomerPassword?: Maybe<Customer>;
  checkIfCustomerHasEliteAccount: CheckIfCustomerHasEliteAccountOutput;
  /** Contact us mutation */
  contactForm?: Maybe<Scalars['String']>;
  /** Creates Client Token for Braintree Javascript SDK initialization. */
  createBraintreeClientToken: Scalars['String'];
  createCancelRequest?: Maybe<CancelRequest>;
  /** Create a new compare list. The compare list is saved for logged in customers. */
  createCompareList?: Maybe<CompareList>;
  /** Use `createCustomerV2` instead. */
  createCustomer?: Maybe<CustomerOutput>;
  /** Create a billing or shipping address for a customer or guest. */
  createCustomerAddress?: Maybe<CustomerAddress>;
  /** Create a customer account. */
  createCustomerV2?: Maybe<CustomerOutput>;
  /** Create an empty shopping cart for a guest or logged in user */
  createEmptyCart?: Maybe<Scalars['String']>;
  /** create paymob utility service payment inquiry */
  createInquiry?: Maybe<InquiryOutput>;
  /** this mutation fires an email to a configured one with the maintenance request details */
  createMaintenanceRequest: Scalars['Boolean'];
  /** Initiate a transaction and receive a token. Use this mutation for Payflow Pro and Payments Pro payment methods */
  createPayflowProToken?: Maybe<CreatePayflowProTokenOutput>;
  /** Initiate an Express Checkout transaction and receive a token. Use this mutation for Express Checkout and Payments Standard payment methods. */
  createPaypalExpressToken?: Maybe<PaypalExpressTokenOutput>;
  /** Create a product review for the specified product. */
  createProductReview: CreateProductReviewOutput;
  /** Delete the specified compare list. */
  deleteCompareList?: Maybe<DeleteCompareListOutput>;
  /** Delete customer account */
  deleteCustomer: Scalars['Boolean'];
  /** Delete the billing or shipping address of a customer. */
  deleteCustomerAddress?: Maybe<Scalars['Boolean']>;
  /** Delete a customer's payment token. */
  deletePaymentToken?: Maybe<DeletePaymentTokenOutput>;
  dismissMatchPredictionPopup: Scalars['Boolean'];
  /** Edits unverified customer phone number using OTP */
  editCustomerPhoneNumber: Scalars['Boolean'];
  generateAdminToken: AdminToken;
  /** Generate a token for specified customer. */
  generateCustomerToken?: Maybe<CustomerToken>;
  /** Request a customer token so that an administrator can perform remote shopping assistance. */
  generateCustomerTokenAsAdmin?: Maybe<GenerateCustomerTokenAsAdminOutput>;
  generatePremiumOTP: Scalars['Boolean'];
  generateValuOtp?: Maybe<GenerateValuOtpResponse>;
  /** Handle a payment response and save the payment in Quote. Use this mutation for Payflow Pro and Payments Pro payment methods. */
  handlePayflowProResponse?: Maybe<PayflowProResponseOutput>;
  logInWithSocialProvider?: Maybe<CustomerToken>;
  /** Transfer the contents of a guest cart into the cart of a logged-in customer. */
  mergeCarts: Cart;
  /** Transfer the contents of a guest cart into the cart of a logged-in customer. */
  mergeCartsV2: Cart;
  /** Reset Customer Password */
  otpResetPassword?: Maybe<Scalars['String']>;
  payInstallments: Array<Maybe<PayInstallmentsOutput>>;
  payfortMotoSignature?: Maybe<TokenizationRequestParamsOutput>;
  payfortMotoSignatureV2?: Maybe<TokenizationRequestParamsOutput>;
  payfortSignature?: Maybe<TokenizationRequestParamsOutput>;
  /** Convert the quote into an order. */
  placeOrder?: Maybe<SplittedPlaceOrderOutput>;
  /** Predict The Match */
  predictMatch: PredictMatchOutput;
  /** Share Product With Deep Link */
  productShare?: Maybe<Scalars['String']>;
  rateOrder?: Maybe<Scalars['Boolean']>;
  refreshAdminToken: AdminToken;
  refreshToken: CustomerToken;
  removeCardFromUser?: Maybe<CustomerOutput>;
  /** Remove Customizable options from cart items */
  removeCartItemsCustomOptions?: Maybe<UpdateCartItemCustomizableOptionsOutput>;
  /** Remove a previously-applied coupon from the cart. The cart must contain at least one item in order to remove the coupon. */
  removeCouponFromCart?: Maybe<RemoveCouponFromCartOutput>;
  /** remove a product from wishlist */
  removeFromWishlist: Wishlist;
  /** Delete the entire quantity of a specified item from the cart. If you remove all items from the cart, the cart continues to exist. */
  removeItemFromCart?: Maybe<RemoveItemFromCartOutput>;
  /** Remove products from the specified compare list. */
  removeProductsFromCompareList?: Maybe<CompareList>;
  /** Remove one or more products from the specified wish list. */
  removeProductsFromWishlist?: Maybe<RemoveProductsFromWishlistOutput>;
  /** Add all products from a customer's previous order to the cart. */
  reorderItems?: Maybe<ReorderItemsOutput>;
  /** Request an email with a reset password token for the registered user/admin identified by the specified username. */
  requestAdminPasswordReset?: Maybe<Scalars['Boolean']>;
  /** Request an email with a reset password token for the registered customer identified by the specified email. */
  requestPasswordResetEmail?: Maybe<Scalars['Boolean']>;
  /** Request an SMS with a OTP for the registered customer identified by the specified phone. */
  requestPasswordResetSMS?: Maybe<Scalars['Boolean']>;
  /** Sends a new OTP to the customer phone number. */
  resendOTP: Scalars['Boolean'];
  /** Reset a user's password using the reset password token that the user received in an email. */
  resetAdminPassword?: Maybe<Scalars['Boolean']>;
  /** Reset a customer's password using the reset password token that the customer received in an email after requesting it using `requestPasswordResetEmail`. */
  resetPassword?: Maybe<Scalars['Boolean']>;
  revokeAdminToken?: Maybe<RevokeAdminTokenOutput>;
  /** Revoke the customer token. */
  revokeCustomerToken?: Maybe<RevokeCustomerTokenOutput>;
  /** Select Sources In Cart */
  selectSourcesInCart?: Maybe<CartSourcesOutput>;
  /** Send a message on behalf of a customer to the specified email addresses. */
  sendEmailToFriend?: Maybe<SendEmailToFriendOutput>;
  sendOtpToGuestUser?: Maybe<SendOtpToGuestUserOutput>;
  /** Set the billing address on a specific cart. */
  setBillingAddressOnCart?: Maybe<SetBillingAddressOnCartOutput>;
  setCardOnCart?: Maybe<SetCartCardOutput>;
  setContactMethodOnCart?: Maybe<SetCartContactMethodOutput>;
  setCustomerDataOnCart?: Maybe<SetCustomerDataOnCartOutput>;
  setCustomerPreferredContactMethod?: Maybe<SetCustomerPreferredContactMethodOutput>;
  setCustomerRayaEliteCorporateInformation: Scalars['Boolean'];
  setCustomerRayaEliteGovernorateAndDistrictIds: Scalars['Boolean'];
  setCustomerRayaEliteMedicalId: Scalars['Boolean'];
  setCustomerRayaEliteNationalIDInformation: Scalars['Boolean'];
  setCustomerRayaElitePersonalImage: Scalars['Boolean'];
  setCustomerSelectedStockSource: SetCustomerSelectedStockSourceOutput;
  setEliteOrderDownPaymentAndPlanOnCart: SetEliteOrderDownPaymentAndPlanOnCartOutput;
  setEliteOrderDownPaymentMethodOnCart: SetEliteOrderDownPaymentMethodOnCartOutput;
  /** set the chosen flip and win card that user chose */
  setFlipAndWinChoiceOnCart: SetFlipAndWinChoiceOnCartOutput;
  /** Assign the email address of a guest to the cart. */
  setGuestEmailOnCart?: Maybe<SetGuestEmailOnCartOutput>;
  /** set Accept Installment plan to orders */
  setInstallmentPlanOnCart?: Maybe<SetInstallmentPlanOnCartOutput>;
  setInstallmentsPaymentOptionsOnCart?: Maybe<SetInstallmentsPaymentOptionsOnCartOutput>;
  setInstallmentsSignatureOptionsOnCart?: Maybe<SetInstallmentsSignatureOptionsOnCartOutput>;
  /** Set MPGS Installment plan to orders */
  setMpgsInstallmentPlanOnCart?: Maybe<SetInstallmentPlanOnCartOutput>;
  setNationalIdOnAccount: Scalars['Boolean'];
  /** Sets the medium source on cart. */
  setOrderMediumSourceOnCart: OrderMediumSourceOnCartOutput;
  /** sets the client type the user used on cart */
  setOrderSourceOnCart: OrderSourceOnCartOutput;
  /**
   * Set the cart payment method and convert the cart into an order.
   * @deprecated Should use setPaymentMethodOnCart and placeOrder mutations in single request.
   */
  setPaymentMethodAndPlaceOrder?: Maybe<PlaceOrderOutput>;
  /** Apply a payment method to the cart. */
  setPaymentMethodOnCart?: Maybe<SetPaymentMethodOnCartOutput>;
  /** Set one or more shipping addresses on a specific cart. */
  setShippingAddressesOnCart?: Maybe<SetShippingAddressesOnCartOutput>;
  /** Set one or more delivery methods on a cart. */
  setShippingMethodsOnCart?: Maybe<SetShippingMethodsOnCartOutput>;
  setTaksetyAddressInfo: TaksetyApplication;
  setTaksetyCarOwnershipInformation: TaksetyApplication;
  setTaksetyGuarantorInformation: TaksetyApplication;
  setTaksetyMedicalInsurance: TaksetyApplication;
  setTaksetyOccupationDetails: TaksetyApplication;
  setTaksetyPersonalInfo: TaksetyApplication;
  setTaksetySportsClub: TaksetyApplication;
  /** Spin the active wheel */
  spinTheWheel: WheelResult;
  /** add Product to stock back notification */
  stockBackNotification: Scalars['Boolean'];
  /** Submit Question Answer */
  submitAnswer: SubmitAnswerOutput;
  submitCustomerData: GetCustomerDataResponse;
  /** request for credit limit increase */
  submitEliteCreditLimitIncreaseRequest?: Maybe<Scalars['Boolean']>;
  submitRayaEliteApplication: Scalars['Boolean'];
  /** Submit order return request */
  submitReturnRequest: Scalars['Boolean'];
  submitTaksetyApplication: Scalars['Boolean'];
  /** Subscribe the specified email to the store's newsletter. */
  subscribeEmailToNewsletter?: Maybe<SubscribeEmailToNewsletterOutput>;
  /** Modify items in the cart. */
  updateCartItems?: Maybe<UpdateCartItemsOutput>;
  /** Update Customizable options for cart items */
  updateCartItemsCustomOptions?: Maybe<UpdateCartItemCustomizableOptionsOutput>;
  /** Use `updateCustomerV2` instead. */
  updateCustomer?: Maybe<CustomerOutput>;
  /** Update the billing or shipping address of a customer or guest. */
  updateCustomerAddress?: Maybe<CustomerAddress>;
  /** Change the email address for the logged-in customer. */
  updateCustomerEmail?: Maybe<CustomerOutput>;
  /** Update the customer's personal information. */
  updateCustomerV2?: Maybe<CustomerOutput>;
  /** Update customer fcm token */
  updateFcmToken: Scalars['Boolean'];
  /** Update one or more products in the specified wish list. */
  updateProductsInWishlist?: Maybe<UpdateProductsInWishlistOutput>;
  updateSellerOrderStatus?: Maybe<CustomerOrder>;
  updateSellerProducts: UpdateSellerProductsOutput;
  /** Fetch available plans form valu for the cart */
  valuPlans: Array<Maybe<ValuPlan>>;
  valuPurchase?: Maybe<VerifyValuOtpResponse>;
  /** Send OTP To Customer To Verify The Payment */
  valuVerify?: Maybe<Scalars['Boolean']>;
  /** Verifies customer phone number using OTP */
  verifyCustomer: CustomerToken;
  /** Verifies customer information using OTP */
  verifyCustomerInformation: Scalars['Boolean'];
  /** Verifies national ID using OTP */
  verifyCustomerNationalId: Scalars['Boolean'];
  verifyEliteCart: Scalars['Boolean'];
  verifyGuestUserOtp?: Maybe<VerifyGuestUserCartOtpOutput>;
  /** Verify OTP sent to customer */
  verifyOTP?: Maybe<Scalars['String']>;
  verifyOldEliteAccount: Scalars['Boolean'];
  /** Verify customer's reset password token that they received in an email after requesting it using requestPasswordResetEmail. */
  verifyResetPasswordToken?: Maybe<Scalars['Boolean']>;
  verifyValuOtp?: Maybe<VerifyValuOtpResponse>;
  /** Update the system about a successful product page rendering */
  viewProductPage?: Maybe<Scalars['Boolean']>;
};


export type MutationAdminSetOrderSourceOnCartArgs = {
  input?: Maybe<AdminOrderSourceOnCartInput>;
};


export type MutationAddBundlePackToCartArgs = {
  input?: Maybe<AddBundlePackToCartInput>;
};


export type MutationAddBundleProductsToCartArgs = {
  input?: Maybe<AddBundleProductsToCartInput>;
};


export type MutationAddConfigurableProductsToCartArgs = {
  input?: Maybe<AddConfigurableProductsToCartInput>;
};


export type MutationAddCustomLogsArgs = {
  input: StoreCustomLogs;
};


export type MutationAddCustomOptionsToCartItemsArgs = {
  cartId: Scalars['String'];
  items: Array<UpdateCartItemCustomizableOptionsInput>;
};


export type MutationAddDownloadableProductsToCartArgs = {
  input?: Maybe<AddDownloadableProductsToCartInput>;
};


export type MutationAddProductsToCartArgs = {
  cartId: Scalars['String'];
  cartItems: Array<CartItemInput>;
};


export type MutationAddProductsToCompareListArgs = {
  input?: Maybe<AddProductsToCompareListInput>;
};


export type MutationAddProductsToWishlistArgs = {
  wishlistId: Scalars['ID'];
  wishlistItems: Array<WishlistItemInput>;
};


export type MutationAddSimpleProductsToCartArgs = {
  input?: Maybe<AddSimpleProductsToCartInput>;
};


export type MutationAddToWishlistArgs = {
  product_id: Scalars['Int'];
};


export type MutationAddVirtualProductsToCartArgs = {
  input?: Maybe<AddVirtualProductsToCartInput>;
};


export type MutationAddWishlistItemsToCartArgs = {
  wishlistId: Scalars['ID'];
  wishlistItemIds?: Maybe<Array<Scalars['ID']>>;
};


export type MutationApplyCouponToCartArgs = {
  input?: Maybe<ApplyCouponToCartInput>;
};


export type MutationAssignCompareListToCustomerArgs = {
  uid: Scalars['ID'];
};


export type MutationAssignCustomerToGuestCartArgs = {
  cart_id: Scalars['String'];
};


export type MutationChangeAdminPasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationChangeCustomerPasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationCheckIfCustomerHasEliteAccountArgs = {
  national_id: Scalars['String'];
};


export type MutationContactFormArgs = {
  input?: Maybe<ContactFormInput>;
};


export type MutationCreateCancelRequestArgs = {
  input: CancelRequestInput;
};


export type MutationCreateCompareListArgs = {
  input?: Maybe<CreateCompareListInput>;
};


export type MutationCreateCustomerArgs = {
  input?: Maybe<CreateCustomerInput>;
};


export type MutationCreateCustomerAddressArgs = {
  input: CustomerAddressInput;
};


export type MutationCreateCustomerV2Args = {
  input: CustomerCreateInput;
};


export type MutationCreateEmptyCartArgs = {
  input?: Maybe<CreateEmptyCartInput>;
};


export type MutationCreateInquiryArgs = {
  categoryId: Scalars['Int'];
  inputs: Array<Maybe<KeyValuePair>>;
  providerId: Scalars['Int'];
  serviceId: Scalars['Int'];
};


export type MutationCreateMaintenanceRequestArgs = {
  input: CreateMaintenanceRequestInput;
};


export type MutationCreatePayflowProTokenArgs = {
  input: PayflowProTokenInput;
};


export type MutationCreatePaypalExpressTokenArgs = {
  input: PaypalExpressTokenInput;
};


export type MutationCreateProductReviewArgs = {
  input: CreateProductReviewInput;
};


export type MutationDeleteCompareListArgs = {
  uid: Scalars['ID'];
};


export type MutationDeleteCustomerAddressArgs = {
  id: Scalars['Int'];
};


export type MutationDeletePaymentTokenArgs = {
  public_hash: Scalars['String'];
};


export type MutationDismissMatchPredictionPopupArgs = {
  input?: Maybe<DismissPopupInput>;
};


export type MutationEditCustomerPhoneNumberArgs = {
  new_phone_number: Scalars['String'];
  old_phone_number: Scalars['String'];
};


export type MutationGenerateAdminTokenArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationGenerateCustomerTokenArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationGenerateCustomerTokenAsAdminArgs = {
  input: GenerateCustomerTokenAsAdminInput;
};


export type MutationGeneratePremiumOtpArgs = {
  card_number: Scalars['String'];
  cart_id: Scalars['String'];
  expiry_date: Scalars['String'];
};


export type MutationGenerateValuOtpArgs = {
  cart_id: Scalars['String'];
  sandbox: Scalars['Boolean'];
};


export type MutationHandlePayflowProResponseArgs = {
  input: PayflowProResponseInput;
};


export type MutationLogInWithSocialProviderArgs = {
  input: SocialProviderLogInInput;
  socialProvider: SocialProviderEnum;
};


export type MutationMergeCartsArgs = {
  destination_cart_id?: Maybe<Scalars['String']>;
  source_cart_id: Scalars['String'];
};


export type MutationMergeCartsV2Args = {
  destination_cart_id?: Maybe<Scalars['String']>;
  source_cart_id: Scalars['String'];
};


export type MutationOtpResetPasswordArgs = {
  input?: Maybe<ResetPasswordInput>;
};


export type MutationPayInstallmentsArgs = {
  acs_eci: Scalars['String'];
  authentication_token: Scalars['String'];
  installment_id: Scalars['String'];
  session_id: Scalars['String'];
  transaction_id: Scalars['String'];
};


export type MutationPayfortMotoSignatureArgs = {
  cart_id: Scalars['String'];
  device_fingerprint?: Maybe<Scalars['String']>;
  return_url: Scalars['String'];
  sandbox: Scalars['Boolean'];
};


export type MutationPayfortMotoSignatureV2Args = {
  return_url: Scalars['String'];
  sandbox: Scalars['Boolean'];
};


export type MutationPayfortSignatureArgs = {
  cart_id: Scalars['String'];
  issuer_code?: Maybe<Scalars['String']>;
  plan_code?: Maybe<Scalars['String']>;
  return_url: Scalars['String'];
  sandbox: Scalars['Boolean'];
};


export type MutationPlaceOrderArgs = {
  input?: Maybe<PlaceOrderInput>;
};


export type MutationPredictMatchArgs = {
  input?: Maybe<PredictTheMatchInput>;
};


export type MutationProductShareArgs = {
  input: ProductShareInput;
};


export type MutationRateOrderArgs = {
  input: RateOrderInput;
};


export type MutationRefreshAdminTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationRemoveCardFromUserArgs = {
  card_id: Scalars['String'];
};


export type MutationRemoveCartItemsCustomOptionsArgs = {
  cartId: Scalars['String'];
  items: Array<UpdateCartItemCustomizableOptionsInput>;
};


export type MutationRemoveCouponFromCartArgs = {
  input?: Maybe<RemoveCouponFromCartInput>;
};


export type MutationRemoveFromWishlistArgs = {
  product_id: Scalars['Int'];
};


export type MutationRemoveItemFromCartArgs = {
  input?: Maybe<RemoveItemFromCartInput>;
};


export type MutationRemoveProductsFromCompareListArgs = {
  input?: Maybe<RemoveProductsFromCompareListInput>;
};


export type MutationRemoveProductsFromWishlistArgs = {
  wishlistId: Scalars['ID'];
  wishlistItemsIds: Array<Scalars['ID']>;
};


export type MutationReorderItemsArgs = {
  orderNumber: Scalars['String'];
};


export type MutationRequestAdminPasswordResetArgs = {
  email: Scalars['String'];
  username: Scalars['String'];
};


export type MutationRequestPasswordResetEmailArgs = {
  email: Scalars['String'];
};


export type MutationRequestPasswordResetSmsArgs = {
  phone: Scalars['String'];
};


export type MutationResendOtpArgs = {
  phone_number: Scalars['String'];
  skip_email?: Maybe<Scalars['Boolean']>;
};


export type MutationResetAdminPasswordArgs = {
  newPassword: Scalars['String'];
  resetPasswordToken: Scalars['String'];
  username: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};


export type MutationRevokeAdminTokenArgs = {
  revokeRefreshToken?: Maybe<Scalars['Boolean']>;
};


export type MutationSelectSourcesInCartArgs = {
  input: CartSourcesInput;
};


export type MutationSendEmailToFriendArgs = {
  input?: Maybe<SendEmailToFriendInput>;
};


export type MutationSendOtpToGuestUserArgs = {
  input?: Maybe<SendOtpToGuestUserInput>;
};


export type MutationSetBillingAddressOnCartArgs = {
  input?: Maybe<SetBillingAddressOnCartInput>;
};


export type MutationSetCardOnCartArgs = {
  card_id: Scalars['String'];
};


export type MutationSetContactMethodOnCartArgs = {
  input: SetContactMethodOnCartInput;
};


export type MutationSetCustomerDataOnCartArgs = {
  input?: Maybe<SetCustomerDataOnCartInput>;
};


export type MutationSetCustomerPreferredContactMethodArgs = {
  contactMethod: ContactMethodsEnum;
};


export type MutationSetCustomerRayaEliteCorporateInformationArgs = {
  input: SetCustomerRayaEliteCorporateInformationInput;
};


export type MutationSetCustomerRayaEliteGovernorateAndDistrictIdsArgs = {
  input: SetCustomerRayaEliteGovernorateAndDistrictIdsInput;
};


export type MutationSetCustomerRayaEliteMedicalIdArgs = {
  input: SetCustomerRayaEliteMedicalIdInput;
};


export type MutationSetCustomerRayaEliteNationalIdInformationArgs = {
  input: SetCustomerRayaEliteNationalIdInformationInput;
};


export type MutationSetCustomerRayaElitePersonalImageArgs = {
  input: SetCustomerRayaElitePersonalImageInput;
};


export type MutationSetCustomerSelectedStockSourceArgs = {
  sourceCode: Scalars['String'];
};


export type MutationSetEliteOrderDownPaymentAndPlanOnCartArgs = {
  cart_id: Scalars['String'];
  down_payment: Scalars['Float'];
  plan: Scalars['Int'];
};


export type MutationSetEliteOrderDownPaymentMethodOnCartArgs = {
  cart_id: Scalars['String'];
  down_payment_payment_method: DownPaymentPaymentMethod;
};


export type MutationSetFlipAndWinChoiceOnCartArgs = {
  cartId: Scalars['String'];
  choiceId: Scalars['String'];
};


export type MutationSetGuestEmailOnCartArgs = {
  input?: Maybe<SetGuestEmailOnCartInput>;
};


export type MutationSetInstallmentPlanOnCartArgs = {
  bank_id: Scalars['Int'];
  cart_id: Scalars['String'];
  months: Scalars['Int'];
};


export type MutationSetInstallmentsPaymentOptionsOnCartArgs = {
  input?: Maybe<SetInstallmentsPaymentOptionsOnCartInput>;
};


export type MutationSetInstallmentsSignatureOptionsOnCartArgs = {
  input?: Maybe<SetInstallmentsSignatureOptionsOnCartInput>;
};


export type MutationSetMpgsInstallmentPlanOnCartArgs = {
  cart_id: Scalars['String'];
  months: Scalars['Int'];
};


export type MutationSetNationalIdOnAccountArgs = {
  input: SetNationalIdOnAccountInput;
};


export type MutationSetOrderMediumSourceOnCartArgs = {
  input: OrderMediumSourceOnCartInput;
};


export type MutationSetOrderSourceOnCartArgs = {
  input?: Maybe<OrderSourceOnCartInput>;
};


export type MutationSetPaymentMethodAndPlaceOrderArgs = {
  input?: Maybe<SetPaymentMethodAndPlaceOrderInput>;
};


export type MutationSetPaymentMethodOnCartArgs = {
  input?: Maybe<SetPaymentMethodOnCartInput>;
};


export type MutationSetShippingAddressesOnCartArgs = {
  input?: Maybe<SetShippingAddressesOnCartInput>;
};


export type MutationSetShippingMethodsOnCartArgs = {
  input?: Maybe<SetShippingMethodsOnCartInput>;
};


export type MutationSetTaksetyAddressInfoArgs = {
  input?: Maybe<AddressInfoInput>;
};


export type MutationSetTaksetyCarOwnershipInformationArgs = {
  input?: Maybe<CarOwnershipInformationInput>;
};


export type MutationSetTaksetyGuarantorInformationArgs = {
  input?: Maybe<GuarantorInformationInput>;
};


export type MutationSetTaksetyMedicalInsuranceArgs = {
  input?: Maybe<MedicalInsuranceInput>;
};


export type MutationSetTaksetyOccupationDetailsArgs = {
  input?: Maybe<OccupationDetailsInput>;
};


export type MutationSetTaksetyPersonalInfoArgs = {
  input?: Maybe<PersonalInfoInput>;
};


export type MutationSetTaksetySportsClubArgs = {
  input?: Maybe<SportsClubInput>;
};


export type MutationStockBackNotificationArgs = {
  email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['Int']>;
  product_uid?: Maybe<Scalars['String']>;
};


export type MutationSubmitAnswerArgs = {
  input: SubmitAnswerInput;
};


export type MutationSubmitCustomerDataArgs = {
  input?: Maybe<SubmitCustomerDataInput>;
};


export type MutationSubmitEliteCreditLimitIncreaseRequestArgs = {
  bankStatement: Scalars['String'];
  hrLetter: Scalars['String'];
};


export type MutationSubmitReturnRequestArgs = {
  bankAccountNo?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  customerAddressId?: Maybe<Scalars['Int']>;
  iban?: Maybe<Scalars['String']>;
  orderItems: Array<Maybe<ReturnOrderItemInput>>;
  orderNumber: Scalars['String'];
  preferredPickupDate: Scalars['String'];
  refundMethod?: Maybe<ReturnRefundMethod>;
};


export type MutationSubmitTaksetyApplicationArgs = {
  source?: Maybe<Scalars['String']>;
};


export type MutationSubscribeEmailToNewsletterArgs = {
  email: Scalars['String'];
};


export type MutationUpdateCartItemsArgs = {
  input?: Maybe<UpdateCartItemsInput>;
};


export type MutationUpdateCartItemsCustomOptionsArgs = {
  cartId: Scalars['String'];
  items: Array<UpdateCartItemCustomizableOptionsInput>;
};


export type MutationUpdateCustomerArgs = {
  input: CustomerInput;
};


export type MutationUpdateCustomerAddressArgs = {
  id: Scalars['Int'];
  input?: Maybe<CustomerAddressInput>;
};


export type MutationUpdateCustomerEmailArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationUpdateCustomerV2Args = {
  input: CustomerUpdateInput;
};


export type MutationUpdateFcmTokenArgs = {
  channel: FcmChannelEnum;
  token: Scalars['String'];
};


export type MutationUpdateProductsInWishlistArgs = {
  wishlistId: Scalars['ID'];
  wishlistItems: Array<WishlistItemUpdateInput>;
};


export type MutationUpdateSellerOrderStatusArgs = {
  input: UpdateSellerOrderStatusInput;
};


export type MutationUpdateSellerProductsArgs = {
  input: UpdateSellerProductsInput;
};


export type MutationValuPlansArgs = {
  cartId: Scalars['String'];
  downPayment: Scalars['Float'];
  phoneNumber?: Maybe<Scalars['String']>;
};


export type MutationValuPurchaseArgs = {
  cart_id: Scalars['String'];
  merchant_reference: Scalars['String'];
  otp: Scalars['String'];
  sandbox: Scalars['Boolean'];
  tenure: Scalars['String'];
  total_down_payment?: Maybe<Scalars['Float']>;
  transaction_id: Scalars['String'];
};


export type MutationValuVerifyArgs = {
  cartId: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
};


export type MutationVerifyCustomerArgs = {
  otp: Scalars['String'];
  username: Scalars['String'];
};


export type MutationVerifyCustomerInformationArgs = {
  otp: Scalars['String'];
};


export type MutationVerifyCustomerNationalIdArgs = {
  otp: Scalars['String'];
};


export type MutationVerifyEliteCartArgs = {
  cart_id: Scalars['String'];
  otp: Scalars['String'];
};


export type MutationVerifyGuestUserOtpArgs = {
  input?: Maybe<VerifyGuestUserCartOtpInput>;
};


export type MutationVerifyOtpArgs = {
  input?: Maybe<VerifyOtpInput>;
};


export type MutationVerifyOldEliteAccountArgs = {
  otp: Scalars['String'];
};


export type MutationVerifyResetPasswordTokenArgs = {
  email: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};


export type MutationVerifyValuOtpArgs = {
  cart_id: Scalars['String'];
  merchant_reference: Scalars['String'];
  otp: Scalars['String'];
  sandbox: Scalars['Boolean'];
  total_down_payment?: Maybe<Scalars['Float']>;
};


export type MutationViewProductPageArgs = {
  input?: Maybe<ViewProductPageInput>;
};

/** Contains an error message when an invalid UID was specified. */
export type NoSuchEntityUidError = ErrorInterface & {
  __typename?: 'NoSuchEntityUidError';
  /** The returned error message. */
  message: Scalars['String'];
  /** The specified invalid unique ID of an object. */
  uid: Scalars['ID'];
};

export type OccupationDetails = {
  __typename?: 'OccupationDetails';
  company_name?: Maybe<Scalars['String']>;
  customer_job_title?: Maybe<Scalars['String']>;
  is_private_sector?: Maybe<Scalars['Boolean']>;
  job_address?: Maybe<Scalars['String']>;
  job_starting_date?: Maybe<Scalars['String']>;
  job_type_id?: Maybe<Scalars['Int']>;
  salary?: Maybe<Scalars['Float']>;
};

export type OccupationDetailsInput = {
  company_name?: Maybe<Scalars['String']>;
  customer_job_title?: Maybe<Scalars['String']>;
  is_private_sector?: Maybe<Scalars['Boolean']>;
  job_address?: Maybe<Scalars['String']>;
  job_starting_date?: Maybe<Scalars['String']>;
  job_type_id?: Maybe<Scalars['Int']>;
  salary?: Maybe<Scalars['Float']>;
};

export type Offer = {
  __typename?: 'Offer';
  button_text?: Maybe<Scalars['String']>;
  cms_page_key_ar?: Maybe<Scalars['String']>;
  cms_page_key_en?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  flash_sale_end_time?: Maybe<Scalars['String']>;
  flash_sale_end_time_timezone_adjusted?: Maybe<Scalars['String']>;
  flash_sale_start_time?: Maybe<Scalars['String']>;
  flash_sale_start_time_timezone_adjusted?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image_url: Scalars['String'];
  is_dimmed?: Maybe<Scalars['Boolean']>;
  is_featured?: Maybe<Scalars['Boolean']>;
  is_hot?: Maybe<Scalars['Boolean']>;
  is_mega_menu?: Maybe<Scalars['Boolean']>;
  link_object_url_key?: Maybe<Scalars['String']>;
  link_type?: Maybe<Scalars['String']>;
  mobile_image_url: Scalars['String'];
  offer_position?: Maybe<Scalars['Int']>;
  offer_url?: Maybe<Scalars['String']>;
  responsive_image_url: Scalars['String'];
  sort?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** Contains the order ID. */
export type Order = {
  __typename?: 'Order';
  acceptIframeUrl?: Maybe<Scalars['String']>;
  /** Accept installment plan payment fees */
  accept_installment_plan_fees?: Maybe<Scalars['Float']>;
  /** Accept installment plan payment fees */
  accept_installment_plan_interest_amount?: Maybe<Scalars['Float']>;
  /** get is refundable for return or not */
  is_refundable_for_return: Scalars['Boolean'];
  mpgsUrl?: Maybe<Scalars['String']>;
  /** @deprecated Use `order_number` instead. */
  order_id?: Maybe<Scalars['String']>;
  /** The unique ID for an `Order` object. */
  order_number: Scalars['String'];
  /** Returnable details if order is returnable, null if order is not returnable */
  order_returnable_details?: Maybe<IsOrderReturnableOutput>;
  /** return requests details */
  return_requests?: Maybe<Array<Maybe<ReturnRequestsOutput>>>;
};


/** Contains the order ID. */
export type OrderMpgsUrlArgs = {
  installments_months?: Maybe<Scalars['Int']>;
  return_url: Scalars['String'];
};

/** Contains detailed information about an order's billing and shipping addresses. */
export type OrderAddress = {
  __typename?: 'OrderAddress';
  address_name?: Maybe<Scalars['String']>;
  apartment?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  /** An object containing the city name, city code, and city ID */
  city?: Maybe<City>;
  /** The customer's company. */
  company?: Maybe<Scalars['String']>;
  /** An object containing the country name, country code, and country ID */
  country?: Maybe<Country>;
  /** The customer's country. */
  country_code?: Maybe<CountryCodeEnum>;
  /** The fax number. */
  fax?: Maybe<Scalars['String']>;
  /** The first name of the person associated with the shipping/billing address. */
  firstname: Scalars['String'];
  floor?: Maybe<Scalars['Int']>;
  /** The family name of the person associated with the shipping/billing address. */
  lastname: Scalars['String'];
  /** The middle name of the person associated with the shipping/billing address. */
  middlename?: Maybe<Scalars['String']>;
  /** The customer's ZIP or postal code. */
  postcode?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** The state or province name. */
  region?: Maybe<Scalars['String']>;
  /** The unique ID for a `Region` object of a pre-defined region. */
  region_id?: Maybe<Scalars['ID']>;
  /** An array of strings that define the street number and name. */
  street: Array<Maybe<Scalars['String']>>;
  /** A value such as Sr., Jr., or III. */
  suffix?: Maybe<Scalars['String']>;
  /** The telephone number. */
  telephone?: Maybe<Scalars['String']>;
  /** The customer's Value-added tax (VAT) number (for corporate customers). */
  vat_id?: Maybe<Scalars['String']>;
};

export type OrderCancelRequestStatus = {
  __typename?: 'OrderCancelRequestStatus';
  created_at?: Maybe<Scalars['String']>;
  has_cancel_request?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  refund_method?: Maybe<Scalars['String']>;
  request_id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
};

export type OrderItem = OrderItemInterface & {
  __typename?: 'OrderItem';
  /** The final discount information for the product. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The entered option for the base product, such as a logo or image. */
  entered_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The selected gift message for the order item */
  gift_message?: Maybe<GiftMessage>;
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID'];
  original_price?: Maybe<Scalars['Float']>;
  price_incl_tax?: Maybe<Scalars['Float']>;
  /** The product brand. */
  product_brand?: Maybe<Brand>;
  /** The categories assigned to the product. */
  product_categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  product_data?: Maybe<ProductInterface>;
  product_image?: Maybe<Scalars['String']>;
  /** The URL of the main product image of the base product */
  product_image_url?: Maybe<Scalars['String']>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The original price of the base product */
  product_original_price: Money;
  /** The sale price of the base product, including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The URL of the thumbnail image of the base product */
  product_thumbnail_url?: Maybe<Scalars['String']>;
  /** The type of product, such as simple, configurable, etc. */
  product_type?: Maybe<Scalars['String']>;
  /** URL key of the base product. */
  product_url_key?: Maybe<Scalars['String']>;
  /** The number of canceled items. */
  quantity_canceled?: Maybe<Scalars['Float']>;
  /** The number of invoiced items. */
  quantity_invoiced?: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item. */
  quantity_ordered?: Maybe<Scalars['Float']>;
  /** The number of refunded items. */
  quantity_refunded?: Maybe<Scalars['Float']>;
  /** The number of returned items. */
  quantity_returned?: Maybe<Scalars['Float']>;
  /** The number of shipped items. */
  quantity_shipped?: Maybe<Scalars['Float']>;
  row_total_incl_tax?: Maybe<Scalars['Float']>;
  /** The selected options for the base product, such as color or size. */
  selected_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item. */
  status?: Maybe<Scalars['String']>;
};

/** Order item details. */
export type OrderItemInterface = {
  /** The final discount information for the product. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The entered option for the base product, such as a logo or image. */
  entered_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The selected gift message for the order item */
  gift_message?: Maybe<GiftMessage>;
  /** The unique ID for an `OrderItemInterface` object. */
  id: Scalars['ID'];
  original_price?: Maybe<Scalars['Float']>;
  price_incl_tax?: Maybe<Scalars['Float']>;
  /** The product brand. */
  product_brand?: Maybe<Brand>;
  /** The categories assigned to the product. */
  product_categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  product_data?: Maybe<ProductInterface>;
  product_image?: Maybe<Scalars['String']>;
  /** The URL of the main product image of the base product */
  product_image_url?: Maybe<Scalars['String']>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The original price of the base product */
  product_original_price: Money;
  /** The sale price of the base product, including selected options. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The URL of the thumbnail image of the base product */
  product_thumbnail_url?: Maybe<Scalars['String']>;
  /** The type of product, such as simple, configurable, etc. */
  product_type?: Maybe<Scalars['String']>;
  /** URL key of the base product. */
  product_url_key?: Maybe<Scalars['String']>;
  /** The number of canceled items. */
  quantity_canceled?: Maybe<Scalars['Float']>;
  /** The number of invoiced items. */
  quantity_invoiced?: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item. */
  quantity_ordered?: Maybe<Scalars['Float']>;
  /** The number of refunded items. */
  quantity_refunded?: Maybe<Scalars['Float']>;
  /** The number of returned items. */
  quantity_returned?: Maybe<Scalars['Float']>;
  /** The number of shipped items. */
  quantity_shipped?: Maybe<Scalars['Float']>;
  row_total_incl_tax?: Maybe<Scalars['Float']>;
  /** The selected options for the base product, such as color or size. */
  selected_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item. */
  status?: Maybe<Scalars['String']>;
};

/** Represents order item options like selected or entered. */
export type OrderItemOption = {
  __typename?: 'OrderItemOption';
  /** The name of the option. */
  label: Scalars['String'];
  /** The price of the option. */
  price?: Maybe<Scalars['Float']>;
  /** The price type of the option. */
  type?: Maybe<Scalars['String']>;
  /** The value of the option. */
  value: Scalars['String'];
};

export type OrderMediumSourceOnCartInput = {
  cart_id: Scalars['String'];
  order_medium_source: Scalars['String'];
};

export type OrderMediumSourceOnCartOutput = {
  __typename?: 'OrderMediumSourceOnCartOutput';
  cart: Cart;
};

/** Contains details about the payment method used to pay for the order. */
export type OrderPaymentMethod = {
  __typename?: 'OrderPaymentMethod';
  /** Additional data per payment method type. */
  additional_data?: Maybe<Array<Maybe<KeyValue>>>;
  /** The label that describes the payment method. */
  name: Scalars['String'];
  /** The payment method code that indicates how the order was paid for. */
  type: Scalars['String'];
};

export type OrderRateSingle = {
  criteria_id: Scalars['String'];
  value: Scalars['Float'];
};

export type OrderRateSingleCriteria = {
  __typename?: 'OrderRateSingleCriteria';
  name: Scalars['String'];
  value: Scalars['String'];
};

/** Contains order shipment details. */
export type OrderShipment = {
  __typename?: 'OrderShipment';
  /** Comments added to the shipment. */
  comments?: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** The unique ID for a `OrderShipment` object. */
  id: Scalars['ID'];
  /** An array of items included in the shipment. */
  items?: Maybe<Array<Maybe<ShipmentItemInterface>>>;
  /** The sequential credit shipment number. */
  number: Scalars['String'];
  /** An array of shipment tracking details. */
  tracking?: Maybe<Array<Maybe<ShipmentTracking>>>;
};

export type OrderSourceOnCartInput = {
  cart_id: Scalars['String'];
  order_source: Scalars['String'];
};

export type OrderSourceOnCartOutput = {
  __typename?: 'OrderSourceOnCartOutput';
  cart: Cart;
};

export enum OrderStatus {
  Delivered = 'DELIVERED',
  InProcess = 'IN_PROCESS',
  Pending = 'PENDING',
  Shipped = 'SHIPPED'
}

/** Contains details about the sales total amounts used to calculate the final price. */
export type OrderTotal = {
  __typename?: 'OrderTotal';
  /** The final base grand total amount in the base currency. */
  base_grand_total: Money;
  /** The applied discounts to the order. */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The final total amount, including shipping, discounts, and taxes. */
  grand_total: Money;
  /** Details about the shipping and handling costs for the order. */
  shipping_handling?: Maybe<ShippingHandling>;
  /** The subtotal of the order, excluding shipping, discounts, and taxes. */
  subtotal: Money;
  /** The order tax details. */
  taxes?: Maybe<Array<Maybe<TaxItem>>>;
  /** The shipping amount for the order. */
  total_shipping: Money;
  /** The amount of tax applied to the order. */
  total_tax: Money;
};

export type OrverallProductRating = {
  __typename?: 'OrverallProductRating';
  overallRating: Scalars['Float'];
  overallRatingPercentage: Scalars['Float'];
  stars: Array<Maybe<ProductReviewsStarAverage>>;
};

export type Page = {
  __typename?: 'Page';
  categories_ids?: Maybe<Scalars['String']>;
  first_category_id?: Maybe<Scalars['Int']>;
  first_category_name?: Maybe<Scalars['String']>;
  first_category_url_key?: Maybe<Scalars['String']>;
  fourth_category_id?: Maybe<Scalars['Int']>;
  fourth_category_name?: Maybe<Scalars['String']>;
  fourth_category_url_key?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** The Page offers. */
  offers: Array<Maybe<PageOffer>>;
  second_category_id?: Maybe<Scalars['Int']>;
  second_category_name?: Maybe<Scalars['String']>;
  second_category_url_key?: Maybe<Scalars['String']>;
  third_category_id?: Maybe<Scalars['Int']>;
  third_category_name?: Maybe<Scalars['String']>;
  third_category_url_key?: Maybe<Scalars['String']>;
  url_key?: Maybe<Scalars['String']>;
};


export type PageOffersArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export type PageOffer = {
  __typename?: 'PageOffer';
  button_text?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image_url: Scalars['String'];
  link_object_url_key?: Maybe<Scalars['String']>;
  link_type?: Maybe<Scalars['String']>;
  mobile_image_url: Scalars['String'];
  offer_url: Scalars['String'];
  sort?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** The collection of pages that match the conditions defined in the filter. */
export type Pages = {
  __typename?: 'Pages';
  /** An array of pages. */
  items: Array<Maybe<Page>>;
  /** Contains pagination metadata. */
  page_info?: Maybe<SearchResultPageInfo>;
  /** The total count of pages. */
  total_count?: Maybe<Scalars['Int']>;
};

export type PagesAttributeFilterInput = {
  category_id?: Maybe<Scalars['String']>;
  name?: Maybe<PagesNameMatchTypeInput>;
  url_key?: Maybe<FilterEqualTypeInput>;
};

/** Defines a filter that performs a fuzzy search. */
export type PagesNameMatchTypeInput = {
  /** Use this attribute to exactly match the specified string. For example, to filter on a specific SKU, specify a value such as `24-MB01`. */
  match?: Maybe<Scalars['String']>;
  /** Use this attribute to match the start string. For example, to filter on a specific start alphabet name A, specify a value such as `A` */
  matchAlpha?: Maybe<Scalars['String']>;
};

export type PayInstallmentsOutput = {
  __typename?: 'PayInstallmentsOutput';
  deposit_id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

/** Contains required input for Payflow Express Checkout payments. */
export type PayflowExpressInput = {
  /** The unique ID of the PayPal user. */
  payer_id: Scalars['String'];
  /** The token returned by the createPaypalExpressToken mutation. */
  token: Scalars['String'];
};

/** A set of relative URLs that PayPal uses in response to various actions during the authorization process. Adobe Commerce prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payflow Link and Payments Advanced payment methods. */
export type PayflowLinkInput = {
  /** The relative URL of the page that PayPal redirects to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String'];
  /** The relative URL of the transaction error page that PayPal redirects to upon payment error. If the full URL to this page is https://www.example.com/paypal/action/error.html, the relative URL is paypal/action/error.html. */
  error_url: Scalars['String'];
  /** The relative URL of the order confirmation page that PayPal redirects to when the payment is successful and additional confirmation is not needed. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String'];
};

/** Indicates the mode for payment. Applies to the Payflow Link and Payments Advanced payment methods. */
export enum PayflowLinkMode {
  Live = 'LIVE',
  Test = 'TEST'
}

/** Contains information used to generate PayPal iframe for transaction. Applies to Payflow Link and Payments Advanced payment methods. */
export type PayflowLinkToken = {
  __typename?: 'PayflowLinkToken';
  /** The mode for the Payflow transaction. */
  mode?: Maybe<PayflowLinkMode>;
  /** The PayPal URL used for requesting a Payflow form. */
  paypal_url?: Maybe<Scalars['String']>;
  /** The secure token generated by PayPal. */
  secure_token?: Maybe<Scalars['String']>;
  /** The secure token ID generated by PayPal. */
  secure_token_id?: Maybe<Scalars['String']>;
};

/** Contains information required to fetch payment token information for the Payflow Link and Payments Advanced payment methods. */
export type PayflowLinkTokenInput = {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String'];
};

/** Contains input for the Payflow Pro and Payments Pro payment methods. */
export type PayflowProInput = {
  /** Required input for credit card related information. */
  cc_details: CreditCardDetailsInput;
  /** Indicates whether details about the shopper's credit/debit card should be tokenized for later usage. Required only if Vault is enabled for the PayPal Payflow Pro payment integration. */
  is_active_payment_token_enabler?: Maybe<Scalars['Boolean']>;
};

/** Input required to complete payment. Applies to Payflow Pro and Payments Pro payment methods. */
export type PayflowProResponseInput = {
  /** The unique ID that identifies the shopper's cart. */
  cart_id: Scalars['String'];
  /** The payload returned from PayPal. */
  paypal_payload: Scalars['String'];
};

export type PayflowProResponseOutput = {
  __typename?: 'PayflowProResponseOutput';
  /** The cart with the updated selected payment method. */
  cart: Cart;
};

/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export type PayflowProToken = {
  __typename?: 'PayflowProToken';
  /** The RESPMSG returned by PayPal. If the `result` is `0`, then `response_message` is `Approved`. */
  response_message: Scalars['String'];
  /** A non-zero value if any errors occurred. */
  result: Scalars['Int'];
  /** The RESULT returned by PayPal. A value of `0` indicates the transaction was approved. */
  result_code: Scalars['Int'];
  /** A secure token generated by PayPal. */
  secure_token: Scalars['String'];
  /** A secure token ID generated by PayPal. */
  secure_token_id: Scalars['String'];
};

/** Contains input required to fetch payment token information for the Payflow Pro and Payments Pro payment methods. */
export type PayflowProTokenInput = {
  /** The unique ID that identifies the shopper's cart. */
  cart_id: Scalars['String'];
  /** A set of relative URLs that PayPal uses for callback. */
  urls: PayflowProUrlInput;
};

/** Contains a set of relative URLs that PayPal uses in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for the Payflow Pro and Payment Pro payment methods. */
export type PayflowProUrlInput = {
  /** The relative URL of the page that PayPal redirects to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String'];
  /** The relative URL of the transaction error page that PayPal redirects to upon payment error. If the full URL to this page is https://www.example.com/paypal/action/error.html, the relative URL is paypal/action/error.html. */
  error_url: Scalars['String'];
  /** The relative URL of the final confirmation page that PayPal redirects to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String'];
};

export type PayfortInstallmentsPlans = {
  __typename?: 'PayfortInstallmentsPlans';
  access_code: Scalars['String'];
  amount: Scalars['String'];
  currency: Scalars['String'];
  installment_detail?: Maybe<InstallmentDetails>;
  language: Scalars['String'];
  merchant_identifier: Scalars['String'];
  query_command: Scalars['String'];
  response_code: Scalars['String'];
  response_message: Scalars['String'];
  status: Scalars['String'];
};

/** Defines the payment method. */
export type PaymentMethodInput = {
  braintree?: Maybe<BraintreeInput>;
  braintree_cc_vault?: Maybe<BraintreeCcVaultInput>;
  /** The internal name for the payment method. */
  code: Scalars['String'];
  /** Required input for PayPal Hosted pro payments. */
  hosted_pro?: Maybe<HostedProInput>;
  /** Required input for Payflow Express Checkout payments. */
  payflow_express?: Maybe<PayflowExpressInput>;
  /** Required input for PayPal Payflow Link and Payments Advanced payments. */
  payflow_link?: Maybe<PayflowLinkInput>;
  /** Required input for PayPal Payflow Pro and Payment Pro payments. */
  payflowpro?: Maybe<PayflowProInput>;
  /** Required input for PayPal Payflow Pro vault payments. */
  payflowpro_cc_vault?: Maybe<VaultTokenInput>;
  /** Required input for Express Checkout and Payments Standard payments. */
  paypal_express?: Maybe<PaypalExpressInput>;
  /** The purchase order number. Optional for most payment methods. */
  purchase_order_number?: Maybe<Scalars['String']>;
};

/** The stored payment method available to the customer. */
export type PaymentToken = {
  __typename?: 'PaymentToken';
  /** A description of the stored account details. */
  details?: Maybe<Scalars['String']>;
  /** The payment method code associated with the token. */
  payment_method_code: Scalars['String'];
  /** The public hash of the token. */
  public_hash: Scalars['String'];
  /** Specifies the payment token type. */
  type: PaymentTokenTypeEnum;
};

/** The list of available payment token types. */
export enum PaymentTokenTypeEnum {
  /** phpcs:ignore Magento2.GraphQL.ValidArgumentName */
  Account = 'account',
  /** phpcs:ignore Magento2.GraphQL.ValidArgumentName */
  Card = 'card'
}

export type PaymobUtilitesCategories = {
  __typename?: 'PaymobUtilitesCategories';
  /** ID of the category, used to fetch providers */
  id: Scalars['Int'];
  /** Category Image */
  image?: Maybe<Scalars['String']>;
  /** Name of the category */
  name: Scalars['String'];
};

export type PaymobUtilitesInquiryInputs = {
  __typename?: 'PaymobUtilitesInquiryInputs';
  /** Character limit for the input if needed, default = null */
  char_limit?: Maybe<Scalars['Int']>;
  /** List of choices if the input has choices */
  choices?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Input datatype (str/float) */
  data_type?: Maybe<Scalars['String']>;
  /** Description of the input */
  description?: Maybe<Scalars['String']>;
  /** Does this input have fixed choices or not */
  has_choices?: Maybe<Scalars['Boolean']>;
  /** numeric for numbers only - decimal for numbers but allowing a decimal point - character for alphanumeric character inputs */
  input_type: Scalars['String'];
  /** Determine if the input is mandatory or not */
  mandatory?: Maybe<Scalars['Boolean']>;
  /** Maximum allowed amount */
  max_amount?: Maybe<Scalars['Float']>;
  /** Minimum allowed amount */
  min_amount?: Maybe<Scalars['Float']>;
  /** Field name key */
  name: Scalars['String'];
};

export type PaymobUtilitesPaymentMethods = {
  __typename?: 'PaymobUtilitesPaymentMethods';
  /** fees of the payment method */
  fees: Scalars['Float'];
  /** ID of the payment method */
  id: Scalars['String'];
  /** Name of the payment method */
  name: Scalars['String'];
};

export type PaymobUtilitesProviders = {
  __typename?: 'PaymobUtilitesProviders';
  /** ID of the provider */
  id: Scalars['Int'];
  /** name of the provider */
  name: Scalars['String'];
  /** services of the provider */
  services?: Maybe<Array<Maybe<PaymobUtilitesServices>>>;
};

export type PaymobUtilitesServices = {
  __typename?: 'PaymobUtilitesServices';
  can_replicate_payment?: Maybe<Scalars['Boolean']>;
  fees_inquiry_only?: Maybe<Scalars['Boolean']>;
  /** Service ID */
  id: Scalars['Int'];
  inputs?: Maybe<Array<Maybe<PaymobUtilitesInquiryInputs>>>;
  /** Service name */
  name: Scalars['String'];
};

/** Contains required input for Express Checkout and Payments Standard payments. */
export type PaypalExpressInput = {
  /** The unique ID of the PayPal user. */
  payer_id: Scalars['String'];
  /** The token returned by the `createPaypalExpressToken` mutation. */
  token: Scalars['String'];
};

/** Deprecated. Use `PaypalExpressTokenOutput` instead. */
export type PaypalExpressToken = {
  __typename?: 'PaypalExpressToken';
  /**
   * A set of URLs that allow the buyer to authorize payment and adjust checkout details.
   * @deprecated Use `PaypalExpressTokenOutput.paypal_urls` instead.
   */
  paypal_urls?: Maybe<PaypalExpressUrlList>;
  /**
   * The token returned by PayPal.
   * @deprecated Use `PaypalExpressTokenOutput.token` instead.
   */
  token?: Maybe<Scalars['String']>;
};

/** Defines the attributes required to receive a payment token for Express Checkout and Payments Standard payment methods. */
export type PaypalExpressTokenInput = {
  /** The unique ID that identifies the customer's cart. */
  cart_id: Scalars['String'];
  /** The payment method code. */
  code: Scalars['String'];
  /** Indicates whether the buyer selected the quick checkout button. The default value is false. */
  express_button?: Maybe<Scalars['Boolean']>;
  /** A set of relative URLs that PayPal uses in response to various actions during the authorization process. */
  urls: PaypalExpressUrlsInput;
  /** Indicates whether the buyer clicked the PayPal credit button. The default value is false. */
  use_paypal_credit?: Maybe<Scalars['Boolean']>;
};

/** Contains the token returned by PayPal and a set of URLs that allow the buyer to authorize payment and adjust checkout details. Applies to Express Checkout and Payments Standard payment methods. */
export type PaypalExpressTokenOutput = {
  __typename?: 'PaypalExpressTokenOutput';
  /** A set of URLs that allow the buyer to authorize payment and adjust checkout details. */
  paypal_urls?: Maybe<PaypalExpressUrlList>;
  /** The token returned by PayPal. */
  token?: Maybe<Scalars['String']>;
};

/** Contains a set of URLs that allow the buyer to authorize payment and adjust checkout details for Express Checkout and Payments Standard transactions. */
export type PaypalExpressUrlList = {
  __typename?: 'PaypalExpressUrlList';
  /** The PayPal URL that allows the buyer to edit their checkout details. */
  edit?: Maybe<Scalars['String']>;
  /** The URL to the PayPal login page. */
  start?: Maybe<Scalars['String']>;
};

/** Contains a set of relative URLs that PayPal uses in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Express Checkout and Payments Standard payment methods. */
export type PaypalExpressUrlsInput = {
  /** The relative URL of the page that PayPal redirects to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String'];
  /** The relative URL of the page that PayPal redirects to when the payment has been put on hold for additional review. This condition mostly applies to ACH transactions, and is not applicable to most PayPal solutions. If the full URL to this page is https://www.example.com/paypal/action/success_pending.html, the relative URL is paypal/action/success_pending.html. */
  pending_url?: Maybe<Scalars['String']>;
  /** The relative URL of the final confirmation page that PayPal redirects to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String'];
  /** The relative URL of the order confirmation page that PayPal redirects to when the payment is successful and additional confirmation is not needed. Not applicable to most PayPal solutions. If the full URL to this page is https://www.example.com/paypal/action/success.html, the relative URL is paypal/action/success.html. */
  success_url?: Maybe<Scalars['String']>;
};

export type PersonalInfo = {
  __typename?: 'PersonalInfo';
  customer_martial_status?: Maybe<Scalars['Int']>;
  family_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  national_id_back_image?: Maybe<Scalars['String']>;
  national_id_front_image?: Maybe<Scalars['String']>;
};

export type PersonalInfoInput = {
  customer_martial_status?: Maybe<Scalars['Int']>;
  family_name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  national_id_back_image?: Maybe<Scalars['String']>;
  national_id_front_image?: Maybe<Scalars['String']>;
};

/** Contains attributes specific to tangible products. */
export type PhysicalProductInterface = {
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>;
};

/** Defines Pickup Location information. */
export type PickupLocation = {
  __typename?: 'PickupLocation';
  city?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pickup_location_code?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
};

/** PickupLocationFilterInput defines the list of attributes and filters for the search. */
export type PickupLocationFilterInput = {
  /** Filter by city. */
  city?: Maybe<FilterTypeInput>;
  /** Filter by country. */
  country_id?: Maybe<FilterTypeInput>;
  /** Filter by pickup location name. */
  name?: Maybe<FilterTypeInput>;
  /** Filter by pickup location code. */
  pickup_location_code?: Maybe<FilterTypeInput>;
  /** Filter by postcode. */
  postcode?: Maybe<FilterTypeInput>;
  /** Filter by region. */
  region?: Maybe<FilterTypeInput>;
  /** Filter by region id. */
  region_id?: Maybe<FilterTypeInput>;
  /** Filter by street. */
  street?: Maybe<FilterTypeInput>;
};

/** PickupLocationSortInput specifies attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. */
export type PickupLocationSortInput = {
  /** City where pickup location is placed. */
  city?: Maybe<SortEnum>;
  /** Name of the contact person. */
  contact_name?: Maybe<SortEnum>;
  /** Id of the country in two letters. */
  country_id?: Maybe<SortEnum>;
  /** Description of the pickup location. */
  description?: Maybe<SortEnum>;
  /** Distance to the address, requested by distance filter. Applicable only with distance filter. If distance sort order is present, all other sort orders will be ignored. */
  distance?: Maybe<SortEnum>;
  /** Contact email of the pickup location. */
  email?: Maybe<SortEnum>;
  /** Contact fax of the pickup location. */
  fax?: Maybe<SortEnum>;
  /** Geographic latitude where pickup location is placed. */
  latitude?: Maybe<SortEnum>;
  /** Geographic longitude where pickup location is placed. */
  longitude?: Maybe<SortEnum>;
  /** The pickup location name. Customer use this to identify the pickup location. */
  name?: Maybe<SortEnum>;
  /** Contact phone number of the pickup location. */
  phone?: Maybe<SortEnum>;
  /** A code assigned to pickup location to identify the source. */
  pickup_location_code?: Maybe<SortEnum>;
  /** Postcode where pickup location is placed. */
  postcode?: Maybe<SortEnum>;
  /** Name of the region. */
  region?: Maybe<SortEnum>;
  /** Id of the region. */
  region_id?: Maybe<SortEnum>;
  /** Street where pickup location is placed. */
  street?: Maybe<SortEnum>;
};

/** Top level object returned in a pickup locations search. */
export type PickupLocations = {
  __typename?: 'PickupLocations';
  /** An array of pickup locations that match the specific search request. */
  items?: Maybe<Array<Maybe<PickupLocation>>>;
  /** An object that includes the page_info and currentPage values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>;
  /** The number of products returned. */
  total_count?: Maybe<Scalars['Int']>;
};

/** Specifies the quote to be converted to an order. */
export type PlaceOrderInput = {
  acs_eci?: Maybe<Scalars['String']>;
  authentication_token?: Maybe<Scalars['String']>;
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  down_payment?: Maybe<Scalars['Float']>;
  phone_number?: Maybe<Scalars['String']>;
  premium_otp?: Maybe<Scalars['String']>;
  session_id?: Maybe<Scalars['String']>;
  tenure?: Maybe<Scalars['Int']>;
  transaction_id?: Maybe<Scalars['String']>;
  valu_otp?: Maybe<Scalars['String']>;
};

/** Contains the results of the request to place an order. */
export type PlaceOrderOutput = {
  __typename?: 'PlaceOrderOutput';
  /** The ID of the order. */
  order: Order;
};

export type PlanDetails = {
  __typename?: 'PlanDetails';
  amountPerMonth?: Maybe<Scalars['String']>;
  currency_code?: Maybe<Scalars['String']>;
  fee_display_value?: Maybe<Scalars['String']>;
  fees_amount?: Maybe<Scalars['String']>;
  fees_type?: Maybe<Scalars['String']>;
  maximum_amount?: Maybe<Scalars['String']>;
  minimum_amount?: Maybe<Scalars['String']>;
  number_of_installment?: Maybe<Scalars['String']>;
  plan_code?: Maybe<Scalars['String']>;
  plan_merchant_type?: Maybe<Scalars['String']>;
  plan_type?: Maybe<Scalars['String']>;
  processing_fees_amount?: Maybe<Scalars['String']>;
  processing_fees_type?: Maybe<Scalars['String']>;
  rate_type?: Maybe<Scalars['String']>;
};

export type PredictMatchOutput = {
  __typename?: 'PredictMatchOutput';
  message: Scalars['String'];
  status: Scalars['Boolean'];
};

export type PredictTheMatchInput = {
  match_id: Scalars['String'];
  match_prediction: Scalars['String'];
};

/** Deprecated. Use `ProductPrice` instead. Defines the price of a product as well as any tax-related adjustments. */
export type Price = {
  __typename?: 'Price';
  /**
   * An array that provides information about tax, weee, or weee_tax adjustments.
   * @deprecated Use `ProductPrice` instead.
   */
  adjustments?: Maybe<Array<Maybe<PriceAdjustment>>>;
  /**
   * The price of a product plus a three-letter currency code.
   * @deprecated Use `ProductPrice` instead.
   */
  amount?: Maybe<Money>;
};

/** Deprecated. Taxes will be included or excluded in the price. Defines the amount of money to apply as an adjustment, the type of adjustment to apply, and whether the item is included or excluded from the adjustment. */
export type PriceAdjustment = {
  __typename?: 'PriceAdjustment';
  /** The amount of the price adjustment and its currency code. */
  amount?: Maybe<Money>;
  /**
   * Indicates whether the adjustment involves tax, weee, or weee_tax.
   * @deprecated `PriceAdjustment` is deprecated.
   */
  code?: Maybe<PriceAdjustmentCodesEnum>;
  /**
   * Indicates whether the entity described by the code attribute is included or excluded from the adjustment.
   * @deprecated `PriceAdjustment` is deprecated.
   */
  description?: Maybe<PriceAdjustmentDescriptionEnum>;
};

/** `PriceAdjustment.code` is deprecated. */
export enum PriceAdjustmentCodesEnum {
  Tax = 'TAX',
  Weee = 'WEEE',
  WeeeTax = 'WEEE_TAX'
}

/** `PriceAdjustmentDescriptionEnum` is deprecated. States whether a price adjustment is included or excluded. */
export enum PriceAdjustmentDescriptionEnum {
  Excluded = 'EXCLUDED',
  Included = 'INCLUDED'
}

/** Contains the price range for a product. If the product has a single price, the minimum and maximum price will be the same. */
export type PriceRange = {
  __typename?: 'PriceRange';
  /** The highest possible price for the product. */
  maximum_price?: Maybe<ProductPrice>;
  /** The lowest possible price for the product. */
  minimum_price: ProductPrice;
};

/** Defines the price type. */
export enum PriceTypeEnum {
  Dynamic = 'DYNAMIC',
  Fixed = 'FIXED',
  Percent = 'PERCENT'
}

/** Defines whether a bundle product's price is displayed as the lowest possible value or as a range. */
export enum PriceViewEnum {
  AsLowAs = 'AS_LOW_AS',
  PriceRange = 'PRICE_RANGE'
}

/** Contains a product attribute code and value. */
export type ProductAttribute = {
  __typename?: 'ProductAttribute';
  /** The unique identifier for a product attribute code. */
  code: Scalars['String'];
  /** The display value of the attribute. */
  value: Scalars['String'];
};

/** Defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export type ProductAttributeFilterInput = {
  /** Attribute label: Keyboard Backlit */
  Keyboards_backlit?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Cooling System */
  ac_cooling_system?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Horse Power */
  ac_horsepower?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Inverter Function */
  ac_inverter_function?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Accessory Type */
  accessories_type?: Maybe<FilterEqualTypeInput>;
  ar_available?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Chair Armrest Direction */
  armrest_direction?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Air Treatment Type */
  at_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Audio Type */
  audio_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Bags Material */
  bags_material?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Bags Size */
  bags_size_filter?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Target Group */
  bags_target_group?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Bags Type */
  bags_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Compatible With */
  battery_compatible_with?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Rechargeable Battery */
  battery_rechargeable_battery?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Battery Type */
  battery_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Bike Types */
  bike_types?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Wheel Size */
  bike_wheel_size?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Blender Type */
  blender_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Food Preparation Capacity */
  bm_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Consumption */
  bm_power_consumption?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Food Preparation Speed */
  bm_speeds?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Food Preparation Type */
  bm_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Compatible With */
  cables_compatible_with?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Cable Length */
  cables_length?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Cable Type */
  cables_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Accessory Type */
  cam_acc_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Compatible With */
  camera_acc_compatible_with?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Digital Camera Type */
  camera_digital_camera_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Focus type */
  camera_lens_focus_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Camera Resolution */
  camera_megapixel?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: WiFi */
  camera_wifi?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Car Accessory Type */
  car_acc_type?: Maybe<FilterEqualTypeInput>;
  /** Deprecated: use `category_uid` to filter product by category ID. */
  category_id?: Maybe<FilterEqualTypeInput>;
  /** Filter product by the unique ID for a `CategoryInterface` object. */
  category_uid?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Chair Height Adjustability */
  chair_height_djustability?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Chair Maximum Height */
  chair_maximum_height?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Compatible With */
  chargers_compatible_with?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Charger Output */
  chargers_output?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Charger Type */
  chargers_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Clock Type */
  clocks_clock_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Espresso Machine Bar Pressure */
  coffee_maker_bar_pressure?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Coffee Using Type */
  coffee_maker_beverage_form?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Number Of Cups */
  coffee_maker_cups?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Coffee Machine Design */
  coffee_maker_design?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Grinder Function */
  coffee_maker_grinder?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Consumption */
  coffee_maker_power_consumption?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Coffee Maker Type */
  coffee_maker_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Color */
  color?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Computer & Laptop Accessory Type */
  comp_lap_accessories_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Compatible With */
  connector_compatible_with?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Connector Type */
  connector_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Game Console Type */
  consoles_console_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Hard Disk Capacity */
  consoles_hard_disk_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Consoles Included Games */
  consoles_included_games?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Number Of Controllers */
  consoles_number_of_controllers?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Number Of Burners */
  cooker_flames?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Safety Function */
  cooker_safety?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Cooker Size */
  cooker_size?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Cooker Type */
  cooker_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Consumption */
  cooler_power_consumption?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Timer Function */
  cooler_timer?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Water Tank Capacity */
  cooler_water_tank_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Compatible With */
  cover_compatible_with?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Compatible With Brand */
  cover_compatible_with_brand?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Cover Type */
  cover_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Custom Pages */
  custom_pages_ids?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Fryer Capacity */
  deep_fryers_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Fryer Digital Display */
  deep_fryers_digital_display?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Consumption */
  deep_fryers_power_consumption?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Fryer Type */
  deep_fryers_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Dental Care Type */
  dental_care_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Deodorant Type */
  deodorants_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Number Of Place Settings */
  dishwasher_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Dishwasher Digital Display */
  dishwasher_digital_display?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Inverter Function */
  dishwasher_inverter?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Number Of Programs */
  dishwasher_number_of_programs?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Dryer Inverter */
  dryer_inverter?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Dryer Type */
  dryer_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Electrical Type */
  electrical_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Express Shipping Time */
  express_shipping_time?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Fan Diameter */
  fans_diameter_in_inches?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Ventilating Fan Net Included */
  fans_net_included?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Number Of Blades */
  fans_number_of_blades?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Remote Control */
  fans_remote_control?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Number Of Speeds */
  fans_speed?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Fan Type */
  fans_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Type */
  feeding_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Freezer Capacity */
  freezer_cap_lit_filter?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Cooling Type */
  freezer_cooling_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Freezer Inverter Technology */
  freezer_inverter?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Number Of Drawers */
  freezer_number_of_drawers?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Freezer Type */
  freezer_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Compatible With */
  game_access_compitable?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Gaming Accessory Type */
  game_accessories_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Arcade Game Type */
  game_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Compatible With */
  gaming_controller_compatible_with?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Connectivity */
  gaming_controller_connectivity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Grill Accessory Type */
  grills_acc_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Consumption */
  grills_power_consumption?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Grill Type */
  grills_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Consumption */
  hair_dryer_power?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Hair Dryer Speed */
  hair_dryer_speed?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Consumption */
  hair_style_power_consumption?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Hair care Type */
  hair_style_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Auto Shut Off */
  hair_styler_auto_shut_off?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Hand Tools Type */
  hand_tools_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Hard Disk Capacity */
  hard_disks_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Hard Disk Type */
  hard_disks_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Compatible With */
  hardware_compatible_with?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Hardware Type */
  hardware_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Has Deal */
  has_deal?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Earbuds Battery Life */
  headphones_battery?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Headphone & Earbuds Connectivity */
  headphones_connectivity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Headphone & Earbuds Type */
  headphones_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Fan Function */
  heaters_fan?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Number Of Heat Settings */
  heaters_heat_settings?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Number Of Fins */
  heaters_number_of_fins?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Overheating Protection */
  heaters_overheating_protection?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Consumption */
  heaters_power_consumption?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Source */
  heaters_power_source?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Remote Control Included */
  heaters_remote_control?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Timer Function */
  heaters_timer?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Heater Type */
  heaters_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Number Of Burners */
  hobs_flames?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Hob Size */
  hobs_size?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Hob Type */
  hobs_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Category */
  home_decor_category?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Hood Size */
  hoods_size?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Hood Type */
  hoods_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Promotions */
  hot_offers?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Hot Plate Type */
  hot_plate_fuel_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Number Of Plates */
  hot_plate_number_plate?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Consumption */
  hot_plate_power_consumption?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Installment Offers */
  installment_offers?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Number of Ways */
  intercome_number_way?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Video Call Support */
  intercome_video_support?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Irons Base Plate Material */
  irons_base_plate_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Consumption */
  irons_power_consumption?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Iron Type */
  irons_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Vertical Steam Shot */
  irons_vertical_steam_shot?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Water Tank Capacity */
  irons_water_tank_capacity?: Maybe<FilterEqualTypeInput>;
  is_coming_soon?: Maybe<FilterEqualTypeInput>;
  is_discontinued?: Maybe<FilterEqualTypeInput>;
  /** Filters by featured offers */
  is_featured_offer?: Maybe<FilterEqualTypeInput>;
  is_shown_in_brands_homepage?: Maybe<FilterEqualTypeInput>;
  is_shown_in_categories_homepage?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Juicer Capacity */
  juicers_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Juicer Speeds */
  juicers_number_of_speeds?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Consumption */
  juicers_power_consumption?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Juicer Type */
  juicers_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Consumption */
  kettle_power_consumption?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Kettle Type */
  kettle_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Compatible With */
  keyboards_compatible_with?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Keyboard Connectivity */
  keyboards_connectivity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Keyboard Type */
  keyboards_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Water Resistant */
  keyboards_water_resistant?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Graphics Card Capacity */
  lap_graphics_card_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Graphics Card Brand */
  lap_graphics_card_manufacturer?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Graphic Card Type */
  lap_graphics_card_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Hard Disk Capacity */
  lap_hard_desk_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Hard Disk Type */
  lap_hard_disk_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Laptop Family */
  lap_laptop_family?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Laptop Operating System */
  lap_operating_system?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Processor Brand */
  lap_processor_brand?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Processor Core */
  lap_processor_core?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Laptop Processor Family */
  lap_processor_family?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Laptop Processor Speed */
  lap_processor_speed?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: RAM Capacity */
  lap_ram_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Laptop Screen Size */
  lap_screen_size?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Laptop Usage */
  lap_usage?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Chair Maximum Load */
  maximum_load?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Consumption */
  meat_grinder_power_consumption?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Medical Supplies Type */
  medical_supplies_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Medical Device Type */
  medical_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Memory Card Compatible With */
  memory_card_compatible_with?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Speed Class */
  memory_card_speed_class?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Memory Cards Capacity */
  memory_cards_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Memory Card Type */
  memory_cards_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Microwave Capacity */
  microwaves_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Microwave Design */
  microwaves_design?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Microwave Digital Display */
  microwaves_digital_display?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Consumption */
  microwaves_power_consumption?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Microwave Type */
  microwaves_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Mixer Type */
  mixer_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Battery Capacity */
  mob_battery_filter?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Mobile Model */
  mob_common_model_filter?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Internal Memory */
  mob_memory_internal?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Memory RAM */
  mob_memory_ram?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Network Type */
  mob_network_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Mobile Screen Size */
  mob_screen_size_inch?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Rear Camera */
  mob_tab_rear_camera_filter?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Mouse RGB */
  mouse_rgb?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Mouse Sensitivity */
  mouse_sensitivity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Compatible With */
  mouses_compatible_with?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Mouse Connectivity */
  mouses_connectivity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Mouse Type */
  mouses_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Musical Instrument Type */
  musical_instrument_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Product Name */
  name?: Maybe<FilterMatchTypeInput>;
  /** Attribute label: Network accessories Type */
  network_access_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Network Device Type */
  network_devices_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Office Device Type */
  office_device_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Oven Capacity */
  ovens_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Oven Design */
  ovens_design?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Digital Display */
  ovens_digital_display?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Oven Fan */
  ovens_fan?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Oven Fuel Type */
  ovens_fuel_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Oven With Grill */
  ovens_grill?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Oven Power Consumption */
  ovens_power_consumption?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Oven Size */
  ovens_size?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Oven Type */
  ovens_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Graphics Card Capacity */
  pc_graphics_card_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Graphics Card Brand */
  pc_graphics_card_manufacturer?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Hard Disk Capacity */
  pc_hard_desk_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: PC Operating System */
  pc_operating_system?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: PC Family */
  pc_pc_family?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: PC Processor Brand */
  pc_processor_brand?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: PC Processor Core */
  pc_processor_core?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: PC Processor Family */
  pc_processor_family?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: RAM Capacity */
  pc_ram_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Phone Type */
  phone_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Capacity In mAh */
  power_bank_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Number Of Ports */
  power_bank_ports?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Bank Type */
  power_bank_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Type */
  power_tools_power_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power tool Type */
  power_tools_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Price */
  price?: Maybe<FilterRangeTypeInput>;
  /** Attribute label: Printer Accessories Type */
  printer_accessories_type?: Maybe<FilterEqualTypeInput>;
  product_brand_id?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Color */
  product_color?: Maybe<FilterEqualTypeInput>;
  /** Filters by offer type */
  product_offer_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: HDMI Port */
  projectors_hdmi_port?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Projector Type */
  projectors_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: VGA Port */
  projectors_vga_port?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Quantity */
  quantity_and_stock_status?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Receiver Resolution */
  receivers_hd_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Receiver Type */
  receivers_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Receiver Wi-Fi */
  receivers_wifi?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Refrigerator Capacity */
  ref_cap_lit_filter?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Cooling Type */
  ref_cooling_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Refrigerator Inverter Technology */
  ref_inverter?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Digital Display */
  ref_lcd?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Refrigerator Style */
  ref_refrigerator_style?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Water Dispenser */
  ref_water_dispenser?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Compatible With */
  remote_control_compatible_with?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Category */
  report_main_category?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Product Type */
  report_sub_category?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Rice Cooker Capacity */
  rice_cookers_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Digital Display */
  rice_cookers_digital_display?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Consumption */
  rice_cookers_power_consumption?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Scale Type */
  scale_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Digital Display */
  scales_digital_display?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Maximum Weight Capacity */
  scales_maximum_weight_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Connectivity */
  scanner_connectivity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Touch Screen */
  scanner_touch_screen?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Scanner Type */
  scanner_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Compatible With */
  screen_compatible_with?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Compatible With Brand */
  screen_compatible_with_brand?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Compatible With Model */
  screen_protector_compatible_with_model?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Screen Protector Type */
  screen_type?: Maybe<FilterEqualTypeInput>;
  seller_email?: Maybe<FilterEqualTypeInput>;
  /** Filter by seller IDs. */
  seller_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  seller_product_sku?: Maybe<FilterMatchTypeInput>;
  /** Attribute label: Shavers Attachment Numbers */
  shavers_attachment_numbers?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Shaver Power Source */
  shavers_power_source?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Targeted Group */
  shavers_targeted_group?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Shaver Type */
  shavers_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Type Of Shaving */
  shavers_type_of_shaving?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Type Of Usage */
  shavers_type_of_usage?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Shop By Seller */
  shop_by_seller?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Target Group */
  skin_care_target_group?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Skin Care Type */
  skin_care_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Type Of Usage */
  skin_care_type_of_usage?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: SKU */
  sku?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Non-Stick Coated Plates */
  sm_non_stick?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Consumption */
  sm_power_consumption?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Sandwich Maker Type */
  sm_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Smart Device Type */
  smart_home_device_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Smart Scale Bluetooth */
  smart_scales_bluetooth?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Compatible With */
  smart_watch_accessory_compatible_with?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Smart Watch Compatibility Size */
  smart_watch_accessory_size?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Smart Watch Display Type */
  smart_watch_display_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Band Material */
  smart_watches_band_material?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Smart Watch Battery */
  smart_watches_battery_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Compatible With */
  smart_watches_compatible_with_os?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Screen Size */
  smart_watches_screen_size?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Smart Watch Shape */
  smart_watches_shape?: Maybe<FilterEqualTypeInput>;
  /** Attribute label:  Included SIM Card */
  smart_watches_sim?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Water Resistance */
  smart_watches_waterproof?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Remote Control Included */
  speaker_remote_control?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Bluetooth */
  speakers_bluetooth?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Speaker Connectivity */
  speakers_connectivity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Number of Units */
  speakers_number_of_unit?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Speakers Type */
  speakers_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Water Resistance */
  speakers_waterproof?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Specific Appliance Type */
  specific_appliance_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Sporting Category */
  sporting_goods_category?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Sports Equipment Type */
  sports_equipment?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Stationery Type */
  stationery_type?: Maybe<FilterEqualTypeInput>;
  status?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Capacity */
  steam_mob_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Consumption */
  steam_mob_power_consumption?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Automatic Shut Off */
  steamers_automatic_shut_off?: Maybe<FilterEqualTypeInput>;
  /** Attribute label:  Capacity */
  steamers_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Digital Display */
  steamers_digital_display?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Number Of Layers */
  steamers_layers?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Consumption */
  steamers_power_consumption?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Auto Shut Off */
  straighteners_auto_shut_off?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Type Of Usage */
  straighteners_type_of_usage?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Number Of Browning Levels */
  toasters_browning?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Defrost Function */
  toasters_defrost_function?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Number Of Slices */
  toasters_number_of_slices?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Consumption */
  toasters_power_consumption?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Toys Age Target */
  toys_age_target?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Toys Gender */
  toys_gender?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Toys Power Source */
  toys_power_source?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Remote Control Included */
  toys_remote_control?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Toys Type */
  toys_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Toys vehicles Type */
  toys_vehicles_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: TV Accessory Type */
  tv_acc_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Built-In Receiver */
  tv_built_in_receiver?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Screen Resolution */
  tv_hd_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: TVs Screen Size */
  tv_monitor_display_size_filter?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Screen Technology */
  tv_screen_technology?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Smart Connection */
  tv_smart_connection?: Maybe<FilterEqualTypeInput>;
  /** Product Type */
  type_id?: Maybe<FilterTypeInput>;
  /** Attribute label: Power Capacity */
  ups_power_capacity?: Maybe<FilterEqualTypeInput>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: USB Storage Capacity */
  usb_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Dust Bag Capacity */
  vac_dust_bag_capacity_filter?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Dust Bag Type */
  vac_dust_bag_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Power Consumption */
  vac_power_consumption?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Vacuum Cleaner Type */
  vac_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Fuel Type */
  vehicles_fuel_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Vehicle Type */
  vehicles_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Video Game Platform */
  vg_game_platform?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Wall Mount Adjustable */
  wall_mount_adjustable?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Wall Mount Size Compatibility  */
  wall_mount_size_compatability?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Watches Band Material */
  watches_band_material?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Watches Diameter Size */
  watches_diameter_size?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Watches Display Type */
  watches_display_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Watches Shape */
  watches_shape?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Watches Targeted Group */
  watches_targeted_group?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Watches Type */
  watches_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Watches Water Resistant */
  watches_water_resistant?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Cartridge Included */
  water_filter_cartridges?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Number of Stages */
  water_filter_cartridges_stages?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Type */
  water_filter_or_cartridges?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Capacity In Liter */
  water_heater_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Digital Display */
  water_heater_digital_display?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Water Heater Type */
  water_heater_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Cold Water Tank Capacity */
  wd_cold_water_tank_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Digital Display */
  wd_digital_display?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Hot Water Tank Capacity */
  wd_hot_water_tank_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Loading Type */
  wd_loading_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Number Of Taps */
  wd_number_of_taps?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Refrigerator Included */
  wd_refrigerator_included?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Water Temperature */
  wd_water_temperature_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Loading Capacity In KG */
  wm_capacity?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Washing Machine Inverter */
  wm_inverter?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Washing Machine Loading */
  wm_loading_type?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Steam Function */
  wm_steam_function?: Maybe<FilterEqualTypeInput>;
  /** Attribute label: Washing Machine Type */
  wm_type?: Maybe<FilterEqualTypeInput>;
};

/** Specifies the attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. It's possible to sort products using searchable attributes with enabled 'Use in Filter Options' option */
export type ProductAttributeSortInput = {
  is_featured_offer?: Maybe<SortEnum>;
  /** Attribute label: Product Name */
  name?: Maybe<SortEnum>;
  /** Sort by the position assigned to each product. */
  position?: Maybe<SortEnum>;
  price?: Maybe<SortEnum>;
  /** Sort by the search relevance score (default). */
  relevance?: Maybe<SortEnum>;
};

/** Contains the discount applied to a product price. */
export type ProductDiscount = {
  __typename?: 'ProductDiscount';
  /** The actual value of the discount. */
  amount_off?: Maybe<Scalars['Float']>;
  /** The discount expressed a percentage. */
  percent_off?: Maybe<Scalars['Float']>;
};

/** ProductFilterInput is deprecated, use @ProductAttributeFilterInput instead. ProductFilterInput defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export type ProductFilterInput = {
  /** The category ID the product belongs to. */
  category_id?: Maybe<FilterTypeInput>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<FilterTypeInput>;
  /** The timestamp indicating when the product was created. */
  created_at?: Maybe<FilterTypeInput>;
  /** The name of a custom layout. */
  custom_layout?: Maybe<FilterTypeInput>;
  /** XML code that is applied as a layout update to the product page. */
  custom_layout_update?: Maybe<FilterTypeInput>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<FilterTypeInput>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<FilterTypeInput>;
  /** Indicates whether additional attributes have been created for the product. */
  has_options?: Maybe<FilterTypeInput>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<FilterTypeInput>;
  /** The label assigned to a product image. */
  image_label?: Maybe<FilterTypeInput>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<FilterTypeInput>;
  /** The numeric maximal price of the product. Do not include the currency code. */
  max_price?: Maybe<FilterTypeInput>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<FilterTypeInput>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<FilterTypeInput>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<FilterTypeInput>;
  /** The numeric minimal price of the product. Do not include the currency code. */
  min_price?: Maybe<FilterTypeInput>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<FilterTypeInput>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  news_from_date?: Maybe<FilterTypeInput>;
  /** The end date for new product listings. */
  news_to_date?: Maybe<FilterTypeInput>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<FilterTypeInput>;
  /** The keyword required to perform a logical OR comparison. */
  or?: Maybe<ProductFilterInput>;
  /** The price of an item. */
  price?: Maybe<FilterTypeInput>;
  /** Indicates whether the product has required options. */
  required_options?: Maybe<FilterTypeInput>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<FilterTypeInput>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<FilterTypeInput>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<FilterTypeInput>;
  /** The label assigned to a product's small image. */
  small_image_label?: Maybe<FilterTypeInput>;
  /** The beginning date that a product has a special price. */
  special_from_date?: Maybe<FilterTypeInput>;
  /** The discounted price of the product. Do not include the currency code. */
  special_price?: Maybe<FilterTypeInput>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<FilterTypeInput>;
  /** The file name of a swatch image. */
  swatch_image?: Maybe<FilterTypeInput>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<FilterTypeInput>;
  /** The label assigned to a product's thumbnail image. */
  thumbnail_label?: Maybe<FilterTypeInput>;
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tier_price?: Maybe<FilterTypeInput>;
  /** The timestamp indicating when the product was updated. */
  updated_at?: Maybe<FilterTypeInput>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<FilterTypeInput>;
  url_path?: Maybe<FilterTypeInput>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<FilterTypeInput>;
};

/** Contains product image information, including the image URL and label. */
export type ProductImage = MediaGalleryInterface & {
  __typename?: 'ProductImage';
  /** Indicates whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>;
  /** The label of the product image or video. */
  label?: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>;
  /** The URL of the product image or video. */
  url?: Maybe<Scalars['String']>;
};

/** Product Information used for Pickup Locations search. */
export type ProductInfoInput = {
  /** Product SKU. */
  sku: Scalars['String'];
};

/** The ProductInterface contains attributes that are common to all types of products. Note that descriptions may not be available for custom and EAV attributes. */
export type ProductInterface = {
  /** @deprecated Use the `custom_attributes` field instead. */
  Keyboards_backlit?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_cooling_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_cooling_system?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_coverage_area?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_horsepower?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_inverter_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_plasma_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_turbo_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessories_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  armrest_direction?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  as_featured?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  at_coverage_area?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  at_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  at_type?: Maybe<Scalars['Int']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  /** List of product attributes */
  attributes?: Maybe<Array<Maybe<CustomAttribute>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  audio_type?: Maybe<Scalars['Int']>;
  /**
   * Does the product has COD
   * @deprecated Use the `custom_attributes` field instead.
   */
  available_cod?: Maybe<Scalars['Int']>;
  available_vouchers?: Maybe<Array<Maybe<AvailableVoucher>>>;
  /**
   * The product average reviews.
   * @deprecated Use the `custom_attributes` field instead.
   */
  average_rating?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_size?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_size_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_target_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  barcode?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  battery_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  battery_rechargeable_battery?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  battery_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bike_num_of_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bike_types?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bike_wheel_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  blender_type?: Maybe<Scalars['Int']>;
  /** Product Content Blocks */
  blocks?: Maybe<Array<Maybe<Block>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_type?: Maybe<Scalars['Int']>;
  /** The product brand. */
  brand?: Maybe<Brand>;
  bundle_packs: Array<Maybe<BundlePack>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cables_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cables_length?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cables_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_acc_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_memory_cardslot?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_memory_internal?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_package_components?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_ports?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_shooting_contin_shooting?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_acc_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_battery?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_digital_camera_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_features_primary_camera?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_features_secondary?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_flash?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_focus_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_maximum_aperture?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_maximum_focal_length?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_minimum_aperture?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_minimum_focal_length?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_megapixel?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_optical_zoom?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_screen_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_touch_screen?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_usb?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_wifi?: Maybe<Scalars['Int']>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonical_url?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  car_acc_type?: Maybe<Scalars['Int']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chair_height_djustability?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chair_maximum_height?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chargers_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chargers_output?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chargers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  clocks_built_in_alarm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  clocks_clock_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  clocks_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_grinder_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_bar_pressure?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_beverage_form?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_cups?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_grinder?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  comp_lap_accessories_compitable_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  comp_lap_accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  connector_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  connector_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_console_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_hard_disk_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_hdmi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_included_games?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_number_of_controllers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_fan?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_flames?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_grill?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_holders_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_ignition?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_safety?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_filter_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_number_of_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_remote_control_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_wheels_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  copy_resolution?: Maybe<Scalars['String']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_compatible_with_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_compatible_with_model?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_type?: Maybe<Scalars['Int']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  custom_label_1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  custom_label_2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  custom_pages_ids?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_automatic_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  delivery_time?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  delivery_time_inside_cairo_giz?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dental_care_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_form?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_type?: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_half_load?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_number_of_programs?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_sanitization?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_loading_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_rpm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_steam_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dvr_channel_number?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dvr_input?: Maybe<Scalars['String']>;
  /** Product Short Dynamic Link */
  dynamic_link?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_light_color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_outlet?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_watt?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  express_shipping_time?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_age_group?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_age_range?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_color?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_decor_style?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_digital_zoom?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_display_technology?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_finish?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_gemstone?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_gender?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_health_concern?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_ingredients?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_is_assembly_required?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_material?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_maximum_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_megapixels?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_minimum_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_model?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_number_of_licenses?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_operating_system?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_optical_zoom?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_package_quantity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_pattern?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_depth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_form?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_height?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_length?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_width?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_recommended_use?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_scent?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_screen_size?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_shoe_width?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_size?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_storage_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_style?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_system_requirements?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_thread_count?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_video_game_platform?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_diameter_in_inches?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_net_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_number_of_blades?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_type?: Maybe<Scalars['Int']>;
  featured_bullet_attributes_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_targeted_age_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_cap_lit?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_cap_lit_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_cooling_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_number_of_drawers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  game_access_compitable?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  game_accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  game_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gaming_controller_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gaming_controller_connectivity?: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_acc_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_dryer_power?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_dryer_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_style_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_style_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_styler_auto_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hand_tools_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hardware_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hardware_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  has_deal?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_battery?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_microphone?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_noise_cancellation?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_fan?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_heat_settings?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_number_of_fins?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_overheating_protection?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heavy_item?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  height?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_flames?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_holders_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_ignition?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_power_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_safety?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  home_decor_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_air_flow_rate?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_filters?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_motors?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_offers?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_plate_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_plate_number_plate?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_plate_power_consumption?: Maybe<Scalars['Int']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ideal_installing_frame_dimensions?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ink_color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ink_compitable_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  installment_offers?: Maybe<Scalars['String']>;
  /** The product qualified installment plans. */
  installments?: Maybe<InstallmentOutput>;
  /** @deprecated Use the `custom_attributes` field instead. */
  intercome_number_way?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  intercome_video_support?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_base_plate_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_vertical_steam_shot?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_air_condition?: Maybe<Scalars['Int']>;
  /** Check if product is a best seller */
  is_best_seller?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_component?: Maybe<Scalars['Int']>;
  is_has_rayax?: Maybe<Scalars['Boolean']>;
  is_shown_in_brands_homepage?: Maybe<Scalars['Int']>;
  is_shown_in_categories_homepage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_number_of_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_automatic_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_water_resistant?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kitchen_supplies_materials?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kitchen_supplies_type?: Maybe<Scalars['Int']>;
  /** product labels */
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_battery_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_card_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_card_manufacturer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_card_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_model?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hard_desk_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hard_disk_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hd_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hdmi_port?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_laptop_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_operating_system?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_core?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_type?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_ram_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_ram_technology?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_screen_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_touch_screen?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  length?: Maybe<Scalars['Float']>;
  /** Product Manuals */
  manuals?: Maybe<Array<Maybe<Manual>>>;
  /** Product Manuals Of Both ar_EG And en_US Locals */
  manuals_of_all_locales?: Maybe<Array<Maybe<ManualV2>>>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  marketplace_seller?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maximum_load?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meat_grinder_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meat_grinder_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meat_grinder_reverse_speed?: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  medical_supplies_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  medical_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_card_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_card_speed_class?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_cards_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_cards_type?: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_cavity_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_grill?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mixer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_battery?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_battery_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_camera_video?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_common_model_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_display_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_memory_internal?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_memory_ram?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_network_sim?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_network_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_os?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_os_version?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_processor_chipset?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_processor_cpu?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_screen_size_inch?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_display_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_front_camera?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_rear_camera?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_rear_camera_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_wifi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  model_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  model_number?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mosquito_insect_killer_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouse_rgb?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouse_sensitivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouses_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouses_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouses_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  musical_instrument_type?: Maybe<Scalars['Int']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  network_access_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  network_devices_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  network_number_ports?: Maybe<Scalars['Int']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  new_from_date?: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  new_to_date?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  office_device_type?: Maybe<Scalars['Int']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_cavity_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_fan?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_grill?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_type?: Maybe<Scalars['Int']>;
  /** The product overall rating */
  overallRating: OrverallProductRating;
  /** @deprecated Use the `custom_attributes` field instead. */
  paper_input_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_graphics_card_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_graphics_card_manufacturer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_hard_desk_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_hard_disk_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_operating_system?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_pc_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_core?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_type?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_ram_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_ram_technology?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  perfumes_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  phone_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_ports?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_consumption?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_tools_power_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_tools_type?: Maybe<Scalars['Int']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** The range of prices for the product */
  price_range: PriceRange;
  /** An array of `TierPrice` objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  print_quality?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  print_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  print_speed_black?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_accessories_compitable_with?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_compitable_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_memory?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_output_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_processor?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printers_functions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_brand_id?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_color?: Maybe<Scalars['Int']>;
  product_featured_attributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of `ProductLinks` objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_offer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_aspect_ratio?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_hdmi_port?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_resolution?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_vga_port?: Maybe<Scalars['Int']>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** @deprecated Use the `custom_attributes` field instead. */
  receivers_hd_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  receivers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  receivers_wifi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_cap_lit?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_cap_lit_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_cooling_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_doors?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_freezer_position?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_lcd?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_number_of_shelves?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_refrigerator_style?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_water_dispenser?: Maybe<Scalars['Int']>;
  /** An array of products to be displayed in a Related Products block. */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  remote_control_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  report_main_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  report_sub_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  return_refund?: Maybe<Scalars['Int']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_keep_warm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scale_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scales_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scales_maximum_weight_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scan_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scanner_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scanner_touch_screen?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scanner_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_compatible_with_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_protector_compatible_with_model?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_type?: Maybe<Scalars['Int']>;
  /** The product seller. */
  seller?: Maybe<Customer>;
  /** @deprecated Use the `custom_attributes` field instead. */
  seller_note?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_attachment_numbers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_type_of_shaving?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_type_of_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shop_by_seller?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_target_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_type_of_usage?: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sm_non_stick?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sm_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sm_type?: Maybe<Scalars['Int']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_home_device_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_scales_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_accessory_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_accessory_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_accessory_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_band_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_battery_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_compatible_os?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_compatible_with_os?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_ram?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_screen_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_shape?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_sim?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_target_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_waterproof?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_wifi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speaker_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_battery?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_number_of_unit?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_waterproof?: Maybe<Scalars['Int']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specific_appliance_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specific_appliance_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specific_appliance_type?: Maybe<Scalars['Int']>;
  /** Attribute names that contains the product specifications */
  specification_attributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sporting_goods_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sporting_goods_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sports_equipment?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  stationery_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steam_mob_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steam_mob_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_automatic_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_layers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_power_consumption?: Maybe<Scalars['Int']>;
  /** List of product attributes */
  stockQtyTerm?: Maybe<Array<Maybe<ProductStockQty>>>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  /** The product available store locators. */
  store_locators?: Maybe<Array<Maybe<AmsStoreLocator>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_auto_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_max_temp?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_min_temp?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_plates_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_type_of_usage?: Maybe<Scalars['Int']>;
  /** Product Support Videos */
  support_videos?: Maybe<Array<Maybe<SupportVideo>>>;
  /** The file name of a swatch image. */
  swatch_image?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_browning?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_defrost_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_number_of_slices?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_age_target?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_gender?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_vehicles_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_acc_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_built_in_receiver?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_connectivity_usb?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_curved?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_dimentions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_hd_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_hdmi?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_monitor_display_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_monitor_display_size_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_refresh_rate?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_screen_resolution?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_screen_technology?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_smart_connection?: Maybe<Scalars['Int']>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ups_power_capacity?: Maybe<Scalars['Int']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  usb_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_dust_bag_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_dust_bag_capacity_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_dust_bag_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_year_model?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vg_game_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vg_game_platform?: Maybe<Scalars['Int']>;
  /** The visibility of a product */
  visibility: UpdateSellerProductVisibilityEnum;
  /** @deprecated Use the `custom_attributes` field instead. */
  wall_mount_adjustable?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wall_mount_size_compatability?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  warranty?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_band_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_diameter_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_shape?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_water_resistant?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_filter_cartridges?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_filter_cartridges_stages?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_filter_or_cartridges?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_inner_tank?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_cold_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_hot_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_loading_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_number_of_taps?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_refrigerator_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_water_temperature_type?: Maybe<Scalars['Int']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  width?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_dryer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_dryer_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_loading_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_rpm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_steam_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  yogurt_maker_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  yogurt_maker_power_consumption?: Maybe<Scalars['Int']>;
};


/** The ProductInterface contains attributes that are common to all types of products. Note that descriptions may not be available for custom and EAV attributes. */
export type ProductInterfaceAttributesArgs = {
  groupCode?: Maybe<Scalars['String']>;
};


/** The ProductInterface contains attributes that are common to all types of products. Note that descriptions may not be available for custom and EAV attributes. */
export type ProductInterfaceInstallmentsArgs = {
  corporateId?: Maybe<Scalars['Int']>;
};


/** The ProductInterface contains attributes that are common to all types of products. Note that descriptions may not be available for custom and EAV attributes. */
export type ProductInterfaceReviewsArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

/** An implementation of `ProductLinksInterface`. */
export type ProductLinks = ProductLinksInterface & {
  __typename?: 'ProductLinks';
  /** One of related, associated, upsell, or crosssell. */
  link_type?: Maybe<Scalars['String']>;
  /** The SKU of the linked product. */
  linked_product_sku?: Maybe<Scalars['String']>;
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linked_product_type?: Maybe<Scalars['String']>;
  /** The position within the list of product links. */
  position?: Maybe<Scalars['Int']>;
  /** The identifier of the linked product. */
  sku?: Maybe<Scalars['String']>;
};

/** Contains information about linked products, including the link type and product type of each item. */
export type ProductLinksInterface = {
  /** One of related, associated, upsell, or crosssell. */
  link_type?: Maybe<Scalars['String']>;
  /** The SKU of the linked product. */
  linked_product_sku?: Maybe<Scalars['String']>;
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linked_product_type?: Maybe<Scalars['String']>;
  /** The position within the list of product links. */
  position?: Maybe<Scalars['Int']>;
  /** The identifier of the linked product. */
  sku?: Maybe<Scalars['String']>;
};

/** Contains an image in base64 format and basic information about the image. */
export type ProductMediaGalleryEntriesContent = {
  __typename?: 'ProductMediaGalleryEntriesContent';
  /** The image in base64 format. */
  base64_encoded_data?: Maybe<Scalars['String']>;
  /** The file name of the image. */
  name?: Maybe<Scalars['String']>;
  /** The MIME type of the file, such as image/png. */
  type?: Maybe<Scalars['String']>;
};

/** Contains a link to a video file and basic information about the video. */
export type ProductMediaGalleryEntriesVideoContent = {
  __typename?: 'ProductMediaGalleryEntriesVideoContent';
  /** Must be external-video. */
  media_type?: Maybe<Scalars['String']>;
  /** A description of the video. */
  video_description?: Maybe<Scalars['String']>;
  /** Optional data about the video. */
  video_metadata?: Maybe<Scalars['String']>;
  /** Describes the video source. */
  video_provider?: Maybe<Scalars['String']>;
  /** The title of the video. */
  video_title?: Maybe<Scalars['String']>;
  /** The URL to the video. */
  video_url?: Maybe<Scalars['String']>;
};

export type ProductOfferAggregation = {
  __typename?: 'ProductOfferAggregation';
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Represents a product price. */
export type ProductPrice = {
  __typename?: 'ProductPrice';
  /** The price discount. Represents the difference between the regular and final price. */
  discount?: Maybe<ProductDiscount>;
  /** The final price of the product after applying discounts. */
  final_price: Money;
  /** An array of the multiple Fixed Product Taxes that can be applied to a product price. */
  fixed_product_taxes?: Maybe<Array<Maybe<FixedProductTax>>>;
  /** The regular price of the product. */
  regular_price: Money;
};

/** Deprecated. Use `PriceRange` instead. Contains the regular price of an item, as well as its minimum and maximum prices. Only composite products, which include bundle, configurable, and grouped products, can contain a minimum and maximum price. */
export type ProductPrices = {
  __typename?: 'ProductPrices';
  /**
   * The highest possible final price for all the options defined within a composite product. If you are specifying a price range, this would be the `to` value.
   * @deprecated Use `PriceRange.maximum_price` instead.
   */
  maximalPrice?: Maybe<Price>;
  /**
   * The lowest possible final price for all the options defined within a composite product. If you are specifying a price range, this would be the `from` value.
   * @deprecated Use `PriceRange.minimum_price` instead.
   */
  minimalPrice?: Maybe<Price>;
  /**
   * The base price of a product.
   * @deprecated Use `regular_price` from `PriceRange.minimum_price` or `PriceRange.maximum_price` instead.
   */
  regularPrice?: Maybe<Price>;
};

/** Contains details of a product review. */
export type ProductReview = {
  __typename?: 'ProductReview';
  /** The average of all ratings for this product. */
  average_rating: Scalars['Float'];
  /** The date the review was created. */
  created_at: Scalars['String'];
  /** The customer's nickname. Defaults to the customer name, if logged in. */
  nickname: Scalars['String'];
  /** The reviewed product. */
  product: ProductInterface;
  /** An array of ratings by rating category, such as quality, price, and value. */
  ratings_breakdown: Array<Maybe<ProductReviewRating>>;
  /** The summary (title) of the review. */
  summary: Scalars['String'];
  /** The review text. */
  text: Scalars['String'];
};

/** Contains data about a single aspect of a product review. */
export type ProductReviewRating = {
  __typename?: 'ProductReviewRating';
  /** The label assigned to an aspect of a product that is being rated, such as quality or price. */
  name: Scalars['String'];
  /** The rating value given by customer. By default, possible values range from 1 to 5. */
  value: Scalars['String'];
};

/** Contains the reviewer's rating for a single aspect of a review. */
export type ProductReviewRatingInput = {
  /** An encoded rating ID. */
  id: Scalars['String'];
  /** An encoded rating value ID. */
  value_id: Scalars['String'];
};

/** Contains details about a single aspect of a product review. */
export type ProductReviewRatingMetadata = {
  __typename?: 'ProductReviewRatingMetadata';
  /** An encoded rating ID. */
  id: Scalars['String'];
  /** The label assigned to an aspect of a product that is being rated, such as quality or price. */
  name: Scalars['String'];
  /** List of product review ratings sorted by position. */
  values: Array<Maybe<ProductReviewRatingValueMetadata>>;
};

/** Contains details about a single value in a product review. */
export type ProductReviewRatingValueMetadata = {
  __typename?: 'ProductReviewRatingValueMetadata';
  /** A ratings scale, such as the number of stars awarded. */
  value: Scalars['String'];
  /** An encoded rating value ID. */
  value_id: Scalars['String'];
};

/** Contains an array of metadata about each aspect of a product review. */
export type ProductReviewRatingsMetadata = {
  __typename?: 'ProductReviewRatingsMetadata';
  /** An array of product reviews sorted by position. */
  items: Array<Maybe<ProductReviewRatingMetadata>>;
};

/** Contains an array of product reviews. */
export type ProductReviews = {
  __typename?: 'ProductReviews';
  /** An array of product reviews. */
  items: Array<Maybe<ProductReview>>;
  /** Metadata for pagination rendering. */
  page_info: SearchResultPageInfo;
};

export type ProductReviewsStarAverage = {
  __typename?: 'ProductReviewsStarAverage';
  count: Scalars['Int'];
  stars: Scalars['Int'];
};

export type ProductShareInput = {
  sku: Scalars['String'];
};

/** Deprecated. Use `ProductAttributeSortInput` instead. Specifies the attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. */
export type ProductSortInput = {
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<SortEnum>;
  /** The timestamp indicating when the product was created. */
  created_at?: Maybe<SortEnum>;
  /** The name of a custom layout. */
  custom_layout?: Maybe<SortEnum>;
  /** XML code that is applied as a layout update to the product page. */
  custom_layout_update?: Maybe<SortEnum>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<SortEnum>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<SortEnum>;
  /** Indicates whether additional attributes have been created for the product. */
  has_options?: Maybe<SortEnum>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<SortEnum>;
  /** The label assigned to a product image. */
  image_label?: Maybe<SortEnum>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<SortEnum>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<SortEnum>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<SortEnum>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<SortEnum>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<SortEnum>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  news_from_date?: Maybe<SortEnum>;
  /** The end date for new product listings. */
  news_to_date?: Maybe<SortEnum>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<SortEnum>;
  /** The price of the item. */
  price?: Maybe<SortEnum>;
  /** Indicates whether the product has required options. */
  required_options?: Maybe<SortEnum>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<SortEnum>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<SortEnum>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<SortEnum>;
  /** The label assigned to a product's small image. */
  small_image_label?: Maybe<SortEnum>;
  /** The beginning date that a product has a special price. */
  special_from_date?: Maybe<SortEnum>;
  /** The discounted price of the product. */
  special_price?: Maybe<SortEnum>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<SortEnum>;
  /** Indicates the criteria to sort swatches. */
  swatch_image?: Maybe<SortEnum>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<SortEnum>;
  /** The label assigned to a product's thumbnail image. */
  thumbnail_label?: Maybe<SortEnum>;
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tier_price?: Maybe<SortEnum>;
  /** The timestamp indicating when the product was updated. */
  updated_at?: Maybe<SortEnum>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<SortEnum>;
  url_path?: Maybe<SortEnum>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<SortEnum>;
};

export type ProductStockQty = {
  __typename?: 'ProductStockQty';
  max_sale_qty?: Maybe<Scalars['Float']>;
  min_sale_qty?: Maybe<Scalars['Float']>;
};

/** This enumeration states whether a product stock status is in stock or out of stock */
export enum ProductStockStatus {
  InStock = 'IN_STOCK',
  OutOfStock = 'OUT_OF_STOCK'
}

/** Deprecated. Use `TierPrice` instead. Defines a tier price, which is a quantity discount offered to a specific customer group. */
export type ProductTierPrices = {
  __typename?: 'ProductTierPrices';
  /**
   * The ID of the customer group.
   * @deprecated Not relevant for the storefront.
   */
  customer_group_id?: Maybe<Scalars['String']>;
  /**
   * The percentage discount of the item.
   * @deprecated Use `TierPrice.discount` instead.
   */
  percentage_value?: Maybe<Scalars['Float']>;
  /**
   * The number of items that must be purchased to qualify for tier pricing.
   * @deprecated Use `TierPrice.quantity` instead.
   */
  qty?: Maybe<Scalars['Float']>;
  /**
   * The price of the fixed price item.
   * @deprecated Use `TierPrice.final_price` instead.
   */
  value?: Maybe<Scalars['Float']>;
  /**
   * The ID assigned to the website.
   * @deprecated Not relevant for the storefront.
   */
  website_id?: Maybe<Scalars['Float']>;
};

/** Contains information about a product video. */
export type ProductVideo = MediaGalleryInterface & {
  __typename?: 'ProductVideo';
  /** Indicates whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>;
  /** The label of the product image or video. */
  label?: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>;
  /** The URL of the product image or video. */
  url?: Maybe<Scalars['String']>;
  /** Contains a `ProductMediaGalleryEntriesVideoContent` object. */
  video_content?: Maybe<ProductMediaGalleryEntriesVideoContent>;
};

/** Contains the results of a `products` query. */
export type Products = {
  __typename?: 'Products';
  /** A bucket that contains the attribute code and label for each filterable option. */
  aggregations?: Maybe<Array<Maybe<Aggregation>>>;
  /**
   * Layered navigation filters array.
   * @deprecated Use `aggregations` instead.
   */
  filters?: Maybe<Array<Maybe<LayerFilter>>>;
  /** An array of products that match the specified search criteria. */
  items?: Maybe<Array<Maybe<ProductInterface>>>;
  /** An object that includes the page_info and currentPage values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>;
  /** An object that includes the default sort field and all available sort fields. */
  sort_fields?: Maybe<SortFields>;
  /** An array of search suggestions for case when search query have no results. */
  suggestions?: Maybe<Array<Maybe<SearchSuggestion>>>;
  /** The number of products that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  total_count?: Maybe<Scalars['Int']>;
};


/** Contains the results of a `products` query. */
export type ProductsAggregationsArgs = {
  filter?: Maybe<AggregationsFilterInput>;
};

export type Query = {
  __typename?: 'Query';
  /** Get Active Campaign */
  activeCampaign: Campaign;
  admin: Admin;
  /** Get a list of available store views and their config information. */
  availableStores?: Maybe<Array<Maybe<StoreConfig>>>;
  /** get brand by url key */
  brandByUrlKey: Brand;
  /** Listing all brands */
  brands: Brands;
  caclulateBundleWithSelectedProducts?: Maybe<CaclulateBundleWithSelectedProductsResult>;
  /** Return information about the specified shopping cart. */
  cart?: Maybe<Cart>;
  /** Return a list of categories that match the specified filter. */
  categories?: Maybe<CategoryResult>;
  /** Return a list of categories that match the specified filter. */
  categoriesByBrand?: Maybe<CategoryResult>;
  /**
   * Search for categories that match the criteria specified in the `search` and `filter` attributes.
   * @deprecated Use `categories` instead.
   */
  category?: Maybe<CategoryTree>;
  /**
   * Return an array of categories based on the specified filters.
   * @deprecated Use `categories` instead.
   */
  categoryList?: Maybe<Array<Maybe<CategoryTree>>>;
  checkCustomerCredit: CheckCustomerCreditResponse;
  /** Return Terms and Conditions configuration information. */
  checkoutAgreements?: Maybe<Array<Maybe<CheckoutAgreement>>>;
  /** The city query provides information for a single city. */
  city?: Maybe<City>;
  /** Return information about CMS blocks. */
  cmsBlocks?: Maybe<CmsBlocks>;
  /** Return details about a CMS page. */
  cmsPage?: Maybe<CmsPage>;
  /** Return products that have been added to the specified compare list. */
  compareList?: Maybe<CompareList>;
  contact_form_subjects?: Maybe<Array<Maybe<ContactFormSubject>>>;
  /** Get All Contests */
  contests: Array<Maybe<Contest>>;
  /** Get All Corporates */
  corporates: Array<Maybe<Corporate>>;
  /** The countries query provides information for all countries. */
  countries?: Maybe<Array<Maybe<Country>>>;
  /** The countries query provides information for a single country. */
  country?: Maybe<Country>;
  /** Return information about the store's currency. */
  currency?: Maybe<Currency>;
  /** Return the attribute type, given an attribute code and entity type. */
  customAttributeMetadata?: Maybe<CustomAttributeMetadata>;
  /** Listing all pages */
  custom_pages: Pages;
  /** Return detailed information about a customer account. */
  customer?: Maybe<Customer>;
  /** Return information about the customer's shopping cart. */
  customerCart: Cart;
  /** Return a list of downloadable products the customer has purchased. */
  customerDownloadableProducts?: Maybe<CustomerDownloadableProducts>;
  /** Get latest customer order */
  customerLatestOrder?: Maybe<CustomerOrder>;
  /** Get order and details by id */
  customerOrder?: Maybe<CustomerOrder>;
  /** Get order and details by increment id */
  customerOrderByIncrementId?: Maybe<CustomerOrder>;
  /** @deprecated Use the `customer` query instead. */
  customerOrders?: Maybe<CustomerOrders>;
  /** Get order and details by increment id */
  customerOrdersByIncrementId?: Maybe<Array<Maybe<CustomerOrder>>>;
  /** Get orders and details by payment id */
  customerOrdersByPaymentId?: Maybe<AcceptOrdersOutput>;
  /** Return a list of customer payment tokens stored in the vault. */
  customerPaymentTokens?: Maybe<CustomerPaymentTokens>;
  deals_offers: Array<Maybe<Offer>>;
  deals_offers_rayaelite: Array<Maybe<Offer>>;
  dynamic_values: Array<Maybe<DynamicValue>>;
  /** Frequently Asked Questions Categories */
  faqCategories: Array<Maybe<FaqCategory>>;
  /** Frequently Asked Questions Codes */
  faqCodes: Array<Maybe<FaqCode>>;
  /** Frequently Asked Questions */
  faqs: Array<Maybe<Faq>>;
  /** Get All Available Feature Flag */
  features?: Maybe<Array<Maybe<AvailableFeatureFlag>>>;
  flashsale_offers: Array<Maybe<Offer>>;
  flashsale_offers_rayaelite: Array<Maybe<Offer>>;
  /** Get installment plans with banks for paymob accept */
  getAcceptInstallmentBanks: Array<Maybe<AcceptBanks>>;
  getAllClubs: Array<Maybe<Club>>;
  getAllGovernorates: Array<Maybe<IdArabicEnglishName>>;
  getAllRelations: Array<Maybe<IdArabicEnglishName>>;
  getBuildingTypes: Array<Maybe<IdArabicEnglishName>>;
  getCancelBankNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  getCancelReasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  getCartAcceptIframeUrl?: Maybe<Scalars['String']>;
  getCartMpgsUrl?: Maybe<Scalars['String']>;
  getCitiesOfGovernorate: Array<Maybe<IdArabicEnglishName>>;
  getCityById: IdArabicEnglishName;
  getClubById: Club;
  /** Get Customer Coupons */
  getCustomerCoupons?: Maybe<Array<Maybe<CustomerCoupon>>>;
  getCustomerCredit: GetCustomerCreditResponse;
  getCustomerData: GetCustomerDataResponse;
  getExcludedAttributesFromPage?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Get flip and win active campaigns and there choices */
  getFlipAndWinActiveCampaign: FlipAndWinCampaignOutput;
  getGovernorateById: IdArabicEnglishName;
  /** Retrieve the secure PayPal URL for a Payments Pro Hosted Solution transaction. */
  getHostedProUrl?: Maybe<HostedProUrl>;
  /** Fetch inquiry details */
  getInquiryDetails?: Maybe<InquiryDetails>;
  getInstallmentAcceptIframeUrl?: Maybe<Scalars['String']>;
  getInstallmentsDistricts: Array<Maybe<InstallmentsDistrict>>;
  getInstallmentsMPGSData?: Maybe<GetInstallmentsMpgsDataOutput>;
  /** check if order can return and get quantities can be returned */
  getIsOrderReturnable: IsOrderReturnableOutput;
  getJobTypes: Array<Maybe<IdArabicEnglishName>>;
  getMaritalStatuses: Array<Maybe<IdArabicEnglishName>>;
  getMpgsInstallmentPlans?: Maybe<Array<Maybe<Scalars['Int']>>>;
  getMultipleInstallmentAcceptIframeUrl?: Maybe<Scalars['String']>;
  getMultipleInstallmentsMPGSData: GetInstallmentsMpgsDataOutput;
  /** Get order invoice PDF */
  getOrderInvoice: Scalars['String'];
  /** Retrieve payment credentials for a transaction. Use this query for Payflow Link and Payments Advanced payment methods. */
  getPayflowLinkToken?: Maybe<PayflowLinkToken>;
  getPayfortInstallmentsPlans?: Maybe<PayfortInstallmentsPlans>;
  /** Fetch categories of paymob utilities service */
  getPaymobUtilitiesCategories?: Maybe<Array<Maybe<PaymobUtilitesCategories>>>;
  /** Fetch payment methods of paymob utilities service */
  getPaymobUtilitiesPaymentMethods?: Maybe<Array<Maybe<PaymobUtilitesPaymentMethods>>>;
  /** Fetch providers of paymob utilities service by category ID */
  getPaymobUtilitiesProviders?: Maybe<Array<Maybe<PaymobUtilitesProviders>>>;
  /** Gets account phone number (last 3 digits) by email */
  getPhoneNumberByEmail: GetPhoneNumberByEmailOutput;
  /** get predefined bank names */
  getReturnBankNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** get predefined reasons */
  getReturnReasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  getSdkToken?: Maybe<GetSdkTokenOutput>;
  getServiceDetails?: Maybe<ServiceDetailsOutput>;
  /** Get Slider Title and products List given a specific keyword */
  getSliderProducts: SliderProductsOutput;
  /** get Product ids that you are subscribed to */
  getStockBackSubscriptionProductIds: Array<Maybe<Scalars['Int']>>;
  /** get Product uids that you are subscribed to */
  getStockBackSubscriptionProductUids: Array<Maybe<Scalars['String']>>;
  getTaksetyApplication: TaksetyApplication;
  /** Get the Contest Prize (Coupon Code) */
  getUserContestPrize: TriviaContestPrizeOutput;
  getUtilitiesIframeUrl?: Maybe<UtilitiesIframeUrlOutput>;
  giftregistry_offers: Array<Maybe<Offer>>;
  giftregistry_offers_rayaelite: Array<Maybe<Offer>>;
  guest_installments?: Maybe<CustomerInstallmentOutput>;
  /** Get homepage categories */
  homepageCategories?: Maybe<Array<Maybe<Scalars['String']>>>;
  homepage_offers: Array<Maybe<Offer>>;
  homepage_offers_rayaelite: Array<Maybe<Offer>>;
  /** Check If Contest active or not */
  isActiveContest?: Maybe<Scalars['Boolean']>;
  /** Check whether the specified email has already been used to create a customer account. */
  isEmailAvailable?: Maybe<IsEmailAvailableOutput>;
  isOrderCancelable?: Maybe<Scalars['Boolean']>;
  isOrderRefundableForCancel?: Maybe<Scalars['Boolean']>;
  /** check if the account is a social one. */
  isSocialAccount: Scalars['Boolean'];
  /** Get All Merchants */
  merchants: Array<Maybe<Merchant>>;
  /** get page by url key */
  pageByUrlKey: Page;
  /** The pickup locations query searches for locations that match the search request requirements. */
  pickupLocations?: Maybe<PickupLocations>;
  /** get product by url key */
  product?: Maybe<ProductInterface>;
  /** Listing all products that have special price, Very Important Notes: You MUST retrieve sku for items to not get internal server error - never filter by sku as module overrides the sku filter */
  productDeals?: Maybe<Products>;
  /** Return the active ratings attributes and the values each rating can have. */
  productReviewRatingsMetadata: ProductReviewRatingsMetadata;
  /** Search for products that match the criteria specified in the `search` and `filter` attributes. */
  products?: Maybe<Products>;
  rate_order_criteria: Array<Maybe<RateOrderCriteria>>;
  rayaEliteCreditLimits?: Maybe<EliteCreditLimits>;
  rayaplus_offers: Array<Maybe<Offer>>;
  /** The regions query provides information for a single region. */
  region?: Maybe<Region>;
  requestToVerifyNationalId: Scalars['String'];
  /** Return the full details for a specified product, category, or CMS page. */
  route?: Maybe<RoutableInterface>;
  search?: Maybe<SearchResult>;
  /** Get seller data by email */
  seller_data?: Maybe<Customer>;
  /** Get All Services */
  services: Array<Maybe<Service>>;
  stockSources: StockSources;
  /** Return details about the store's configuration. */
  storeConfig?: Maybe<StoreConfig>;
  /** Listing store locators */
  store_locators?: Maybe<AmsStoreLocatorOutput>;
  /** Get All Contest Questions */
  triviaContestQuestions: Array<Maybe<Question>>;
  /** Get All Contests */
  triviaContests: Array<Maybe<TriviaContest>>;
  /**
   * Return the relative URL for a specified product, category or CMS page.
   * @deprecated Use the `route` query instead.
   */
  urlResolver?: Maybe<EntityUrl>;
  /** All Videos Blocks */
  videosBlocks: VideoBlock;
  /**
   * Return the contents of a customer's wish list.
   * @deprecated Moved under `Customer.wishlist`.
   */
  wishlist?: Maybe<WishlistOutput>;
};


export type QueryAvailableStoresArgs = {
  useCurrentGroup?: Maybe<Scalars['Boolean']>;
};


export type QueryBrandByUrlKeyArgs = {
  url_key: Scalars['String'];
};


export type QueryBrandsArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<BrandAttributeFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<BrandsSortInput>;
};


export type QueryCaclulateBundleWithSelectedProductsArgs = {
  input?: Maybe<CaclulateBundleWithSelectedProductsInput>;
};


export type QueryCartArgs = {
  cart_id: Scalars['String'];
};


export type QueryCategoriesArgs = {
  corporateId?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  filters?: Maybe<CategoryFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryCategoriesByBrandArgs = {
  brand_id?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  filters?: Maybe<CategoryFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryCategoryArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryCategoryListArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  filters?: Maybe<CategoryFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryCheckCustomerCreditArgs = {
  input: CheckCustomerCreditInput;
};


export type QueryCityArgs = {
  filter?: Maybe<AddressFilterInput>;
  id?: Maybe<Scalars['String']>;
};


export type QueryCmsBlocksArgs = {
  identifiers?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryCmsPageArgs = {
  id?: Maybe<Scalars['Int']>;
  identifier?: Maybe<Scalars['String']>;
};


export type QueryCompareListArgs = {
  uid: Scalars['ID'];
};


export type QueryCountryArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryCustomAttributeMetadataArgs = {
  attributes: Array<AttributeInput>;
};


export type QueryCustom_PagesArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<PagesAttributeFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryCustomerOrderArgs = {
  order_id: Scalars['Int'];
};


export type QueryCustomerOrderByIncrementIdArgs = {
  order_number: Scalars['String'];
};


export type QueryCustomerOrdersByIncrementIdArgs = {
  order_number: Scalars['String'];
};


export type QueryCustomerOrdersByPaymentIdArgs = {
  payment_id: Scalars['String'];
};


export type QueryDeals_OffersArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type QueryDeals_Offers_RayaeliteArgs = {
  corporateId?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryDynamic_ValuesArgs = {
  code?: Maybe<Scalars['String']>;
};


export type QueryFaqCategoriesArgs = {
  slug?: Maybe<Scalars['String']>;
};


export type QueryFaqsArgs = {
  categoryId?: Maybe<Scalars['Int']>;
  codeId?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
};


export type QueryFlashsale_OffersArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type QueryFlashsale_Offers_RayaeliteArgs = {
  corporateId?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetCartAcceptIframeUrlArgs = {
  cart_id: Scalars['String'];
};


export type QueryGetCartMpgsUrlArgs = {
  cart_id: Scalars['String'];
  installments_months?: Maybe<Scalars['Int']>;
  return_url: Scalars['String'];
};


export type QueryGetCitiesOfGovernorateArgs = {
  id: Scalars['Int'];
};


export type QueryGetCityByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetClubByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetCustomerCouponsArgs = {
  currentPage?: Scalars['Int'];
  pageSize?: Scalars['Int'];
};


export type QueryGetExcludedAttributesFromPageArgs = {
  page: Scalars['String'];
};


export type QueryGetGovernorateByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetHostedProUrlArgs = {
  input: HostedProUrlInput;
};


export type QueryGetInquiryDetailsArgs = {
  entityId: Scalars['Int'];
};


export type QueryGetInstallmentAcceptIframeUrlArgs = {
  application_id: Scalars['String'];
  installment_ids: Array<Scalars['String']>;
  phone_number: Scalars['String'];
  social_number: Scalars['String'];
};


export type QueryGetInstallmentsMpgsDataArgs = {
  application_id: Scalars['String'];
  installment_ids: Array<Scalars['String']>;
  social_number: Scalars['String'];
};


export type QueryGetIsOrderReturnableArgs = {
  orderNumber: Scalars['String'];
};


export type QueryGetMultipleInstallmentAcceptIframeUrlArgs = {
  input: MultipleInstallmentInput;
};


export type QueryGetMultipleInstallmentsMpgsDataArgs = {
  input: GetInstallmentsMpgsDataInput;
};


export type QueryGetOrderInvoiceArgs = {
  orderNumber: Scalars['String'];
};


export type QueryGetPayflowLinkTokenArgs = {
  input: PayflowLinkTokenInput;
};


export type QueryGetPayfortInstallmentsPlansArgs = {
  cart_id: Scalars['String'];
  sandbox: Scalars['Boolean'];
};


export type QueryGetPaymobUtilitiesProvidersArgs = {
  categoryId: Scalars['Int'];
};


export type QueryGetPhoneNumberByEmailArgs = {
  email: Scalars['String'];
};


export type QueryGetSdkTokenArgs = {
  device_id: Scalars['String'];
  sandbox: Scalars['Boolean'];
};


export type QueryGetServiceDetailsArgs = {
  mobileNumber: Scalars['String'];
  receipt: Scalars['String'];
};


export type QueryGetSliderProductsArgs = {
  keyword: Scalars['String'];
};


export type QueryGetUserContestPrizeArgs = {
  contestId: Scalars['Int'];
};


export type QueryGetUtilitiesIframeUrlArgs = {
  entity_id: Scalars['Int'];
  payment_method: Scalars['String'];
  phone_number?: Maybe<Scalars['String']>;
};


export type QueryGiftregistry_OffersArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGiftregistry_Offers_RayaeliteArgs = {
  corporateId?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGuest_InstallmentsArgs = {
  applicationId?: Maybe<Scalars['String']>;
  currentPage?: Maybe<Scalars['Int']>;
  nationalId: Scalars['String'];
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryHomepage_OffersArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type QueryHomepage_Offers_RayaeliteArgs = {
  corporateId?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryIsActiveContestArgs = {
  contestId: Scalars['Int'];
};


export type QueryIsEmailAvailableArgs = {
  email: Scalars['String'];
};


export type QueryIsOrderCancelableArgs = {
  orderNumber: Scalars['String'];
};


export type QueryIsOrderRefundableForCancelArgs = {
  orderNumber: Scalars['String'];
};


export type QueryIsSocialAccountArgs = {
  username: Scalars['String'];
};


export type QueryMerchantsArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type QueryPageByUrlKeyArgs = {
  url_key: Scalars['String'];
};


export type QueryPickupLocationsArgs = {
  area?: Maybe<AreaInput>;
  currentPage?: Maybe<Scalars['Int']>;
  filters?: Maybe<PickupLocationFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
  productsInfo?: Maybe<Array<Maybe<ProductInfoInput>>>;
  sort?: Maybe<PickupLocationSortInput>;
};


export type QueryProductArgs = {
  sku?: Maybe<Scalars['String']>;
  url_key?: Maybe<Scalars['String']>;
};


export type QueryProductDealsArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<ProductAttributeFilterInput>;
  includeBundles?: Maybe<Scalars['Boolean']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<ProductAttributeSortInput>;
};


export type QueryProductsArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<ProductAttributeFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<ProductAttributeSortInput>;
};


export type QueryRayaplus_OffersArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type QueryRegionArgs = {
  filter?: Maybe<AddressFilterInput>;
  id?: Maybe<Scalars['String']>;
};


export type QueryRouteArgs = {
  url: Scalars['String'];
};


export type QuerySearchArgs = {
  query?: Maybe<Scalars['String']>;
};


export type QuerySeller_DataArgs = {
  email: Scalars['String'];
};


export type QueryStockSourcesArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<StockSourcesFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryStore_LocatorsArgs = {
  city?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  skus?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryTriviaContestQuestionsArgs = {
  contestId: Scalars['Int'];
};


export type QueryUrlResolverArgs = {
  url: Scalars['String'];
};


export type QueryVideosBlocksArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type Question = {
  __typename?: 'Question';
  choices: Array<Maybe<Choice>>;
  id: Scalars['String'];
  is_correct?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type Range = {
  __typename?: 'Range';
  from_percent?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  to_percent?: Maybe<Scalars['Int']>;
};

export type RateOrderCriteria = {
  __typename?: 'RateOrderCriteria';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type RateOrderInput = {
  comment?: Maybe<Scalars['String']>;
  increment_id: Scalars['String'];
  rates: Array<Maybe<OrderRateSingle>>;
  split_order_id: Scalars['String'];
};

export type RayaEliteInformation = {
  __typename?: 'RayaEliteInformation';
  corporate?: Maybe<Corporate>;
  district_id?: Maybe<Scalars['Int']>;
  employee_id?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  governorate_id?: Maybe<Scalars['Int']>;
  hr_letter?: Maybe<Scalars['String']>;
  medical_id?: Maybe<Scalars['String']>;
  national_id_image_back?: Maybe<Scalars['String']>;
  national_id_image_front?: Maybe<Scalars['String']>;
  personal_image?: Maybe<Scalars['String']>;
  salary?: Maybe<Scalars['Int']>;
  status?: Maybe<RayaEliteStatus>;
};

export enum RayaEliteStatus {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  NotRegistered = 'NOT_REGISTERED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum RefundMethod {
  Visa = 'VISA',
  Voucher = 'VOUCHER'
}

export type Region = {
  __typename?: 'Region';
  available_cities: Array<Maybe<City>>;
  /** The two-letter code for the region, such as TX for Texas. */
  code?: Maybe<Scalars['String']>;
  /** The unique ID for a `Region` object. */
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  /** The name of the region, such as Texas. */
  name?: Maybe<Scalars['String']>;
};


export type RegionAvailable_CitiesArgs = {
  filter?: Maybe<AddressFilterInput>;
};

/** Specifies the cart from which to remove a coupon. */
export type RemoveCouponFromCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
};

/** Contains details about the cart after removing a coupon. */
export type RemoveCouponFromCartOutput = {
  __typename?: 'RemoveCouponFromCartOutput';
  /** The cart after removing a coupon. */
  cart?: Maybe<Cart>;
};

/** Specifies which items to remove from the cart. */
export type RemoveItemFromCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** Deprecated. Use `cart_item_uid` instead. */
  cart_item_id?: Maybe<Scalars['Int']>;
  /** Required field. The unique ID for a `CartItemInterface` object. */
  cart_item_uid?: Maybe<Scalars['ID']>;
};

/** Contains details about the cart after removing an item. */
export type RemoveItemFromCartOutput = {
  __typename?: 'RemoveItemFromCartOutput';
  /** The cart after removing an item. */
  cart: Cart;
};

/** Defines which products to remove from a compare list. */
export type RemoveProductsFromCompareListInput = {
  /** An array of product IDs to remove from the compare list. */
  products: Array<Maybe<Scalars['ID']>>;
  /** The unique identifier of the compare list to modify. */
  uid: Scalars['ID'];
};

/** Contains the customer's wish list and any errors encountered. */
export type RemoveProductsFromWishlistOutput = {
  __typename?: 'RemoveProductsFromWishlistOutput';
  /** An array of errors encountered while deleting products from a wish list. */
  user_errors: Array<Maybe<WishListUserInputError>>;
  /** Contains the wish list with after items were successfully deleted. */
  wishlist: Wishlist;
};

/** Contains the cart and any errors after adding products. */
export type ReorderItemsOutput = {
  __typename?: 'ReorderItemsOutput';
  /** Detailed information about the customer's cart. */
  cart: Cart;
  /** An array of reordering errors. */
  userInputErrors: Array<Maybe<CheckoutUserInputError>>;
};

export type RequestedInstallments = {
  application_id: Scalars['String'];
  installment_ids: Array<Maybe<Scalars['String']>>;
};

export type ResetPasswordInput = {
  otp: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  phone_number: Scalars['String'];
};

export type ReturnItemDetails = {
  __typename?: 'ReturnItemDetails';
  item_id?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  refund_amount?: Maybe<Scalars['Float']>;
  requested_qty?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
};

export type ReturnOrderItemInput = {
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  item_id: Scalars['String'];
  qty: Scalars['Float'];
  reason: Scalars['String'];
};

export enum ReturnRefundMethod {
  Visa = 'VISA',
  Voucher = 'VOUCHER'
}

export type ReturnRequestsOutput = {
  __typename?: 'ReturnRequestsOutput';
  created_at: Scalars['String'];
  customer_address_id?: Maybe<Scalars['Int']>;
  preferred_pickup_date?: Maybe<Scalars['String']>;
  refund_method?: Maybe<ReturnRefundMethod>;
  return_items?: Maybe<Array<Maybe<ReturnItemDetails>>>;
  status: Scalars['String'];
};

export type ReturnableOrderItemOutput = {
  __typename?: 'ReturnableOrderItemOutput';
  id: Scalars['String'];
  returnable_qty: Scalars['Float'];
  sku: Scalars['String'];
};

export type RevokeAdminTokenOutput = {
  __typename?: 'RevokeAdminTokenOutput';
  result: Scalars['Boolean'];
};

/** Contains the result of a request to revoke a customer token. */
export type RevokeCustomerTokenOutput = {
  __typename?: 'RevokeCustomerTokenOutput';
  /** The result of a request to revoke a customer token. */
  result: Scalars['Boolean'];
};

/** Routable entities serve as the model for a rendered page. */
export type RoutableInterface = {
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirect_code: Scalars['Int'];
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
};

/** Contains details about a comment. */
export type SalesCommentItem = {
  __typename?: 'SalesCommentItem';
  /** The text of the message. */
  message: Scalars['String'];
  /** The timestamp of the comment. */
  timestamp: Scalars['String'];
};

export type SalesItemInterface = {
  __typename?: 'SalesItemInterface';
  /** The entered gift message for the order item */
  gift_message?: Maybe<GiftMessage>;
};

/** This enumeration defines the scope type for customer orders. */
export enum ScopeTypeEnum {
  Global = 'GLOBAL',
  Store = 'STORE',
  Website = 'WEBSITE'
}

export type SearchResult = {
  __typename?: 'SearchResult';
  aheadworks_blog_post?: Maybe<SearchResultAheadworksBlogPost>;
  amasty_blog_post?: Maybe<SearchResultAmastyBlogPost>;
  amasty_faq_question?: Maybe<SearchResultAmastyFaqQuestion>;
  external_wordpress_post?: Maybe<SearchResultExternalWordpressPost>;
  magefan_blog_post?: Maybe<SearchResultMagefanBlogPost>;
  magento_catalog_attribute?: Maybe<SearchResultMagentoCatalogAttribute>;
  magento_catalog_attribute_2?: Maybe<SearchResultMagentoCatalogAttribute_2>;
  magento_catalog_attribute_3?: Maybe<SearchResultMagentoCatalogAttribute_3>;
  magento_catalog_attribute_4?: Maybe<SearchResultMagentoCatalogAttribute_4>;
  magento_catalog_attribute_5?: Maybe<SearchResultMagentoCatalogAttribute_5>;
  magento_catalog_category?: Maybe<SearchResultMagentoCatalogCategory>;
  magento_catalog_product?: Maybe<SearchResultMagentoCatalogProduct>;
  magento_cms_page?: Maybe<SearchResultMagentoCmsPage>;
  mageplaza_blog_post?: Maybe<SearchResultMageplazaBlogPost>;
  mirasvit_kb_article?: Maybe<SearchResultMirasvitKbArticle>;
  query?: Maybe<Scalars['String']>;
};


export type SearchResultAheadworks_Blog_PostArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type SearchResultAmasty_Blog_PostArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type SearchResultAmasty_Faq_QuestionArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type SearchResultExternal_Wordpress_PostArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type SearchResultMagefan_Blog_PostArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type SearchResultMagento_Catalog_AttributeArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type SearchResultMagento_Catalog_Attribute_2Args = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type SearchResultMagento_Catalog_Attribute_3Args = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type SearchResultMagento_Catalog_Attribute_4Args = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type SearchResultMagento_Catalog_Attribute_5Args = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type SearchResultMagento_Catalog_CategoryArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type SearchResultMagento_Catalog_ProductArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<ProductAttributeFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<ProductAttributeSortInput>;
};


export type SearchResultMagento_Cms_PageArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type SearchResultMageplaza_Blog_PostArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type SearchResultMirasvit_Kb_ArticleArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

export type SearchResultAheadworksBlogPost = {
  __typename?: 'SearchResultAheadworksBlogPost';
  identifier?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<AheadworksBlogPost>>>;
  page_info?: Maybe<SearchResultPageInfo>;
  position?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total_count?: Maybe<Scalars['Int']>;
};

export type SearchResultAmastyBlogPost = {
  __typename?: 'SearchResultAmastyBlogPost';
  identifier?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<AmastyBlogPost>>>;
  page_info?: Maybe<SearchResultPageInfo>;
  position?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total_count?: Maybe<Scalars['Int']>;
};

export type SearchResultAmastyFaqQuestion = {
  __typename?: 'SearchResultAmastyFaqQuestion';
  identifier?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<AmastyFaqQuestion>>>;
  page_info?: Maybe<SearchResultPageInfo>;
  position?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total_count?: Maybe<Scalars['Int']>;
};

export type SearchResultExternalWordpressPost = {
  __typename?: 'SearchResultExternalWordpressPost';
  identifier?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<ExternalWordpressPost>>>;
  page_info?: Maybe<SearchResultPageInfo>;
  position?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total_count?: Maybe<Scalars['Int']>;
};

export type SearchResultMagefanBlogPost = {
  __typename?: 'SearchResultMagefanBlogPost';
  identifier?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<MagefanBlogPost>>>;
  page_info?: Maybe<SearchResultPageInfo>;
  position?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total_count?: Maybe<Scalars['Int']>;
};

export type SearchResultMagentoCatalogAttribute = {
  __typename?: 'SearchResultMagentoCatalogAttribute';
  identifier?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<AttributeOption>>>;
  page_info?: Maybe<SearchResultPageInfo>;
  position?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total_count?: Maybe<Scalars['Int']>;
};

export type SearchResultMagentoCatalogAttribute_2 = {
  __typename?: 'SearchResultMagentoCatalogAttribute_2';
  identifier?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<AttributeOption>>>;
  page_info?: Maybe<SearchResultPageInfo>;
  position?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total_count?: Maybe<Scalars['Int']>;
};

export type SearchResultMagentoCatalogAttribute_3 = {
  __typename?: 'SearchResultMagentoCatalogAttribute_3';
  identifier?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<AttributeOption>>>;
  page_info?: Maybe<SearchResultPageInfo>;
  position?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total_count?: Maybe<Scalars['Int']>;
};

export type SearchResultMagentoCatalogAttribute_4 = {
  __typename?: 'SearchResultMagentoCatalogAttribute_4';
  identifier?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<AttributeOption>>>;
  page_info?: Maybe<SearchResultPageInfo>;
  position?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total_count?: Maybe<Scalars['Int']>;
};

export type SearchResultMagentoCatalogAttribute_5 = {
  __typename?: 'SearchResultMagentoCatalogAttribute_5';
  identifier?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<AttributeOption>>>;
  page_info?: Maybe<SearchResultPageInfo>;
  position?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total_count?: Maybe<Scalars['Int']>;
};

export type SearchResultMagentoCatalogCategory = {
  __typename?: 'SearchResultMagentoCatalogCategory';
  identifier?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<CategoryInterface>>>;
  page_info?: Maybe<SearchResultPageInfo>;
  position?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total_count?: Maybe<Scalars['Int']>;
};

export type SearchResultMagentoCatalogProduct = {
  __typename?: 'SearchResultMagentoCatalogProduct';
  aggregations?: Maybe<Array<Maybe<Aggregation>>>;
  identifier?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<ProductInterface>>>;
  page_info?: Maybe<SearchResultPageInfo>;
  position?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total_count?: Maybe<Scalars['Int']>;
};

export type SearchResultMagentoCmsPage = {
  __typename?: 'SearchResultMagentoCmsPage';
  identifier?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<CmsPage>>>;
  page_info?: Maybe<SearchResultPageInfo>;
  position?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total_count?: Maybe<Scalars['Int']>;
};

export type SearchResultMageplazaBlogPost = {
  __typename?: 'SearchResultMageplazaBlogPost';
  identifier?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<MageplazaBlogPost>>>;
  page_info?: Maybe<SearchResultPageInfo>;
  position?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total_count?: Maybe<Scalars['Int']>;
};

export type SearchResultMirasvitKbArticle = {
  __typename?: 'SearchResultMirasvitKbArticle';
  identifier?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<MirasvitKbArticle>>>;
  page_info?: Maybe<SearchResultPageInfo>;
  position?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  total_count?: Maybe<Scalars['Int']>;
};

/** Provides navigation for the query response. */
export type SearchResultPageInfo = {
  __typename?: 'SearchResultPageInfo';
  /** The specific page to return. */
  current_page?: Maybe<Scalars['Int']>;
  /** The maximum number of items to return per page of results. */
  page_size?: Maybe<Scalars['Int']>;
  /** The total number of pages in the response. */
  total_pages?: Maybe<Scalars['Int']>;
};

/** A string that contains search suggestion */
export type SearchSuggestion = {
  __typename?: 'SearchSuggestion';
  /** The search suggestion of existing product. */
  search: Scalars['String'];
};

export type Segment = {
  __typename?: 'Segment';
  description?: Maybe<Scalars['String']>;
  discount_percentage: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

/** Contains details about a selected bundle option. */
export type SelectedBundleOption = {
  __typename?: 'SelectedBundleOption';
  /** @deprecated Use `uid` instead */
  id: Scalars['Int'];
  /** The display name of the selected bundle product option. */
  label: Scalars['String'];
  /** The type of selected bundle product option. */
  type: Scalars['String'];
  /** The unique ID for a `SelectedBundleOption` object */
  uid: Scalars['ID'];
  /** An array of selected bundle option values. */
  values: Array<Maybe<SelectedBundleOptionValue>>;
};

/** Contains details about a value for a selected bundle option. */
export type SelectedBundleOptionValue = {
  __typename?: 'SelectedBundleOptionValue';
  /** Use `uid` instead */
  id: Scalars['Int'];
  /** The display name of the value for the selected bundle product option. */
  label: Scalars['String'];
  /** The price of the value for the selected bundle product option. */
  price: Scalars['Float'];
  /** The quantity of the value for the selected bundle product option. */
  quantity: Scalars['Float'];
  /** The unique ID for a `SelectedBundleOptionValue` object */
  uid: Scalars['ID'];
};

/** Contains details about a selected configurable option. */
export type SelectedConfigurableOption = {
  __typename?: 'SelectedConfigurableOption';
  /** The unique ID for a `ConfigurableProductOptions` object. */
  configurable_product_option_uid: Scalars['ID'];
  /** The unique ID for a `ConfigurableProductOptionsValues` object. */
  configurable_product_option_value_uid: Scalars['ID'];
  /** @deprecated Use `SelectedConfigurableOption.configurable_product_option_uid` instead. */
  id: Scalars['Int'];
  /** The display text for the option. */
  option_label: Scalars['String'];
  /** @deprecated Use `SelectedConfigurableOption.configurable_product_option_value_uid` instead. */
  value_id: Scalars['Int'];
  /** The display name of the selected configurable option. */
  value_label: Scalars['String'];
};

/** Identifies a customized product that has been placed in a cart. */
export type SelectedCustomizableOption = {
  __typename?: 'SelectedCustomizableOption';
  /** The unique ID for a specific `CustomizableOptionInterface` object, such as a `CustomizableFieldOption`, `CustomizableFileOption`, or `CustomizableAreaOption` object. */
  customizable_option_uid: Scalars['ID'];
  /** @deprecated Use `SelectedCustomizableOption.customizable_option_uid` instead. */
  id: Scalars['Int'];
  /** Indicates whether the customizable option is required. */
  is_required: Scalars['Boolean'];
  /** The display name of the selected customizable option. */
  label: Scalars['String'];
  /** A value indicating the order to display this option. */
  sort_order: Scalars['Int'];
  /** The type of `CustomizableOptionInterface` object. */
  type: Scalars['String'];
  /** An array of selectable values. */
  values: Array<Maybe<SelectedCustomizableOptionValue>>;
};

/** Identifies the value of the selected customized option. */
export type SelectedCustomizableOptionValue = {
  __typename?: 'SelectedCustomizableOptionValue';
  /** The unique ID for a value object that corresponds to the object represented by the `customizable_option_uid` attribute. */
  customizable_option_value_uid: Scalars['ID'];
  /** @deprecated Use `SelectedCustomizableOptionValue.customizable_option_value_uid` instead. */
  id: Scalars['Int'];
  /** The display name of the selected value. */
  label: Scalars['String'];
  /** The price of the selected customizable value. */
  price: CartItemSelectedOptionValuePrice;
  /** The text identifying the selected value. */
  value: Scalars['String'];
};

/** Describes the payment method the shopper selected. */
export type SelectedPaymentMethod = {
  __typename?: 'SelectedPaymentMethod';
  /** The payment method code. */
  code: Scalars['String'];
  mpgsCredentials?: Maybe<MpgsCredentials>;
  /** The purchase order number. */
  purchase_order_number?: Maybe<Scalars['String']>;
  /** The payment method title. */
  title: Scalars['String'];
};


/** Describes the payment method the shopper selected. */
export type SelectedPaymentMethodMpgsCredentialsArgs = {
  installments_months?: Maybe<Scalars['Int']>;
};

/** Contains details about the selected shipping method and carrier. */
export type SelectedShippingMethod = {
  __typename?: 'SelectedShippingMethod';
  /** The cost of shipping using this shipping method. */
  amount: Money;
  /** @deprecated The field should not be used on the storefront. */
  base_amount?: Maybe<Money>;
  /** A string that identifies a commercial carrier or an offline shipping method. */
  carrier_code: Scalars['String'];
  /** The label for the carrier code. */
  carrier_title: Scalars['String'];
  /** A shipping method code associated with a carrier. */
  method_code: Scalars['String'];
  /** The label for the method code. */
  method_title: Scalars['String'];
  /** The cost of shipping using this shipping method, excluding tax. */
  price_excl_tax: Money;
  /** The cost of shipping using this shipping method, including tax. */
  price_incl_tax: Money;
};

export type SellerData = {
  __typename?: 'SellerData';
  number_of_products_as_seller?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['String']>;
};

export type SellerProduct = {
  __typename?: 'SellerProduct';
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  qty: Scalars['Float'];
  sku: Scalars['String'];
  special_price?: Maybe<Scalars['Float']>;
  status: Scalars['Boolean'];
  thumbnail?: Maybe<Scalars['String']>;
  visibility: UpdateSellerProductVisibilityEnum;
};

export type SellerProductOutput = {
  __typename?: 'SellerProductOutput';
  items: Array<Maybe<SellerProduct>>;
  page_info: SearchResultPageInfo;
  total_count: Scalars['Int'];
};

export type SellerTransaction = {
  __typename?: 'SellerTransaction';
  actual_seller_amount?: Maybe<Scalars['String']>;
  adjustment_amount?: Maybe<Scalars['String']>;
  adjustment_amount_vat?: Maybe<Scalars['String']>;
  adjustment_by?: Maybe<Scalars['String']>;
  adjustment_comment?: Maybe<Scalars['String']>;
  adjustment_reason?: Maybe<Scalars['String']>;
  amount_collected_by_seller?: Maybe<Scalars['String']>;
  base_actual_seller_amount?: Maybe<Scalars['String']>;
  delivery_method?: Maybe<Scalars['String']>;
  discount_description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  entity_id?: Maybe<Scalars['Int']>;
  is_paid?: Maybe<Scalars['Boolean']>;
  magepro_name?: Maybe<Scalars['String']>;
  magepro_price?: Maybe<Scalars['String']>;
  magequantity?: Maybe<Scalars['String']>;
  magerealorder_id?: Maybe<Scalars['String']>;
  processing_fee?: Maybe<Scalars['String']>;
  processing_fee_vat?: Maybe<Scalars['String']>;
  row_id?: Maybe<Scalars['Int']>;
  seller_id?: Maybe<Scalars['Int']>;
  shipping_amount?: Maybe<Scalars['String']>;
  shop_title?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  total_amount?: Maybe<Scalars['String']>;
  total_commission?: Maybe<Scalars['String']>;
  total_commission_vat?: Maybe<Scalars['String']>;
  trans_id?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type SellerTransactionFilterInput = {
  actual_seller_amount?: Maybe<FilterStringTypeInput>;
  adjustment_amount?: Maybe<FilterStringTypeInput>;
  adjustment_amount_vat?: Maybe<FilterStringTypeInput>;
  adjustment_by?: Maybe<FilterStringTypeInput>;
  adjustment_comment?: Maybe<FilterStringTypeInput>;
  adjustment_reason?: Maybe<FilterStringTypeInput>;
  amount_collected_by_seller?: Maybe<FilterStringTypeInput>;
  base_actual_seller_amount?: Maybe<FilterStringTypeInput>;
  delivery_method?: Maybe<FilterStringTypeInput>;
  discount_description?: Maybe<FilterStringTypeInput>;
  end_date?: Maybe<FilterTypeInput>;
  entity_id?: Maybe<FilterStringTypeInput>;
  is_paid?: Maybe<FilterStringTypeInput>;
  magepro_name?: Maybe<FilterStringTypeInput>;
  magepro_price?: Maybe<FilterStringTypeInput>;
  magequantity?: Maybe<FilterStringTypeInput>;
  magerealorder_id?: Maybe<FilterStringTypeInput>;
  processing_fee?: Maybe<FilterStringTypeInput>;
  processing_fee_vat?: Maybe<FilterStringTypeInput>;
  row_id?: Maybe<FilterStringTypeInput>;
  seller_id?: Maybe<FilterStringTypeInput>;
  shipping_amount?: Maybe<FilterStringTypeInput>;
  shop_title?: Maybe<FilterStringTypeInput>;
  start_date?: Maybe<FilterTypeInput>;
  total_amount?: Maybe<FilterStringTypeInput>;
  total_commission?: Maybe<FilterStringTypeInput>;
  total_commission_vat?: Maybe<FilterStringTypeInput>;
  trans_id?: Maybe<FilterStringTypeInput>;
  transaction_id?: Maybe<FilterStringTypeInput>;
  value?: Maybe<FilterStringTypeInput>;
};

export type SellerTransactionsOutput = {
  __typename?: 'SellerTransactionsOutput';
  items: Array<Maybe<SellerTransaction>>;
  page_info: SearchResultPageInfo;
  total_count: Scalars['Int'];
};

/** Defines the referenced product and the email sender and recipients. */
export type SendEmailToFriendInput = {
  /** The ID of the product that the sender is referencing. */
  product_id: Scalars['Int'];
  /** An array containing information about each recipient. */
  recipients: Array<Maybe<SendEmailToFriendRecipientInput>>;
  /** Information about the customer and the content of the message. */
  sender: SendEmailToFriendSenderInput;
};

/** Contains information about the sender and recipients. */
export type SendEmailToFriendOutput = {
  __typename?: 'SendEmailToFriendOutput';
  /** An array containing information about each recipient. */
  recipients?: Maybe<Array<Maybe<SendEmailToFriendRecipient>>>;
  /** Information about the customer and the content of the message. */
  sender?: Maybe<SendEmailToFriendSender>;
};

/** An output object that contains information about the recipient. */
export type SendEmailToFriendRecipient = {
  __typename?: 'SendEmailToFriendRecipient';
  /** The email address of the recipient. */
  email: Scalars['String'];
  /** The name of the recipient. */
  name: Scalars['String'];
};

/** Contains details about a recipient. */
export type SendEmailToFriendRecipientInput = {
  /** The email address of the recipient. */
  email: Scalars['String'];
  /** The name of the recipient. */
  name: Scalars['String'];
};

/** An output object that contains information about the sender. */
export type SendEmailToFriendSender = {
  __typename?: 'SendEmailToFriendSender';
  /** The email address of the sender. */
  email: Scalars['String'];
  /** The text of the message to be sent. */
  message: Scalars['String'];
  /** The name of the sender. */
  name: Scalars['String'];
};

/** Contains details about the sender. */
export type SendEmailToFriendSenderInput = {
  /** The email address of the sender. */
  email: Scalars['String'];
  /** The text of the message to be sent. */
  message: Scalars['String'];
  /** The name of the sender. */
  name: Scalars['String'];
};

/** Contains details about the configuration of the Email to a Friend feature. */
export type SendFriendConfiguration = {
  __typename?: 'SendFriendConfiguration';
  /** Indicates whether the Email to a Friend feature is enabled. */
  enabled_for_customers: Scalars['Boolean'];
  /** Indicates whether the Email to a Friend feature is enabled for guests. */
  enabled_for_guests: Scalars['Boolean'];
};

export type SendOtpToGuestUserInput = {
  cart_id: Scalars['String'];
};

export type SendOtpToGuestUserOutput = {
  __typename?: 'SendOtpToGuestUserOutput';
  message: Scalars['String'];
  otp_sent: Scalars['Boolean'];
};

export type Service = {
  __typename?: 'Service';
  button_text?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  image_url?: Maybe<Scalars['String']>;
  mobile_image_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  responsive_image_url?: Maybe<Scalars['String']>;
};

export type ServiceDetailsOutput = {
  __typename?: 'ServiceDetailsOutput';
  brand?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  deliveryDate?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  serviceCode?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  systemMessage?: Maybe<Scalars['String']>;
};

/** Sets the billing address. */
export type SetBillingAddressOnCartInput = {
  /** The billing address. */
  billing_address: BillingAddressInput;
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
};

/** Contains details about the cart after setting the billing address. */
export type SetBillingAddressOnCartOutput = {
  __typename?: 'SetBillingAddressOnCartOutput';
  /** The cart after setting the billing address. */
  cart: Cart;
};

export type SetCartCardOutput = {
  __typename?: 'SetCartCardOutput';
  cart: Cart;
};

export type SetCartContactMethodOutput = {
  __typename?: 'SetCartContactMethodOutput';
  cart: Cart;
};

export type SetContactMethodOnCartInput = {
  cart_id: Scalars['String'];
  contact_method: ContactMethodsEnum;
};

export type SetCustomerDataOnCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
};

/** Contains details about the cart after setting the customer information. */
export type SetCustomerDataOnCartOutput = {
  __typename?: 'SetCustomerDataOnCartOutput';
  /** The cart after setting the customer information. */
  cart: Cart;
};

export type SetCustomerPreferredContactMethodOutput = {
  __typename?: 'SetCustomerPreferredContactMethodOutput';
  customer: Customer;
};

export type SetCustomerRayaEliteCorporateInformationInput = {
  corporate_id: Scalars['Int'];
  employee_id?: Maybe<Scalars['String']>;
  hr_letter?: Maybe<Scalars['String']>;
  salary: Scalars['Int'];
};

export type SetCustomerRayaEliteGovernorateAndDistrictIdsInput = {
  district_id: Scalars['Int'];
  governorate_id: Scalars['Int'];
};

export type SetCustomerRayaEliteMedicalIdInput = {
  medical_id: Scalars['String'];
};

export type SetCustomerRayaEliteNationalIdInformationInput = {
  national_id_image_back: Scalars['String'];
  national_id_image_front: Scalars['String'];
};

export type SetCustomerRayaElitePersonalImageInput = {
  personal_image: Scalars['String'];
};

export type SetCustomerSelectedStockSourceOutput = {
  __typename?: 'SetCustomerSelectedStockSourceOutput';
  customer: Customer;
};

export type SetEliteOrderDownPaymentAndPlanOnCartOutput = {
  __typename?: 'SetEliteOrderDownPaymentAndPlanOnCartOutput';
  cart: Cart;
  phone_number: Scalars['String'];
};

export type SetEliteOrderDownPaymentMethodOnCartOutput = {
  __typename?: 'SetEliteOrderDownPaymentMethodOnCartOutput';
  cart: Cart;
};

export type SetFlipAndWinChoiceOnCartOutput = {
  __typename?: 'SetFlipAndWinChoiceOnCartOutput';
  cart: Cart;
};

/** Defines the guest email and cart. */
export type SetGuestEmailOnCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** The email address of the guest. */
  email: Scalars['String'];
};

/** Contains details about the cart after setting the email of a guest. */
export type SetGuestEmailOnCartOutput = {
  __typename?: 'SetGuestEmailOnCartOutput';
  /** The cart after setting the guest email. */
  cart: Cart;
};

export type SetInstallmentPlanOnCartOutput = {
  __typename?: 'SetInstallmentPlanOnCartOutput';
  cart: Cart;
};

export type SetInstallmentsPaymentOptionsOnCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  pay_downpayment_at_branch: Scalars['Boolean'];
};

/** Contains details about the cart after setting the installments payment options. */
export type SetInstallmentsPaymentOptionsOnCartOutput = {
  __typename?: 'SetInstallmentsPaymentOptionsOnCartOutput';
  /** The cart after setting the installments payment options. */
  cart: Cart;
};

export type SetInstallmentsSignatureOptionsOnCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  installments_store_id?: Maybe<Scalars['String']>;
  sign_at_branch: Scalars['Boolean'];
};

/** Contains details about the cart after setting the installments signature options. */
export type SetInstallmentsSignatureOptionsOnCartOutput = {
  __typename?: 'SetInstallmentsSignatureOptionsOnCartOutput';
  /** The cart after setting the installments signature options. */
  cart: Cart;
};

export type SetNationalIdOnAccountInput = {
  national_id: Scalars['String'];
};

/** Applies a payment method to the quote. */
export type SetPaymentMethodAndPlaceOrderInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** The payment method data to apply to the cart. */
  payment_method: PaymentMethodInput;
};

/** Applies a payment method to the cart. */
export type SetPaymentMethodOnCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** The payment method data to apply to the cart. */
  payment_method: PaymentMethodInput;
};

/** Contains details about the cart after setting the payment method. */
export type SetPaymentMethodOnCartOutput = {
  __typename?: 'SetPaymentMethodOnCartOutput';
  /** The cart after setting the payment method. */
  cart: Cart;
};

/** Specifies an array of addresses to use for shipping. */
export type SetShippingAddressesOnCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** An array of shipping addresses. */
  shipping_addresses: Array<Maybe<ShippingAddressInput>>;
};

/** Contains details about the cart after setting the shipping addresses. */
export type SetShippingAddressesOnCartOutput = {
  __typename?: 'SetShippingAddressesOnCartOutput';
  /** The cart after setting the shipping addresses. */
  cart: Cart;
};

/** Applies one or shipping methods to the cart. */
export type SetShippingMethodsOnCartInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** An array of shipping methods. */
  shipping_methods: Array<Maybe<ShippingMethodInput>>;
};

/** Contains details about the cart after setting the shipping methods. */
export type SetShippingMethodsOnCartOutput = {
  __typename?: 'SetShippingMethodsOnCartOutput';
  /** The cart after setting the shipping methods. */
  cart: Cart;
};

/** Defines whether bundle items must be shipped together. */
export enum ShipBundleItemsEnum {
  Separately = 'SEPARATELY',
  Together = 'TOGETHER'
}

export type ShipmentItem = ShipmentItemInterface & {
  __typename?: 'ShipmentItem';
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID'];
  /** The order item associated with the shipment item. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of shipped items. */
  quantity_shipped: Scalars['Float'];
};

/** Order shipment item details. */
export type ShipmentItemInterface = {
  /** The unique ID for a `ShipmentItemInterface` object. */
  id: Scalars['ID'];
  /** The order item associated with the shipment item. */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product. */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product. */
  product_sale_price: Money;
  /** The SKU of the base product. */
  product_sku: Scalars['String'];
  /** The number of shipped items. */
  quantity_shipped: Scalars['Float'];
};

/** Contains order shipment tracking details. */
export type ShipmentTracking = {
  __typename?: 'ShipmentTracking';
  /** The shipping carrier for the order delivery. */
  carrier: Scalars['String'];
  /** The tracking number of the order shipment. */
  number?: Maybe<Scalars['String']>;
  /** The shipment tracking title. */
  title: Scalars['String'];
};

/** Defines a single shipping address. */
export type ShippingAddressInput = {
  /** Defines a shipping address. */
  address?: Maybe<CartAddressInput>;
  /** An ID from the customer's address book that uniquely identifies the address to be used for shipping. */
  customer_address_id?: Maybe<Scalars['Int']>;
  /** Text provided by the shopper. */
  customer_notes?: Maybe<Scalars['String']>;
  /** The code of Pickup Location which will be used for In-Store Pickup. */
  pickup_location_code?: Maybe<Scalars['String']>;
};

/** Contains shipping addresses and methods. */
export type ShippingCartAddress = CartAddressInterface & {
  __typename?: 'ShippingCartAddress';
  address_name?: Maybe<Scalars['String']>;
  apartment?: Maybe<Scalars['String']>;
  /** An array that lists the shipping methods that can be applied to the cart. */
  available_shipping_methods?: Maybe<Array<Maybe<AvailableShippingMethod>>>;
  building?: Maybe<Scalars['String']>;
  /** @deprecated Use `cart_items_v2` instead. */
  cart_items?: Maybe<Array<Maybe<CartItemQuantity>>>;
  /** An array that lists the items in the cart. */
  cart_items_v2?: Maybe<Array<Maybe<CartItemInterface>>>;
  /** An object containing the city name, city code, and city ID */
  city?: Maybe<City>;
  /** The company specified for the billing or shipping address. */
  company?: Maybe<Scalars['String']>;
  /** An object containing the country name, country code, and country ID */
  country?: Maybe<Country>;
  /** Text provided by the shopper. */
  customer_notes?: Maybe<Scalars['String']>;
  /** The first name of the customer or guest. */
  firstname: Scalars['String'];
  floor?: Maybe<Scalars['Int']>;
  /** @deprecated This information should not be exposed on the frontend. */
  items_weight?: Maybe<Scalars['Float']>;
  /** The last name of the customer or guest. */
  lastname: Scalars['String'];
  pickup_location_code?: Maybe<Scalars['String']>;
  /** The ZIP or postal code of the billing or shipping address. */
  postcode?: Maybe<Scalars['String']>;
  region: Region;
  /** An object that describes the selected shipping method. */
  selected_shipping_method?: Maybe<SelectedShippingMethod>;
  /** An array containing the street for the billing or shipping address. */
  street: Array<Maybe<Scalars['String']>>;
  /** The telephone number for the billing or shipping address. */
  telephone?: Maybe<Scalars['String']>;
  /** The unique id of the customer address. */
  uid: Scalars['String'];
  /** The VAT company number for billing or shipping address. */
  vat_id?: Maybe<Scalars['String']>;
};

/** Defines an individual shipping discount. This discount can be applied to shipping. */
export type ShippingDiscount = {
  __typename?: 'ShippingDiscount';
  /** The amount of the discount. */
  amount: Money;
};

/** Contains details about shipping and handling costs. */
export type ShippingHandling = {
  __typename?: 'ShippingHandling';
  /** The shipping amount, excluding tax. */
  amount_excluding_tax?: Maybe<Money>;
  /** The shipping amount, including tax. */
  amount_including_tax?: Maybe<Money>;
  /** The applied discounts to the shipping. */
  discounts?: Maybe<Array<Maybe<ShippingDiscount>>>;
  /** Details about taxes applied for shipping. */
  taxes?: Maybe<Array<Maybe<TaxItem>>>;
  /** The total amount for shipping. */
  total_amount: Money;
};

/** Defines the shipping carrier and method. */
export type ShippingMethodInput = {
  /** A string that identifies a commercial carrier or an offline delivery method. */
  carrier_code: Scalars['String'];
  /** A string that indicates which service a commercial carrier will use to ship items. For offline delivery methods, this value is similar to the label displayed on the checkout page. */
  method_code: Scalars['String'];
};

/** An implementation for simple product cart items. */
export type SimpleCartItem = CartItemInterface & {
  __typename?: 'SimpleCartItem';
  /** An array containing the customizable options the shopper selected. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors?: Maybe<Array<Maybe<CartItemError>>>;
  /** The entered gift message for the cart item */
  gift_message?: Maybe<GiftMessage>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices?: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
};

/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export type SimpleProduct = CustomizableProductInterface & PhysicalProductInterface & ProductInterface & RoutableInterface & {
  __typename?: 'SimpleProduct';
  /** @deprecated Use the `custom_attributes` field instead. */
  Keyboards_backlit?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_cooling_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_cooling_system?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_coverage_area?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_horsepower?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_inverter_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_plasma_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_turbo_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessories_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  armrest_direction?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  as_featured?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  at_coverage_area?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  at_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  at_type?: Maybe<Scalars['Int']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  /** List of product attributes */
  attributes?: Maybe<Array<Maybe<CustomAttribute>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  audio_type?: Maybe<Scalars['Int']>;
  /**
   * Does the product has COD
   * @deprecated Use the `custom_attributes` field instead.
   */
  available_cod?: Maybe<Scalars['Int']>;
  available_vouchers?: Maybe<Array<Maybe<AvailableVoucher>>>;
  /**
   * The product average reviews.
   * @deprecated Use the `custom_attributes` field instead.
   */
  average_rating?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_size?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_size_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_target_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  barcode?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  battery_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  battery_rechargeable_battery?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  battery_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bike_num_of_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bike_types?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bike_wheel_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  blender_type?: Maybe<Scalars['Int']>;
  /** Product Content Blocks */
  blocks?: Maybe<Array<Maybe<Block>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_type?: Maybe<Scalars['Int']>;
  /** The product brand. */
  brand?: Maybe<Brand>;
  bundle_packs: Array<Maybe<BundlePack>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cables_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cables_length?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cables_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_acc_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_memory_cardslot?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_memory_internal?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_package_components?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_ports?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_shooting_contin_shooting?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_acc_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_battery?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_digital_camera_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_features_primary_camera?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_features_secondary?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_flash?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_focus_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_maximum_aperture?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_maximum_focal_length?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_minimum_aperture?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_minimum_focal_length?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_megapixel?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_optical_zoom?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_screen_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_touch_screen?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_usb?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_wifi?: Maybe<Scalars['Int']>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonical_url?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  car_acc_type?: Maybe<Scalars['Int']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chair_height_djustability?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chair_maximum_height?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chargers_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chargers_output?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chargers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  clocks_built_in_alarm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  clocks_clock_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  clocks_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_grinder_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_bar_pressure?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_beverage_form?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_cups?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_grinder?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  comp_lap_accessories_compitable_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  comp_lap_accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  connector_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  connector_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_console_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_hard_disk_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_hdmi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_included_games?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_number_of_controllers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_fan?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_flames?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_grill?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_holders_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_ignition?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_safety?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_filter_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_number_of_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_remote_control_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_wheels_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  copy_resolution?: Maybe<Scalars['String']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_compatible_with_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_compatible_with_model?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_type?: Maybe<Scalars['Int']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  custom_label_1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  custom_label_2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  custom_pages_ids?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_automatic_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  delivery_time?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  delivery_time_inside_cairo_giz?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dental_care_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_form?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_type?: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_half_load?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_number_of_programs?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_sanitization?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_loading_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_rpm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_steam_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dvr_channel_number?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dvr_input?: Maybe<Scalars['String']>;
  /** Product Short Dynamic Link */
  dynamic_link?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_light_color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_outlet?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_watt?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  express_shipping_time?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_age_group?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_age_range?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_color?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_decor_style?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_digital_zoom?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_display_technology?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_finish?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_gemstone?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_gender?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_health_concern?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_ingredients?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_is_assembly_required?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_material?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_maximum_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_megapixels?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_minimum_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_model?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_number_of_licenses?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_operating_system?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_optical_zoom?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_package_quantity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_pattern?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_depth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_form?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_height?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_length?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_width?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_recommended_use?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_scent?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_screen_size?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_shoe_width?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_size?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_storage_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_style?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_system_requirements?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_thread_count?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_video_game_platform?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_diameter_in_inches?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_net_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_number_of_blades?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_type?: Maybe<Scalars['Int']>;
  featured_bullet_attributes_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_targeted_age_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_cap_lit?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_cap_lit_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_cooling_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_number_of_drawers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  game_access_compitable?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  game_accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  game_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gaming_controller_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gaming_controller_connectivity?: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_acc_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_dryer_power?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_dryer_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_style_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_style_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_styler_auto_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hand_tools_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hardware_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hardware_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  has_deal?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_battery?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_microphone?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_noise_cancellation?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_fan?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_heat_settings?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_number_of_fins?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_overheating_protection?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heavy_item?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  height?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_flames?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_holders_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_ignition?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_power_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_safety?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  home_decor_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_air_flow_rate?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_filters?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_motors?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_offers?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_plate_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_plate_number_plate?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_plate_power_consumption?: Maybe<Scalars['Int']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ideal_installing_frame_dimensions?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ink_color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ink_compitable_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  installment_offers?: Maybe<Scalars['String']>;
  /** The product qualified installment plans. */
  installments?: Maybe<InstallmentOutput>;
  /** @deprecated Use the `custom_attributes` field instead. */
  intercome_number_way?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  intercome_video_support?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_base_plate_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_vertical_steam_shot?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_air_condition?: Maybe<Scalars['Int']>;
  /** Check if product is a best seller */
  is_best_seller?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_component?: Maybe<Scalars['Int']>;
  is_has_rayax?: Maybe<Scalars['Boolean']>;
  is_shown_in_brands_homepage?: Maybe<Scalars['Int']>;
  is_shown_in_categories_homepage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_number_of_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_automatic_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_water_resistant?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kitchen_supplies_materials?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kitchen_supplies_type?: Maybe<Scalars['Int']>;
  /** product labels */
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_battery_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_card_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_card_manufacturer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_card_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_model?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hard_desk_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hard_disk_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hd_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hdmi_port?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_laptop_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_operating_system?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_core?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_type?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_ram_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_ram_technology?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_screen_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_touch_screen?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  length?: Maybe<Scalars['Float']>;
  /** Product Manuals */
  manuals?: Maybe<Array<Maybe<Manual>>>;
  /** Product Manuals Of Both ar_EG And en_US Locals */
  manuals_of_all_locales?: Maybe<Array<Maybe<ManualV2>>>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  marketplace_seller?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maximum_load?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meat_grinder_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meat_grinder_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meat_grinder_reverse_speed?: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  medical_supplies_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  medical_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_card_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_card_speed_class?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_cards_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_cards_type?: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_cavity_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_grill?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mixer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_battery?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_battery_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_camera_video?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_common_model_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_display_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_memory_internal?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_memory_ram?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_network_sim?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_network_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_os?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_os_version?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_processor_chipset?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_processor_cpu?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_screen_size_inch?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_display_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_front_camera?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_rear_camera?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_rear_camera_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_wifi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  model_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  model_number?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mosquito_insect_killer_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouse_rgb?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouse_sensitivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouses_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouses_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouses_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  musical_instrument_type?: Maybe<Scalars['Int']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  network_access_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  network_devices_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  network_number_ports?: Maybe<Scalars['Int']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  new_from_date?: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  new_to_date?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  office_device_type?: Maybe<Scalars['Int']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_cavity_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_fan?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_grill?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_type?: Maybe<Scalars['Int']>;
  /** The product overall rating */
  overallRating: OrverallProductRating;
  /** @deprecated Use the `custom_attributes` field instead. */
  paper_input_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_graphics_card_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_graphics_card_manufacturer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_hard_desk_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_hard_disk_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_operating_system?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_pc_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_core?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_type?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_ram_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_ram_technology?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  perfumes_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  phone_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_ports?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_consumption?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_tools_power_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_tools_type?: Maybe<Scalars['Int']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** The range of prices for the product */
  price_range: PriceRange;
  /** An array of `TierPrice` objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  print_quality?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  print_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  print_speed_black?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_accessories_compitable_with?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_compitable_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_memory?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_output_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_processor?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printers_functions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_brand_id?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_color?: Maybe<Scalars['Int']>;
  product_featured_attributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of `ProductLinks` objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_offer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_aspect_ratio?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_hdmi_port?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_resolution?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_vga_port?: Maybe<Scalars['Int']>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** @deprecated Use the `custom_attributes` field instead. */
  receivers_hd_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  receivers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  receivers_wifi?: Maybe<Scalars['Int']>;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirect_code: Scalars['Int'];
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_cap_lit?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_cap_lit_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_cooling_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_doors?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_freezer_position?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_lcd?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_number_of_shelves?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_refrigerator_style?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_water_dispenser?: Maybe<Scalars['Int']>;
  /** An array of products to be displayed in a Related Products block. */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  remote_control_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  report_main_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  report_sub_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  return_refund?: Maybe<Scalars['Int']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_keep_warm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scale_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scales_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scales_maximum_weight_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scan_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scanner_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scanner_touch_screen?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scanner_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_compatible_with_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_protector_compatible_with_model?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_type?: Maybe<Scalars['Int']>;
  /** The product seller. */
  seller?: Maybe<Customer>;
  /** @deprecated Use the `custom_attributes` field instead. */
  seller_note?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_attachment_numbers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_type_of_shaving?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_type_of_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shop_by_seller?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_target_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_type_of_usage?: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sm_non_stick?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sm_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sm_type?: Maybe<Scalars['Int']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_home_device_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_scales_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_accessory_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_accessory_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_accessory_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_band_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_battery_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_compatible_os?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_compatible_with_os?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_ram?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_screen_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_shape?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_sim?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_target_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_waterproof?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_wifi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speaker_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_battery?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_number_of_unit?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_waterproof?: Maybe<Scalars['Int']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specific_appliance_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specific_appliance_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specific_appliance_type?: Maybe<Scalars['Int']>;
  /** Attribute names that contains the product specifications */
  specification_attributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sporting_goods_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sporting_goods_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sports_equipment?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  stationery_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steam_mob_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steam_mob_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_automatic_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_layers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_power_consumption?: Maybe<Scalars['Int']>;
  /** List of product attributes */
  stockQtyTerm?: Maybe<Array<Maybe<ProductStockQty>>>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  /** The product available store locators. */
  store_locators?: Maybe<Array<Maybe<AmsStoreLocator>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_auto_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_max_temp?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_min_temp?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_plates_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_type_of_usage?: Maybe<Scalars['Int']>;
  /** Product Support Videos */
  support_videos?: Maybe<Array<Maybe<SupportVideo>>>;
  /** The file name of a swatch image. */
  swatch_image?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_browning?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_defrost_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_number_of_slices?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_age_target?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_gender?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_vehicles_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_acc_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_built_in_receiver?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_connectivity_usb?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_curved?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_dimentions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_hd_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_hdmi?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_monitor_display_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_monitor_display_size_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_refresh_rate?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_screen_resolution?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_screen_technology?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_smart_connection?: Maybe<Scalars['Int']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ups_power_capacity?: Maybe<Scalars['Int']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  usb_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_dust_bag_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_dust_bag_capacity_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_dust_bag_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_year_model?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vg_game_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vg_game_platform?: Maybe<Scalars['Int']>;
  /** The visibility of a product */
  visibility: UpdateSellerProductVisibilityEnum;
  /** @deprecated Use the `custom_attributes` field instead. */
  wall_mount_adjustable?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wall_mount_size_compatability?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  warranty?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_band_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_diameter_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_shape?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_water_resistant?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_filter_cartridges?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_filter_cartridges_stages?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_filter_or_cartridges?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_inner_tank?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_cold_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_hot_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_loading_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_number_of_taps?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_refrigerator_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_water_temperature_type?: Maybe<Scalars['Int']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  width?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_dryer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_dryer_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_loading_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_rpm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_steam_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  yogurt_maker_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  yogurt_maker_power_consumption?: Maybe<Scalars['Int']>;
};


/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export type SimpleProductAttributesArgs = {
  groupCode?: Maybe<Scalars['String']>;
};


/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export type SimpleProductInstallmentsArgs = {
  corporateId?: Maybe<Scalars['Int']>;
};


/** Defines a simple product, which is tangible and is usually sold in single units or in fixed quantities. */
export type SimpleProductReviewsArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

/** Defines a single product to add to the cart. */
export type SimpleProductCartItemInput = {
  /** An array that defines customizable options for the product. */
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  /** An object containing the `sku`, `quantity`, and other relevant information about the product. */
  data: CartItemInput;
};

/** Contains a simple product wish list item. */
export type SimpleWishlistItem = WishlistItemInterface & {
  __typename?: 'SimpleWishlistItem';
  /** The date and time the item was added to the wish list. */
  added_at: Scalars['String'];
  /** Custom options selected for the wish list item. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
};

export type SingleInstallmentPlanOutput = {
  __typename?: 'SingleInstallmentPlanOutput';
  admin_fees?: Maybe<Scalars['Float']>;
  /** down payment value ceiled */
  down_payment?: Maybe<Scalars['Int']>;
  down_payment_percentage?: Maybe<Scalars['Float']>;
  interest?: Maybe<Scalars['Float']>;
  months?: Maybe<Scalars['Int']>;
};

export type SliderDetails = {
  __typename?: 'SliderDetails';
  id: Scalars['Int'];
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type SliderProductsOutput = {
  __typename?: 'SliderProductsOutput';
  products: Array<Maybe<ProductInterface>>;
  slider: SliderDetails;
};

export enum SocialProviderEnum {
  Apple = 'APPLE',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE'
}

export type SocialProviderLogInInput = {
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

/** Indicates whether to return results in ascending or descending order. */
export enum SortEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Defines a possible sort field. */
export type SortField = {
  __typename?: 'SortField';
  /** The label of the sort field. */
  label?: Maybe<Scalars['String']>;
  /** The attribute code of the sort field. */
  value?: Maybe<Scalars['String']>;
};

/** Contains a default value for sort fields and all available sort fields. */
export type SortFields = {
  __typename?: 'SortFields';
  /** The default sort field value. */
  default?: Maybe<Scalars['String']>;
  /** An array of possible sort fields. */
  options?: Maybe<Array<Maybe<SortField>>>;
};

export type SplittedPlaceOrderOutput = {
  __typename?: 'SplittedPlaceOrderOutput';
  orders: Array<Maybe<Order>>;
};

export type SportsClub = {
  __typename?: 'SportsClub';
  club_id?: Maybe<Scalars['Int']>;
  sports_club_front_image?: Maybe<Scalars['String']>;
};

export type SportsClubInput = {
  club_id?: Maybe<Scalars['Int']>;
  sports_club_front_image?: Maybe<Scalars['String']>;
};

export type Stage = {
  __typename?: 'Stage';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  matches: Array<Maybe<Match>>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type StockSourceItem = {
  __typename?: 'StockSourceItem';
  cities?: Maybe<Array<Maybe<CitiesInfo>>>;
  city?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Int']>;
  fax?: Maybe<Scalars['String']>;
  frontend_description?: Maybe<Scalars['String']>;
  frontend_name?: Maybe<Scalars['String']>;
  is_pickup_location_active?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  source_code?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  use_default_carrier_config?: Maybe<Scalars['Int']>;
};

export type StockSources = {
  __typename?: 'StockSources';
  items: Array<Maybe<StockSourceItem>>;
  page_info: SearchResultPageInfo;
  total_count: Scalars['Int'];
};

export type StockSourcesFilterInput = {
  city?: Maybe<Scalars['String']>;
  source_code?: Maybe<Scalars['String']>;
};

/** Contains information about a store's configuration. */
export type StoreConfig = {
  __typename?: 'StoreConfig';
  /** Contains scripts that must be included in the HTML before the closing `<body>` tag. */
  absolute_footer?: Maybe<Scalars['String']>;
  /** Indicates whether guest users can write product reviews. Possible values: 1 (Yes) and 0 (No). */
  allow_guests_to_write_product_reviews?: Maybe<Scalars['String']>;
  /** The value of the Allow Gift Messages for Order Items option */
  allow_items?: Maybe<Scalars['String']>;
  /** The value of the Allow Gift Messages on Order Level option */
  allow_order?: Maybe<Scalars['String']>;
  /** Android Version */
  android_version?: Maybe<Scalars['String']>;
  /** Indicates whether to enable autocomplete on login and forgot password forms. */
  autocomplete_on_storefront?: Maybe<Scalars['Boolean']>;
  /** Extended Config Data - Label text for Availabe in AR */
  available_in_ar_text?: Maybe<Scalars['String']>;
  /** The base currency code. */
  base_currency_code?: Maybe<Scalars['String']>;
  /** A fully-qualified URL that is used to create relative links to the `base_url`. */
  base_link_url?: Maybe<Scalars['String']>;
  /** The fully-qualified URL that specifies the location of media files. */
  base_media_url?: Maybe<Scalars['String']>;
  /** The fully-qualified URL that specifies the location of static view files. */
  base_static_url?: Maybe<Scalars['String']>;
  /** The store’s fully-qualified base URL. */
  base_url?: Maybe<Scalars['String']>;
  /** An array containing the best seller products */
  bestsellers?: Maybe<Products>;
  /** Returns Black Friday End Date and Time */
  blackfriday_end_date?: Maybe<Scalars['String']>;
  /** Indicate if the black friday theme is enabled or not */
  blackfriday_theme_enabled?: Maybe<Scalars['Boolean']>;
  /** Braintree cc vault status. */
  braintree_cc_vault_active?: Maybe<Scalars['String']>;
  /** Car Ownership */
  car_ownership?: Maybe<Scalars['Boolean']>;
  /** The default sort order of the search results list. */
  catalog_default_sort_by?: Maybe<Scalars['String']>;
  /** Extended Config Data - Categories seo template description */
  categories_seo_template_description?: Maybe<Scalars['String']>;
  /** Extended Config Data - Categories seo template keywords */
  categories_seo_template_keywords?: Maybe<Scalars['String']>;
  /** Extended Config Data - Categories seo template title */
  categories_seo_template_title?: Maybe<Scalars['String']>;
  /** Corresponds to the 'Display Prices In Product Lists' field in the Admin. It indicates how FPT information is displayed on category pages. */
  category_fixed_product_tax_display_setting?: Maybe<FixedProductTaxDisplaySettings>;
  /** The suffix applied to category pages, such as `.htm` or `.html`. */
  category_url_suffix?: Maybe<Scalars['String']>;
  /** Club Membership */
  club_membership?: Maybe<Scalars['Boolean']>;
  /** The name of the CMS page that identifies the home page for the store. */
  cms_home_page?: Maybe<Scalars['String']>;
  /** A specific CMS page that displays when cookies are not enabled for the browser. */
  cms_no_cookies?: Maybe<Scalars['String']>;
  /** A specific CMS page that displays when a 404 'Page Not Found' error occurs. */
  cms_no_route?: Maybe<Scalars['String']>;
  /**
   * A code assigned to the store to identify it.
   * @deprecated Use `store_code` instead.
   */
  code?: Maybe<Scalars['String']>;
  /** Extended Config Data - Label text for Coming Soon */
  coming_soon_text?: Maybe<Scalars['String']>;
  /** Indicates whether the `parent` or child (`itself`) thumbnail should be used in the cart for configurable products. */
  configurable_thumbnail_source?: Maybe<Scalars['String']>;
  /** The copyright statement that appears at the bottom of each page. */
  copyright?: Maybe<Scalars['String']>;
  /** Deals footer content */
  deals_footer_content?: Maybe<Scalars['String']>;
  /** The description that provides a summary of your site for search engine listings. It should not be more than 160 characters in length. */
  default_description?: Maybe<Scalars['String']>;
  /** The default display currency code. */
  default_display_currency_code?: Maybe<Scalars['String']>;
  /** A series of keywords that describe your store, each separated by a comma. */
  default_keywords?: Maybe<Scalars['String']>;
  /** The title that appears at the title bar of each page when viewed in a browser. */
  default_title?: Maybe<Scalars['String']>;
  /** Controls the display of the demo store notice at the top of the page. Options: 0 (No) or 1 (Yes). */
  demonotice?: Maybe<Scalars['Int']>;
  /** Extended Config Data - Label text for Discontinued Products */
  discontinued_text?: Maybe<Scalars['String']>;
  enable_b2c_view?: Maybe<Scalars['Boolean']>;
  /** The landing page that is associated with the base URL. */
  front?: Maybe<Scalars['String']>;
  /** The default number of products per page in Grid View. */
  grid_per_page?: Maybe<Scalars['Int']>;
  /** A list of numbers that define how many products can be displayed in Grid View. */
  grid_per_page_values?: Maybe<Scalars['String']>;
  /** Guarantor Info */
  guarantor_info?: Maybe<Scalars['Boolean']>;
  /** Scripts that must be included in the HTML before the closing `<head>` tag. */
  head_includes?: Maybe<Scalars['String']>;
  /** The small graphic image (favicon) that appears in the address bar and tab of the browser. */
  head_shortcut_icon?: Maybe<Scalars['String']>;
  /** The path to the logo that appears in the header. */
  header_logo_src?: Maybe<Scalars['String']>;
  header_promoted_label?: Maybe<Scalars['String']>;
  /** Homepage footer content */
  home_footer_content?: Maybe<Scalars['String']>;
  homepage_banner_body?: Maybe<Scalars['String']>;
  homepage_banner_title?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the store.
   * @deprecated Use `store_code` instead.
   */
  id?: Maybe<Scalars['Int']>;
  installments_card_remaining_limit?: Maybe<Scalars['String']>;
  installments_card_total_limit?: Maybe<Scalars['String']>;
  installments_card_used_limit?: Maybe<Scalars['String']>;
  /** IOS Version */
  ios_version?: Maybe<Scalars['String']>;
  /** Indicates whether the store view has been designated as the default within the store group. */
  is_default_store?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the store group has been designated as the default within the website. */
  is_default_store_group?: Maybe<Scalars['Boolean']>;
  /** Extended Config Data - Label text when a product is limited */
  limited_qty_text?: Maybe<Scalars['String']>;
  /** Extended Config Data - Label quantity threshold determines when to show the limited qty label */
  limited_qty_threshold?: Maybe<Scalars['Int']>;
  /** The format of the search results list. */
  list_mode?: Maybe<Scalars['String']>;
  /** The default number of products per page in List View. */
  list_per_page?: Maybe<Scalars['Int']>;
  /** A list of numbers that define how many products can be displayed in List View. */
  list_per_page_values?: Maybe<Scalars['String']>;
  /** The store locale. */
  locale?: Maybe<Scalars['String']>;
  /** The Alt text that is associated with the logo. */
  logo_alt?: Maybe<Scalars['String']>;
  /** The height of the logo image, in pixels. */
  logo_height?: Maybe<Scalars['Int']>;
  /** The width of the logo image, in pixels. */
  logo_width?: Maybe<Scalars['Int']>;
  /** Indicates whether wishlists are enabled (1) or disabled (0). */
  magento_wishlist_general_is_enabled?: Maybe<Scalars['String']>;
  /** Medical Insurance */
  medical_insurance?: Maybe<Scalars['Boolean']>;
  /** The minimum number of characters required for a valid password. */
  minimum_password_length?: Maybe<Scalars['String']>;
  /** The default page that displays when a 404 'Page not Found' error occurs. */
  no_route?: Maybe<Scalars['String']>;
  /** some desc */
  offer_ranges?: Maybe<Array<Maybe<Range>>>;
  offers_label?: Maybe<Scalars['String']>;
  pay_installment_digital_wallet_fees?: Maybe<Scalars['String']>;
  pay_installment_fees?: Maybe<Scalars['String']>;
  pay_with_installments_button?: Maybe<Scalars['String']>;
  /** Payflow Pro vault status. */
  payment_payflowpro_cc_vault_active?: Maybe<Scalars['String']>;
  /** Corresponds to the 'Display Prices On Product View Page' field in the Admin. It indicates how FPT information is displayed on product pages. */
  product_fixed_product_tax_display_setting?: Maybe<FixedProductTaxDisplaySettings>;
  /** Indicates whether product reviews are enabled. Possible values: 1 (Yes) and 0 (No). */
  product_reviews_enabled?: Maybe<Scalars['String']>;
  /** The suffix applied to product pages, such as `.htm` or `.html`. */
  product_url_suffix?: Maybe<Scalars['String']>;
  /** Extended Config Data - Products seo template description */
  products_seo_template_description?: Maybe<Scalars['String']>;
  /** Extended Config Data - Products seo template keywords */
  products_seo_template_keywords?: Maybe<Scalars['String']>;
  /** Extended Config Data - Products seo template title */
  products_seo_template_title?: Maybe<Scalars['String']>;
  profile_section_body?: Maybe<Scalars['String']>;
  profile_section_title?: Maybe<Scalars['String']>;
  promoted_category_id?: Maybe<Scalars['Int']>;
  promoted_category_url_key?: Maybe<Scalars['String']>;
  /** Indicate if the ramadan theme is enabled or not */
  ramadan_theme?: Maybe<Scalars['Boolean']>;
  raya_seller_emails?: Maybe<Scalars['String']>;
  /** The number of different character classes (lowercase, uppercase, digits, special characters) required in a password. */
  required_character_classes_number?: Maybe<Scalars['String']>;
  /** Extended Config Data - Product Deals Footer Content */
  robusta_products_deals_module_config_deals_footer_content?: Maybe<Scalars['String']>;
  /** Extended Config Data - Product Deals Meta Description */
  robusta_products_deals_module_config_deals_meta_description?: Maybe<Scalars['String']>;
  /** Extended Config Data -  Product Deals Meta Title */
  robusta_products_deals_module_config_deals_meta_title?: Maybe<Scalars['String']>;
  /** Extended Config Data - Product Deals Page Title */
  robusta_products_deals_module_config_deals_page_title?: Maybe<Scalars['String']>;
  /**
   * The ID of the root category.
   * @deprecated Use `root_category_uid` instead.
   */
  root_category_id?: Maybe<Scalars['Int']>;
  /** The unique ID for a `CategoryInterface` object. */
  root_category_uid?: Maybe<Scalars['ID']>;
  /** Corresponds to the 'Display Prices In Sales Modules' field in the Admin. It indicates how FPT information is displayed on cart, checkout, and order pages. */
  sales_fixed_product_tax_display_setting?: Maybe<FixedProductTaxDisplaySettings>;
  /** A secure fully-qualified URL that is used to create relative links to the `base_url`. */
  secure_base_link_url?: Maybe<Scalars['String']>;
  /** The secure fully-qualified URL that specifies the location of media files. */
  secure_base_media_url?: Maybe<Scalars['String']>;
  /** The secure fully-qualified URL that specifies the location of static view files. */
  secure_base_static_url?: Maybe<Scalars['String']>;
  /** The store’s fully-qualified secure base URL. */
  secure_base_url?: Maybe<Scalars['String']>;
  /** Email to a Friend configuration. */
  send_friend?: Maybe<SendFriendConfiguration>;
  /** Indicates whether a breadcrumb trail appears on all CMS pages in the catalog. 0 (No) or 1 (Yes). */
  show_cms_breadcrumbs?: Maybe<Scalars['Int']>;
  slider_title_label?: Maybe<Scalars['String']>;
  /** The unique ID of the store view. In the Admin, this is called the Store View Code. When making a GraphQL call, assign this value to the `Store` header to provide the scope. */
  store_code?: Maybe<Scalars['ID']>;
  /** The unique ID assigned to the store group. In the Admin, this is called the Store Name. */
  store_group_code?: Maybe<Scalars['ID']>;
  /** The label assigned to the store group. */
  store_group_name?: Maybe<Scalars['String']>;
  /** The label assigned to the store view. */
  store_name?: Maybe<Scalars['String']>;
  /** The store view sort order. */
  store_sort_order?: Maybe<Scalars['Int']>;
  /** The time zone of the store. */
  timezone?: Maybe<Scalars['String']>;
  /** A prefix that appears before the title to create a two- or three-part title. */
  title_prefix?: Maybe<Scalars['String']>;
  /** The character that separates the category name and subcategory in the browser title bar. */
  title_separator?: Maybe<Scalars['String']>;
  /** A suffix that appears after the title to create a two- or three-part title. */
  title_suffix?: Maybe<Scalars['String']>;
  /** Indicates whether the store code should be used in the URL. */
  use_store_in_url?: Maybe<Scalars['Boolean']>;
  /** The unique ID for the website. */
  website_code?: Maybe<Scalars['ID']>;
  /**
   * The ID number assigned to the website store.
   * @deprecated The field should not be used on the storefront.
   */
  website_id?: Maybe<Scalars['Int']>;
  /** The label assigned to the website. */
  website_name?: Maybe<Scalars['String']>;
  /** The unit of weight. */
  weight_unit?: Maybe<Scalars['String']>;
  /** Text that appears in the header of the page and includes the name of the logged in customer. */
  welcome?: Maybe<Scalars['String']>;
};


/** Contains information about a store's configuration. */
export type StoreConfigBestsellersArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  filter?: Maybe<ProductAttributeFilterInput>;
  pageSize?: Maybe<Scalars['Int']>;
  period?: Maybe<BestSellersPeriods>;
  sort?: Maybe<ProductAttributeSortInput>;
};

/** Indicates where an attribute can be displayed. */
export type StorefrontProperties = {
  __typename?: 'StorefrontProperties';
  /** The relative position of the attribute in the layered navigation block. */
  position?: Maybe<Scalars['Int']>;
  /** Indicates whether the attribute is filterable with results, without results, or not at all. */
  use_in_layered_navigation?: Maybe<UseInLayeredNavigationOptions>;
  /** Indicates whether the attribute is displayed in product listings. */
  use_in_product_listing?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the attribute can be used in layered navigation on search results pages. */
  use_in_search_results_layered_navigation?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the attribute is displayed on product pages. */
  visible_on_catalog_pages?: Maybe<Scalars['Boolean']>;
};

export type SubmitAnswerInput = {
  choice_id: Scalars['String'];
};

export type SubmitAnswerOutput = {
  __typename?: 'SubmitAnswerOutput';
  correct_choice_id?: Maybe<Scalars['String']>;
  is_correct: Scalars['Boolean'];
};

export type SubmitCustomerDataInput = {
  data: UpdateCustomerDataInput;
};

/** Contains the result of the `subscribeEmailToNewsletter` operation. */
export type SubscribeEmailToNewsletterOutput = {
  __typename?: 'SubscribeEmailToNewsletterOutput';
  /** The status of the subscription request. */
  status?: Maybe<SubscriptionStatusesEnum>;
};

/** Indicates the status of the request. */
export enum SubscriptionStatusesEnum {
  NotActive = 'NOT_ACTIVE',
  Subscribed = 'SUBSCRIBED',
  Unconfirmed = 'UNCONFIRMED',
  Unsubscribed = 'UNSUBSCRIBED'
}

export type SupportVideo = {
  __typename?: 'SupportVideo';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_active: Scalars['Boolean'];
  sort: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  video_url?: Maybe<Scalars['String']>;
};

/** Describes the swatch type and a value. */
export type SwatchData = {
  __typename?: 'SwatchData';
  /** The type of swatch filter item: 1 - text; 2 - image. */
  type?: Maybe<Scalars['String']>;
  /** The value for the swatch item. It could be text or an image link. */
  value?: Maybe<Scalars['String']>;
};

export type SwatchDataInterface = {
  /** The value can be represented as color (HEX code), image link, or text. */
  value?: Maybe<Scalars['String']>;
};

export type SwatchLayerFilterItem = LayerFilterItemInterface & SwatchLayerFilterItemInterface & {
  __typename?: 'SwatchLayerFilterItem';
  /**
   * The count of items per filter.
   * @deprecated Use `AggregationOption.count` instead.
   */
  items_count?: Maybe<Scalars['Int']>;
  /**
   * The label for a filter.
   * @deprecated Use `AggregationOption.label` instead.
   */
  label?: Maybe<Scalars['String']>;
  /** Data required to render a swatch filter item. */
  swatch_data?: Maybe<SwatchData>;
  /**
   * The value of a filter request variable to be used in query.
   * @deprecated Use `AggregationOption.value` instead.
   */
  value_string?: Maybe<Scalars['String']>;
};

export type SwatchLayerFilterItemInterface = {
  /** Data required to render a swatch filter item. */
  swatch_data?: Maybe<SwatchData>;
};

export type TaksetyApplication = {
  __typename?: 'TaksetyApplication';
  addressInfo?: Maybe<AddressInfo>;
  carOwnershipInformation?: Maybe<CarOwnershipInformation>;
  guarantorInformation?: Maybe<GuarantorInformation>;
  medicalInsurance?: Maybe<MedicalInsurance>;
  occupationDetails?: Maybe<OccupationDetails>;
  personalInfo?: Maybe<PersonalInfo>;
  sportsClub?: Maybe<SportsClub>;
};

/** Contains tax item details. */
export type TaxItem = {
  __typename?: 'TaxItem';
  /** The amount of tax applied to the item. */
  amount: Money;
  /** The rate used to calculate the tax. */
  rate: Scalars['Float'];
  /** A title that describes the tax. */
  title: Scalars['String'];
};

export type Tenure = {
  __typename?: 'Tenure';
  EMI: Scalars['String'];
  InterestRate: Scalars['String'];
  TENURE: Scalars['String'];
};

export type TextSwatchData = SwatchDataInterface & {
  __typename?: 'TextSwatchData';
  /** The value can be represented as color (HEX code), image link, or text. */
  value?: Maybe<Scalars['String']>;
};

/** Defines a price based on the quantity purchased. */
export type TierPrice = {
  __typename?: 'TierPrice';
  /** The price discount that this tier represents. */
  discount?: Maybe<ProductDiscount>;
  /** The price of the product at this tier. */
  final_price?: Maybe<Money>;
  /** The minimum number of items that must be purchased to qualify for this price tier. */
  quantity?: Maybe<Scalars['Float']>;
};

export type TokenizationRequestParamsOutput = {
  __typename?: 'TokenizationRequestParamsOutput';
  access_code?: Maybe<Scalars['String']>;
  merchant_identifier?: Maybe<Scalars['String']>;
  merchant_reference?: Maybe<Scalars['String']>;
  return_url?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  equivalent?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['String']>;
  status?: Maybe<TransactionStatus>;
};

export enum TransactionStatus {
  /** Added/Earned */
  AddedEarned = 'ADDED_EARNED',
  /** Expired */
  Expired = 'EXPIRED',
  /** Refund */
  Refund = 'REFUND',
  /** Unknown */
  Unknown = 'UNKNOWN'
}

export type TriviaContest = {
  __typename?: 'TriviaContest';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  questions: Array<Maybe<Question>>;
  title?: Maybe<Scalars['String']>;
  user_completed_contest: Scalars['Boolean'];
  user_won: Scalars['Boolean'];
};

export type TriviaContestPrizeOutput = {
  __typename?: 'TriviaContestPrizeOutput';
  coupon_code?: Maybe<Scalars['String']>;
  coupon_description?: Maybe<Scalars['String']>;
  discount_percentage?: Maybe<Scalars['String']>;
};

export type UpdateCartItemCustomizableOptionsInput = {
  cart_item_id?: Maybe<Scalars['Int']>;
  /** The unique ID for a `CartItemInterface` object */
  cart_item_uid?: Maybe<Scalars['ID']>;
  customizable_options: Array<Maybe<CustomizableOptionInput>>;
};

export type UpdateCartItemCustomizableOptionsOutput = {
  __typename?: 'UpdateCartItemCustomizableOptionsOutput';
  cart: Cart;
};

/** Modifies the specified items in the cart. */
export type UpdateCartItemsInput = {
  /** The unique ID of a `Cart` object. */
  cart_id: Scalars['String'];
  /** An array of items to be updated. */
  cart_items: Array<Maybe<CartItemUpdateInput>>;
};

/** Contains details about the cart after updating items. */
export type UpdateCartItemsOutput = {
  __typename?: 'UpdateCartItemsOutput';
  /** The cart after updating products. */
  cart: Cart;
};

export type UpdateCustomerDataInput = {
  building_number?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  current_address?: Maybe<Scalars['String']>;
  district_name?: Maybe<Scalars['String']>;
  job?: Maybe<Scalars['String']>;
  job_address?: Maybe<Scalars['String']>;
  job_date?: Maybe<Scalars['String']>;
  martial_status?: Maybe<Scalars['String']>;
  national_id_address?: Maybe<Scalars['String']>;
  national_id_date?: Maybe<Scalars['String']>;
  national_id_governorate?: Maybe<Scalars['String']>;
  street_name?: Maybe<Scalars['String']>;
  total_salary?: Maybe<Scalars['String']>;
};

/** Contains the customer's wish list and any errors encountered. */
export type UpdateProductsInWishlistOutput = {
  __typename?: 'UpdateProductsInWishlistOutput';
  /** An array of errors encountered while updating products in a wish list. */
  user_errors: Array<Maybe<WishListUserInputError>>;
  /** Contains the wish list with all items that were successfully updated. */
  wishlist: Wishlist;
};

export type UpdateSellerOrderStatusInput = {
  order_number: Scalars['String'];
  status: Scalars['String'];
};

export type UpdateSellerProductInput = {
  price?: Maybe<Scalars['Float']>;
  qty?: Maybe<Scalars['Float']>;
  sku: Scalars['String'];
  special_price?: Maybe<Scalars['Float']>;
  status: Scalars['Boolean'];
  visibility?: Maybe<UpdateSellerProductVisibilityEnum>;
};

export enum UpdateSellerProductVisibilityEnum {
  Catalog = 'CATALOG',
  CatalogSearch = 'CATALOG_SEARCH',
  NotVisibleIndividually = 'NOT_VISIBLE_INDIVIDUALLY',
  Search = 'SEARCH'
}

export type UpdateSellerProductsInput = {
  by_sheet?: Maybe<Scalars['Boolean']>;
  items: Array<Maybe<UpdateSellerProductInput>>;
};

export type UpdateSellerProductsOutput = {
  __typename?: 'UpdateSellerProductsOutput';
  products: Array<Maybe<SellerProduct>>;
  status: Scalars['Boolean'];
};

/** Contains URL rewrite details. */
export type UrlRewrite = {
  __typename?: 'UrlRewrite';
  /** An array of request parameters. */
  parameters?: Maybe<Array<Maybe<HttpQueryParameter>>>;
  /** The request URL. */
  url?: Maybe<Scalars['String']>;
};

/** This enumeration defines the entity type. */
export enum UrlRewriteEntityTypeEnum {
  Category = 'CATEGORY',
  CmsPage = 'CMS_PAGE',
  Product = 'PRODUCT'
}

/** Defines whether the attribute is filterable in layered navigation. */
export enum UseInLayeredNavigationOptions {
  FilterableNoResult = 'FILTERABLE_NO_RESULT',
  FilterableWithResults = 'FILTERABLE_WITH_RESULTS',
  No = 'NO'
}

export type UtilitiesIframeUrlOutput = {
  __typename?: 'UtilitiesIframeUrlOutput';
  data?: Maybe<InquiryDetails>;
  iframe_url?: Maybe<Scalars['String']>;
};


export type UtilitiesIframeUrlOutputIframe_UrlArgs = {
  phone_number?: Maybe<Scalars['String']>;
};

export type ValuPlan = {
  __typename?: 'ValuPlan';
  adminFees: Scalars['Float'];
  downPayment: Scalars['Float'];
  emi: Scalars['Float'];
  tenorMonth: Scalars['Int'];
};

/** Contains required input for payment methods with Vault support. */
export type VaultTokenInput = {
  /** The public hash of the payment token. */
  public_hash: Scalars['String'];
};

export type VerifyGuestUserCartOtpInput = {
  cart_id: Scalars['String'];
  otp: Scalars['String'];
};

export type VerifyGuestUserCartOtpOutput = {
  __typename?: 'VerifyGuestUserCartOtpOutput';
  success: Scalars['Boolean'];
};

export type VerifyOtpInput = {
  otp: Scalars['String'];
  phone_number: Scalars['String'];
};

export type VerifyValuOtpResponse = {
  __typename?: 'VerifyValuOtpResponse';
  response_message: Scalars['String'];
  success: Scalars['Boolean'];
  tenure: Array<Maybe<Tenure>>;
};

export type VideoBlock = {
  __typename?: 'VideoBlock';
  items: Array<Maybe<Block>>;
  total_count: Scalars['Int'];
};

export type ViewProductPageInput = {
  product_id?: Maybe<Scalars['String']>;
};

/** An implementation for virtual product cart items. */
export type VirtualCartItem = CartItemInterface & {
  __typename?: 'VirtualCartItem';
  /** An array containing customizable options the shopper selected. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** An array of errors encountered while loading the cart item */
  errors?: Maybe<Array<Maybe<CartItemError>>>;
  /** @deprecated Use `uid` instead. */
  id: Scalars['String'];
  /** Contains details about the price of the item, including taxes and discounts. */
  prices?: Maybe<CartItemPrices>;
  /** Details about an item in the cart. */
  product: ProductInterface;
  /** The quantity of this item in the cart. */
  quantity: Scalars['Float'];
  /** The unique ID for a `CartItemInterface` object. */
  uid: Scalars['ID'];
};

/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export type VirtualProduct = CustomizableProductInterface & ProductInterface & RoutableInterface & {
  __typename?: 'VirtualProduct';
  /** @deprecated Use the `custom_attributes` field instead. */
  Keyboards_backlit?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_cooling_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_cooling_system?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_coverage_area?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_horsepower?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_inverter_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_plasma_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_turbo_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ac_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessories_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  armrest_direction?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  as_featured?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  at_coverage_area?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  at_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  at_type?: Maybe<Scalars['Int']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  /** List of product attributes */
  attributes?: Maybe<Array<Maybe<CustomAttribute>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  audio_type?: Maybe<Scalars['Int']>;
  /**
   * Does the product has COD
   * @deprecated Use the `custom_attributes` field instead.
   */
  available_cod?: Maybe<Scalars['Int']>;
  available_vouchers?: Maybe<Array<Maybe<AvailableVoucher>>>;
  /**
   * The product average reviews.
   * @deprecated Use the `custom_attributes` field instead.
   */
  average_rating?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_size?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_size_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_target_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bags_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  barcode?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  battery_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  battery_rechargeable_battery?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  battery_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bike_num_of_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bike_types?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bike_wheel_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  blender_type?: Maybe<Scalars['Int']>;
  /** Product Content Blocks */
  blocks?: Maybe<Array<Maybe<Block>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  bm_type?: Maybe<Scalars['Int']>;
  /** The product brand. */
  brand?: Maybe<Brand>;
  bundle_packs: Array<Maybe<BundlePack>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cables_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cables_length?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cables_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_acc_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_memory_cardslot?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_memory_internal?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_package_components?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_ports?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cam_shooting_contin_shooting?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_acc_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_battery?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_digital_camera_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_features_primary_camera?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_features_secondary?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_flash?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_focus_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_maximum_aperture?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_maximum_focal_length?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_minimum_aperture?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_lens_minimum_focal_length?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_megapixel?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_optical_zoom?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_screen_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_touch_screen?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_usb?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  camera_wifi?: Maybe<Scalars['Int']>;
  /** The relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled. */
  canonical_url?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  car_acc_type?: Maybe<Scalars['Int']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chair_height_djustability?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chair_maximum_height?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chargers_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chargers_output?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  chargers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  clocks_built_in_alarm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  clocks_clock_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  clocks_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_grinder_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_bar_pressure?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_beverage_form?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_cups?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_grinder?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  coffee_maker_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  comp_lap_accessories_compitable_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  comp_lap_accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  connector_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  connector_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_console_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_hard_disk_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_hdmi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_included_games?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  consoles_number_of_controllers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_fan?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_flames?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_grill?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_holders_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_ignition?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_safety?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooker_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_filter_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_number_of_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_remote_control_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cooler_wheels_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  copy_resolution?: Maybe<Scalars['String']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_compatible_with_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_compatible_with_model?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  cover_type?: Maybe<Scalars['Int']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  custom_label_1?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  custom_label_2?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  custom_pages_ids?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_automatic_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deep_fryers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  delivery_time?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  delivery_time_inside_cairo_giz?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dental_care_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_form?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  deodorants_type?: Maybe<Scalars['Int']>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_half_load?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_number_of_programs?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_sanitization?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dishwasher_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_loading_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_rpm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_steam_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dryer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dvr_channel_number?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  dvr_input?: Maybe<Scalars['String']>;
  /** Product Short Dynamic Link */
  dynamic_link?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_light_color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_outlet?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  electrical_watt?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  express_shipping_time?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_age_group?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_age_range?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_color?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_decor_style?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_digital_zoom?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_display_technology?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_finish?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_gemstone?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_gender?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_health_concern?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_ingredients?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_is_assembly_required?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_material?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_maximum_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_megapixels?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_minimum_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_model?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_number_of_licenses?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_operating_system?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_optical_zoom?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_package_quantity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_pattern?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_depth?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_form?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_height?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_length?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_product_width?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_recommended_use?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_scent?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_screen_size?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_shoe_width?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_size?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_storage_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_style?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_system_requirements?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_thread_count?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  facebook_video_game_platform?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_diameter_in_inches?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_net_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_number_of_blades?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  fans_type?: Maybe<Scalars['Int']>;
  featured_bullet_attributes_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_targeted_age_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  feeding_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_cap_lit?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_cap_lit_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_cooling_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_number_of_drawers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  freezer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  game_access_compitable?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  game_accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  game_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gaming_controller_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  gaming_controller_connectivity?: Maybe<Scalars['Int']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_acc_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  grills_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_dryer_power?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_dryer_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_style_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_style_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hair_styler_auto_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hand_tools_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hard_disks_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hardware_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hardware_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  has_deal?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_battery?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_microphone?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_noise_cancellation?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  headphones_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_fan?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_heat_settings?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_number_of_fins?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_overheating_protection?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heaters_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  heavy_item?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  height?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_flames?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_holders_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_ignition?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_power_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_safety?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hobs_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  home_decor_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_air_flow_rate?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_filters?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_motors?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hoods_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_offers?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_plate_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_plate_number_plate?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  hot_plate_power_consumption?: Maybe<Scalars['Int']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ideal_installing_frame_dimensions?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ink_color?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ink_compitable_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  installment_offers?: Maybe<Scalars['String']>;
  /** The product qualified installment plans. */
  installments?: Maybe<InstallmentOutput>;
  /** @deprecated Use the `custom_attributes` field instead. */
  intercome_number_way?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  intercome_video_support?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_base_plate_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_vertical_steam_shot?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  irons_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_air_condition?: Maybe<Scalars['Int']>;
  /** Check if product is a best seller */
  is_best_seller?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  is_component?: Maybe<Scalars['Int']>;
  is_has_rayax?: Maybe<Scalars['Boolean']>;
  is_shown_in_brands_homepage?: Maybe<Scalars['Int']>;
  is_shown_in_categories_homepage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_number_of_speeds?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  juicers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_automatic_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kettle_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  keyboards_water_resistant?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kitchen_supplies_materials?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  kitchen_supplies_type?: Maybe<Scalars['Int']>;
  /** product labels */
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_battery_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_card_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_card_manufacturer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_card_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_graphics_model?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hard_desk_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hard_disk_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hd_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_hdmi_port?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_laptop_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_operating_system?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_core?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_processor_type?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_ram_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_ram_technology?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_screen_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_touch_screen?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  lap_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  length?: Maybe<Scalars['Float']>;
  /** Product Manuals */
  manuals?: Maybe<Array<Maybe<Manual>>>;
  /** Product Manuals Of Both ar_EG And en_US Locals */
  manuals_of_all_locales?: Maybe<Array<Maybe<ManualV2>>>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  marketplace_seller?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  maximum_load?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meat_grinder_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meat_grinder_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  meat_grinder_reverse_speed?: Maybe<Scalars['Int']>;
  /** An array of media gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use `media_gallery` instead.
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  medical_supplies_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  medical_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_card_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_card_speed_class?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_cards_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  memory_cards_type?: Maybe<Scalars['Int']>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_cavity_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_dimension?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_grill?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  microwaves_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mixer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_battery?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_battery_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_camera_video?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_common_model_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_display_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_memory_internal?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_memory_ram?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_network_sim?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_network_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_os?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_os_version?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_processor_chipset?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_processor_cpu?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_screen_size_inch?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_display_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_front_camera?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_rear_camera?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_tab_rear_camera_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_weight?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mob_wifi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  model_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  model_number?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mosquito_insect_killer_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouse_rgb?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouse_sensitivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouses_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouses_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  mouses_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  musical_instrument_type?: Maybe<Scalars['Int']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  network_access_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  network_devices_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  network_number_ports?: Maybe<Scalars['Int']>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  new_from_date?: Maybe<Scalars['String']>;
  /** The end date for new product listings. */
  new_to_date?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  office_device_type?: Maybe<Scalars['Int']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_cavity_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_fan?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_grill?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ovens_type?: Maybe<Scalars['Int']>;
  /** The product overall rating */
  overallRating: OrverallProductRating;
  /** @deprecated Use the `custom_attributes` field instead. */
  paper_input_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_graphics_card_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_graphics_card_manufacturer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_hard_desk_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_hard_disk_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_operating_system?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_pc_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_core?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_family?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_speed?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_processor_type?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_ram_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  pc_ram_technology?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  perfumes_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  phone_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_ports?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_bank_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_consumption?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_tools_power_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  power_tools_type?: Maybe<Scalars['Int']>;
  /**
   * Indicates the price of an item.
   * @deprecated Use `price_range` for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** The range of prices for the product */
  price_range: PriceRange;
  /** An array of `TierPrice` objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  print_quality?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  print_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  print_speed_black?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_accessories_compitable_with?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_accessories_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_compitable_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_memory?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_output_capacity?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printer_processor?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  printers_functions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_brand_id?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_color?: Maybe<Scalars['Int']>;
  product_featured_attributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of `ProductLinks` objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  product_offer_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_aspect_ratio?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_hdmi_port?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_resolution?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  projectors_vga_port?: Maybe<Scalars['Int']>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** @deprecated Use the `custom_attributes` field instead. */
  receivers_hd_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  receivers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  receivers_wifi?: Maybe<Scalars['Int']>;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect. */
  redirect_code: Scalars['Int'];
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_cap_lit?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_cap_lit_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_cooling_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_doors?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_freezer_position?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_lcd?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_number_of_shelves?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_refrigerator_style?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ref_water_dispenser?: Maybe<Scalars['Int']>;
  /** An array of products to be displayed in a Related Products block. */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  remote_control_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  report_main_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  report_sub_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  return_refund?: Maybe<Scalars['Int']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_keep_warm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  rice_cookers_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scale_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scales_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scales_maximum_weight_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scan_resolution?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scanner_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scanner_touch_screen?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  scanner_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_compatible_with_brand?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_protector_compatible_with_model?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  screen_type?: Maybe<Scalars['Int']>;
  /** The product seller. */
  seller?: Maybe<Customer>;
  /** @deprecated Use the `custom_attributes` field instead. */
  seller_note?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_attachment_numbers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_type_of_shaving?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shavers_type_of_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  shop_by_seller?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_target_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  skin_care_type_of_usage?: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sm_non_stick?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sm_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sm_type?: Maybe<Scalars['Int']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_home_device_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_scales_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_accessory_compatible_with?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_accessory_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_accessory_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watch_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_band_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_battery_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_compatible_os?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_compatible_with_os?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_ram?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_screen_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_shape?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_sim?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_target_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_waterproof?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  smart_watches_wifi?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speaker_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_battery?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_bluetooth?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_connectivity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_number_of_unit?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  speakers_waterproof?: Maybe<Scalars['Int']>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date for a product with a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specific_appliance_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specific_appliance_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  specific_appliance_type?: Maybe<Scalars['Int']>;
  /** Attribute names that contains the product specifications */
  specification_attributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sporting_goods_category?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sporting_goods_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  sports_equipment?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  stationery_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steam_mob_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steam_mob_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_automatic_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_dishwasher_safe?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_layers?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  steamers_power_consumption?: Maybe<Scalars['Int']>;
  /** List of product attributes */
  stockQtyTerm?: Maybe<Array<Maybe<ProductStockQty>>>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  /** The product available store locators. */
  store_locators?: Maybe<Array<Maybe<AmsStoreLocator>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_auto_shut_off?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_max_temp?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_min_temp?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_plates_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  straighteners_type_of_usage?: Maybe<Scalars['Int']>;
  /** Product Support Videos */
  support_videos?: Maybe<Array<Maybe<SupportVideo>>>;
  /** The file name of a swatch image. */
  swatch_image?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use `price_tiers` for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_browning?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_defrost_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_number_of_slices?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toasters_timer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_age_target?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_gender?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_power_source?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_remote_control?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  toys_vehicles_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_acc_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_built_in_receiver?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_connectivity_usb?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_curved?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_dimentions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_hd_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_hdmi?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_monitor_display_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_monitor_display_size_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_refresh_rate?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_screen_resolution?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_screen_technology?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  tv_smart_connection?: Maybe<Scalars['Int']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use `__typename` instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  ups_power_capacity?: Maybe<Scalars['Int']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  usb_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_dust_bag_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_dust_bag_capacity_filter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_dust_bag_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_power_consumption?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vac_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_fuel_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_usage?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vehicles_year_model?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vg_game_name?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  vg_game_platform?: Maybe<Scalars['Int']>;
  /** The visibility of a product */
  visibility: UpdateSellerProductVisibilityEnum;
  /** @deprecated Use the `custom_attributes` field instead. */
  wall_mount_adjustable?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wall_mount_size_compatability?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  warranty?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_band_material?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_diameter_size?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_display_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_shape?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_targeted_group?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  watches_water_resistant?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_filter_cartridges?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_filter_cartridges_stages?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_filter_or_cartridges?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_inner_tank?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  water_heater_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_cold_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_hot_water_tank_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_loading_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_number_of_taps?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_refrigerator_included?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wd_water_temperature_type?: Maybe<Scalars['Int']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
  /** @deprecated Use the `custom_attributes` field instead. */
  width?: Maybe<Scalars['Float']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_design?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_digital_display?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_dimensions?: Maybe<Scalars['String']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_dryer?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_dryer_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_inverter?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_loading_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_rpm?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_steam_function?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  wm_type?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  yogurt_maker_capacity?: Maybe<Scalars['Int']>;
  /** @deprecated Use the `custom_attributes` field instead. */
  yogurt_maker_power_consumption?: Maybe<Scalars['Int']>;
};


/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export type VirtualProductAttributesArgs = {
  groupCode?: Maybe<Scalars['String']>;
};


/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export type VirtualProductInstallmentsArgs = {
  corporateId?: Maybe<Scalars['Int']>;
};


/** Defines a virtual product, which is a non-tangible product that does not require shipping and is not kept in inventory. */
export type VirtualProductReviewsArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

/** Defines a single product to add to the cart. */
export type VirtualProductCartItemInput = {
  /** An array that defines customizable options for the product. */
  customizable_options?: Maybe<Array<Maybe<CustomizableOptionInput>>>;
  /** An object containing the `sku`, `quantity`, and other relevant information about the product. */
  data: CartItemInput;
};

/** Contains a virtual product wish list item. */
export type VirtualWishlistItem = WishlistItemInterface & {
  __typename?: 'VirtualWishlistItem';
  /** The date and time the item was added to the wish list. */
  added_at: Scalars['String'];
  /** Custom options selected for the wish list item. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
};

/** Deprecated. It should not be used on the storefront. Contains information about a website. */
export type Website = {
  __typename?: 'Website';
  /**
   * A code assigned to the website to identify it.
   * @deprecated The field should not be used on the storefront.
   */
  code?: Maybe<Scalars['String']>;
  /**
   * The default group ID of the website.
   * @deprecated The field should not be used on the storefront.
   */
  default_group_id?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the website.
   * @deprecated The field should not be used on the storefront.
   */
  id?: Maybe<Scalars['Int']>;
  /**
   * Indicates whether this is the default website.
   * @deprecated The field should not be used on the storefront.
   */
  is_default?: Maybe<Scalars['Boolean']>;
  /**
   * The website name. Websites use this name to identify it easier.
   * @deprecated The field should not be used on the storefront.
   */
  name?: Maybe<Scalars['String']>;
  /**
   * The attribute to use for sorting websites.
   * @deprecated The field should not be used on the storefront.
   */
  sort_order?: Maybe<Scalars['Int']>;
};

export type WheelResult = {
  __typename?: 'WheelResult';
  coupon_code: Scalars['String'];
  id: Scalars['Int'];
};

/** An error encountered while performing operations with WishList. */
export type WishListUserInputError = {
  __typename?: 'WishListUserInputError';
  /** A wish list-specific error code. */
  code: WishListUserInputErrorType;
  /** A localized error message. */
  message: Scalars['String'];
};

/** A list of possible error types. */
export enum WishListUserInputErrorType {
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  Undefined = 'UNDEFINED'
}

/** Contains a customer wish list. */
export type Wishlist = {
  __typename?: 'Wishlist';
  /** The unique ID for a `Wishlist` object. */
  id?: Maybe<Scalars['ID']>;
  /** @deprecated Use the `items_v2` field instead. */
  items?: Maybe<Array<Maybe<WishlistItem>>>;
  /** The number of items in the wish list. */
  items_count?: Maybe<Scalars['Int']>;
  /** An array of items in the customer's wish list. */
  items_v2?: Maybe<WishlistItems>;
  /** An encrypted code that Magento uses to link to the wish list. */
  sharing_code?: Maybe<Scalars['String']>;
  /** The time of the last modification to the wish list. */
  updated_at?: Maybe<Scalars['String']>;
};


/** Contains a customer wish list. */
export type WishlistItemsArgs = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};


/** Contains a customer wish list. */
export type WishlistItems_V2Args = {
  currentPage?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

/** Contains details about errors encountered when a customer added wish list items to the cart. */
export type WishlistCartUserInputError = {
  __typename?: 'WishlistCartUserInputError';
  /** An error code that describes the error encountered. */
  code: WishlistCartUserInputErrorType;
  /** A localized error message. */
  message: Scalars['String'];
  /** The unique ID of the `Wishlist` object containing an error. */
  wishlistId: Scalars['ID'];
  /** The unique ID of the wish list item containing an error. */
  wishlistItemId: Scalars['ID'];
};

/** A list of possible error types. */
export enum WishlistCartUserInputErrorType {
  InsufficientStock = 'INSUFFICIENT_STOCK',
  NotSalable = 'NOT_SALABLE',
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  Undefined = 'UNDEFINED'
}

/** Contains details about a wish list item. */
export type WishlistItem = {
  __typename?: 'WishlistItem';
  /** The time when the customer added the item to the wish list. */
  added_at?: Maybe<Scalars['String']>;
  /** The customer's comment about this item. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItem` object. */
  id?: Maybe<Scalars['Int']>;
  /** Details about the wish list item. */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item */
  qty?: Maybe<Scalars['Float']>;
};

/** Defines the items to add to a wish list. */
export type WishlistItemInput = {
  /** An array of options that the customer entered. */
  entered_options?: Maybe<Array<Maybe<EnteredOptionInput>>>;
  /** For complex product types, the SKU of the parent product. */
  parent_sku?: Maybe<Scalars['String']>;
  /** The amount or number of items to add. */
  quantity: Scalars['Float'];
  /** An array of strings corresponding to options the customer selected. */
  selected_options?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The SKU of the product to add. For complex product types, specify the child product SKU. */
  sku: Scalars['String'];
};

/** The interface for wish list items. */
export type WishlistItemInterface = {
  /** The date and time the item was added to the wish list. */
  added_at: Scalars['String'];
  /** Custom options selected for the wish list item. */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item. */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object. */
  id: Scalars['ID'];
  /** Product details of the wish list item. */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item. */
  quantity: Scalars['Float'];
};

/** Defines updates to items in a wish list. */
export type WishlistItemUpdateInput = {
  /** Customer-entered comments about the item. */
  description?: Maybe<Scalars['String']>;
  /** An array of options that the customer entered. */
  entered_options?: Maybe<Array<Maybe<EnteredOptionInput>>>;
  /** The new amount or number of this item. */
  quantity?: Maybe<Scalars['Float']>;
  /** An array of strings corresponding to options the customer selected. */
  selected_options?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The unique ID for a `WishlistItemInterface` object. */
  wishlist_item_id: Scalars['ID'];
};

/** Contains an array of items in a wish list. */
export type WishlistItems = {
  __typename?: 'WishlistItems';
  /** A list of items in the wish list. */
  items: Array<Maybe<WishlistItemInterface>>;
  /** Contains pagination metadata. */
  page_info?: Maybe<SearchResultPageInfo>;
};

/** Deprecated: Use the `Wishlist` type instead. */
export type WishlistOutput = {
  __typename?: 'WishlistOutput';
  /**
   * An array of items in the customer's wish list
   * @deprecated Use the `Wishlist.items` field instead.
   */
  items?: Maybe<Array<Maybe<WishlistItem>>>;
  /**
   * The number of items in the wish list.
   * @deprecated Use the `Wishlist.items_count` field instead.
   */
  items_count?: Maybe<Scalars['Int']>;
  /**
   * When multiple wish lists are enabled, the name the customer assigns to the wishlist.
   * @deprecated This field is related to Commerce functionality and is always `null` in Open Source.
   */
  name?: Maybe<Scalars['String']>;
  /**
   * An encrypted code that links to the wish list.
   * @deprecated Use the `Wishlist.sharing_code` field instead.
   */
  sharing_code?: Maybe<Scalars['String']>;
  /**
   * The time of the last modification to the wish list.
   * @deprecated Use the `Wishlist.updated_at` field instead.
   */
  updated_at?: Maybe<Scalars['String']>;
};

/** Assigns a specific `cart_id` to the empty cart. */
export type CreateEmptyCartInput = {
  /** The ID to assign to the cart. */
  cart_id?: Maybe<Scalars['String']>;
};

export type StoreCustomLogs = {
  filename: Scalars['String'];
  message: Scalars['String'];
  source?: Maybe<Scalars['String']>;
};
